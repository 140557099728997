import React from 'react';
import io from 'socket.io-client';
import SocketEvents from 'constants/socket-events';
import { PresenceStatusType, SocketState, DeviceStatus, SocketDisconnectReason } from 'constants/enums';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { actionCreators as deviceActionCreators } from 'state/devices/actions';
import { isAuthenticated, getAccessToken, getUserId } from 'infrastructure/auth';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { fetchUserPresence, userPresenceUpdateSucceeded } from 'state/userPresence/actions';
import { APP_CONFIG, AmWellWebClientType, AmWellAppType } from 'constants/global-variables';
import { findDeviceById, getStorage } from 'infrastructure/helpers/commonHelpers';
import { SocketContext } from 'io-client/SocketContext';

const findRoomById = (arr, id) =>
	arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		if (item.roomId === id) {
			return item;
		}
		if (item.subOptions) {
			return findRoomById(item.subOptions, id);
		}
		return null;
	}, null);

class Socket extends React.Component {
	constructor(props) {
		super(props);

		this.setSocket();
	}

	socketState = SocketState.CONNECTED;

	clientInfo = null;

	connectPromise = new Promise(resolve => {
		this.connectResolveCallback = resolve;
	});

	awaitAuthorization = new Promise(resolve => {
		this.authResolveCallback = resolve;
	});

	setSocket = () => {
		const signalingUrl = `${
			process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? process.env.REACT_APP_SIGNALING_URL : window.__env__.REACT_APP_SIGNALING_URL
		}amwell`;

		this._socket = io(signalingUrl, {
			secure: true,
			transports: ['websocket'],
			autoConnect: false,
		});

		Object.assign(this._socket, {
			emitWithPromise: this.emitWithPromise,
			emitWithPromiseTimeout: this.emitWithPromiseTimeout,
			doConnect: this.connect,
			doDisconnect: this.disconnect,
			awaitAuthorization: this.awaitAuthorization,
		});
		this.initSocketListeners();

		this.connect();
	};

	reAuthorize = () => {
		const myClientInfo = {
			token: getAccessToken(),
			clearConferences: false,
			clientType: AmWellWebClientType,
			appType: AmWellAppType,
			versionName: APP_CONFIG.buildNumber,
			oldSocketId: this._socket.id,
			incomingCallsDisabled: this.props.shouldDisableIncomingCalls(),
		};

		this.clientInfo = myClientInfo;
		this._socket.emit(SocketEvents.Client.AUTHORIZE, myClientInfo, this.handleUserPresence);
	};

	connect = async () => {
		// @ts-ignore
		if (!isAuthenticated() || (this._socket.connected && !this.clientInfo)) {
			return;
		}

		// @ts-ignore
		if (this._socket.connected && this.clientInfo.token !== getAccessToken()) {
			this.reAuthorize();
			return;
		}

		this._socket.connect();
		await this.connectPromise;
	};

	disconnect = () => {
		if (this._socket.connected) {
			this._socket.disconnect();

			this.connectPromise = new Promise(resolve => {
				this.connectResolveCallback = resolve;
			});

			this.awaitAuthorization = new Promise(resolve => {
				this.authResolveCallback = resolve;
			});

			Object.assign(this._socket, { awaitAuthorization: this.awaitAuthorization });
		}
	};

	isCallView = () => {
		const pathName = window.location.pathname.toString().toLowerCase();
		return pathName.includes('talk-to-patient') || pathName.includes('view-patient');
	};

	initSocketListeners = () => {
		const { _socket } = this;
		let myClientInfo = null;
		let mySocketId = null;

		_socket.on(SocketEvents.Client.ON_CONNECT, () => {
			this.connectResolveCallback();
			// change socket state to SocketState.CONNECTED only if it's from re-connection
			if (this.socketState.type !== SocketState.CONNECTED.type) {
				this.changeSocketState(SocketState.CONNECTED);
			}

			myClientInfo = {
				token: getAccessToken(),
				clearConferences: false,
				clientType: AmWellWebClientType,
				appType: AmWellAppType,
				versionName: APP_CONFIG.buildNumber,
				oldSocketId: mySocketId,
				incomingCallsDisabled: this.props.shouldDisableIncomingCalls(),
			};

			mySocketId = _socket.id;
			this.clientInfo = myClientInfo;
			_socket.emit(SocketEvents.Client.AUTHORIZE, myClientInfo, this.handleUserPresence);
		});

		_socket.on(SocketEvents.Client.ON_AUTHENTICATED, () => {
			this.authResolveCallback();
		});

		_socket.on(SocketEvents.Client.ON_DISCONNECT, reason => {
			if (reason === SocketDisconnectReason.SERVER_DISCONNECT) {
				// the disconnection was initiated by the server, you need to reconnect manually
				_socket.connect();
			} else if (reason === SocketDisconnectReason.CLIENT_DISCONNECT) {
				// do not notify for socket disconnect if disconnect is initiated from the client
				return;
			}

			this.changeSocketState(SocketState.DISCONNECTED);
		});

		_socket.on(SocketEvents.Client.ON_RECONNECTING, () => {
			this.changeSocketState(SocketState.RECONNECTING);
		});

		_socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}

			this.setStatusDevice(_data.helloDeviceId, false);
		});

		_socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}
			this.setStatusDevice(_data.helloDeviceId, true);
		});

		_socket.on(SocketEvents.Client.ON_UPDATED_USER_PRESENCE, data => {
			this.props.userPresenceUpdateSucceeded(getUserId(), data.customMessage, data.presenceStatusTypeId);
		});

		_socket.on(SocketEvents.HelloDevice.ON_UPDATE, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}
			this.setStatusDevice(_data.id, _data.status);
		});

		_socket.on('error', function(err) {
			console.error(`Socket.IO error: ${err}`);
		});

		_socket.on(SocketEvents.User.NOTIFICATIONS_UPDATED, () => {
			this.props.fetchNotificationsCounter();
		});

		_socket.on(SocketEvents.User.ON_PASSWORD_CHANGED, () => {
			window.location.href = '/logout';
		});

		_socket.on(SocketEvents.Team.ON_TEAM_CHANNEL_NAME_CHANGED, _data => {
			if (!this.props.organization.treeData.tree) {
				return;
			}
			this.setRoomName(_data.channelId, _data.channelName);
		});
		_socket.on(SocketEvents.HelloDevice.ON_CALL_STATE_CHANGED, data => {
			if (this.isCallView()) {
				return;
			}
			if (data.activeConferences.length > 0) {
				this.props.deviceActions.setBusyDevice(data.deviceId);
			} else {
				this.props.deviceActions.removeBusyDevice(data.deviceId);
			}
		});
	};

	setStatusDevice = (deviceId, isOnline) => {
		const newTree = JSON.parse(JSON.stringify(this.props.organization.treeData.tree));
		const room = findDeviceById(newTree, deviceId);

		if (room) {
			room.status = isOnline ? DeviceStatus.ONLINE : DeviceStatus.OFFLINE;
			this.props.organizationActions.setTreeData({
				tree: newTree,
				preSelected: this.props.organization.treeData.preSelected,
			});
		}
	};

	setRoomName = (roomId, roomName) => {
		const newTree = JSON.parse(JSON.stringify(this.props.organization.treeData.tree));
		const room = findRoomById(newTree, roomId);

		if (room) {
			room.name = roomName;
			this.props.organizationActions.setTreeData({
				tree: newTree,
				preSelected: this.props.organization.treeData.preSelected,
			});
		}
	};

	handleUserPresence = data => {
		if (data && data.userSocketsLength === 1) {
			const presenceStatusTypeId = getStorage().getItem('presenceStatusTypeId');
			const notFirstConnect = sessionStorage.getItem('notFirstConnect');

			if (presenceStatusTypeId && notFirstConnect) {
				const a = JSON.parse(presenceStatusTypeId);

				this.changeUserPresence(a);
			} else {
				this.changeUserPresence(PresenceStatusType.AVAILABLE);
				if (presenceStatusTypeId) {
					getStorage().removeItem('presenceStatusTypeId');
				}
			}
			sessionStorage.setItem('notFirstConnect', 'true');
		} else {
			this.props.fetchUserPresence();
		}
	};

	changeUserPresence = presenceStatusTypeId => {
		const data = {
			userId: getUserId(),
			presenceStatusTypeId: presenceStatusTypeId,
			customMessage: null,
		};
		this._socket.emit(SocketEvents.Client.UPDATE_USER_PRESENCE, data, _ack => {
			this.props.fetchUserPresence();
		});
	};

	emitWithPromise = (event, data) => {
		return new Promise((resolve, reject) => {
			this._socket.emit(event, data, resolve);
		});
	};

	emitWithPromiseTimeout = (event, data, time) => {
		const timeout = new Promise((resolve, reject) => setTimeout(() => reject(new Error('Timeout')), time));

		const socketEmitPromise = new Promise((resolve, reject) => {
			this._socket.emit(event, data, resolve);
		});

		return Promise.race([timeout, socketEmitPromise]);
	};

	/**
	 * @param {{ type: number, message: string }} state
	 */
	changeSocketState = state => {
		this.socketState = state;
		this.props.onConnectionStateChange(state);
	};

	render() {
		return <SocketContext.Provider value={this._socket}>{this.props.children}</SocketContext.Provider>;
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		deviceActions: bindActionCreators(deviceActionCreators, dispatch),
		fetchNotificationsCounter: () => dispatch(fetchNotificationCounter()),
		fetchUserPresence: () => dispatch(fetchUserPresence()),
		userPresenceUpdateSucceeded: (userId, customMessage, presenceStatusTypeId) =>
			dispatch(userPresenceUpdateSucceeded(userId, customMessage, presenceStatusTypeId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);

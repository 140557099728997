import React, { useState } from 'react';
import { AiAlertActivityType, AlertTypes, MeasurementActivityTypes } from 'constants/enums';
import classNames from 'classnames';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { addAlertActivity } from 'api/alerts';
import Alert from 'components/Alert';
import { healthCareCdnUrl } from 'constants/global-variables';

const WarningDetection = props => {
	const [alertType, setAlertType] = useState(null);
	const [messageContent, setMessageContent] = useState('');
	const [error, setError] = useState(null);
	const monitoringIconLink = `${healthCareCdnUrl}monitoring/`;

	const onSubmit = async () => {
		if (!messageContent || messageContent.trim() === '') {
			return;
		}
		const params = {
			alertId: props.warningObject?.alertId,
			activityType: alertType,
			isAiAlert: props.warningObject?.isAiAlert,
			comment: messageContent,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		setAlertType(null);
		setMessageContent('');
		props.onAlertCloseClick();
	};

	const onDiscardClick = () => {
		setAlertType(null);
		setMessageContent('');
	};

	return (
		<>
			<div
				className={classNames(
					!props.isFeedExpanded && props.numberOfFeeds !== 1 ? 'detection-wrapper' : 'detection-wrapper wrapper-top',
					props.warningObject?.value ? 'show' : ''
				)}>
				<div className={classNames('alert-body fall-alert-body', props.alertType || '')}>
					<div className='alert-content'>
						<div>
							<i className='material-icons'>{props.alertType === AlertTypes.SUCCESS ? 'check_circle_outline' : 'error_outline'}</i>
						</div>
						<div>
							<p>{props.warningObject?.text}</p>
						</div>
						<div>
							<i className='material-icons cursor-pointer close-alert' onClick={props.onAlertCloseClick}>
								close
							</i>
						</div>
					</div>
					{(props.isFeedExpanded || props.numberOfFeeds === 1) && (
						<div className='alert-feed-sub-option'>
							<div className='alert-feed-sub-options-wrapper'>
								<div
									className='position-relative flex column-direction flex-align-center flex-justify-center'
									onClick={() => setAlertType(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT)}>
									<img src={`${monitoringIconLink}video-feed/fake-alert.svg`} alt='icon' />
									<p className='--red-color'>False Alert</p>
								</div>

								<div
									className='position-relative flex column-direction flex-align-center flex-justify-center'
									onClick={() => setAlertType(MeasurementActivityTypes.ACKNOWLEDGED)}>
									<img src={`${monitoringIconLink}video-feed/acknowledge.svg`} alt='icon' />
									<p className='--green-color'>Acknowledge</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{alertType && (
				<Modal
					display={true}
					onModalSubmit={onSubmit}
					primaryButtonLabel='Save Comment'
					closeButtonText='Discard'
					position='center'
					onModalClose={onDiscardClick}
					shouldSubmitOnEnter={false}
					isSubmitDisabled={!messageContent || messageContent.trim() === ''}
					submitImgIcon={`${healthCareCdnUrl}forward-arrow-white.svg`}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
					className='ai-event-modal'
					primaryButtonLoading={props.isManualReportLoading}>
					<Form height={300} className='create-appointment-form send-group-message' onSubmit={event => event.preventDefault()}>
						<h3>Add Comment</h3>
						<span className='red-error'>
							{alertType === AiAlertActivityType.AI_FAKE_ALERT
								? 'This alert will be snoozed for 5 minutes.'
								: 'This alert will be turned off for 15 minutes while the patient gets help.'}
						</span>
						<p>Message</p>
						<textarea maxLength={350} rows={5} value={messageContent} onChange={event => setMessageContent(event.target.value)} name='message' />
					</Form>
				</Modal>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default WarningDetection;

import React, { useState, useEffect, useRef } from 'react';
import hark from 'hark';
import { HarkEvents, MediaDevicePermissionsState } from 'constants/enums';

const AudioMeter = props => {
	const [volume, setVolume] = useState(0);

	const streamRef = useRef(null);

	const mountedRef = useRef(false);

	const volumeThreshold = {
		MAX: 50,
		MIN: 3,
	};

	const lines = {
		FIRST: 1,
		SECOND: 1.5,
		THIRD: 2,
		FOURTH: 2.5,
		FIFTH: 3,
		SIXTH: 3.5,
	};

	let harkIn = useRef(null);

	const getVolume = line => {
		const newVol = volume / 1.5;
		if (newVol > volumeThreshold.MAX) {
			return volumeThreshold.MAX;
		}
		if (newVol < volumeThreshold.MIN) {
			return volumeThreshold.MIN;
		}
		return newVol / line;
	};

	const handleVolumeChange = (v, threshold) => {
		if (v < threshold) {
			return;
		}
		const calcVolume = Math.round(((v - threshold) * -100) / threshold);
		setVolume(calcVolume);
	};

	const setMicVolume = stream => {
		if (harkIn.current) {
			harkIn.current.stop();
			harkIn.current = null;
		}
		harkIn.current = hark(stream, {});
		harkIn.current.on(HarkEvents.STOPPED_SPEAKING, () => {
			setVolume(0);
		});
		harkIn.current.on(HarkEvents.VOLUME_CHANGE, handleVolumeChange);
	};

	const stopActiveTracks = () => {
		if (!streamRef.current) return;
		const micTracks = streamRef.current.getTracks();
		micTracks.forEach(track => track.stop());
	};

	useEffect(() => {
		const unMountFunction = () => {
			stopActiveTracks();
			if (harkIn.current) {
				harkIn.current.off(HarkEvents.STOPPED_SPEAKING);
				harkIn.current.off(HarkEvents.VOLUME_CHANGE, handleVolumeChange);
				harkIn.current = null;
			}
		};
		mountedRef.current = true;
		const getStream = () => {
			navigator.mediaDevices
				.getUserMedia({
					audio: {
						deviceId: {
							exact: props.activeMicId,
						},
					},
				})
				.then(stream => {
					setMicVolume(stream);
					streamRef.current = stream;
					if (!mountedRef.current) {
						unMountFunction();
					}
				});
		};

		if (props.micPermissionState === MediaDevicePermissionsState.GRANTED) {
			getStream();
		}

		return () => {
			mountedRef.current = false;
			unMountFunction();
		};
	}, [props.activeMicId, props.micPermissionState]);

	const audioMeterAnimationCount = 4;

	const getAudioMeterAnimation = () => (
		<>
			<div style={{ height: `${getVolume(lines.SIXTH)}px` }} />
			<div style={{ height: `${getVolume(lines.FIFTH)}px` }} />
			<div style={{ height: `${getVolume(lines.FOURTH)}px` }} />
			<div style={{ height: `${getVolume(lines.THIRD)}px` }} />
			<div style={{ height: `${getVolume(lines.SECOND)}px` }} />
			<div style={{ height: `${getVolume(lines.FIRST)}px` }} />
			<div style={{ height: `${getVolume(lines.SECOND)}px` }} />
			<div style={{ height: `${getVolume(lines.THIRD)}px` }} />
			<div style={{ height: `${getVolume(lines.FOURTH)}px` }} />
			<div style={{ height: `${getVolume(lines.FIFTH)}px` }} />
			<div style={{ height: `${getVolume(lines.SIXTH)}px` }} />
		</>
	);

	return (
		<div className='media-controls' style={{ height: '20px' }}>
			{[...Array(audioMeterAnimationCount)].map(getAudioMeterAnimation)}
		</div>
	);
};

export default AudioMeter;

// #region npm package imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import { TrackType, ErrorCanceled, ErrorDeviceBusy, ErrorDeviceNotFound } from '@solaborate/webrtc/lib/Tracks';
import { TrackAdded } from '@solaborate/webrtc/lib/Connection';
import { v4 } from 'uuid';
import { SocketContext } from 'io-client/SocketContext';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { isTablet } from 'react-device-detect';
import { Modal, Form } from 'components';
import InviteParticipantsModal from 'calls/components/calls/modals/InviteParticipantsModal';
// #endregion

// #region state imports
import { fetchNotificationCounter } from 'state/notifications/actions';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
// #endregion

// #region infrastructure imports
import { getCurrentHealthSystemInfo, isNullOrUndefined, snooze } from 'infrastructure/helpers/commonHelpers';
// #endregion

// #region constants imports
import {
	CallTypes,
	ObjectType,
	ConferenceEndReason,
	ParticipantRemoveReason,
	StreamError,
	ConferenceAction,
	CameraType,
	MediaDeviceKinds,
	ParticipantState,
	UserRoles,
} from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { amwellIconLink } from 'constants/global-variables';
// #endregion

// #region components imports
import Grid from 'components/Grid';
import CallEndReason from 'calls/components/calls/CallEndReason';
import ActiveConference from 'calls/components/calls/ActiveConference';
import AnotherNursePickedUp from 'calls/components/calls/AnotherNursePickedUp';
import StreamPermissions from 'calls/components/calls/StreamPermissions';
import Loader from 'components/Loader';

import CallMain from 'calls/components/calls/CallMain';
import CallAside from 'calls/components/calls/CallAside';
import CallFooter from 'calls/components/calls/CallFooter';
import ParticipantAudio from 'calls/components/calls/ParticipantAudio';
import CallInviteMenu from 'calls/components/calls/CallInviteMenu';
import TechCheck from 'calls/components/TechCheck';
// #endregion

// #region scripts imports
import Conference from 'calls/scripts/conference';
import ConferenceEvent from 'calls/scripts/conference-events.enum';
import PatientParticipant from 'calls/scripts/patient-participant';
import { HasActiveConferenceError, WrongParticipantState, ConferenceError, NullConference } from 'calls/scripts/conference-errors';
import { stopOutgoingCallSound, outGoingCallSound } from 'calls/scripts/call-sounds';
// #endregion

// #region Do not remove, it is used to define JSDoc param type
import Participant from 'calls/scripts/participant';
// #endregion

import ConferenceParticipant from 'calls/base/ConferenceParticipant';
import PeerStats from 'containers/PeerStats';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import WindowMessages from 'constants/window-messages';
import { getUserRole, signoutSilent } from 'infrastructure/auth';
import { getMediaDeviceUId, isMobileOrTabletDevice, storeDefaultMediaDevices } from 'calls/helpers/conference-utils';

class CallNewRefactored extends Component {
	constructor(props, socket) {
		super(props);
		this.socket = socket;
		this.state = {
			callStarted: false,
			callEnded: false,
			callStartedTime: null,

			activeParticipant: null,
			activeTrackType: TrackType.VIDEO,

			// TODO maybe those 3 can be on single component
			hasActiveConference: false,
			anotherNursePickedUp: false,
			conferenceEndReason: null,
			// TODO stream permission can set some permission queries to listen for stream permission changes
			// navigator.permissions.query({name:'camera'}).then(({state})=>{})
			// navigator.permissions.query({name:'microphone'}).then(({state})=>{})
			// navigator.permissions.query({name:'speaker'}).then(({state})=>{})
			streamPermission: null,

			healthSystemPath: null,
			disableButtons: false,

			isGridView: false,
			isInviteVisible: false,
			isRoomLocked: false,
			isCamSettingsActive: false,
			showRightColumn: true,
			showRoomLockedModal: false,
			shouldHideFooterControls: false,
			newOwner: '',

			isMaximizedFrame: true,
			isFrameWindow: false,
			pinnedParticipantId: '',
			conferenceName: '',
			selectedMediaDevices: props.location.state.selectedMediaDevices || {
				[MediaDeviceKinds.AUDIO_INPUT]: null,
				[MediaDeviceKinds.AUDIO_OUTPUT]: null,
				[MediaDeviceKinds.VIDEO_INPUT]: null,
			},
			isChangeOwnerModalOpen: false,
			hasSoundPermission: true,
			isDeviceBeingMonitoredModalOpen: false,
			isPrivacyButtonEnabled: false,
			isConnectionLost: false,
			selectedParticipantToRemove: null,
			isTechCheckCamLoading: true,
		};

		this.wasInGridViewBeforePinningFeed = false;
		this.endedConferenceLink = null;
		this.playErrorPromiseResolveCallback = null;
		this.playErrorPromise = new Promise(resolve => {
			this.playErrorPromiseResolveCallback = resolve;
		});

		// bind listeners
		this.bindSocketListeners();
		this.bindWindowListeners();
	}

	ownerTimeout = null;

	prevIsMobileDevice = null;

	async componentDidMount() {
		this.props.fetchNotificationCounter();
		if (!this.props.location.state?.conferenceAction) {
			const link = this.props.location.state?.conferenceLink;
			if (link) {
				this.props.history.replace(link.replace(/^.*\/\/[^/]+/, ''));
			} else {
				this.handleErrors(NullConference);
			}
			if (getUserRole() === UserRoles.NURSE) {
				this.props.history.push(this.props.history.location.state?.from || '/');
			}
			return;
		}

		const { conferenceAction, callType, patientId, roomName, incomingConferenceInfo, from } = this.props.location.state;
		let conferenceLink;
		if (incomingConferenceInfo?.conferenceLink) {
			conferenceLink = incomingConferenceInfo.conferenceLink;
		}

		this.props.history.replace({ state: { conferenceLink, from } });

		try {
			if (conferenceAction === ConferenceAction.START) {
				const participants = [new ConferenceParticipant(v4(), patientId, ObjectType.HELLO_DEVICE)];
				const audioOutputDevice = this.state.selectedMediaDevices[MediaDeviceKinds.AUDIO_OUTPUT];

				Conference.setAbortConference(false);

				const hasSoundPermission = await outGoingCallSound(audioOutputDevice ? await getMediaDeviceUId(audioOutputDevice) : null);
				if (!hasSoundPermission) {
					await this.setSoundPermissionState({ hasSoundPermission });
					await this.playErrorPromise;
					this.setState({ hasSoundPermission: true });
					await outGoingCallSound(audioOutputDevice ? await getMediaDeviceUId(audioOutputDevice) : null);
				}

				await snooze(3000);
				this.conference = await Conference.start(this.socket, callType, participants, roomName, this.state.selectedMediaDevices, null);
			} else {
				this.conference = await Conference.join(this.socket, incomingConferenceInfo);
			}

			this.setActiveParticipant(this.conference.localParticipant, this.state.activeTrackType);
			if (!this.conference.localParticipant.isInitiator) {
				this.callStarted();
			}
			this.bindConferenceListeners();
			this.bindConferenceParticipantListeners();
			this.setState({
				healthSystemPath: this.getHealthSystemPath(),
				isFrameWindow: this.conference.isFrameWindow,
				conferenceName: this.conference.name,
			});
		} catch (error) {
			this.handleErrors(error);
		}
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		if (this.conference && !this.conference.hasEnded) {
			this.conference.leave();
			this.destroyConference();
		} else if (!this.conference && !this.state.hasActiveConference && !this.state.anotherNursePickedUp) {
			Conference.setAbortConference(true);
		}

		this.unBindWindowListeners();
		this.unBindSocketListeners();
		stopOutgoingCallSound();

		if (this.goBackTimeout) {
			clearTimeout(this.goBackTimeout);
			this.goBackTimeout = null;
		}

		if (this.ownerTimeout) {
			clearTimeout(this.ownerTimeout);
			this.ownerTimeout = null;
		}

		const conferenceLink = this.props.location.state?.conferenceLink;
		if (
			getUserRole() === UserRoles.GUEST &&
			conferenceLink &&
			[ConferenceEndReason.DROPPED, ConferenceEndReason.ABORTED].includes(this.state.conferenceEndReason)
		) {
			this.props.history.replace(conferenceLink.replace(/^.*\/\/[^/]+/, ''));
		}
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = () => {
		const currentIsMobileDevice = window.innerWidth <= 992;
		if (this.prevIsMobileDevice && !currentIsMobileDevice) {
			this.togglePTZ(true);
		}
		this.prevIsMobileDevice = currentIsMobileDevice;
	};

	handleErrors = error => {
		switch (error.constructor) {
			case HasActiveConferenceError: {
				this.setState({ hasActiveConference: true }, () => {
					this.startGoBackTimeout();
				});
				break;
			}
			case WrongParticipantState:
				this.setState({ anotherNursePickedUp: true });
				break;
			case ConferenceError:
			case NullConference:
				this.onConferenceEnded({ reason: ConferenceEndReason.ABORTED });
				break;
			case ErrorCanceled:
				// TODO use navigator.permission.query({name:'camera'}) or microphone to listner for permission granted
				this.setState({ streamPermission: StreamError.PERMISSION_DISMISSED });
				break;
			case ErrorDeviceNotFound:
				// TODO use navigator.permission.query({name:'camera'}) or microphone to listner for permission granted
				this.setState({ streamPermission: StreamError.DEVICE_NOT_FOUND });
				break;
			case ErrorDeviceBusy:
				// TODO use navigator.permission.query({name:'camera'}) or microphone to listner for permission granted
				this.setState({ streamPermission: StreamError.NOT_READABLE_ERROR });
				break;
			default:
		}
		console.debug('[Call] -', error);
	};

	/**
	 * Method used to prevent refresh or tab close on ongoing conference
	 * @param {object} event
	 */
	onBeforeUnload = event => {
		if (!this.conference.hasEnded) {
			event.preventDefault();
			// eslint-disable-next-line no-param-reassign
			event.returnValue = '';
		}
	};

	onUnload = () => {
		if (!this.conference.hasEnded) {
			this.conference.leave();
		}
	};

	/**
	 * @param {{ data: string; }} message
	 */
	onWindowMessage = message => {
		if (message.data === 'IN_CALL') {
			window.removeEventListener('beforeunload', this.onBeforeUnload);

			if (!this.conference.hasEnded) {
				this.conference.leave(ParticipantRemoveReason.IDLE);
			}
			window.location.href = '/logout';
		}
	};

	onToggleFooter = () => {
		if (isMobileOrTabletDevice()) {
			this.setState(prevState => ({ shouldHideFooterControls: !prevState.shouldHideFooterControls }));
		}
	};

	/**
	 * @param {Participant} participant
	 */
	onConferenceNewParticipant = async participant => {
		// TODO move this somewhere else and change logic if needed if there are multiple participants.
		if (participant.constructor === PatientParticipant) {
			participant.remoteTrackController.on(ev => {
				if (ev.type === TrackType.VIDEO || (ev.track && ev.track.type === TrackType.VIDEO)) {
					const newState = {};
					if (ev.constructor === TrackAdded) {
						// Object.assign(newState, { activeParticipant: participant });
						if (this.conference.callType === CallTypes.SECURITYCAM) {
							this.setState({});
						}
						this.setActiveParticipant(participant, this.state.activeTrackType);
					} else {
						Object.assign(participant, { showPTZ: false });
						if (this.conference.localParticipant.localTrackController.tracks.has(TrackType.VIDEO)) {
							// Object.assign(newState, { activeParticipant: this.conference.localParticipant });
							this.setActiveParticipant(this.conference.localParticipant, this.state.activeTrackType);
						}
					}
					this.setState(newState);
				} else if (ev.type === TrackType.AUDIO || (ev.track && ev.track.type === TrackType.AUDIO)) {
					// only needed on security cam to update the button on CallFooter.js
					// on talk to patient the buttons will be updated in Participant.js file
					if (this.conference.callType === CallTypes.SECURITYCAM) {
						this.setState({});
					}
				}
			});

			participant.on(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, () => {
				// only needed on security cam to update the button on CallFooter.js
				// on talk to patient the buttons will be updated in Participant.js file
				if (this.conference.callType === CallTypes.SECURITYCAM) {
					this.setState({});
				}
			});
			if (this.conference.callType !== CallTypes.SECURITYCAM) {
				participant.on(ParticipantEvent.STATE_CHANGED, state => {
					if (participant === this.state.activeParticipant && state === ParticipantState.RECONNECTING.type) {
						this.setActiveParticipant(this.conference.localParticipant, this.state.activeTrackType);
					}
				});
			}
		} else if (this.state.isGridView) {
			this.changeGridVideoTrackConfigs();
		} else {
			this.sendChangeVideoTrackConfigEvent({ substream: 0, temporal: 0 });
		}

		// start the timer when firt participant joins the conference
		if (!this.state.callStartTime) {
			this.callStarted();
			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				const response = await this.conference.checkDeviceIsBeingMonitored(participant.objectId);
				this.setState({
					isDeviceBeingMonitoredModalOpen: !!response,
				});
			}
			return;
		}
		this.setState({});
	};

	onConferenceEnded = ({ reason: conferenceEndReason }) => {
		this.unBindConferenceListeners();
		this.setState({ conferenceEndReason }, () => {
			const { isOriginalOwner } = this.conference.localParticipant;
			this.endedConferenceLink = this.conference.conferenceLink;
			this.destroyConference();
			if (isOriginalOwner) {
				this.startGoBackTimeout();
			}
		});
	};

	onConferenceStateChanged = data => {
		if (data && data.action === ParticipantEvent.REMOVED && data.participantId === this.state.activeParticipant.id) {
			if (data.participantId === this.state.pinnedParticipantId) {
				this.setPinnedFeed('');
			}
			this.chooseDefaultActiveParticipant();
		} else if (data && data.action === ParticipantEvent.OWNER_CHANGED) {
			let name = this.conference.localParticipant.isInitiator ? 'You' : data.newInitiator.name;
			this.onOwnerChanged(name);
		}
		this.setState({});
	};

	onOwnerChanged = name => {
		this.setState({
			newOwner: name,
		});
		this.ownerTimeout = setTimeout(() => {
			this.setState({
				newOwner: '',
			});
		}, 3000);
	};

	onConferenceRemoved = () => {
		this.setState({ conferenceEndReason: ConferenceEndReason.REMOVED }, () => {
			this.destroyConference();
			this.startGoBackTimeout();
		});
	};

	onConnectionStateChanged = ({ disconnected: isConnectionLost }) => {
		this.setState({ isConnectionLost });
	};

	/**
	 * Method used to bind window listeners
	 */
	bindWindowListeners() {
		window.addEventListener('beforeunload', this.onBeforeUnload);

		window.addEventListener('unload', this.onUnload);

		window.addEventListener('message', this.onWindowMessage);

		window.addEventListener('click', this.onToggleFooter);
	}

	/**
	 * Method used to unbind window listeners
	 */
	unBindWindowListeners() {
		window.removeEventListener('beforeunload', this.onBeforeUnload);

		window.removeEventListener('unload', this.onUnload);

		window.removeEventListener('message', this.onWindowMessage);

		window.removeEventListener('click', this.onToggleFooter);
	}

	/**
	 * Method used to bind conference listeners
	 */
	bindConferenceListeners() {
		this.conference.on(ConferenceEvent.ON_NEW_PARTICIPANT, this.onConferenceNewParticipant);

		this.conference.on(ConferenceEvent.ON_ENDED, this.onConferenceEnded);

		this.conference.on(ConferenceEvent.ON_CHANGED_STATE, this.onConferenceStateChanged);

		this.conference.on(ConferenceEvent.ON_REMOVED, this.onConferenceRemoved);

		this.conference.on(ConferenceEvent.ON_CONNECTION_STATE_CHANGED, this.onConnectionStateChanged);
	}

	/**
	 * Method used to unbind conference listeners
	 */
	unBindConferenceListeners() {
		this.conference.off(ConferenceEvent.ON_NEW_PARTICIPANT, this.onConferenceNewParticipant);

		this.conference.off(ConferenceEvent.ON_ENDED, this.onConferenceEnded);

		this.conference.off(ConferenceEvent.ON_CHANGED_STATE, this.onConferenceStateChanged);

		this.conference.off(ConferenceEvent.ON_REMOVED, this.onConferenceRemoved);

		this.conference.off(ConferenceEvent.ON_CONNECTION_STATE_CHANGED, this.onConnectionStateChanged);
	}

	/**
	 * Method used to bind conference participant listeners
	 */
	bindConferenceParticipantListeners = () => {
		this.conference.localParticipant.localTrackController.on(this.onLocalParticipantTrackChanged);
	};

	/**
	 * Method used to unbind conference participant listeners
	 */
	unBindConferenceParticipantListeners = () => {
		this.conference.localParticipant.localTrackController.off(this.onLocalParticipantTrackChanged);
	};

	/**
	 * Method used to bind socket listeners
	 */
	bindSocketListeners = () => {
		if (!this.socket) {
			console.debug('[Call] -', 'Socket null or undefined!');
			return;
		}

		this.socket.on(SocketEvents.Client.ON_CONNECT, this.onSocketConnected);
		this.socket.on(SocketEvents.Client.ON_DISCONNECT, this.onSocketDisconnected);
	};

	/**
	 * Method used to unbind socket listeners
	 */
	unBindSocketListeners = () => {
		if (!this.socket) {
			console.debug('[Call] -', 'Socket null or undefined!');
			return;
		}

		this.socket.off(SocketEvents.Client.ON_CONNECT, this.onSocketConnected);
		this.socket.off(SocketEvents.Client.ON_DISCONNECT, this.onSocketDisconnected);
	};

	/**
	 * Method used to handle socket connected event
	 */
	onSocketConnected = () => {
		this.setState({ disableButtons: false });
	};

	/**
	 * Method used to handle socket disconnected event
	 */
	onSocketDisconnected = () => {
		this.setState({ disableButtons: true });
	};

	/**
	 * Method used to handle call started
	 */
	callStarted = () => {
		stopOutgoingCallSound();
		this.setState({ callStartTime: new Date(), callStarted: true }, () => {
			if (this.state.isFrameWindow) {
				// Post CALL STARTED message when call starts
				window.parent.postMessage(WindowMessages.Call.STARTED, '*');
			}
		});
	};

	destroyConference = () => {
		this.unBindConferenceListeners();
		this.unBindConferenceParticipantListeners();
		this.conference.destroy();
	};

	/**
	 * Method used to end a conference
	 */
	endCall = () => {
		const { isInitiator } = this.conference.localParticipant;

		if (isInitiator && this.conference.participants.size > 1) {
			this.setState({
				isChangeOwnerModalOpen: true,
			});
		} else {
			this.leaveConference();
		}
		if (this.state.isFrameWindow) {
			const participant = [...this.conference.participants.values()][0];
			window.parent.postMessage(
				JSON.stringify({
					event: WindowMessages.Call.ENDED,
					callType: this.conference.callType !== CallTypes.SECURITYCAM ? 'talk to patient' : 'view patient',
					roomSid: this.conference.id,
					participantId: participant.id,
					participantSid: participant.id,
				}),
				'*'
			);
		}
	};

	leaveMeeting = async transferOwnership => {
		if (transferOwnership) {
			const firstParticipantId = [...this.conference.participants.values()].filter(p => p.constructor === Participant)[0].id;
			await this.conference.transferOwnership(firstParticipantId);
		}
		this.leaveConference();
		this.setState({ isChangeOwnerModalOpen: false });
	};

	leaveConference = () => {
		const { isInitiator, isOriginalOwner } = this.conference.localParticipant;
		this.endedConferenceLink = this.conference.conferenceLink;
		this.conference.leave();
		this.setState(
			{
				conferenceEndReason: isInitiator ? ConferenceEndReason.INITIATOR_LEFT : ConferenceEndReason.PARTICIPANT_LEFT,
			},
			() => {
				this.destroyConference();
				if (isOriginalOwner) {
					this.startGoBackTimeout();
				}
			}
		);
	};

	/**
	 * Method used to start a close tab timeout
	 */
	startGoBackTimeout = () => {
		if (this.state.isFrameWindow) {
			signoutSilent();
		}

		this.goBackTimeout = setTimeout(() => {
			clearTimeout(this.goBackTimeout);
			this.goBackTimeout = null;
			if (this.state.isFrameWindow) {
				window.parent.postMessage(WindowMessages.Window.CLOSE, '*');
			} else {
				this.props.history.goBack();
			}
		}, 3000);
	};

	onActiveParticipantTrackChanged = () => {
		const { activeParticipant, activeTrackType } = this.state;

		if (activeParticipant instanceof PatientParticipant) {
			this.togglePTZ(!!activeParticipant.remoteTrackController.tracks.get(TrackType.VIDEO));
		}
		if (activeParticipant.remoteTrackController.tracks.get(activeTrackType)) {
			return;
		}

		const activeTrack =
			activeParticipant.remoteTrackController.tracks.get(TrackType.SCREEN) ?? activeParticipant.remoteTrackController.tracks.get(TrackType.VIDEO);
		if (activeTrack) {
			this.setActiveParticipant(activeParticipant, activeTrack.type);
		}
	};

	/**
	 * Method used to set active participant and his active track type
	 * @param {Participant | LocalParticipant} participant
	 * @param {TrackType} trackType
	 */
	setActiveParticipant = (participant, trackType) => {
		if (this.state.pinnedParticipantId) {
			return;
		}
		const { activeParticipant, activeTrackType, isGridView } = this.state;
		// TODO maybe add a property of activeTrackType to activeParticipant
		if (participant !== activeParticipant || trackType !== activeTrackType) {
			this.setState(
				{
					activeParticipant: participant,
					activeTrackType: trackType,
					conferenceName: participant.cameraType === CameraType.HELLO ? this.conference.name : participant.name,
				},
				() => {
					if (activeParticipant instanceof Participant) {
						activeParticipant.remoteTrackController.off(this.onActiveParticipantTrackChanged);

						if (activeParticipant instanceof PatientParticipant) {
							this.togglePTZ(false);
						}
					}
					if (participant instanceof Participant) {
						participant.remoteTrackController.on(this.onActiveParticipantTrackChanged);

						if (participant instanceof PatientParticipant) {
							this.togglePTZ(!!participant.remoteTrackController.tracks.get(TrackType.VIDEO));
						}
					}
					if (!isGridView) {
						this.sendChangeVideoTrackConfigEvent({ substream: 0, temporal: 0 });
					}
				}
			);
		}
	};

	setPinnedFeed = participantId => {
		return new Promise(resolve => {
			this.setState(
				{
					pinnedParticipantId: participantId,
				},
				() => {
					resolve();
				}
			);
		});
	};

	/**
	 * Method used to toggle PTZ
	 * @param {boolean} [status]
	 */
	togglePTZ = status => {
		// TODO
		// If there are multiple patient participants we have to do it by participantId or by activeParticipant
		// Question: Should PTZ show if our localParticipant is the active participant ?
		const patientParticipant = this.getPatientParticipant(); // participantParticipant = this.state.activeParticipant
		if (patientParticipant) {
			patientParticipant.showPTZ = status ?? !patientParticipant.showPTZ;
		}

		this.setState({});
	};

	shouldShowStateComponents = () => {
		return this.state.hasActiveConference || this.state.anotherNursePickedUp || this.state.conferenceEndReason || this.state.streamPermission;
	};

	// TODO
	// render full screen call state
	// render not full screen call states(only for certain participant)
	renderCallStates = () => {
		if (this.state.hasActiveConference) {
			return <ActiveConference />;
		}

		if (this.state.anotherNursePickedUp) {
			return <AnotherNursePickedUp />;
		}

		if (this.state.conferenceEndReason) {
			return (
				<CallEndReason
					conferenceLink={() => this.props.history.push(new URL(this.endedConferenceLink).pathname)}
					reason={this.state.conferenceEndReason}
					onPatientBusyNurse={this.state.onPatientBusyNurse}
				/>
			);
		}

		if (this.state.streamPermission) {
			return <StreamPermissions reason={this.state.streamPermission} />;
		}

		return undefined;
	};

	/**
	 * Method used to get participant participant
	 */
	getPatientParticipant = () => {
		const patientParticipants = [...this.conference.participants.values()].filter(p => p.constructor === PatientParticipant);
		let patientParticipant;
		if (patientParticipants.length === 1) {
			[patientParticipant] = patientParticipants;
		} else if (this.state.activeParticipant.constructor === PatientParticipant) {
			patientParticipant = this.state.activeParticipant;
		}
		return patientParticipant;
	};

	setSoundPermissionState = state => {
		return new Promise(resolve => {
			this.setState(state, () => {
				resolve();
			});
		});
	};

	getHealthSystemPath = () => {
		const healthSystemInfo = getCurrentHealthSystemInfo();
		if (!healthSystemInfo) {
			return '';
		}

		let healthSystemPath;
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const healthSystem = this.props.organization.allHealthSystems.find(healthSystem => healthSystem.id === currentHealthSystemId);
		if (healthSystem) {
			healthSystemPath = `${healthSystem.name} > `;

			const region = healthSystem.regions.find(region => currentRegionId === region.id);
			if (region) {
				healthSystemPath += region.name;
			}
		}

		return healthSystemPath;
	};

	changeGridVideoTrackConfigs = () => {
		let trackConfig = { substream: 2, temporal: 1 };
		const participantsSize = this.conference.participants.size;
		if (participantsSize > 9) {
			trackConfig = { substream: 0, temporal: 1 };
		} else if (participantsSize > 4) {
			trackConfig = { substream: 1, temporal: 1 };
		}

		this.sendChangeVideoTrackConfigEvent(trackConfig);
	};

	/**
	 * @param {{ substream: number, temporal: number }} trackConfig
	 */
	sendChangeVideoTrackConfigEvent = trackConfig => {
		if (!this.conference.isUsingMediaServer) {
			return;
		}

		const { activeParticipant, isGridView } = this.state;

		const config = {};
		this.conference.participants.forEach(participant => {
			config[participant.id] = {
				[TrackType.VIDEO]: !isGridView && activeParticipant && participant.id === activeParticipant.id ? { substream: 2, temporal: 1 } : trackConfig,
			};
		});

		this.conference.sendChangeStreamConfigEvent(config);
	};

	onGridViewToggled = () => {
		this.setState(prevState => ({
			isGridView: !prevState.isGridView,
			pinnedParticipantId: prevState.isGridView ? prevState.pinnedParticipantId : null,
		}));

		if (!this.state.isGridView) {
			this.sendChangeVideoTrackConfigEvent({ substream: 0, temporal: 0 });
		} else {
			this.changeGridVideoTrackConfigs();
		}
	};

	onLocalParticipantTrackChanged = event => {
		if (event.constructor === TrackAdded && event.track.type === TrackType.SCREEN) {
			event.track.track.addEventListener('ended', () => {
				this.conference.toggleLocalParticipantTrack(TrackType.SCREEN, false);
			});
		}
	};

	chooseDefaultActiveParticipant = () => {
		const participantList = [...this.conference.participants.values()];
		if (!participantList.length) {
			const activeTrackType = this.conference.localParticipant.localTrackController.tracks.has(TrackType.VIDEO) ? TrackType.VIDEO : TrackType.AUDIO;
			this.setActiveParticipant(this.conference.localParticipant, activeTrackType);
			return;
		}

		let activeParticipant = participantList.find(participant => participant.remoteTrackController.tracks.has(TrackType.SCREEN));
		let activeTrackType = TrackType.SCREEN;

		if (!activeParticipant) {
			activeParticipant = participantList.find(participant => participant.objectType === ObjectType.HELLO_DEVICE) || participantList[0];
			activeTrackType =
				activeParticipant.objectType === ObjectType.HELLO_DEVICE || activeParticipant.remoteTrackController.tracks.has(TrackType.VIDEO)
					? TrackType.VIDEO
					: TrackType.AUDIO;
		}
		this.setActiveParticipant(activeParticipant, activeTrackType);
	};

	toggleMaximize = () => {
		this.setState(
			prevState => ({
				isMaximizedFrame: !prevState.isMaximizedFrame,
			}),
			() => {
				window.parent.postMessage(this.state.isMaximizedFrame ? WindowMessages.Window.MINIMIZE : WindowMessages.Window.MAXIMIZE, '*');
			}
		);
	};

	onPinnedFeed = async (participant, trackType) => {
		const { pinnedParticipantId, isGridView, activeTrackType } = this.state;

		if (!pinnedParticipantId) {
			this.wasInGridViewBeforePinningFeed = isGridView;
		}

		if (pinnedParticipantId !== participant.id || activeTrackType !== trackType) {
			await this.setPinnedFeed('');
			this.setActiveParticipant(participant, trackType);
			await this.setPinnedFeed(participant.id);
			if (isGridView) {
				this.onGridViewToggled();
			}
			return;
		}

		await this.setPinnedFeed('');
		if (this.wasInGridViewBeforePinningFeed) {
			this.onGridViewToggled();
		}
	};

	setSelectedMediaDevices = selectedMediaDevices => {
		this.setState(prevState => ({
			selectedMediaDevices: { ...prevState.selectedMediaDevices, ...selectedMediaDevices },
		}));
		storeDefaultMediaDevices(selectedMediaDevices);
	};

	onLeaveMeeting = (event, isButtonClick) => {
		if (isButtonClick) {
			this.leaveMeeting(true);
		} else {
			this.setState({
				isChangeOwnerModalOpen: false,
			});
		}
	};

	getCallAside = isInviteModal => {
		return (
			<CallAside
				localParticipant={this.conference.localParticipant}
				participants={this.conference.participants}
				showRightColumn={this.state.showRightColumn}
				disableButtons={this.state.disableButtons}
				isGridView={this.state.isGridView}
				activeTrackType={this.state.activeTrackType}
				setActiveParticipant={this.setActiveParticipant}
				onRemoveParticipant={val =>
					this.setState({
						selectedParticipantToRemove: val,
					})
				}
				onTransferOwnership={this.conference.transferOwnership}
				onPinnedFeed={this.onPinnedFeed}
				activeSpeakersId={this.state.selectedMediaDevices?.[MediaDeviceKinds.AUDIO_OUTPUT]?.deviceId || ''}
				isMaximizedFrame={this.state.isMaximizedFrame}
				pinnedParticipantId={this.state.pinnedParticipantId}
				activeParticipant={this.state.activeParticipant}
				isInviteModal={isInviteModal}
				setInviteVisible={() =>
					this.setState({
						isInviteVisible: true,
					})
				}
			/>
		);
	};

	submitRemoveParticipant = () => {
		this.conference.removeParticipant(this.state.selectedParticipantToRemove?.id);
		this.setState({
			selectedParticipantToRemove: null,
		});
	};

	render() {
		// stream permission can be shown before the conference even starts
		// stream permission can be shown also during the conference if the permissions change(idea: maybe using Alert component)
		if (this.shouldShowStateComponents()) {
			return this.renderCallStates();
		}

		if (!this.state.hasSoundPermission) {
			return (
				<TechCheck
					selectedMediaDevices={
						this.state.selectedMediaDevices || {
							[MediaDeviceKinds.AUDIO_INPUT]: null,
							[MediaDeviceKinds.AUDIO_OUTPUT]: null,
							[MediaDeviceKinds.VIDEO_INPUT]: null,
						}
					}
					setSelectedMediaDevices={this.setSelectedMediaDevices}
					setDefaultDevicesFromStore={() => {}}
					playErrorPromiseResolveCallback={() => this.playErrorPromiseResolveCallback()}
					isSoundPermissionTechCheck={true}
					isCamLoading={this.state.isTechCheckCamLoading}
					setIsCamLoading={isTechCheckCamLoading => {
						this.setState({
							isTechCheckCamLoading,
						});
					}}
				/>
			);
		}

		if (!this.state.callStarted) {
			return (
				<div className='loader__container call-initiating-loader'>
					<div className='center-loader'>
						<img src={`${amwellIconLink}camera-loading.svg`} alt='cam-icon' />
						<Loader />
					</div>
				</div>
			);
		}

		// Get patientParticipant
		const patientParticipant = this.getPatientParticipant();

		// USE this variable to hide components on PATIENT VIEW
		const shouldHideOnPatientView = this.conference.callType !== CallTypes.SECURITYCAM;
		return (
			<Grid
				className={classNames(
					'call-view',
					this.conference.localParticipant.isInitiator ? 'initiator' : '',
					this.state.shouldHideFooterControls ? 'hide-controls' : '',
					this.state.activeParticipant.id === this.conference.localParticipant.id ? 'top-participant-me' : '',
					this.conference.callType === CallTypes.SECURITYCAM ? 'patient-view' : '',
					!this.conference.localParticipant.isInitiator ? 'guest-call-view' : '',
					patientParticipant?.showPTZ && this.conference.localParticipant?.isInitiator ? 'ptz-is-active' : '',
					this.state.isPrivacyButtonEnabled ? 'privacy-buttons-enabled' : '',
					isTablet ? 'tablet-call-view' : ''
				)}
				columns='1fr'
				stretch='100vh'
				onClick={this.onToggleFooter}>
				{shouldHideOnPatientView && (
					<CallInviteMenu
						toggleView={() =>
							this.setState(prevState => ({
								isInviteVisible: !prevState.isInviteVisible,
							}))
						}
						isActive={this.state.isInviteVisible}
						isGuest={!this.conference.localParticipant.isInitiator}
						disableButtons={this.state.disableButtons}
					/>
				)}
				<CallMain
					isGridView={this.state.isGridView}
					activeParticipant={this.state.activeParticipant}
					activeTrackType={this.state.activeTrackType}
					patientParticipant={patientParticipant}
					localParticipant={this.conference.localParticipant}
					disableButtons={this.state.disableButtons}
					inviteParticipants={this.conference.inviteParticipants}
					closeInviteParticipantModal={() => this.setState({ isInviteVisible: false })}
					closeCamSettingsModal={() => this.setState({ isCamSettingsActive: false })}
					participants={this.conference.participants}
					isNotExpanded={this.state.isInviteVisible}
					isCamSettingsActive={this.state.isCamSettingsActive}
					showRoomLockedModal={this.state.showRoomLockedModal}
					closeRoomLockedModal={() => this.setState({ showRoomLockedModal: false })}
					isLocked={this.conference.isLocked}
					conferenceId={this.conference.id}
					conferenceLink={this.conference.conferenceLink}
					showRightColumn={this.state.showRightColumn}
					changeMediaConstraints={this.conference.changeMediaConstraints}
					siteName={this.props.organization.currentHealthSystemName}
					selectedMediaDevices={this.state.selectedMediaDevices}
					setSelectedMediaDevices={this.setSelectedMediaDevices}
					togglePTZ={() => this.togglePTZ()}
					onTransferOwnership={this.conference.transferOwnership}
					setIsPrivacyButtonEnabled={val =>
						this.setState({
							isPrivacyButtonEnabled: val,
						})
					}
					callStartTime={this.state.callStartTime}
				/>
				{this.state.isInviteVisible && (
					<InviteParticipantsModal
						conferenceId={this.conference.id}
						conferenceLink={this.conference.conferenceLink}
						inviteParticipants={this.conference.inviteParticipants}
						onModalClose={() => this.setState({ isInviteVisible: false })}
						participants={this.conference.participants}
						localParticipant={this.conference.localParticipant}
						siteName={this.props.organization.currentHealthSystemName}
						onTransferOwnership={this.conference.transferOwnership}
						localParticipantIsInitiator={this.conference.localParticipant}>
						{this.getCallAside(true)}
					</InviteParticipantsModal>
				)}
				{!shouldHideOnPatientView && (
					<>
						<ParticipantAudio
							participant={patientParticipant}
							activeSpeakersId={this.state.selectedMediaDevices?.[MediaDeviceKinds.AUDIO_OUTPUT]?.deviceId || ''}
						/>
						<PeerStats connection={patientParticipant.connection} />
					</>
				)}
				{shouldHideOnPatientView && this.getCallAside(false)}
				<CallFooter
					isGridView={this.state.isGridView}
					conferenceName={this.state.conferenceName}
					patientParticipant={patientParticipant}
					localParticipant={this.conference.localParticipant}
					participants={this.conference.participants}
					callStartTime={this.state.callStartTime}
					healthSystemPath={this.state.healthSystemPath}
					disableButtons={this.state.disableButtons}
					callType={this.conference.callType}
					isInviteVisible={this.state.isInviteVisible}
					toggleTrack={this.conference.toggleLocalParticipantTrack}
					isCamSettingsActive={this.state.isCamSettingsActive}
					togglePTZ={() => this.togglePTZ()}
					endCall={this.endCall}
					toggleLockRoomModal={() => this.setState({ showRoomLockedModal: !this.state.showRoomLockedModal })}
					toggleGridView={this.onGridViewToggled}
					openInviteView={() => this.setState({ isInviteVisible: true })}
					openCamSettingsView={() => this.setState({ isCamSettingsActive: true })}
					activeParticipant={this.state.activeParticipant}
					isFrameWindow={this.state.isFrameWindow}
					isMaximizedFrame={this.state.isMaximizedFrame}
					onToggleMaximize={this.toggleMaximize}
					correlationIdData={{ conferenceId: this.conference.id, callStarted: this.state.callStarted, callEnded: this.state.callEnded }}
					closeInviteParticipantModal={() => {
						this.setState({
							isInviteVisible: false,
						});
					}}
					toggleMobileCameraSwitch={this.conference.toggleMobileCameraSwitch}
				/>
				{this.state.isConnectionLost && (
					<Grid className='connection-lost' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh' backgroundColor='rgba(0,0,0,.75)'>
						<div>
							<Loader />
							<p>Please wait…attempting to reconnect to the network</p>
						</div>
					</Grid>
				)}
				<Modal
					display={this.state.isChangeOwnerModalOpen}
					position='center'
					closeButtonText='Leave Meeting'
					submitButtonText='End Meeting for All'
					onModalSubmit={() => this.leaveMeeting(false)}
					onModalClose={this.onLeaveMeeting}>
					<Form title='End Meeting' onSubmit={event => event.preventDefault()}>
						<p>To keep this meeting running, please assign a new host. If you choose Leave Meeting, a new host will be assigned automatically.</p>
					</Form>
				</Modal>
				{this.conference.localParticipant.isInitiator && (
					<Modal
						display={this.state.selectedParticipantToRemove}
						position='center'
						submitButtonText='Disconnect'
						onModalSubmit={this.submitRemoveParticipant}
						onModalClose={() =>
							this.setState({
								selectedParticipantToRemove: null,
							})
						}
						className='confirmation-modal red-modal'>
						<Form onSubmit={event => event.preventDefault()}>
							<p>Do you want to disconnect {this.state.selectedParticipantToRemove?.name}?</p>
						</Form>
					</Modal>
				)}
				{this.state.isDeviceBeingMonitoredModalOpen && (
					<Modal
						modalSelector='deviceInSessionModal'
						display={this.state.isDeviceBeingMonitoredModalOpen}
						position='center'
						submitButtonText=''
						closeButtonText='Dismiss'
						onModalClose={() => this.setState({ isDeviceBeingMonitoredModalOpen: false })}>
						<Form title='Device on a session'>
							<p>
								{' '}
								A Virtual Care Provider or a Virtual Sitter is <br /> actively in an eSitter session.
							</p>
						</Form>
					</Modal>
				)}

				{this.state.newOwner && (
					<div className='notification'>
						{this.conference.localParticipant.isInitiator ? 'You are' : `${this.state.newOwner} is`} now the host of this call.
					</div>
				)}
				<Prompt when={!this.conference.hasEnded} message='Are you sure you want to leave the session?' />
			</Grid>
		);
	}
}

/**
 * Validate conference start propTypes
 * @function conferencePropsValidator
 * @param {any} propType
 * @param {string} [conferenceAction] ConferenceAction
 * @param {any[]} rest PropTypes.Validator args
 */
const conferenceRequiredPropsValidator = (propType, conferenceAction = ConferenceAction.START, ...rest) => {
	const [props, propName, componentName] = rest;
	if (props.conferenceAction === conferenceAction && isNullOrUndefined(props[propName])) {
		return new Error(`'${propName}' is required on conference action '${conferenceAction}' in ${componentName}. Validation failed.`);
	}

	PropTypes.checkPropTypes({ [propName]: propType }, { [propName]: props[propName] }, propName, componentName);

	return null;
};

CallNewRefactored.contextType = SocketContext;
CallNewRefactored.propTypes = {
	fetchNotificationCounter: PropTypes.func.isRequired,
	organization: PropTypes.shape({}).isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			conferenceAction: PropTypes.oneOf(Object.values(ConferenceAction)),
			callType: conferenceRequiredPropsValidator.bind(this, PropTypes.oneOf(Object.values(CallTypes))),
			roomBreadcrumb: conferenceRequiredPropsValidator.bind(this, PropTypes.string),
			patientId: conferenceRequiredPropsValidator.bind(this, PropTypes.number),
			incomingConferenceInfo: conferenceRequiredPropsValidator.bind(this, PropTypes.shape({}), ConferenceAction.JOIN),
		}).isRequired,
	}).isRequired,
};

const mapStateToProps = state => {
	return {
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CallNewRefactored);

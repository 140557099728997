import React, { Component } from 'react';
import Grid from 'components/Grid';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import { Redirect } from 'react-router-dom';
import { isAuthenticated, memberExists } from '../infrastructure/auth';
import { getUserRole } from 'infrastructure/auth';
import { UserRoles } from 'constants/enums';

class Login extends Component {
	render() {
		if (isAuthenticated() && memberExists) {
			if (getUserRole() === UserRoles.VIRTUALSITTER) return <Redirect to='/monitoring' />;
			if (getUserRole() === UserRoles.PATIENT) return <Redirect to='/alert-history' />;
			return <Redirect to='/health-system' />;
		}

		return (
			<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' minContent>
				<Grid width='380px' className='login__wrapper'>
					<div style={{ width: '300px' }}>
						<img alt='logo' src='https://static.solaborate.com/americanwell/amwell_logo.svg' />
					</div>
					<div className='login-form-wrapper'>
						<Form title='Log in' onSubmit={this.handleSubmit}>
							<Input type='email' validationOptions={{ required: true }} label='Email' placeholder='Email' bottomSpace='20px' variant='filled' />
							<Input type='password' validationOptions={{ required: true }} label='Password' placeholder='Password' bottomSpace='15px' variant='filled' />
							<div>
								<small>
									<a>Forgot password?</a>
								</small>
							</div>
							<Button type='submit' text='Sign in' display='block' />
						</Form>
					</div>
				</Grid>
			</Grid>
		);
	}
}

export default Login;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as mainLayoutActionCreators } from 'state/mainLayout/actions';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';

import { If, Then, Else } from 'react-if';
import { Grid, Tabs, Modal } from 'components';
import InviteAdmins from 'components/UserManagement/InviteAdmins';
import Layout from 'components/Common/Layout';
import Header from 'components/Common/Header';
import InviteNurses from 'components/UserManagement/InviteNurses';
import InvitedUsersList from 'components/UserManagement/Lists/InvitedUsersList';
import UsersList from 'components/UserManagement/Lists/UsersList';

import { getHealthSystems } from 'api/users';
import { InviteTypes } from 'constants/enums';
import { getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';

class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			healthSystems: [],
			isSendInvitationsModalOpen: false,
			inviteTypeId: 0,
			sendInvitations: {
				loading: false,
				loaded: false,
				error: false,
			},
		};
	}

	async componentDidMount() {
		let healthSystems = (await getHealthSystems()).map(hs => {
			return { id: hs.id, value: hs.name };
		});

		this.setState({ healthSystems: healthSystems });
	}

	submitMyForm = null;

	handleSubmitMyForm = e => {
		if (this.submitMyForm) {
			this.submitMyForm(e);
		}
	};

	bindSubmitForm = submitForm => {
		this.submitMyForm = submitForm;
	};

	onSuccess = () => {
		this.setState({
			sendInvitations: {
				...this.state.sendInvitations,
				loading: false,
				loaded: true,
			},
		});
	};

	onCloseAlert = () => {
		this.setState({
			sendInvitations: {
				...this.state.sendInvitations,
				loading: false,
				loaded: false,
				error: false,
			},
		});
	};

	onLoading = () => {
		this.setState({
			sendInvitations: {
				...this.state.sendInvitations,
				loading: true,
			},
		});
	};

	onModalClose = () => {
		this.setState({
			isSendInvitationsModalOpen: !this.state.isSendInvitationsModalOpen,
			sendInvitations: {
				...this.state.sendInvitations,
				loaded: false,
				error: false,
			},
		});
	};

	toggleSendInvitationsModal = type => {
		this.setState({
			isSendInvitationsModalOpen: !this.state.isSendInvitationsModalOpen,
			inviteTypeId: type,
		});
	};

	render() {
		return (
			<Layout>
				<div className='users-view'>
					<Grid stretch='100%'>
						<main className='main-view' style={getMobileFullHeightStyle()}>
							<Header />
							<section>
								<Grid width='100%' vertAlign='start'>
									<h3>User Management</h3>
									<Tabs
										links={[{ link: 'Members', active: true }, { link: 'Invited Members' }]}
										components={[
											<UsersList healthSystems={this.state.healthSystems} toggleSendInvitationsModal={this.toggleSendInvitationsModal} />,
											<InvitedUsersList toggleSendInvitationsModal={this.toggleSendInvitationsModal} />,
										]}
									/>
								</Grid>
							</section>
						</main>
					</Grid>
				</div>

				<Modal
					modalSelector='invitationsModal'
					display={this.state.isSendInvitationsModalOpen}
					position='right'
					onModalClose={this.onModalClose}
					onModalSubmit={this.handleSubmitMyForm}
					isLoading={this.state.sendInvitations.loading}>
					{this.state.healthSystems.length !== 0 && (
						<If condition={this.state.inviteTypeId === InviteTypes.ADMIN.type}>
							<Then>
								<InviteAdmins
									inviteTypeId={this.state.inviteTypeId}
									onLoading={this.onLoading}
									loaded={this.state.sendInvitations.loaded}
									error={this.state.sendInvitations.error}
									bindSubmitForm={this.bindSubmitForm}
									onSuccess={this.onSuccess}
									onCloseAlert={this.onCloseAlert}
								/>
							</Then>
							<Else>
								<InviteNurses
									inviteTypeId={this.state.inviteTypeId}
									healthSystems={this.state.healthSystems}
									onLoading={this.onLoading}
									loaded={this.state.sendInvitations.loaded}
									error={this.state.sendInvitations.error}
									bindSubmitForm={this.bindSubmitForm}
									onSuccess={this.onSuccess}
									onCloseAlert={this.onCloseAlert}
								/>
							</Else>
						</If>
					)}
				</Modal>
			</Layout>
		);
	}
}

const mapStateToProps = state => {
	return {
		mainLayout: state.mainLayout,
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		mainLayoutActions: bindActionCreators(mainLayoutActionCreators, dispatch),
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

export { Users }; // Exported for unit tests only

import React, { useEffect, useRef, useState } from 'react';
import { Alert, Loader } from 'components';
import { PatientsAiSettings } from 'constants/enums';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions';
import { getAiSettings, getDeviceOwner, updateAiSettings } from 'api/devices';
import _ from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';

const AlertsWrapper = styled.aside`
	> div {
		padding: var(--spacing-m);
		border-bottom: 1px solid var(--gray-6);

		p {
			color: var(--gray-0);
			padding: 0;
			margin: 0;
			text-align: left;
			font-size: 14px;
			flex: 1;
		}

		> div {
			align-items: center;

			&:first-of-type {
				flex: 1;
				margin-bottom: 0;

				> div {
					background: var(--dark-theme-gray-2);

					div {
						color: var(--dark-theme-gray-1);
					}
				}
			}
		}
	}
`;

const SwitchLabel = styled.label`
	font-size: 14px;
	position: relative;
	display: inline-block;
	width: 30px;
	height: 15px;
	margin-left: var(--spacing-sssl);

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	span {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		transition: 0.4s;
		display: flex;
		align-items: center;
		border-radius: 20px;

		&::before {
			border-radius: 20px;
			transform: translateX(1px);
			position: relative;
			content: '';
			height: 11px;
			width: 11px;
			left: 2px;
			top: 0;
			background-color: var(--gray-0);
			transition: 0.4s;
		}

		&.active {
			background-color: var(--blue-2);
			&::before {
				transform: translateX(15px);
			}
		}
	}
`;

const AiAlertsSetup = props => {
	const inactiveHours = useRef([
		{ label: `10 seconds`, value: '10' },
		{ label: `1 hour`, value: '3600' },
		{ label: `2 hours`, value: '7200' },
		{ label: `3 hours`, value: '10800' },
		{ label: `4 hours`, value: '14400' },
	]);
	const [selectedInactiveTime, setSelectedInactiveTime] = useState(inactiveHours.current[0]);
	const [error, setError] = useState(null);
	const [patientId, setPatientId] = useState('');
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	const aiTypesToShow = [
		{
			aiTypeId: PatientsAiSettings.PERSON_INACTIVE,
			label: 'Inactive Time',
		},
		{
			aiTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
			label: 'Getting out of bed',
		},
		{
			aiTypeId: PatientsAiSettings.RAIL_DOWN,
			label: 'Rails',
		},
		{
			aiTypeId: PatientsAiSettings.SKELETON_PRIVATE_MODE,
			label: 'Privacy Mode',
		},
	];

	useEffect(() => {
		if (props.feed?.objectId && !props.isAiDisabled && !props.feed?.isDefaultOwner) {
			setIsLoading(true);
			const fetchHelloDeviceOwner = async id => {
				const response = await getDeviceOwner(id);
				if (response.error) {
					setError(response.error.message);
				} else {
					setPatientId(response.id);
				}
				setIsLoading(false);
			};
			fetchHelloDeviceOwner(props.feed?.objectId);
		}
	}, [props.feed?.objectId]);

	useEffect(() => {
		if (patientId) {
			const getPatientAiSettings = async () => {
				const response = await getAiSettings(patientId, props.feed?.objectId);
				if (response.error) {
					setError(response.error.message);
					return;
				}
				const list = [...aiSettingList];
				let found = list.find(item => +item.deviceId === +props.feed?.objectId);

				if (found) {
					found = { deviceId: +props.feed?.objectId, settings: response.patientAiSettings };
				} else {
					list.push({ deviceId: +props.feed?.objectId, settings: response.patientAiSettings });
				}
				setAiSettingsAction(list);
				const inactiveSettingType = response.patientAiSettings.find(item => item.settingTypeId === PatientsAiSettings.PERSON_INACTIVE);
				if (inactiveSettingType?.value) {
					setSelectedInactiveTime(inactiveHours.current.find(item => item.value === inactiveSettingType.value));
				}
				const isAiSettingEnabled = response.patientAiSettings.some(item => !['false', undefined, null].includes(item.value));
				props.onAiSettingClick(isAiSettingEnabled, props.feed);
			};
			getPatientAiSettings();
		}
	}, [patientId]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const toggleAiSettings = async (settingTypeId, customValue = '') => {
		const midSensitivity = '2';
		const feedSettings = _.cloneDeep(aiSettingList.find(item => +item.deviceId === +props.feed?.objectId));
		const feedinitialSettings = _.cloneDeep(aiSettingList.find(item => +item.deviceId === +props.feed?.objectId));

		const selectedSetting = feedSettings.settings.find(item => item.settingTypeId === settingTypeId);
		let value = '';
		if (!customValue) {
			value = selectedSetting.value === 'true' ? 'false' : 'true';
			if (settingTypeId === PatientsAiSettings.PERSON_INACTIVE && !selectedSetting.value) {
				value = selectedInactiveTime.value;
			}
			if (settingTypeId === PatientsAiSettings.PERSON_INACTIVE && selectedSetting.value) {
				value = null;
			}
			if (settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && !selectedSetting.value) {
				value = midSensitivity;
			}
			if (settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && selectedSetting.value) {
				value = null;
			}
		} else {
			value = customValue;
		}

		const enabledAiTypes = feedSettings.settings.filter(type => type.settingTypeId !== settingTypeId && !['false', undefined, null].includes(type.value));
		props.onAiSettingClick(!['false', undefined, null].includes(value), props.feed);

		if (enabledAiTypes.length >= 1 && !['false', undefined, null].includes(value)) {
			setIsMoreThanOneAi(true);
			return;
		}

		const dataToSend = {
			settingTypeId,
			value,
		};

		const newSettings = feedSettings.settings.map(obj => {
			if (obj.settingTypeId === settingTypeId) {
				return { settingTypeId, value };
			}
			return obj;
		});

		const list = _.cloneDeep(aiSettingList);
		let found = list.find(item => +item.deviceId === +props.feed?.objectId);

		if (found) {
			found.settings = newSettings;
		} else {
			list.push({ deviceId: +props.feed.objectId, settings: newSettings });
		}
		setAiSettingsAction(list);
		const response = await updateAiSettings(patientId, props.feed.objectId, dataToSend);
		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction(feedinitialSettings);
		}
	};

	const getSelectedAiTypes = aiTypeId =>
		aiSettingList.find(item => +item.deviceId === +props.feed?.objectId)?.settings.find(item => item.settingTypeId === aiTypeId);

	const getValue = aiTypeId =>
		aiSettingList
			.find(item => +item.deviceId === +props.feed?.objectId)
			?.settings.find(
				item => item.settingTypeId === aiTypeId && [PatientsAiSettings.PERSON_INACTIVE, PatientsAiSettings.PERSON_GETTING_OUT_OF_BED].includes(aiTypeId)
			)?.value;

	const isOptionChecked = aiTypeId =>
		(getSelectedAiTypes(aiTypeId) &&
			![PatientsAiSettings.PERSON_INACTIVE, PatientsAiSettings.PERSON_GETTING_OUT_OF_BED].includes(aiTypeId) &&
			getSelectedAiTypes(aiTypeId).value === 'true') ||
		([PatientsAiSettings.PERSON_INACTIVE, PatientsAiSettings.PERSON_GETTING_OUT_OF_BED].includes(aiTypeId) && getValue(aiTypeId));

	return (
		<AlertsWrapper>
			{isLoading && <Loader />}
			{!isLoading &&
				aiTypesToShow.map(item => (
					<div className={classNames('flex', item.aiTypeId === PatientsAiSettings.PERSON_INACTIVE ? 'flex-wrap' : '')} key={item.aiTypeId}>
						<p style={{ flexBasis: item.aiTypeId === PatientsAiSettings.PERSON_INACTIVE && '100%' }}>{item.label}</p>
						{item.aiTypeId === PatientsAiSettings.PERSON_INACTIVE && (
							<Select
								options={inactiveHours.current}
								value={selectedInactiveTime}
								onChange={value => {
									props.setInactiveTimeToFeed({ deviceId: +props.feed?.objectId, value: value.value });
									setSelectedInactiveTime(value);
									if (isOptionChecked(item.aiTypeId)) {
										toggleAiSettings(item.aiTypeId, value.value);
									}
								}}
							/>
						)}
						<div className='flex'>
							<SwitchLabel className='switch-label'>
								<input type='checkbox' checked={isOptionChecked(item.aiTypeId)} onChange={() => toggleAiSettings(item.aiTypeId)} />
								<span className={classNames('slider-input-alert round', isOptionChecked(item.aiTypeId) ? 'active' : '')} />
							</SwitchLabel>
						</div>
					</div>
				))}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<Alert
				variant='dark'
				fixed={true}
				persist={true}
				onClose={() => setIsMoreThanOneAi(false)}
				position='right'
				display={isMoreThanOneAi}
				message="You can't enable more than one AI settings at the same time."
			/>
		</AlertsWrapper>
	);
};

export default AiAlertsSetup;

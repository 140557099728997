import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import queryString from 'query-string';

import Grid from 'components/Grid';
import Input from 'components/Common/FormElements/Input';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { completeOrganizationAccountSetup } from 'api/users';
import { Link } from 'react-router-dom';
import { ChangePasswordUseCases } from 'constants/enums';
import { passwordRegEx } from 'constants/global-variables';
import { Alert } from 'components';

const CompleteOrganizationAccount = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasPasswordChanged, setHasPasswordChanged] = useState(false);
	const params = useRef(queryString.parse(window.location.search));
	const [error, setError] = useState(null);
	const amwellImgUrl = 'https://static.solaborate.com/americanwell/';

	const submitForm = async values => {
		setIsLoading(true);
		const response = await completeOrganizationAccountSetup({
			companyId: params.current.cid,
			email: params.current.e,
			password: values.password,
			code: params.current.c,
		});
		if (response.error) {
			setError(response.error.message);
		} else {
			setHasPasswordChanged(true);
		}
		setIsLoading(false);
	};

	return (
		<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' rows='auto'>
			<Grid width='500px' className='login__wrapper'>
				<div style={{ width: '250px' }}>
					<img src={`${amwellImgUrl}amwell-full-logo-white.svg`} alt='amwell-logo' />
				</div>
				<div className='login-form-wrapper validate-recover-password'>
					{isLoading && (
						<Grid width='100%' stretch='100%' horizAlign='center' vertAlign='center' rows='auto'>
							<div style={{ textAlign: 'center' }}>
								<Loader />
							</div>
						</Grid>
					)}
					{hasPasswordChanged && (
						<div className='warning-message'>
							<span className='material-icons'>error_outline</span>
							<div>
								<span>
									<b>Your account has been successfully created!</b>
								</span>
								<Link to='/' style={{ display: 'block', color: '#0095da', paddingTop: '15px' }}>
									Click here to go to login page
								</Link>
							</div>
						</div>
					)}
					{!hasPasswordChanged && (
						<div>
							<div className='warning-message'>
								<span className='material-icons'>error_outline</span>
								<span>
									Password are case-sensitive and must be at least 7 characters long. A good password should contain a mix of capital and lower-case letters,
									numbers and symbols.
								</span>
							</div>
							<div style={{ paddingTop: '25px' }}>
								<Formik
									initialValues={{ password: '', passwordConfirm: '' }}
									onSubmit={values => submitForm(values)}
									validationSchema={Yup.object().shape({
										password: Yup.string()
											.required('Password is required')
											.min(8, 'At least 8 characters!')
											.max(32, 'No more than 32 characters!')
											.matches(passwordRegEx, ChangePasswordUseCases.strongPasswordCheck),
										passwordConfirm: Yup.string()
											.required('Password confirmation is required')
											.oneOf([Yup.ref('password')], 'Must match password')
											.matches(passwordRegEx, ChangePasswordUseCases.strongPasswordCheck),
									})}
									render={() => (
										<Form>
											<Field name='password' type='password' label='Password' placeholder='Password' variant='filled' component={Input} />
											<Field
												name='passwordConfirm'
												type='password'
												label='Confirm Password'
												placeholder='Confirm Password'
												variant='filled'
												component={Input}
											/>
											<br />
											<Button type='submit' text='Continue' display='block' />
										</Form>
									)}
								/>
							</div>
						</div>
					)}
				</div>
				<br />
				<br />
			</Grid>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Grid>
	);
};

export default CompleteOrganizationAccount;

import { CallTypes, ConferenceAction, ExternalCallType } from 'constants/enums';
import { signoutSilent, hasUserProfile } from 'infrastructure/auth';
import { SocketContext } from 'io-client/SocketContext';
import React from 'react';
import queryString from 'query-string';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

class StartNewCall extends React.Component {
	socket = this.context;

	async componentDidMount() {
		const { receiverId, type, conferenceName } = this.props.match.params;
		const query = queryString.parse(this.props.location.search);
		if (hasUserProfile() && !getStorage().getItem('shouldSignOut')) {
			this.socket.doDisconnect();
			// await signoutSilent(query.refToken);
		}
		let route = '';
		let callType = null;
		if (type === ExternalCallType.VIDEO) {
			route = '/talk-to-patient';
			callType = CallTypes.VIDEO;
		} else if (type === ExternalCallType.VIEW_PATIENT) {
			route = '/view-patient';
			callType = CallTypes.SECURITYCAM;
		}

		if (hasUserProfile()) {
			getStorage().removeItem('shouldSignOut');
			this.props.history.push(route, { conferenceAction: ConferenceAction.START, patientId: +receiverId, roomBreadcrumb: conferenceName || '', callType });
		}
	}

	render() {
		return <> </>;
	}
}

export default StartNewCall;
StartNewCall.contextType = SocketContext;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { actionCreators as mainLayoutActionCreators } from 'state/mainLayout/actions';
import { withRouter } from 'react-router-dom';
import { PresenceStatusType } from 'constants/enums';
import { getUserNotifications, changeNotificationReadStatus } from 'api/notifications';
import { getUserProfile, getUserId, getUserRole } from 'infrastructure/auth';
import { ListGroup, Dropdown, Avatar } from 'components';
import { updateUserPresence, fetchUserPresence } from 'state/userPresence/actions';
import { fetchNotificationCounter } from 'state/notifications/actions';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { postMessage } from 'infrastructure/helpers/notificationsChannel';
import moment from 'moment';
import { AmWellAppType } from 'constants/global-variables';
import VirtualCareProviderRoleModal from 'components/UserSettings/VirtualCareProviderRoleModal';
import { getCurrentHealthSystemInfo, getStorage } from 'infrastructure/helpers/commonHelpers';
import { getPoolRolesByHealthSystemId, getNurseHealthSystemPoolRole } from 'api/nursePooling';
import UserSettingsModal from '../UserSettings/UserSettingsModal';

class Header extends Component {
	constructor(props) {
		super(props);
		this.userPresenceDropdownRef = React.createRef();
		this.state = {
			customMessage: '',
			userProfile: {},
			notifications: [],
			notificationsPageNo: 0,
			notificationsPageSize: 10,
			hasMoreNotifications: true,
			isAccountSettingsModalOpen: false,
			isVCPRoleModalOpen: false,
			fetchPoolRoles: {
				loading: false,
				error: false,
				poolRoles: [],
				nursePoolRoles: [],
				isGeneralNurse: false,
			},
		};
	}

	componentDidMount = async () => {
		const userProfile = getUserProfile();
		const user = {
			fullName: `${userProfile.firstName} ${userProfile.lastName}`,
			profilePicUrl: userProfile.profilePicture?.url,
			virtualRole: userProfile.jobTitle,
			virtualRoleKey: null,
			userWorkExperienceId: 0,
			locations: [],
		};
		this.setState({
			userProfile: user,
		});
		this.props.fetchNotificationCounter();
	};

	componentWillUnmount() {
		this.props.organizationActions.updateBreadcrumb([]);
	}

	updateUserPresence = key => {
		this.userPresenceDropdownRef.current.toggleDropdown(false);
		if (key === this.props.userPresence.presenceStatusTypeId && this.state.customMessage === this.props.userPresence.customMessage) {
			return;
		}
		this.props.updateUserPresence(getUserId(), this.state.customMessage, key);
	};

	onBreadcrumbClick = (options, breadcrumbIndex) => {
		let breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		this.props.organizationActions.updateBreadcrumb(breadcrumb);
	};

	loadUserNotifications = async isDropdownOpen => {
		if (!isDropdownOpen) {
			const userProfile = getUserProfile();

			let unreadNotifications = [];
			this.state.notifications.forEach(i => {
				if (i.isPreview) {
					// isPreview means it hasn't been read yet
					unreadNotifications.push(i.id);
				}
			});

			if (unreadNotifications.length > 0) {
				await changeNotificationReadStatus(userProfile.userId, unreadNotifications);
				this.props.fetchNotificationCounter();
			}
			postMessage('Update notifications');
			this.setState({
				notifications: [],
				hasMoreNotifications: true,
				notificationsPageNo: 0,
			});
		} else {
			await this.getUserNotifications();
			this.setState({
				notificationsPageNo: this.state.notificationsPageNo + 1,
			});
		}
	};

	formatTime = date => {
		let localDate = utcToLocalTime(date);
		let now = new Date();
		now.setHours(0, 0, 0, 0);
		const isNowAfterTheDate = moment(now).isAfter(localDate);
		return isNowAfterTheDate ? moment(localDate).format('dddd, MMMM DD, YYYY - h:mm A') : `Today - ${moment(localDate).format('h:mm A')}`;
	};

	getUserNotifications = async () => {
		const userProfile = getUserProfile();
		const response = await getUserNotifications(userProfile.userId, AmWellAppType, this.state.notificationsPageNo, this.state.notificationsPageSize);

		let { notifications } = this.state;
		let hasMoreNotifications;

		response.notifications.forEach(i => {
			i.timeAgo = this.formatTime(i.dateCreated);
			return i;
		});

		if (response === null) {
			notifications.push({
				title: (
					<div className='notification-group'>
						<div>
							<p>An error ocurred trying to fetch notifications!</p>
						</div>
					</div>
				),
			});
		} else if (response.notifications.length === 0 && this.state.notifications.length === 0) {
			notifications.push({
				title: (
					<div className='notification-group'>
						<div>
							<p>You don't have any notifications!</p>
						</div>
					</div>
				),
			});
		} else {
			hasMoreNotifications = response.total < this.state.notificationsPageNo * 10 ? false : true;
			response.notifications.forEach(n => {
				const el = {
					id: n.id,
					isPreview: n.isPreview,
					title: (
						<div className='notification-group'>
							<Avatar src='https://static.solaborate.com/americanwell/hello2-finallogo.png' fullName={'A B'} size='small' />
							<div>
								{n.isPreview ? (
									<p>
										<b>Missed call from {n.callerName}</b>
									</p>
								) : (
									<p>Missed call from {n.callerName}</p>
								)}
								<small>{n.timeAgo}</small>
							</div>
						</div>
					),
				};
				notifications.push(el);
			});
		}

		this.setState({
			notifications: notifications,
			hasMoreNotifications: hasMoreNotifications,
		});
	};

	checkIfScrolledAtBottom = ({ target }) => {
		const hasScrolledAtBottom = Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight;
		if (!hasScrolledAtBottom || !this.state.hasMoreNotifications) {
			return;
		}
		this.setState({
			notificationsPageNo: this.state.notificationsPageNo + 1,
		});
		this.getUserNotifications();
	};

	onAvailabilityDropdownClick = (evt, { available }) => {
		if (available) {
			this.updateUserPresence(PresenceStatusType.AVAILABLE);
		} else {
			this.updateUserPresence(PresenceStatusType.UNAVAILABLE);
		}
	};

	onUserDropdownClick = (evt, { id }) => {
		if (id === 'account_settings') {
			this.toggleAccountSettingsModal();
		} else if (id === 'log_out') {
			this.signOut();
		} else if (id === 'toggle_dark_theme') {
			this.toggleDarkTheme();
		}
	};

	toggleDarkTheme = () => {
		getStorage().setItem('isDarkThemeEnabled', (!this.props.organization.isDarkThemeEnabled).toString());
		this.props.organizationActions.setDarkTheme(!this.props.organization.isDarkThemeEnabled);
	};

	onCustomMessageChanged = evt => {
		this.setState({
			customMessage: evt.target.value,
		});
	};

	onCustomMessageKeyUp = evt => {
		if (evt.which === 13) {
			this.props.updateUserPresence(getUserId(), this.state.customMessage, this.props.userPresence.presenceStatusTypeId);
			this.setState({
				customMessage: '',
			});
		}
	};

	toggleAccountSettingsModal = () => {
		this.setState({
			isAccountSettingsModalOpen: !this.state.isAccountSettingsModalOpen,
		});
	};

	toggleVCPRoleModal = () => {
		this.setState(
			{
				isVCPRoleModalOpen: !this.state.isVCPRoleModalOpen,
			},
			async () => await this.updatePoolRoles()
		);
	};

	updatePoolRoles = async () => {
		this.setState({
			fetchPoolRoles: {
				...this.state.fetchPoolRoles,
				loading: true,
			},
		});
		let currentHealthSystem = getCurrentHealthSystemInfo();

		let [poolRoles, assignedPoolRoleResponse] = await Promise.all([
			getPoolRolesByHealthSystemId(currentHealthSystem.currentHealthSystemId),
			getNurseHealthSystemPoolRole(getUserId(), currentHealthSystem.currentHealthSystemId),
		]);

		let transformedArray = poolRoles.map(role => {
			return { id: role.id, value: role.name };
		});
		this.setState({
			fetchPoolRoles: {
				...this.state.fetchPoolRoles,
				loading: false,
				poolRoles: transformedArray,
				nursePoolRoles: assignedPoolRoleResponse.nursePoolRoles,
				isGeneralNurse: assignedPoolRoleResponse.generalNurse,
			},
		});
	};

	signOut = () => {
		this.props.history.push('/logout');
	};

	updateProfilePicture = profilePicUrl => {
		this.setState({
			userProfile: {
				...this.state.userProfile,
				profilePicUrl,
			},
		});
	};

	render() {
		const page = window.location.pathname.split('/')[1];
		return (
			<div>
				<header className='header'>
					<div className='logo'>
						{this.props.organization.hsLogo && ['health-system', 'monitoring'].includes(page) && (
							<img src={this.props.organization.hsLogo} alt='left-nav-logo' />
						)}
					</div>
					<div>
						<nav className='navigation'>
							<img src='https://static.solaborate.com/americanwell/amwell-full-logo-white.svg' alt='Amwell logo' />
							<Dropdown
								className='user'
								position='bottom'
								title={this.state.userProfile.fullName}
								imageUrl={this.state.userProfile.profilePicUrl}
								icon='keyboard_arrow_down'>
								<ListGroup
									onItemClick={this.onUserDropdownClick}
									lists={[
										{ title: 'Account settings', icon: 'settings', id: 'account_settings' },
										{ title: 'Log out', icon: 'exit_to_app', id: 'log_out' },
									]}
								/>
							</Dropdown>
						</nav>
					</div>
				</header>
				<UserSettingsModal
					{...this.props}
					isAccountSettingsModalOpen={this.state.isAccountSettingsModalOpen}
					toggleAccountSettingsModal={this.toggleAccountSettingsModal}
					updateProfilePicture={this.updateProfilePicture}
				/>
				<VirtualCareProviderRoleModal
					{...this.props}
					isVCPRoleModalOpen={this.state.isVCPRoleModalOpen}
					toggleVCPRoleModal={this.toggleVCPRoleModal}
					updatePoolRoles={this.updatePoolRoles}
					fetchPoolRoles={this.state.fetchPoolRoles}
					currentHealthSystem={getCurrentHealthSystemInfo()}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
		userPresence: state.userPresence,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		mainLayoutActions: bindActionCreators(mainLayoutActionCreators, dispatch),
		fetchUserPresence: () => dispatch(fetchUserPresence()),
		updateUserPresence: (userId, customMessage, presenceStatusTypeId) => dispatch(updateUserPresence(userId, customMessage, presenceStatusTypeId)),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

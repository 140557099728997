import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import classNames from 'classnames/bind';
import { ProfilePicture } from 'components/ProfilePicture';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import hark from 'hark';
import { HarkEvents } from 'constants/enums';
import Video from '../Video';

class LocalParticipantView extends Component {
	harkIn = null;

	onTrackChanged = ev => {
		this.setSelectedParticipantVolume(ev.track);
		this.setState({});
	};

	componentDidMount() {
		this.props.participant.localTrackController.on(this.onTrackChanged);
		const localAudioTrack = this.props.participant.localTrackController.tracks.get(TrackType.AUDIO);
		this.setSelectedParticipantVolume(localAudioTrack);
	}

	componentWillUnmount() {
		this.props.participant.localTrackController.off(this.onTrackChanged);
	}

	setSelectedParticipantVolume = track => {
		if (track && track.type === TrackType.AUDIO) {
			if (this.harkIn) {
				this.harkIn.stop();
				this.harkIn = null;
			}

			const audioStream = new MediaStream([track.track]);
			this.harkIn = hark(audioStream, {});

			this.harkIn.on(HarkEvents.STOPPED_SPEAKING, () => {
				this.props.participant.emit(ParticipantEvent.VOLUME_CHANGED, 0);
			});

			this.harkIn.on(HarkEvents.VOLUME_CHANGE, (volume, threshold) => {
				if (volume < threshold) {
					return;
				}
				const calcVolume = Math.round(((volume - threshold) * -10) / threshold);
				this.props.participant.emit(ParticipantEvent.VOLUME_CHANGED, calcVolume);
			});
		}
	};

	render() {
		const { localTrackController } = this.props.participant;
		const track = localTrackController.tracks.get(this.props.type);
		const hasLocalAudioTrack = localTrackController.tracks.get(TrackType.AUDIO);
		const name = this.props.isInviteModal ? this.props.participant.name : 'You';

		return (
			<div
				className={classNames(
					'participant-wrapper local-participant-wrapper',
					this.props.activeParticipant && this.props.participant.id === this.props.activeParticipant.id ? ' active-participant-wrapper' : '',
					this.props.type === TrackType.SCREEN ? 'screen-local-participant-wrapper' : ''
				)}>
				<div className={classNames('participant', this.props.type === TrackType.SCREEN ? 'participant-screenshare' : '')}>
					<header>
						<p>{this.props.type === TrackType.SCREEN ? 'Presenting (You)' : name}</p>
						<div>
							{/* {!hasTrack && <i className='material-icons-outlined mic_off-icon'>volume_off</i>} */}
							{/* <PeerStats getLocalTracs()={() => localTrackController.get()}/> */}
						</div>
					</header>
					<main onClick={this.props.onClick} className={track ? 'participant-has-video' : ''}>
						{track && (
							<Video
								muted
								track={track.track}
								styleTransform={this.props.type === TrackType.VIDEO ? 'scaleX(-1)' : ''}
								className={this.props.imgSrc ? 'hidden' : ''}
							/>
						)}
						{(!track || this.props.isInviteModal) && (
							<div>
								<ProfilePicture fullName={this.props.participant.name} size='small-to-medium' />
							</div>
						)}
						<footer>
							<p>{this.props.type === TrackType.SCREEN ? 'Presenting (You)' : 'You'}</p>
							{!hasLocalAudioTrack && this.props.type !== TrackType.SCREEN && <i className='material-icons-outlined mic_off-icon'>mic_off</i>}
						</footer>
					</main>
				</div>
			</div>
		);
	}
}

LocalParticipantView.propTypes = {
	participant: PropTypes.object,
	onClick: PropTypes.func,
};

export default LocalParticipantView;

import React, { useCallback, useEffect, useState } from 'react';
import { Table, Grid, Button, Modal, Form, Input, Select as SelectTag, Alert } from 'components';
import Select from 'react-select';
import Pagination from 'components/Common/Pagination';
import { UserRoles, TeamTypes, ConfigHistoryTypes } from 'constants/enums';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import _ from 'lodash';
import { getUserRole } from 'infrastructure/auth';
import { getHealthSystemHospitals } from 'api/organization';
import { addNtpConfiguration, deleteNtpConfiguration, editNtpConfiguration, getNtpConfigurations } from 'api/ntpConfig';
import { getPort, handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const NetworkConfig = props => {
	const defaultPortValue = '123';
	const initialConfigurationFormData = {
		healthSystems: [],
		hospitals: [],
		selectedHealthSystemValue: -1,
		selectedHospitalValue: -1,
		selectedHospital: null,
		selectedHealthSystem: null,
		selectedHospitalName: '',
		selectedHealthSystemName: '',
		isEdit: false,
		ntpUrl1: '',
		ntpUrl2: '',
		ntpPort1: defaultPortValue,
		ntpPort2: defaultPortValue,
		configurationId: '',
		errors: {
			healthSystemError: null,
			hospitalError: null,
			ntpUrl1: null,
			ntpUrl2: null,
			ntpPort1: null,
			ntpPort2: null,
		},
	};

	const [isLoading, setIsLoading] = useState(true);
	const [showConfigurationModal, setShowConfigurationModal] = useState(false);
	const [hospitals, setHospitals] = useState([]);
	const [configurations, setConfigurations] = useState([]);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [totalConfigs, setTotalConfigs] = useState(null);
	const [configurationFormData, setConfigurationFormData] = useState(_.cloneDeep(initialConfigurationFormData));
	const [isDeleteConfigModalOpen, setIsDeleteConfigModalOpen] = useState(false);
	const [deleteConfig, setDeleteConfig] = useState(null);
	const [editConfig, setEditConfig] = useState(null);
	const [error, setError] = useState('');

	const transformTypes = {
		WithValues: 1,
		WithLabels: 2,
	};

	const ntpConfigHeaders = [{ title: 'Name' }, { title: 'Type' }, { title: 'NTP URL' }, { title: 'Created By' }, { title: 'Date Created' }, { title: '' }];

	const orgProps = useSelector(state => state.organization);

	let role = getUserRole();

	const transformArray = (array, type, isHealthSystem) => {
		if (type === transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item.name };
			});
			if (isHealthSystem) {
				if (role === UserRoles.ADMIN) {
					newArray.unshift({ id: '0', value: 'All' });
				}
			} else {
				newArray.unshift({ id: '0', value: 'All' });
			}
			return newArray;
		}
		if (type === transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item.name };
			});
			if (isHealthSystem) {
				if (role === UserRoles.ADMIN) {
					newArray.unshift({ value: '0', label: 'All' });
				}
			} else {
				newArray.unshift({ value: '0', label: 'All' });
			}
			return newArray;
		}
		return [];
	};

	const getHospitalsByHealthSystemId = async healthSystemId => {
		const response = await getHealthSystemHospitals(healthSystemId);
		if (!response.error) {
			return transformArray(response, transformTypes.WithLabels);
		}
		return [];
	};

	useEffect(() => {
		if (props.selectedHealthSystem.value === '0') {
			setHospitals([]);
			return;
		}
		const getHospitalList = async () => {
			const response = await getHospitalsByHealthSystemId(props.selectedHealthSystem.value);
			setHospitals(response);
		};
		getHospitalList();
	}, [props.selectedHealthSystem]);

	const openDeleteModal = config => {
		setDeleteConfig(config);
		setIsDeleteConfigModalOpen(true);
	};

	const getActionButtons = config => (
		<div className='wrapped' data-cy='viewHistoryEditButtons'>
			<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.Network}`}>
				<span className='material-icons-outlined cursor-pointer mr-20' data-cy='viewHistory' data-tooltip='View history' data-position='top'>
					list_alt
				</span>
			</Link>
			<button type='button' className='blank-button cursor-pointer mr-20' onClick={() => setEditConfig(config)} data-tooltip='Edit' data-position='top'>
				<span className='material-icons-outlined'>create</span>
			</button>
			<button type='button' className='blank-button cursor-pointer mr-20' onClick={() => openDeleteModal(config)} data-tooltip='Delete' data-position='top'>
				<span className='material-icons-outlined' style={{ color: 'red' }}>
					delete
				</span>
			</button>
		</div>
	);

	const getConfigurations = useCallback(async () => {
		setError('');
		const { selectedHealthSystem, selectedHospitalId } = props;
		let hsId = null;
		let teamType = null;
		if (selectedHospitalId && selectedHospitalId?.value !== '0') {
			hsId = selectedHospitalId.value;
			teamType = TeamTypes.HOSPITAL;
		} else if (selectedHealthSystem && selectedHealthSystem.value !== '0') {
			hsId = selectedHealthSystem.value;
			teamType = TeamTypes.HEALTHSYSTEM;
		}

		const configs = await getNtpConfigurations({
			pageSize: props.pageSize,
			pageIndex: props.pageIndex,
			teamId: hsId,
			teamType,
		});
		if (configs.error) {
			setError('Something went wrong!');
			setIsLoading(false);
			return;
		}
		if (!configs.teamNetworkNtpConfigurations.length) {
			setConfigurations([]);
			setIsLoading(false);
			return;
		}

		const transformConfigs = configs.teamNetworkNtpConfigurations.map(config => ({
			name: config.team.name,
			type: config.team.typeId === TeamTypes.HEALTHSYSTEM ? 'Health System' : 'Hospital',
			ntpUrl: config.ntpUrls.map(el => (
				<>
					<span>{el}</span>
					<br />
				</>
			)),
			createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
			dateCreated: moment(utcToLocalTime(config.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
			actions: getActionButtons(config),
		}));
		setTotalConfigs(configs.total);
		setConfigurations(transformConfigs);
		setIsLoading(false);
	}, [props.pageSize, props.pageIndex, props.selectedHealthSystem, props.selectedHospitalId]);

	useEffect(() => {
		getConfigurations();
	}, [getConfigurations, props.pageSize, props.pageIndex, props.selectedHealthSystem, props.selectedHospitalId]);

	const onHealthSystemSelect = hs => {
		props.setSelectedHealthSystem(hs);
	};

	useEffect(() => {
		if (role === UserRoles.SUPERUSER && props.healthSystems.length > 1) {
			onHealthSystemSelect(props.healthSystems[1]);
		}
	}, [props.healthSystems]);

	const onFormHealthSystemSelect = async ({ id }) => {
		const newConfigurationFormData = { ...configurationFormData };
		if (newConfigurationFormData.isEdit) {
			return;
		}
		newConfigurationFormData.errors.healthSystemError = null;
		newConfigurationFormData.selectedHealthSystemValue = newConfigurationFormData.healthSystems.findIndex(hs => id === hs.id);
		newConfigurationFormData.selectedHealthSystem = id;

		const response = await getHospitalsByHealthSystemId(id);
		if (!response.error) {
			newConfigurationFormData.hospitals = response.map(item => ({ id: item.value, value: item.label }));
		}
		setConfigurationFormData(newConfigurationFormData);
	};

	const onFormHospitalSelect = ({ id }) => {
		const newConfigurationFormData = { ...configurationFormData };
		if (newConfigurationFormData.isEdit) {
			return;
		}

		newConfigurationFormData.selectedHospital = id;
		newConfigurationFormData.selectedHospitalValue = newConfigurationFormData.hospitals.findIndex(item => id === item.id);
		newConfigurationFormData.errors.hospitalError = null;

		setConfigurationFormData(newConfigurationFormData);
	};

	const resetAddConfigurationModal = async () => {
		setConfigurationFormData(_.cloneDeep(initialConfigurationFormData));
		setEditConfig(null);
		setIsFormLoading(false);
	};

	const setDeviceConfiguration = async () => {
		const maximumPortNumber = 65535;
		const newConfigurationFormData = { ...configurationFormData };
		const { isEdit, selectedHealthSystemValue, errors, ntpUrl1, ntpUrl2, ntpPort1, ntpPort2 } = newConfigurationFormData;
		let hasError = false;

		if (!isEdit && selectedHealthSystemValue === -1) {
			errors.healthSystemError = 'Please select a health system.';
			hasError = true;
		}

		const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

		if (ntpUrl1 && !URL.test(ntpUrl1)) {
			errors.ntpUrl1 = 'Please enter valid URL.';
			hasError = true;
		}

		if (ntpUrl2 && !URL.test(ntpUrl2)) {
			errors.ntpUrl2 = 'Please enter valid URL.';
			hasError = true;
		}

		if (!ntpUrl1 && !ntpUrl2) {
			errors.ntpUrl1 = 'Please set at least 1 NTP Endpoint.';
			hasError = true;
		}

		if (ntpUrl1 && !ntpPort1) {
			errors.ntpPort1 = 'Please set the NTP Port.';
			hasError = true;
		}

		if (ntpPort1 && +ntpPort1 > maximumPortNumber) {
			errors.ntpPort1 = `Maximum port number is ${maximumPortNumber}.`;
			hasError = true;
		}

		if (ntpPort1 && +ntpPort1 < 0) {
			errors.ntpPort1 = 'Port cannot be a negative number.';
			hasError = true;
		}

		if (ntpUrl2 && !ntpPort2) {
			errors.ntpPort2 = 'Please set the NTP Port.';
			hasError = true;
		}

		if (ntpPort2 && +ntpPort2 > maximumPortNumber) {
			errors.ntpPort2 = `Maximum port number is ${maximumPortNumber}.`;
			hasError = true;
		}

		if (ntpPort2 && +ntpPort2 < 0) {
			errors.ntpPort2 = `Port cannot be a negative number.`;
			hasError = true;
		}

		if (ntpUrl1 && ntpUrl2 && ntpPort1 && ntpPort2 && ntpUrl1 === ntpUrl2 && ntpPort1 === ntpPort2) {
			errors.ntpUrl2 = 'Endpoint + port fields should be unique';
			hasError = true;
		}

		if (hasError) {
			setConfigurationFormData(newConfigurationFormData);
			return;
		}

		setIsFormLoading(true);
		const teamId =
			newConfigurationFormData.selectedHospital && newConfigurationFormData.selectedHospital !== '0'
				? newConfigurationFormData.selectedHospital
				: newConfigurationFormData.selectedHealthSystem;
		const teamTypeId =
			newConfigurationFormData.selectedHospital && newConfigurationFormData.selectedHospital !== '0' ? TeamTypes.HOSPITAL : TeamTypes.HEALTHSYSTEM;
		const configId = newConfigurationFormData.configurationId;

		const ntpUrls = [];
		if (newConfigurationFormData.ntpUrl1) {
			const firstUrlPort = `${newConfigurationFormData.ntpUrl1}:${newConfigurationFormData.ntpPort1}`;
			ntpUrls.push(firstUrlPort);
		}
		if (newConfigurationFormData.ntpUrl2) {
			const secondUrlPort = `${newConfigurationFormData.ntpUrl2}:${newConfigurationFormData.ntpPort2}`;
			ntpUrls.push(secondUrlPort);
		}

		const editParams = {
			teamId,
			teamType: teamTypeId,
			id: configId,
			ntpUrls,
		};

		const addParams = {
			teamId,
			teamType: teamTypeId,
			ntpUrls,
		};

		const { hasSucceeded } = !newConfigurationFormData.isEdit ? await addNtpConfiguration(addParams) : await editNtpConfiguration(editParams);

		if (!hasSucceeded) {
			setIsFormLoading(false);
			return;
		}
		setShowConfigurationModal(prevState => !prevState);
		resetAddConfigurationModal();
		getConfigurations();
	};

	const toggleConfigurationModal = async (config = null) => {
		const newConfigurationFormData = { ...configurationFormData };
		if (!showConfigurationModal) {
			newConfigurationFormData.healthSystems = orgProps.allHealthSystems.map(hs => ({ id: hs.id, value: hs.name }));
			newConfigurationFormData.isEdit = !!config;

			if (role === UserRoles.SUPERUSER && !newConfigurationFormData.isEdit) {
				newConfigurationFormData.selectedHealthSystemValue = 0;
				onFormHealthSystemSelect(newConfigurationFormData.healthSystems[0]);
			}

			if (newConfigurationFormData.isEdit) {
				if (config.team.typeId === TeamTypes.HEALTHSYSTEM) {
					newConfigurationFormData.selectedHealthSystem = config.team.id;
					newConfigurationFormData.selectedHealthSystemName = config.team.name;
				}
				if (config.team.typeId === TeamTypes.HOSPITAL) {
					newConfigurationFormData.selectedHospital = config.team.id;
					newConfigurationFormData.selectedHospitalName = config.team.name;
				}
				newConfigurationFormData.ntpUrl1 = config.ntpUrls[0].substr(0, config.ntpUrls[0].lastIndexOf(':'));
				newConfigurationFormData.ntpPort1 = getPort(config.ntpUrls[0]).toString();
				newConfigurationFormData.ntpUrl2 = config.ntpUrls.length > 1 ? config.ntpUrls[1].substr(0, config.ntpUrls[1].lastIndexOf(':')) : '';
				newConfigurationFormData.ntpPort2 = config.ntpUrls.length > 1 ? getPort(config.ntpUrls[1]).toString() : defaultPortValue;
				newConfigurationFormData.configurationId = config.id;
			}

			setConfigurationFormData(newConfigurationFormData);
		} else {
			resetAddConfigurationModal();
		}
		setShowConfigurationModal(prevState => !prevState);
	};

	useEffect(() => {
		if (editConfig) {
			toggleConfigurationModal(editConfig);
		}
	}, [editConfig]);

	const onPaginationChange = async (pageSize, pageIndex) => {
		await props.onPaginationChange(pageSize, pageIndex);
	};

	const submitDeleteConfig = async () => {
		setError('');
		setIsLoading(true);
		setIsDeleteConfigModalOpen(false);

		const params = {
			teamId: deleteConfig.team.id,
			teamType: deleteConfig.team.typeId,
			id: deleteConfig.id,
		};

		const deleteRes = await deleteNtpConfiguration(params);
		if (deleteRes.error) {
			setError('Something went wrong! Please try again.');
			setIsLoading(false);
			return;
		}
		getConfigurations();
	};

	const onDeleteModal = () => {
		setIsDeleteConfigModalOpen(false);
		setDeleteConfig(null);
	};

	const DropdownIndicator = () => {
		return <i className='material-icons-outlined'>arrow_drop_down</i>;
	};

	const onInputChange = e => {
		const newConfigurationFormData = { ...configurationFormData };
		newConfigurationFormData[e.target.name] = e.target.value;
		newConfigurationFormData.errors[e.target.name] = null;
		setConfigurationFormData(newConfigurationFormData);
	};

	const onHospitalSelect = selection => {
		props.setSelectedHospital(selection);
	};

	return (
		<div>
			<Table isLoading={isLoading} headers={ntpConfigHeaders} rows={configurations}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder='All'
						classNamePrefix='custom-select'
						options={transformArray(props.healthSystems, transformTypes.WithLabels, true)}
						components={{ DropdownIndicator }}
						onChange={onHealthSystemSelect}
						isDisabled={role === UserRoles.SUPERUSER}
					/>
					<Select
						value={props.selectedHospitalId ? hospitals?.find(x => x.value === props.selectedHospitalId) : null}
						isDisabled={props.selectedHealthSystem.value === '0'}
						classNamePrefix='custom-select'
						options={hospitals}
						components={{ DropdownIndicator }}
						onChange={onHospitalSelect}
					/>
					<Button text='New Configuration' horizAlign='end' onClick={() => toggleConfigurationModal()} />
				</Grid>
			</Table>
			<Pagination
				totalCount={totalConfigs}
				pageSize={{ value: props.pageSize, label: `${props.pageSize}` }}
				pageIndex={props.pageIndex}
				onChange={(size, index) => onPaginationChange(size.value, index)}
			/>
			<Modal
				modalSelector='deviceConfigurationModal'
				className='configuration-modal'
				display={showConfigurationModal}
				position='right'
				onModalSubmit={setDeviceConfiguration}
				onModalClose={() => toggleConfigurationModal()}
				isLoading={isFormLoading}>
				<Form title={!configurationFormData.isEdit ? 'NTP Configurations' : 'Edit NTP Configurations'} onSubmit={event => event.preventDefault()}>
					{!configurationFormData.isEdit && (
						<>
							{role === UserRoles.SUPERUSER && (
								<Input
									type='text'
									label='Select Health System'
									name='currentHealthSystemId'
									description='Select Health System so you can choose the hospital to apply the configurations.'
									placeholder='Select Health System'
									value={
										configurationFormData.selectedHealthSystemValue > -1
											? configurationFormData.healthSystems[configurationFormData.selectedHealthSystemValue].value
											: ''
									}
									validationOptions={{}}
									disabled={true}
								/>
							)}
							{role !== UserRoles.SUPERUSER && (
								<SelectTag
									type='text'
									defaultValue={configurationFormData.selectedHealthSystemValue}
									label='Select Health System'
									name='currentHealthSystemId'
									onSelect={onFormHealthSystemSelect}
									items={configurationFormData.healthSystems}
									description='Select Health System so you can choose the hospital to apply the configurations.'
									placeholder='Select Health System'
									error={configurationFormData.errors.healthSystemError}
								/>
							)}
							<SelectTag
								type='text'
								defaultValue={configurationFormData.selectedHospitalValue}
								label='Choose Hospital'
								name='currentHospitalSystemId'
								onSelect={onFormHospitalSelect}
								items={configurationFormData.hospitals}
								description='Choose the hospital you want to apply changes to.'
								placeholder='Choose hospital'
								error={configurationFormData.errors.hospitalError}
							/>
						</>
					)}
					{configurationFormData.isEdit && (
						<Input
							type='text'
							label={configurationFormData.selectedHospital ? 'Hospital' : 'Health System'}
							name={configurationFormData.selectedHospital ? 'currentHospitalSystemId' : 'currentHealthSystemId'}
							placeholder={configurationFormData.selectedHospital ? 'Hospital' : 'Health System'}
							value={configurationFormData.selectedHospital ? configurationFormData.selectedHospitalName : configurationFormData.selectedHealthSystemName}
							validationOptions={{}}
							disabled={true}
						/>
					)}

					<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gridGap: '15px' }}>
						<Input
							type='text'
							label='Set NTP Endpoint'
							name='ntpUrl1'
							description='Please enter NTP Endpoint.'
							placeholder='NTP Endpoint'
							value={configurationFormData.ntpUrl1}
							onChange={onInputChange}
							validationOptions={
								!configurationFormData.ntpUrl2 && {
									required: true,
								}
							}
							error={configurationFormData.errors.ntpUrl1}
							bottomSpace='20px'
						/>
						<Input
							type='number'
							label='Set NTP Port'
							name='ntpPort1'
							placeholder='NTP Port'
							description='Please enter NTP Port.'
							value={configurationFormData.ntpPort1}
							onChange={onInputChange}
							validationOptions={
								configurationFormData.ntpUrl1 && {
									required: true,
								}
							}
							error={configurationFormData.errors.ntpPort1}
							bottomSpace='20px'
							onKeyDown={handleOnKeyDownNumeric}
						/>
					</div>
					<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gridGap: '15px' }}>
						<Input
							type='text'
							label='Set NTP Endpoint'
							name='ntpUrl2'
							description='Please enter NTP Endpoint.'
							placeholder='NTP Endpoint'
							value={configurationFormData.ntpUrl2}
							onChange={onInputChange}
							validationOptions={
								!configurationFormData.ntpUrl1 && {
									required: true,
								}
							}
							error={configurationFormData.errors.ntpUrl2}
							bottomSpace='20px'
						/>
						<Input
							type='number'
							label='Set NTP Port'
							name='ntpPort2'
							description='Please enter NTP Port.'
							placeholder='NTP Port'
							value={configurationFormData.ntpPort2}
							onChange={onInputChange}
							validationOptions={
								configurationFormData.ntpUrl2 && {
									required: true,
								}
							}
							error={configurationFormData.errors.ntpPort2}
							bottomSpace='20px'
							onKeyDown={handleOnKeyDownNumeric}
						/>
					</div>
				</Form>
			</Modal>
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isDeleteConfigModalOpen}
				position='center'
				onModalSubmit={submitDeleteConfig}
				onModalClose={onDeleteModal}>
				<form>
					<h3>Warning</h3>
					<p>Are you sure you want to delete this configuration ?</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default NetworkConfig;

import React, { useEffect, useState } from 'react';
import Layout from 'components/Common/Layout';
import { Grid, Tabs } from 'components';
import Header from 'components/Common/Header';
import UserIdleTimeout from 'components/Configurations/UserIdleTimeout';
import DeviceConfig from 'components/Configurations/DeviceConfig';
import NetworkConfig from 'components/Configurations/NetworkConfig';
import { getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';
import queryString from 'query-string';
import { getHealthSystems } from 'api/users';
import { Roles } from 'constants/enums';

const Configurations = props => {
	const queryParamas = queryString.parse(props.location.search);
	const { activeTab } = queryParamas;
	const [currentTab, setCurrentTab] = useState(Number.isNaN(+activeTab) ? 0 : +activeTab);
	const [healthSystems, setHealthSystems] = useState([]);
	const [selectedHospital, setSelectedHospital] = useState({ value: '0', label: 'All' });
	const [selectedHealthSystem, setSelectedHealthSystem] = useState({ value: '0', label: 'All' });
	const [pagination, setPagination] = useState({ index: 0, size: 10 });
	const [selectedRole, setSelectedRole] = useState({ value: '0', label: 'All' });

	useEffect(() => {
		const setStateFromQueryString = async queryParams => {
			const hsList = await getHealthSystems();
			if (!hsList.error) {
				const { pageIndex, healthSystem, pageSize, role } = queryParams;
				const hs = healthSystem ? hsList.find(x => x.id === healthSystem) : null;
				setPagination({
					index: pageIndex ? parseInt(pageIndex, 10) : 0,
					size: pageSize ? parseInt(pageSize, 10) : 10,
				});
				setSelectedHealthSystem(hs ? { value: hs.id, label: hs.name } : { value: '0', label: 'All' });
				setHealthSystems(hsList);
				const selectedRoleFound = role ? Object.values(Roles).find(x => x.id === parseInt(role, 10)) : null;
				setSelectedRole(selectedRoleFound ? { value: selectedRoleFound.id, label: selectedRoleFound.value } : { value: '0', label: 'All' });
			}
		};
		const currentParams = queryString.parse(props.location.search);
		setStateFromQueryString(currentParams);
	}, [props.location.search]);

	const pushFiltersToQueryString = (size, index, activeTabFilter) => {
		const queryParams = {};
		if (index > 0) {
			queryParams.pageIndex = index.toString();
		}
		if (size > 10) {
			queryParams.pageSize = size.toString();
		}
		if (activeTabFilter > 0) {
			queryParams.activeTab = activeTabFilter.toString();
		}
		if (selectedHealthSystem !== null && selectedHealthSystem.value !== '0') {
			queryParams.healthSystem = selectedHealthSystem.value;
		}
		if (selectedHospital !== null && selectedHospital.value !== '0') {
			queryParams.hospital = selectedHospital;
		}
		if (selectedRole !== null && selectedRole.value !== '0') {
			queryParams.role = selectedRole.value.toString();
		}
		props.history.push({ search: queryString.stringify(queryParams) });
	};

	const onTabChange = index => {
		setPagination({ index: 0, size: 10 });
		pushFiltersToQueryString(10, 0, index);
		setCurrentTab(index);
	};

	const onPaginationChange = (size, index) => {
		setPagination({
			index,
			size,
		});
		pushFiltersToQueryString(size, index, currentTab);
	};

	const onHealthSystemChange = param => {
		setSelectedHospital(null);
		setSelectedHealthSystem(param);
		setPagination(prev => ({
			...prev,
			index: 0,
		}));
	};

	const onHospitalChange = hs => {
		setSelectedHospital(hs);
		setPagination(prev => ({ ...prev, index: 0 }));
	};

	const onRoleChange = param => {
		setSelectedRole(param);
		setPagination(prev => ({
			...prev,
			index: 0,
		}));
	};

	return (
		<Layout>
			<div className='users-view test'>
				<Grid stretch='100%'>
					<main className='main-view' style={getMobileFullHeightStyle()}>
						<Header />
						<section>
							<Grid width='100%' vertAlign='start'>
								<h3>Configurations</h3>
								<Tabs
									onTabChange={onTabChange}
									key={currentTab}
									links={[
										{ link: 'User idle timeout', active: currentTab === 0 },
										{ link: 'Device', active: currentTab === 1 },
										{ link: 'Network', active: currentTab === 2 },
									]}
									components={[
										<UserIdleTimeout
											healthSystems={healthSystems}
											selectedHealthSystem={selectedHealthSystem}
											selectedHospitalId={selectedHospital}
											pageSize={pagination.size}
											pageIndex={pagination.index}
											setSelectedHealthSystem={onHealthSystemChange}
											selectedRole={selectedRole}
											setSelectedRole={onRoleChange}
											onPaginationChange={onPaginationChange}
										/>,
										<DeviceConfig
											healthSystems={healthSystems}
											selectedHealthSystem={selectedHealthSystem}
											selectedHospitalId={selectedHospital}
											pageSize={pagination.size}
											pageIndex={pagination.index}
											setSelectedHealthSystem={onHealthSystemChange}
											setSelectedHospital={onHospitalChange}
											onPaginationChange={onPaginationChange}
										/>,
										<NetworkConfig
											healthSystems={healthSystems}
											selectedHealthSystem={selectedHealthSystem}
											selectedHospitalId={selectedHospital}
											pageSize={pagination.size}
											pageIndex={pagination.index}
											setSelectedHealthSystem={onHealthSystemChange}
											setSelectedHospital={onHospitalChange}
											onPaginationChange={onPaginationChange}
										/>,
									]}
								/>
							</Grid>
						</section>
					</main>
				</Grid>
			</div>
		</Layout>
	);
};

export default Configurations;

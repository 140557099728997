import React from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { editNurseHealthSystem, editSuperUserHealthSystem } from 'api/users';
import { Alert } from 'components';
import Select from 'components/Common/FormElements/Select';
import { UserRoles } from 'constants/enums';

const SuperUserHealthSystem = props => {
	const getUserRole = () => {
		switch (props.member.roles[0].name) {
			case UserRoles.NURSE:
				return 'Virtual Care Provider';
			case UserRoles.VIRTUALSITTER:
				return 'Virtual Sitter';
			case UserRoles.SUPERUSER:
				return 'Super User';
			default:
				return null;
		}
	};

	return (
		<div>
			<Formik
				validateOnBlur={false}
				enableReinitialize={true}
				initialValues={{
					healthSystem: props.member.healthSystems.length !== 0 ? props.member.healthSystems[0].id : '',
				}}
				onSubmit={values => {
					props.onLoading('healthSystemAddition');
					if (UserRoles.NURSE === props.member.roles[0].name || UserRoles.VIRTUALSITTER === props.member.roles[0].name) {
						editNurseHealthSystem(
							props.member.userInfo.id,
							values.healthSystem,
							props.member.healthSystems.length !== 0 ? props.member.healthSystems[0].id : null
						)
							.then(() => props.onSuccess('healthSystemAddition'))
							.catch(() => props.onError('healthSystemAddition'));
						return;
					}
					editSuperUserHealthSystem(
						props.member.userInfo.id,
						values.healthSystem,
						props.member.healthSystems.length !== 0 ? props.member.healthSystems[0].id : null
					)
						.then(() => props.onSuccess('healthSystemAddition'))
						.catch(() => props.onError('healthSystemAddition'));
				}}
				validationSchema={Yup.object().shape({
					healthSystem: Yup.string().required('Health system is required!'),
				})}>
				{formikProps => {
					// bind the submission handler remotely
					props.bindSubmitForm(formikProps.submitForm);

					return (
						<Form>
							<h3>Edit {getUserRole()} Health System</h3>
							<Field
								name='healthSystem'
								type='select'
								label='Enter Health System'
								placeholder='Select Health System'
								description={`Please select the health system for this ${getUserRole()}`}
								items={props.healthSystems}
								component={Select}
							/>

							<Alert
								alertSelector='assignSuperUserToAnotherHSMessage'
								display={props.loaded}
								message={`${getUserRole()} was added successfully to the health system.`}
								variant='success'
								onClose={() => props.onCloseAlert('healthSystemAddition')}
							/>
							<Alert
								display={props.error}
								message='Something went wrong! Please try again. '
								variant='error'
								onClose={() => props.onCloseAlert('healthSystemAddition')}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default SuperUserHealthSystem;

import React, { useState } from 'react';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { CareEventsForNurses, CareEventsForSafetyCompanions, CareEventTypes, ManualAlertTypes } from 'constants/enums';
import classNames from 'classnames/bind';

const CareEventsModal = props => {
	let messageTypes = [];

	switch (props.eventType) {
		case CareEventTypes.Nurses:
			messageTypes = [
				{
					id: CareEventsForNurses.HomeMedListCompleted,
					title: 'HomeMed List Completed',
				},
				{
					id: CareEventsForNurses.AdmissionDataCompleted,
					title: 'Admission Data Completed',
				},
				{
					id: CareEventsForNurses.IPOCcompleted,
					title: 'IPOC completed',
				},
				{
					id: CareEventsForNurses.DischargeInstructionsCreated,
					title: 'Discharge Instructions Created',
				},
				{
					id: CareEventsForNurses.DischargeTeachingCompleted,
					title: 'Discharge Teaching Completed',
				},
				{
					id: CareEventsForNurses.Performed2ndRNMedVerification,
					title: 'Performed 2nd RNMed Verification',
				},
				{
					id: CareEventsForNurses.NotifiedMD,
					title: `Notified MD pop-up to add reason required'`,
				},
				{
					id: CareEventsForNurses.PerformedMentorship,
					title: 'Performed Mentorship',
				},
				{
					id: CareEventsForNurses.PerformedSepsisScreenNotification,
					title: 'Performed Sepsis Screen Notification',
				},
				{
					id: CareEventsForNurses.Completed24HourChartCheck,
					title: 'Completed 24 Hour Chart Check',
				},
				{
					id: CareEventsForNurses.PerformedClinicalRounding,
					title: 'Performed Clinical Rounding',
				},
				{
					id: CareEventsForNurses.ProvidedUpdateToFamily,
					title: 'Provided Update To Family',
				},
				{
					id: CareEventsForNurses.AssistedWithCodeBlue,
					title: 'Assisted With Code Blue',
				},
				{
					id: CareEventsForNurses.OtherCareEventForNurse,
					title: 'Other Care Event For Nurse',
				},
			];
			break;

		case CareEventTypes.SafetyCompanionsAndNurses:
			messageTypes = [
				{
					id: CareEventsForSafetyCompanions.RedirectedPatientToPreventFall,
					title: 'Redirected Patient To Prevent Fall',
				},
				{
					id: CareEventsForSafetyCompanions.NotifiedBedsideStaffToAssistPatientToPreventFall,
					title: 'Notified Bedside Staff To Assist Patient To PreventFall',
				},
				{
					id: CareEventsForSafetyCompanions.NotifiedBedsideStaffToAssistPatient,
					title: 'Notified Bedside Staff To Assist Patient',
				},
				{
					id: CareEventsForSafetyCompanions.CalledRapidResponse,
					title: 'Called Rapid Response',
				},
				{
					id: CareEventsForSafetyCompanions.PreventedHarmToStaffMember,
					title: 'Prevented Harm To Staff Member',
				},
			];
			break;

		case CareEventTypes.RaiseAlert:
			messageTypes = [
				{
					id: ManualAlertTypes.FALL_DETECTED,
					title: 'Fall Detected',
				},
				{
					id: ManualAlertTypes.RISK_OF_FALL,
					title: 'Risk of fall',
				},
				{
					id: ManualAlertTypes.PATIENT_NEEDS_HELP,
					title: 'Patient needs help',
				},
				{
					id: ManualAlertTypes.PATIENT_REQUESTED_HELP,
					title: 'Patient requires help',
				},
				{
					id: ManualAlertTypes.CUSTOM,
					title: 'Other Activity',
				},
			];
			break;

		default:
	}

	const [selectedAlertType, setSelectedAlertType] = useState(null);
	const [messageContent, setMessageContent] = useState('');

	const generateMessage = id => {
		const { title } = messageTypes.find(message => message.id === id);
		return `${title} on ${props.hierarchyNaming.hospital} / ${props.hierarchyNaming.room}`;
	};

	const onSelectAlert = id => {
		setSelectedAlertType(id);
		setMessageContent(generateMessage(id));
	};

	return (
		<Modal
			display={true}
			onModalSubmit={() => props.onSubmitModal(selectedAlertType, messageContent)}
			submitButtonText='Save'
			position='center'
			onModalClose={props.onModalClose}
			shouldSubmitOnEnter={false}
			isSubmitDisabled={!messageContent || !selectedAlertType}
			isLoading={props.isManualReportLoading}
			className='ai-event-modal'>
			<Form height={550} onSubmit={event => event.preventDefault()}>
				<h3>Type of {props.eventType !== CareEventTypes.RaiseAlert ? 'Care Event' : 'Alert Type'}</h3>
				<div className='flex flex-wrap' style={{ marginTop: '20px' }}>
					{messageTypes.map(message => (
						<div
							key={message.id}
							className={classNames('pill-button', selectedAlertType === message.id ? 'selected-pill' : ``)}
							onClick={() => onSelectAlert(message.id)}>
							<input type='radio' name='choice' checked={selectedAlertType === message.id} />
							<h5>{message.title}</h5>
						</div>
					))}
				</div>
				<p>Note:</p>
				<textarea maxLength={400} rows={8} value={messageContent} onChange={event => setMessageContent(event.target.value)} name='message' />
			</Form>
		</Modal>
	);
};

export default CareEventsModal;

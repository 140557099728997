/* eslint-disable import/prefer-default-export */
import { DeviceActionTypes } from 'constants/action-types';

const initialState = {
	busyDevices: [],
	onlineDevices: [],
};

export const devicesStatusList = (state = initialState, action) => {
	switch (action.type) {
		case DeviceActionTypes.SET_DEVICE_BUSY:
			return {
				...state,
				busyDevices: [...state.busyDevices, action.payload],
			};
		case DeviceActionTypes.REMOVE_DEVICE_BUSY:
			return {
				...state,
				busyDevices: [...state.busyDevices.filter(id => id !== action.payload)],
			};
		case DeviceActionTypes.SET_BULK_DEVICES_BUSY:
			return {
				...state,
				busyDevices: action.payload,
			};
		case DeviceActionTypes.REMOVE_BULK_DEVICES_BUSY:
			return {
				...state,
				busyDevices: [],
			};
		default:
			return state;
	}
};

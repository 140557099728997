import { Connection, SfuConnection } from '@solaborate/webrtc';
import { sendStats } from 'api/callStats';
import { getUserId } from 'infrastructure/auth';

class CallStats {
	constructor(conferenceId, participantId) {
		this.conferenceId = conferenceId;
		this.participantId = participantId;
		this.userId = getUserId();
		this.outgoingMetricsBuffer = [];
		this.collectors = [];
		this.flushInterval = null;
	}

	stop = () => {
		this.collectors.forEach(c => clearInterval(c));
		clearInterval(this.flushInterval);
		this.flushInterval = null;
		return this.sendStats();
	};

	collect = ({ connection, remoteParticipantIds, pushInterval }) => {
		const getStatsInterval = setInterval(async () => {
			if (connection.isClosed()) {
				clearInterval(getStatsInterval);
				return;
			}

			let stats = [];
			if (connection instanceof SfuConnection) {
				stats = await Promise.all([connection.getStats(), connection.subscribing.getStats()]);
			} else {
				stats.push(await connection.getStats());
			}

			stats.forEach((stat, idx) => {
				this.pushStats({
					isUsingMediaServer: !(connection instanceof Connection),
					stats: stat,
					isPublisher: idx == 0,
					remoteParticipantIds: typeof remoteParticipantIds === 'function' ? remoteParticipantIds() : remoteParticipantIds,
				});
			});
		}, pushInterval);

		this.collectors.push(getStatsInterval);
		if (!this.flushInterval) {
			this.flushInterval = setInterval(this.sendStats, 30000);
		}
	};

	sendStats = async () => {
		if (this.outgoingMetricsBuffer.length === 0) {
			return;
		}
		const batch = this.prepareBatch();
		await sendStats(batch);
	};

	prepareBatch = () => {
		const batchSize = this.outgoingMetricsBuffer.length;
		return {
			data: { values: this.outgoingMetricsBuffer.splice(0, batchSize) },
		};
	};

	pushStats = ({ isUsingMediaServer, isPublisher, stats, remoteParticipantIds }) => {
		let result = [];

		stats.forEach(report => {
			if (report.type === 'certificate') {
				return;
			}
			// remove those from ms publisher
			if (isPublisher && report.type === 'inbound-rtp') {
				return;
			}
			result.push(report);
		});

		if (result.length === 0) {
			return;
		}

		this.outgoingMetricsBuffer.push({
			conferenceId: this.conferenceId,
			userId: this.userId,
			isUsingMediaServer: isUsingMediaServer,
			msDirection: isUsingMediaServer ? (isPublisher ? 'publisher' : 'subscriber') : '',
			participantId: this.participantId,
			remoteParticipantIds: remoteParticipantIds,
			timeStamp: this.generateTimeStamp(),
			stats: result,
		});
	};

	generateTimeStamp = () => {
		return Math.round(new Date().getTime());
	};
}

export default CallStats;

import React from 'react';
import { healthCareCdnUrl } from 'constants/global-variables';
import { AiAlertTypes, MeasurementActivityTypes, WarningIndexes, ManualAlertTypes, MeasurementTypes } from 'constants/enums';
import { formattedDate } from 'infrastructure/helpers/dateHelper';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers';
import { getAiAlertType, AiAlertActivityType, ManualAlertActivityTypes } from 'infrastructure/helpers/alertsHelper';

const imageUrl = `${healthCareCdnUrl}alert-history/`;
const aiUrl = `${healthCareCdnUrl}vsm/`;

const AlertHistoryModal = props => {
	const getMeasurementAlertImage = alertType => {
		switch (alertType) {
			case MeasurementTypes.BLOOD_PRESSURE:
			case MeasurementTypes.HEART_RATE:
				return `${aiUrl}Heart-1.svg`;
			case MeasurementTypes.OXYGEN:
				return `${aiUrl}Respiratory-1.svg`;
			case MeasurementTypes.DIABETES:
				return `${aiUrl}OtherData-1.svg`;
			case MeasurementTypes.TEMPERATURE:
			case MeasurementTypes.WEIGHT:
				return `${aiUrl}Body-Measurements-1.svg`;
			case MeasurementTypes.SLEEP:
				return `${aiUrl}Sleep.svg`;
			case MeasurementTypes.BLOOD_GLUCOSE:
				return `${aiUrl}Glucose.svg`;
			default:
				return `${aiUrl}Heart-1.svg`;
		}
	};

	const getManualAlertImage = alertType => {
		switch (alertType) {
			case ManualAlertTypes.FALL_DETECTED:
				return `${aiUrl}Respiratory-1.svg`;
			case ManualAlertTypes.CUSTOM:
				return `${aiUrl}OtherData-1.svg`;
			case ManualAlertTypes.RISK_OF_FALL:
				return `${aiUrl}Body-Measurements-1.svg`;
			case ManualAlertTypes.PATIENT_NEEDS_HELP:
				return `${aiUrl}Sleep.svg`;
			case ManualAlertTypes.PATIENT_REQUESTED_HELP:
				return `${aiUrl}Glucose.svg`;
			default:
				return `${aiUrl}Heart-1.svg`;
		}
	};

	const getAiAlertImage = alertType => {
		switch (alertType) {
			case AiAlertTypes.RAIL_DOWN:
				return `${aiUrl}BedRails.svg`;
			case AiAlertTypes.PERSON_SITTING:
			case AiAlertTypes.PERSON_STANDING:
			case AiAlertTypes.PERSON_FACE_DOWN:
			case AiAlertTypes.PERSON_LAYING_SIDE:
			case AiAlertTypes.PERSON_LAYING_FRONT:
			case AiAlertTypes.BABY_FACE_DOWN:
				return `${aiUrl}PatientPosition.svg`;
			case AiAlertTypes.FALL_DETECTION:
				return `${aiUrl}FallDetection.svg`;
			default:
				return `${aiUrl}PatientPosition.svg`;
		}
	};

	const getImageUrl = item => {
		if (item.aiAlertActivityType) {
			if (item.properties && JSON.parse(item.properties)?.Id) {
				return getAiAlertImage(JSON.parse(item.properties)?.Name);
			}
			switch (item.aiAlertActivityType.id) {
				case AiAlertActivityType.AI_ALERT_SENT:
				case AiAlertActivityType.AI_ALERT_RECEIVED:
				case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
				case AiAlertActivityType.ACKNOWLEDGED:
				case AiAlertActivityType.AI_FAKE_ALERT:
				case AiAlertActivityType.AI_FORWARD_TO_NURSES:
					return `${imageUrl}doctor-no-background-space.svg`;
				case AiAlertActivityType.COMMENTED:
					return `${imageUrl}conversation-no-background.svg`;
				default:
					return `${imageUrl}doctor-no-background-space.svg`;
			}
		}
		if (item.measurementAlertActivityType) {
			if (item.properties && JSON.parse(item.properties)) {
				return getMeasurementAlertImage(JSON.parse(item.properties)?.Type);
			}
			switch (item.measurementAlertActivityType.id) {
				case MeasurementActivityTypes.MEASUREMENT_ALERT_SENT:
				case MeasurementActivityTypes.MEASUREMENT_ALERT_RECEIVED:
				case MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER:
				case MeasurementActivityTypes.ACKNOWLEDGED:
				case MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT:
				case MeasurementActivityTypes.MEASUREMENT_FORWARD_TO_NURSES:
					return `${imageUrl}doctor-no-background-space.svg`;
				case MeasurementActivityTypes.COMMENTED:
					return `${imageUrl}conversation-no-background.svg`;
				default:
					return `${imageUrl}doctor-no-background-space.svg`;
			}
		}
		if (item.manualAlertActivityType) {
			if (item.properties && JSON.parse(item.properties)?.Id) {
				return getManualAlertImage(JSON.parse(item.properties)?.Type);
			}
			switch (item.manualAlertActivityType.id) {
				case AiAlertActivityType.AI_ALERT_SENT:
				case AiAlertActivityType.AI_ALERT_RECEIVED:
				case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
				case AiAlertActivityType.ACKNOWLEDGED:
				case AiAlertActivityType.AI_FAKE_ALERT:
				case AiAlertActivityType.AI_FORWARD_TO_NURSES:
					return `${imageUrl}doctor-no-background-space.svg`;
				case AiAlertActivityType.COMMENTED:
					return `${imageUrl}conversation-no-background.svg`;
				default:
					return `${imageUrl}doctor-no-background-space.svg`;
			}
		}
		return null;
	};

	const getDescription = item => {
		if (item.aiAlertActivityType) {
			switch (item.aiAlertActivityType.id) {
				case AiAlertActivityType.AI_ALERT_SENT:
					return 'is at risk';
				case AiAlertActivityType.AI_ALERT_RECEIVED:
					return 'received alert';
				case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
					return (
						<>
							was added as
							<span className='hello-device'>Hospital Care Taker</span>
						</>
					);
				case AiAlertActivityType.COMMENTED:
					return 'added comment';
				case AiAlertActivityType.ACKNOWLEDGED:
					return (
						<>
							marked the
							<span className='completed-alert'>alert as acknowledged</span>
						</>
					);
				case AiAlertActivityType.AI_FAKE_ALERT:
					return (
						<>
							marked the
							<span className='false-alert'>alert as false</span>
						</>
					);
				case AiAlertActivityType.AI_FORWARD_TO_NURSES:
					return (
						<>
							has
							<span className='hello-device'>forwarded the alert to nurses</span>
						</>
					);
				default:
					return '';
			}
		}

		if (item.measurementAlertActivityType) {
			switch (item.measurementAlertActivityType.id) {
				case MeasurementActivityTypes.MEASUREMENT_ALERT_SENT:
					return 'measured';
				case MeasurementActivityTypes.MEASUREMENT_ALERT_RECEIVED:
					return 'received alert';
				case MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER:
					return (
						<>
							was added as
							<span className='hello-device'>Hospital Care Taker</span>
						</>
					);
				case MeasurementActivityTypes.COMMENTED:
					return 'added comment';
				case MeasurementActivityTypes.ACKNOWLEDGED:
					return (
						<>
							marked the
							<span className='completed-alert'>alert as acknowledged</span>
						</>
					);
				case MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT:
					return (
						<>
							marked the
							<span className='false-alert'>alert as false</span>
						</>
					);
				case MeasurementActivityTypes.MEASUREMENT_FORWARD_TO_NURSES:
					return (
						<>
							has
							<span className='hello-device'>forwarded the alert to nurses</span>
						</>
					);
				default:
					return '';
			}
		}

		if (item.manualAlertActivityType) {
			switch (item.manualAlertActivityType.id) {
				case ManualAlertActivityTypes.MANUAL_ALERT_SENT:
					return 'raised an alert';
				case ManualAlertActivityTypes.MANUAL_ALERT_RECEIVED:
					return 'received alert';
				case ManualAlertActivityTypes.ADDED_AS_AN_HOSPITAL_CARETAKER:
					return (
						<>
							was added as
							<span className='hello-device'>Hospital Care Taker</span>
						</>
					);
				case ManualAlertActivityTypes.COMMENTED:
					return 'added comment';
				case ManualAlertActivityTypes.ACKNOWLEDGED:
					return (
						<>
							marked the
							<span className='completed-alert'>alert as acknowledged</span>
						</>
					);
				case ManualAlertActivityTypes.MANUAL_FAKE_ALERT:
					return (
						<>
							marked the
							<span className='false-alert'>alert as false</span>
						</>
					);
				case ManualAlertActivityTypes.MANUAL_FORWARD_TO_NURSES:
					return (
						<>
							has
							<span className='hello-device'>forwarded the alert to nurses</span>
						</>
					);
				case ManualAlertActivityTypes.SAVED_ACTIVITY:
					return (
						<>
							has
							<span className='hello-device'>Saved Care Event</span>
						</>
					);
				default:
					return '';
			}
		}
		return null;
	};

	const getProperties = item => {
		if (!item.properties) {
			return null;
		}
		return JSON.parse(item.properties);
	};

	const getComment = item => {
		if (!item) {
			return '';
		}
		const result = item.Comment || getAiAlertType(item.Name);
		if (!result) {
			return '';
		}
		return `: ${result}`;
	};

	return (
		<>
			{!props.alertDetails.length && (
				<div className='flex flex-align-center flex-justify-center'>
					<p className=''>No results found</p>
				</div>
			)}
			<div className='full-width'>
				{props.alertDetails?.map(item => {
					const properties = getProperties(item);
					return (
						<div
							className={`view-request-history-item alert-history flex full-width flex-space-between${
								props.alertDetails?.length === 1 ? ' one-alert-history' : ''
							}`}>
							<div className='flex'>
								<div className='alert-history-modal-icon-wrapper'>
									<img className='alert-history-modal-icon' src={getImageUrl(item)} alt='icon' />
								</div>
								<p className={`alert-history-desc${props.isFromMonitoring ? ' break-word' : ''}`}>
									<span>
										{item?.causer?.firstName} {item?.causer?.lastName}{' '}
									</span>
									{getDescription(item)}
									{!props.isMeasurementAlert && properties && getComment(properties)}
									{item.measurementAlertActivityType && props.isMeasurementAlert && properties && (
										<span className={`${[WarningIndexes.LOW, WarningIndexes.HIGH].includes(properties?.WarningIndex) ? 'moderate-risk' : 'high-risk'}`}>
											{stringToCamelCase(properties?.Type)}
											{props.isMeasurementAlert && properties && `${properties?.Value} ${properties.Unit}`}
										</span>
									)}
								</p>
								<p className='alert-time'>
									{!props.isFromMonitoring && <img src={`${healthCareCdnUrl}vsm/Time.svg`} alt='ico' />}
									{formattedDate(item.createdAt)}
								</p>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default AlertHistoryModal;

import React from 'react';
import { APP_CONFIG } from 'constants/global-variables';
import { getInitialsColor, onErrorInitialsSrc } from 'infrastructure/helpers/commonHelpers';
import classNames from 'classnames/bind';

const getInitialsFromFullName = (fullName, firstName, lastName) => {
	const firstNameLetter = fullName ? fullName[0].trim() : firstName[0].trim();
	let lastNameLetter = fullName && fullName.split(' ')[1] ? fullName.split(' ')[1][0].trim() : '';
	if (!fullName) {
		const nameFromFirstName = !lastName && firstName && firstName.split(' ')[1] ? fullName.split(' ')[1][0].trim() : '';
		lastNameLetter = lastName[0]?.trim() || nameFromFirstName;
	}
	const fullNameInitials = `${firstNameLetter}${lastNameLetter}`;
	return fullNameInitials;
};

export const ProfilePicture = props => {
	const initialsColor = getInitialsColor(getInitialsFromFullName(props.fullName, props.firstName, props.lastName)[0]);
	return (
		<div className={classNames('profile-picture-wrapper', props.className, props.isActive ? 'active' : '', props.size)} style={props.style}>
			<div
				className={`chat-list-item-initials${props.src ? '' : ' no-profile-pic'}`}
				style={{
					backgroundColor: `${initialsColor.backgroundColor}`,
					color: `${initialsColor.color}`,
				}}>
				{getInitialsFromFullName(props.fullName, props.firstName, props.lastName)}
			</div>
			{props.src && (
				<img
					style={{ position: 'relative' }}
					onError={e => onErrorInitialsSrc(e)}
					className='profile-picture'
					alt={props.fullName}
					src={`${APP_CONFIG.profilePicBaseUrl}/${props.src}`}
				/>
			)}
		</div>
	);
};

export default ProfilePicture;

import React, { Component } from 'react';
import Alert from 'components/Alert';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';
import MultiSelect from 'components/Common/FormElements/MultiSelect';
import { addRolesToPool, deleteRoleFromPool, updatePoolItemOrder } from 'api/nursePooling';

const DragHandle = SortableHandle(() => (
	<span>
		<i className='material-icons'>list</i>
	</span>
));
const SortableItem = SortableElement(({ value, onItemDeleted }) => (
	<div className='pool-item-draggable'>
		<DragHandle />
		{value.name}
		<div className='wrapped' style={{ flexGrow: 1 }}>
			<i className='material-icons-outlined boxed-icon' onClick={() => onItemDeleted(value.id)}>
				delete
			</i>
		</div>
	</div>
));

const SortableList = SortableContainer(({ items, onItemDeleted }) => {
	return (
		<div className='pool-items'>
			{items.map((item, index) => (
				<SortableItem onItemDeleted={onItemDeleted} key={`item-${item.id}`} index={index} value={item} />
			))}
		</div>
	);
});

class PoolItems extends Component {
	state = {
		items: this.props.currentPool.poolRoles,
		showAddPoolRoleForm: false,
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		let item = this.state.items.find((x, i) => i === oldIndex);
		updatePoolItemOrder(this.props.healthSystemId, this.props.currentPool.id, item.id, newIndex + 1).then(() => {
			this.setState(
				({ items }) => ({
					items: arrayMove(items, oldIndex, newIndex),
				}),
				() => this.props.onPoolItemsReordered(this.props.currentPool.id, this.state.items)
			);
		});
	};

	onItemDeleted = itemId => {
		deleteRoleFromPool(this.props.healthSystemId, this.props.currentPool.id, itemId).then(() => {
			this.setState(
				{
					items: this.state.items.filter(item => {
						return item.id !== itemId;
					}),
				},
				() => this.props.onPoolItemsReordered(this.props.currentPool.id, this.state.items)
			);
		});
	};

	getPoolRoles = allPoolRoles => {
		var unassignedRoles = allPoolRoles.filter(role => {
			return !this.state.items.some(assignedRole => {
				return assignedRole.id === role.id;
			});
		});

		return unassignedRoles.map(role => {
			return { value: role.id, label: role.name };
		});
	};

	transformPoolRolesArr = poolRoles => {
		return poolRoles.map(role => {
			return { id: role.value, name: role.label };
		});
	};

	render() {
		return (
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={{
						poolRoles: [],
					}}
					onSubmit={(values, { resetForm }) => {
						this.props.onLoading('addRolesToPool');
						let poolRoles = values.poolRoles.map(role => {
							return role.value;
						});
						addRolesToPool(this.props.healthSystemId, this.props.currentPool.id, poolRoles).then(() => {
							let transformedArray = this.transformPoolRolesArr(values.poolRoles);
							this.setState(
								{
									items: this.state.items.concat(transformedArray),
								},
								() => this.props.onPoolItemsReordered(this.props.currentPool.id, this.state.items)
							);
							this.props.onSuccess();
							resetForm({
								poolRoles: [],
							});
						});
					}}
					validationSchema={Yup.object().shape({
						poolRoles: Yup.string()
							.required('Pool roles are required')
							.nullable(),
					})}>
					{formikProps => {
						// bind the submission handler remotely
						const { values, touched, errors, setFieldValue, setFieldTouched } = formikProps;
						this.props.bindSubmitForm(formikProps.submitForm);
						return (
							<Form>
								<h3>Order Pool Items</h3>
								<SortableList onItemDeleted={this.onItemDeleted} useDragHandle items={this.state.items} lockAxis='y' onSortEnd={this.onSortEnd} />
								<a
									className='action'
									onClick={() => {
										this.setState({ showAddPoolRoleForm: !this.state.showAddPoolRoleForm });
									}}
									data-tooltip={'Add a new pool role to this pool'}
									data-position='right'>
									<i className='material-icons-outlined'>add_box</i>
								</a>
								{this.state.showAddPoolRoleForm && (
									<MultiSelect
										name='poolRoles'
										label='Pool Roles'
										placeholder='Select Roles'
										description='Please select at least one role for this pool'
										options={this.getPoolRoles(this.props.poolRoles)}
										value={values.poolRoles}
										isMulti={true}
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										touched={touched.poolRoles}
										error={errors.poolRoles}
										isClearable={true}
										backspaceRemovesValue={true}
									/>
								)}
								<Alert
									display={this.props.loaded}
									message='The pool role was successfully added to this health system !'
									variant='success'
									onClose={() => this.props.onCloseAlert('addRolesToPool')}
								/>
								<Alert
									display={this.props.error}
									message='Something went wrong! Please try again. '
									variant='error'
									onClose={() => this.props.onCloseAlert('addRolesToPool')}
								/>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default PoolItems;

import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { SectorTypes } from 'constants/enums';
import CheckBox from 'components/Common/CheckBox';
import { getLevelDevices } from 'infrastructure/helpers/commonHelpers';

const OptionName = props => {
	const { option } = props;
	return (
		<>
			{option.name}
			{option.helloDeviceId && <i className={classNames('material-icons-outlined', 'room-status', option.status)}>fiber_manual_record</i>}
		</>
	);
};

const OptionsList = ({ options, expandedOptions, onChange, selectedSectorId, setSelectedId, checkedDevices, handleCheckBoxChange }) => {
	const findProperId = item => {
		const prefix = item.type;
		return item[`${prefix}Id`];
	};

	const toggleExpand = (selectedOptionId, isAddingSection) => {
		if (expandedOptions[selectedOptionId] && !isAddingSection) {
			delete expandedOptions[selectedOptionId];
		} else {
			expandedOptions[selectedOptionId] = {};
		}
		onChange(expandedOptions);
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		expandedOptions[optionId] = subSelections;
		onChange(expandedOptions);
	};

	const allDevicesSelected = (option, checkedDevicesMap) => {
		const devices = getLevelDevices(option);
		let selectedDevices = [];
		for (let k of checkedDevicesMap.entries()) {
			if (k[1] === true) selectedDevices.push(k[0]);
		}
		const checker = devices.every(v => selectedDevices.includes(v));
		return checker;
	};

	const getCheckBoxId = option => {
		switch (option.type) {
			case 'hospital':
				return option.hospitalId;
			case 'deaprtment':
				return option.departmentId;
			case 'floor':
				return option.floorId;
		}
	};

	return (
		<div className='pool-devices-selection'>
			{options.map((option, index) => (
				<div key={index} className={classNames('tree', !option.name && !option.isNewOption ? 'hidden' : '')}>
					<div className={classNames('tree__child', option[`${option.type}Id`] === selectedSectorId ? 'selected' : '')}>
						<div>
							<i
								className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
								onClick={() => {
									toggleExpand(findProperId(option));
								}}>
								{expandedOptions[findProperId(option)] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
							</i>
							<i className='material-icons-outlined'>{option.icon}</i>
							<a className='link'>
								<OptionName option={option} />
							</a>
							{option.type !== 'room' && getLevelDevices(option).length !== 0 && (
								<CheckBox
									name={''}
									id={getCheckBoxId(option)}
									checked={allDevicesSelected(option, checkedDevices)}
									onChange={e => handleCheckBoxChange(e, option)}
								/>
							)}
							{option.type === 'room' && option.helloDeviceId !== undefined && (
								<CheckBox
									name={''}
									id={option.helloDeviceId}
									checked={checkedDevices.get(option.helloDeviceId)}
									onChange={e => handleCheckBoxChange(e, option)}
								/>
							)}
						</div>
					</div>
					{option.subOptions && expandedOptions[findProperId(option)] && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							checkedDevices={checkedDevices}
							handleCheckBoxChange={handleCheckBoxChange}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class DeviceSelection extends Component {
	state = {
		selectedSectorId: this.props.selectedSectorId,
		expandedOptions: this.props.preSelected ? this.props.preSelected : {},
	};

	setSelectedId = sectorId => {
		this.setState({ selectedSectorId: sectorId });
	};

	render() {
		const { selectedSectorId, expandedOptions } = this.state;
		return (
			<OptionsList
				options={this.props.data}
				onChange={() => this.setState({ expandedOptions })}
				expandedOptions={this.state.expandedOptions}
				selectedSectorId={selectedSectorId}
				setSelectedId={this.setSelectedId}
				checkedDevices={this.props.checkedDevices}
				handleCheckBoxChange={this.props.handleCheckBoxChange}
			/>
		);
	}
}

export default DeviceSelection;

import { NursePoolingActionTypes } from 'constants/action-types';

export function setPoolingCurrentHealthSystem(data) {
	return dispatch => {
		dispatch({
			type: NursePoolingActionTypes.SET_CURRENT_HEALTH_SYTEM,
			payload: data,
		});
	};
}

import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { checkUserEmailExists, getCountries, getStates } from 'api/users';
import { editPatientProfile, getPatientDetails } from 'api/patients';
import moment from 'moment';
import { PatientProfileInfoTab } from 'constants/enums';
import Grid from 'components/Grid';
import Loader from 'components/Loader';
import ProfilePicture from 'components/UserSettings/ProfilePicture';
import PersonalInformation from 'components/UserSettings/PersonalInformation';
import ContactInformation from 'components/UserSettings/ContactInformation';
import Location from 'components/UserSettings/Location';
import { APP_CONFIG } from 'constants/global-variables';
import Alert from 'components/Alert';
import Button from 'components/Button';
import { getUserId, getUserProfile, setUserProfile } from 'infrastructure/auth';

const ProfileInformation = props => {
	const transformArray = array => array.map(item => ({ value: item.code || item.id, label: item.name }));

	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [profilePicture, setProfilePicture] = useState('');
	const [patientProfile, setPatientProfile] = useState(null);
	const [patientDetails, setPatientDetails] = useState(null);
	const [tabsExpanded, setTabsExpanded] = useState([
		{ id: 0, isExpanded: false },
		{ id: 1, isExpanded: false },
		{ id: 2, isExpanded: false },
		{ id: 3, isExpanded: false },
		{ id: 4, isExpanded: false },
		{ id: 5, isExpanded: false },
	]);
	const [profilePictureError, setProfilePictureError] = useState('');

	const toggleProfileInfoTabs = id => {
		const expandedTabs = [...tabsExpanded];
		const expandedTab = expandedTabs.find(tab => tab.id === id);
		expandedTab.isExpanded = !expandedTab.isExpanded;
		setTabsExpanded(expandedTabs);
	};

	const profileInfoTabs = [
		{
			id: PatientProfileInfoTab.PROFILE_PICTURE,
			title: 'Profile Picture',
		},
		{
			id: PatientProfileInfoTab.PERSONAL_INFORMATION,
			title: 'Personal Information',
		},
		{
			id: PatientProfileInfoTab.ADDRESS,
			title: 'Address',
		},
		{
			id: PatientProfileInfoTab.CONTACT_INFORMATION,
			title: 'Contact Information',
		},
	];

	const getProfileInformationDetails = async () => {
		const userProfile = getUserProfile();
		const [countriesList, statesList, patientDetailsRes] = await Promise.all([getCountries(), getStates(), getPatientDetails()]);
		const responseError = countriesList.error || statesList.error || patientDetailsRes?.error;
		if (responseError) {
			setError(responseError.message);
			setIsLoading(false);
		} else {
			setCountries(countriesList.countries);
			setStates(statesList.states);
			setPatientDetails(patientDetailsRes.patient);
			setPatientProfile(patientDetailsRes.patient.profile);
			setProfilePicture(patientDetailsRes.patient.profile.profilePicture);
			setUserProfile({
				...userProfile,
				profilePicture: { url: `${APP_CONFIG.profilePicBaseUrl}/${patientDetailsRes.patient.profile.profilePicture}` },
				firstName: patientDetailsRes.patient.profile.firstName,
				lastName: patientDetailsRes.patient.profile.lastName,
			});
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getProfileInformationDetails();
	}, []);

	const getSelectedTab = type => {
		return tabsExpanded.find(x => x.id === type).isExpanded;
	};

	const getDescription = title => {
		switch (title) {
			case 'Profile Picture':
				return 'Profile Picture';
			case 'Personal Information':
				return 'Personal Information';
			case 'Address':
				return 'Address';
			case 'Contact Information':
				return 'Contact Information';
			default:
				return title;
		}
	};

	const onSubmitForm = async values => {
		setError(null);
		const params = {
			patientUserId: patientDetails.userId,
			active: true,
			idCard: values.idNumber.toString(),
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			address: values.address,
			phoneNumber: values.phoneNumber?.toString(),
			email: values.emailAddress,
			genderId: parseInt(values.genderId, 10),
			birthDate: values.birthDate,
			showConsent: values.consentFromPatient,
			stateCode: values?.country === 'US' ? values.stateCode : '',
			postalCode: values.zipCode?.toString(),
			city: values?.city,
			country: values?.country,
			communicationLanguage: '',
			profilePicture,
		};
		const response = await editPatientProfile(getUserId(), params);
		if (response.error) {
			setError(`${response.error.message}`);
			return;
		}
		setSuccess('changes Saved');
		getProfileInformationDetails();
		props.toggleModal();
	};

	const validateEmail = async val => {
		if (!val || val === patientProfile.email) {
			return true;
		}
		let emailExists = false;
		const schema = Yup.string()
			.email()
			.required();
		if (await schema.isValid(val)) {
			const response = await checkUserEmailExists(val);
			if (response.error) {
				setError(response.error.message);
				emailExists = false;
			} else {
				emailExists = response;
			}
		}
		return !emailExists;
	};

	const getInitialValues = () => {
		if (patientProfile) {
			return {
				firstName: patientProfile.firstName,
				lastName: patientProfile.lastName,
				idNumber: patientProfile.idCard,
				address: patientProfile.address,
				birthDate: moment(patientProfile.birthDate).format('YYYY-MM-DD'),
				emailAddress: patientProfile.email.includes('@hello-health.com') ? '' : patientProfile.email,
				phoneNumber: patientProfile.phoneNumber,
				genderId: patientProfile.genderId,
				country: patientProfile?.country,
				city: patientProfile?.city,
				zipCode: patientProfile?.postalCode,
				stateCode: patientProfile?.country === 'US' ? patientProfile?.stateCode : '',
			};
		}
		return {};
	};

	const checkIfFormIsValid = errors => {
		if (Object.keys(errors).length || !profilePicture) {
			setError('Changes can not be saved unless you fill the mandatory fields');
			if (!profilePicture) {
				setProfilePictureError('Please upload a profile picture');
			}
			setTabsExpanded([
				{ id: 0, isExpanded: true },
				{ id: 1, isExpanded: true },
				{ id: 2, isExpanded: true },
				{ id: 3, isExpanded: true },
				{ id: 4, isExpanded: true },
				{ id: 5, isExpanded: true },
			]);
		}
	};

	const getValidationSchema = () =>
		Yup.object().shape({
			firstName: Yup.string()
				.required('First name cannot be empty')
				.matches(/^\D+$/, 'First name should not contain numbers')
				.max(30, 'Max length is 30')
				.trim(),
			lastName: Yup.string()
				.required('Last name cannot be empty')
				.matches(/^\D+$/, 'Last name should not contain numbers')
				.max(30, 'Max length is 30')
				.trim(),
			idNumber: Yup.string()
				.required('ID Number cannot be empty')
				.min(10, 'ID number should consist of 10 characters and should not start with zero.')
				.max(10, 'ID number should consist of 10 characters and should not start with zero.')
				.trim(),
			address: Yup.string()
				.max(100, 'Max length is 100')
				.trim(),
			stateCode: Yup.string()
				.nullable()
				.when('country', {
					is: 'US',
					then: Yup.string().required('State is required'),
				}),
			birthDate: Yup.string()
				.required('Please write your date of birth')
				.test('DOB', 'You must be at least 18 years old', value => moment().diff(moment(value), 'years') >= 18),
			emailAddress: Yup.string()
				.max(100, 'Max length is 100')
				.email('Provided e-mail is invalid')
				.test('existing-email', 'This user already exists', val => validateEmail(val))
				.trim(),
			phoneNumber: Yup.string()
				.max(20, 'Max length is 20')
				.trim(),
			genderId: Yup.string().required('Please select a sex'),
		});

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='calc(100vh - 105px)' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='account-settings-panel-wrapper account-settings-inner-wrapper account-settings-tabs doctor-profile-settings patient-profile'>
					<Formik
						enableReinitialize={true}
						validateOnBlur={true}
						initialValues={getInitialValues()}
						onSubmit={values => {
							onSubmitForm(values);
						}}
						validationSchema={getValidationSchema()}>
						{formikProps => {
							const { values, handleChange, setFieldValue, errors, handleSubmit } = formikProps;
							return (
								<>
									{profileInfoTabs.map(item => (
										<React.Fragment key={item.id}>
											<div className='flex flex-align-center cursor-pointer position-relative' onClick={() => toggleProfileInfoTabs(item.id)}>
												<div className='flex column-direction full-width'>
													<h5 style={{ fontWeight: '700' }}>{item.title}</h5>
													<span style={{ fontSize: '14px' }}>{`Update your ${getDescription(item.title)}`}</span>
												</div>
												<i className='material-icons-outlined' style={{ color: 'var(--gray-6)' }}>
													{tabsExpanded.find(x => x.id === item.id).isExpanded ? 'chevron_right' : 'expand_more'}
												</i>
											</div>
											{getSelectedTab(PatientProfileInfoTab.PROFILE_PICTURE) && item.id === PatientProfileInfoTab.PROFILE_PICTURE && (
												<ProfilePicture
													profilePicture={profilePicture}
													setProfilePicture={setProfilePicture}
													setError={setError}
													profilePictureError={profilePictureError}
												/>
											)}
											{getSelectedTab(PatientProfileInfoTab.PERSONAL_INFORMATION) && item.id === PatientProfileInfoTab.PERSONAL_INFORMATION && (
												<PersonalInformation
													formikProps={formikProps}
													values={values}
													errors={errors}
													onChange={handleChange}
													setFieldValue={setFieldValue}
													transformArray={transformArray}
												/>
											)}
											{getSelectedTab(PatientProfileInfoTab.ADDRESS) && item.id === PatientProfileInfoTab.ADDRESS && (
												<Location
													transformArray={transformArray}
													formikProps={formikProps}
													values={values}
													errors={errors}
													countries={countries}
													states={states}
													onChange={handleChange}
												/>
											)}
											{getSelectedTab(PatientProfileInfoTab.CONTACT_INFORMATION) && item.id === PatientProfileInfoTab.CONTACT_INFORMATION && (
												<ContactInformation onChange={handleChange} values={values} errors={errors} />
											)}
										</React.Fragment>
									))}
									<Button
										className='save-btn edit-profile-btn'
										text='Save'
										size='large'
										onClick={() => {
											checkIfFormIsValid(errors);
											handleSubmit();
										}}
									/>
								</>
							);
						}}
					</Formik>
					<Alert display={error || success} fixed={true} hideCloseButton={true} message={error} variant={error ? 'dark' : 'success'} />
				</div>
			)}
		</>
	);
};

export default ProfileInformation;

import React, { Component } from 'react';
import { Grid, Table, Tabs, Button, Modal } from 'components';
import Select from 'react-select';
import { getPoolRolesByHealthSystemId, getHealthSystemsVCPs, deletePoolRole } from 'api/nursePooling';
import AddPoolRole from './Forms/AddPoolRole';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import moment from 'moment';
import EditPoolRole from './Forms/EditPoolRole';
import AssignedNurses from './AssignedNurses';
import { CSVLink } from 'react-csv';

class PoolRolesList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			poolRoles: [],
			virtualCareProviders: [],
			selectedHealthSystem: this.props.healthSystems[0],
			isAddPoolRoleModalOpen: false,
			isEditPoolRoleModalOpen: false,
			isDeletePoolRoleModalOpen: false,
			addPoolRole: {
				loading: false,
				loaded: false,
				error: false,
			},
			editPoolRole: {
				loading: false,
				loaded: false,
				error: false,
			},
			assignNurse: {
				loading: false,
				laoded: false,
				error: false,
			},
			currentPoolRole: null,
		};
	}

	async componentDidMount() {
		const selectedHealthSystemId = this.props.selectedHealthSystem.value;

		const [poolRoles, virtualCareProviders] = await Promise.all([
			getPoolRolesByHealthSystemId(selectedHealthSystemId),
			getHealthSystemsVCPs(selectedHealthSystemId),
		]);

		this.setState({
			loading: false,
			poolRoles: poolRoles,
			virtualCareProviders: virtualCareProviders,
		});
	}

	submitMyForm = null;

	handleSubmitMyForm = e => {
		if (this.submitMyForm) {
			this.submitMyForm(e);
		}
	};

	bindSubmitForm = submitForm => {
		this.submitMyForm = submitForm;
	};

	onPoleRoleAdded = (addedPoolRole, healthSystemId) => {
		if (healthSystemId === this.props.selectedHealthSystem.value) {
			const newPoolRole = {
				id: addedPoolRole.id,
				name: addedPoolRole.name,
				createdBy: addedPoolRole.createdBy,
				dateCreated: addedPoolRole.dateCreated,
				assignedNurses: [],
			};

			this.setState({
				addPoolRole: {
					...this.state.addPoolRole,
					loading: false,
					loaded: true,
				},
				poolRoles: [newPoolRole].concat(...this.state.poolRoles),
			});
		} else {
			this.setState({
				addPoolRole: {
					...this.state.addPoolRole,
					loading: false,
					loaded: true,
				},
			});
		}
	};

	onPoleRoleEdited = (poolRoleId, poolRoleUpdatedName) => {
		const poolRoleIndex = this.state.poolRoles.findIndex(poolRole => poolRole.id === poolRoleId);

		const items = [...this.state.poolRoles];
		const item = {
			...items[poolRoleIndex],
			name: poolRoleUpdatedName,
		};

		items[poolRoleIndex] = item;
		this.setState({
			poolRoles: items,
			editPoolRole: {
				...this.state.editPoolRole,
				loading: false,
				loaded: true,
			},
		});
	};

	onNursesAssigned = (poolRoleId, nurses) => {
		const poolRoleIndex = this.state.poolRoles.findIndex(poolRole => poolRole.id === poolRoleId);

		const items = [...this.state.poolRoles];
		const item = {
			...items[poolRoleIndex],
			assignedNurses: nurses.concat(...items[poolRoleIndex].assignedNurses),
		};
		items[poolRoleIndex] = item;
		this.setState({
			assignNurse: {
				...this.state.assignNurse,
				loading: false,
				loaded: true,
			},
			poolRoles: items,
		});
	};

	onNurseUnassigned = (poolRoleId, nurseId) => {
		const poolRoleIndex = this.state.poolRoles.findIndex(poolRole => poolRole.id === poolRoleId);

		const items = [...this.state.poolRoles];
		const item = {
			...items[poolRoleIndex],
			assignedNurses: items[poolRoleIndex].assignedNurses.filter(nurse => {
				return nurse.id !== nurseId;
			}),
		};
		items[poolRoleIndex] = item;
		this.setState({
			poolRoles: items,
		});
	};

	onCloseAlert = activity => {
		this.setState({
			[activity]: {
				...this.state[activity],
				loading: false,
				loaded: false,
				error: false,
			},
		});
	};

	onLoading = activity => {
		this.setState({
			[activity]: {
				...this.state[activity],
				loading: true,
			},
		});
	};

	onModalClose = () => {
		this.setState({
			isAddPoolRoleModalOpen: !this.state.isAddPoolRoleModalOpen,
			addPoolRole: {
				...this.state.addPoolRole,
				loaded: false,
				error: false,
			},
		});
	};

	getEditButtons(poolRole) {
		return (
			<div className='wrapped'>
				<i
					data-tooltip='Edit Pool Role'
					data-position='bottom'
					className='material-icons-outlined boxed-icon'
					style={{ marginRight: '0px' }}
					onClick={() => this.setState({ isEditPoolRoleModalOpen: true, currentPoolRole: poolRole })}>
					edit
				</i>{' '}
				&nbsp;
				<CSVLink data={this.exportAsCsv(poolRole)} filename={`${poolRole.name}-${(+new Date()).toString()}.csv`}>
					<i data-tooltip='Export CSV' data-position='bottom' className='material-icons-outlined boxed-icon'>
						get_app
					</i>
				</CSVLink>{' '}
				&nbsp;
				<i
					data-tooltip='Delete Pool Role'
					data-position='bottom'
					className='material-icons-outlined boxed-icon'
					onClick={() => this.setState({ isDeletePoolRoleModalOpen: true, currentPoolRole: poolRole })}>
					delete
				</i>
			</div>
		);
	}
	exportAsCsv(poolRole) {
		if (poolRole.assignedNurses.length === 0) {
			return [
				{
					Name: poolRole.name,
					'Health System': this.props.selectedHealthSystem.label,
					'Pool Role Date Created': moment(utcToLocalTime(poolRole.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					'Pool Role Created By': poolRole.createdBy,
					'Assigned Nurses': 'N/A',
				},
			];
		} else {
			return poolRole.assignedNurses.map(nurse => {
				return {
					Name: poolRole.name,
					'Health System': this.props.selectedHealthSystem.label,
					'Pool Role Date Created': moment(utcToLocalTime(poolRole.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					'Pool Role Created By': poolRole.createdBy,
					'Assigned Nurse': `${nurse.firstName} ${nurse.lastName}`,
				};
			});
		}
	}

	displayPoolRoles() {
		if (this.state.poolRoles.length === 0) return [];

		return this.state.poolRoles.map(poolRole => {
			return {
				name: poolRole.name,
				healthSystem: this.props.selectedHealthSystem.label,
				createdBy: poolRole.createdBy,
				dateCreated: moment(utcToLocalTime(poolRole.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				assignedNurses: poolRole.assignedNurses.length === 0 ? 'N/A' : this.listAssignedNurses(poolRole.assignedNurses),
				edit: this.getEditButtons(poolRole),
			};
		});
	}

	listAssignedNurses(nurses) {
		return nurses.map(nurse => `${nurse.firstName} ${nurse.lastName}`).join(', ');
	}

	async onHealthSystemSelected(healthSystem) {
		await this.props.setPoolingCurrentHealthSystem(healthSystem);
		this.setState({
			loading: true,
		});

		const selectedHealthSystemId = healthSystem.value;
		const [poolRoles, virtualCareProviders] = await Promise.all([
			getPoolRolesByHealthSystemId(selectedHealthSystemId),
			getHealthSystemsVCPs(selectedHealthSystemId),
		]);
		this.setState({
			loading: false,
			poolRoles: poolRoles,
			virtualCareProviders: virtualCareProviders,
		});
	}

	onCloseAddPoolRoleModal = () => {
		this.setState({
			isAddPoolRoleModalOpen: !this.state.isAddPoolRoleModalOpen,
			addPoolRole: {
				...this.state.addPoolRole,
				loaded: false,
				error: false,
			},
		});
	};

	handleDeletePoolRole = async () => {
		this.setState({ deletingPoolRole: true });
		deletePoolRole(this.props.selectedHealthSystem.value, this.state.currentPoolRole.id).then(() => {
			this.setState({
				poolRoles: this.state.poolRoles.filter(role => {
					return role.id !== this.state.currentPoolRole.id;
				}),
				currentPoolRole: null,
				deletingMember: false,
				isDeletePoolRoleModalOpen: false,
			});
		});
	};

	render() {
		const poolRolesTableHeaders = [
			{ title: 'Pool Role Name' },
			{ title: 'Health System Name' },
			{ title: 'Created By' },
			{ title: 'Date Created' },
			{ title: 'Assigned Nurses' },
			{ edit: '' },
		];
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};
		return (
			<div>
				<Table
					isLoading={this.state.loading || this.props.healthSystems.length === 0}
					headers={poolRolesTableHeaders}
					rows={this.state.loading ? [] : this.displayPoolRoles()}>
					<Grid columns='300px 1fr' vertAlign='center'>
						<Select
							value={this.props.selectedHealthSystem}
							placeholder='Select Health System..'
							classNamePrefix='custom-select'
							options={this.props.healthSystems}
							components={{ DropdownIndicator }}
							onChange={value => this.onHealthSystemSelected(value)}
						/>
						<Button text='Add Pool Role' variant='white' horizAlign='end' marginRight='10px' onClick={() => this.setState({ isAddPoolRoleModalOpen: true })} />
					</Grid>
				</Table>
				<Modal
					modalSelector='addPoolRoleModal'
					display={this.state.isAddPoolRoleModalOpen}
					position='right'
					onModalClose={this.onCloseAddPoolRoleModal}
					onModalSubmit={this.handleSubmitMyForm}
					isLoading={this.state.addPoolRole.loading}>
					<AddPoolRole
						healthSystems={this.props.healthSystems}
						selectedHealthSystem={this.props.selectedHealthSystem}
						onLoading={this.onLoading}
						loaded={this.state.addPoolRole.loaded}
						error={this.state.addPoolRole.error}
						bindSubmitForm={this.bindSubmitForm}
						onSuccess={this.onPoleRoleAdded}
						onCloseAlert={this.onCloseAlert}
					/>
				</Modal>
				<Modal
					modalSelector='deletePoolRoleModal'
					display={this.state.isDeletePoolRoleModalOpen}
					position='center'
					onModalSubmit={() => this.handleDeletePoolRole()}
					onModalClose={() => this.setState({ isDeletePoolRoleModalOpen: false })}>
					<form>
						<h3>Warning</h3>
						<p>Are you sure you want to delete this pool role? All the assigned nurses attached to this pool role will be unassigned.</p>
					</form>
				</Modal>
				<Modal
					modalSelector='editPoolRoleModal'
					display={this.state.isEditPoolRoleModalOpen}
					position='right'
					onModalClose={() => this.setState({ isEditPoolRoleModalOpen: false, currentPoolRole: null })}
					onModalSubmit={this.handleSubmitMyForm}
					isLoading={this.state.editPoolRole.loading || this.state.assignNurse.loading}>
					{this.state.currentPoolRole !== null && (
						<Tabs
							links={[{ link: 'Edit Pool Role', active: true }, { link: 'Assigned Nurses' }]}
							components={[
								<EditPoolRole
									currentPoolRole={this.state.currentPoolRole}
									healthSystemId={this.props.selectedHealthSystem.value}
									bindSubmitForm={this.bindSubmitForm}
									onLoading={this.onLoading}
									loaded={this.state.editPoolRole.loaded}
									error={this.state.editPoolRole.error}
									onSuccess={this.onPoleRoleEdited}
									onCloseAlert={this.onCloseAlert}
								/>,
								<AssignedNurses
									healthSystemId={this.props.selectedHealthSystem.value}
									currentPoolRole={this.state.currentPoolRole}
									virtualCareProviders={this.state.virtualCareProviders}
									bindSubmitForm={this.bindSubmitForm}
									onLoading={this.onLoading}
									loaded={this.state.assignNurse.loaded}
									error={this.state.assignNurse.error}
									onSuccess={this.onNursesAssigned}
									onUnnasignSuccessful={this.onNurseUnassigned}
									onCloseAlert={this.onCloseAlert}
								/>,
							]}
						/>
					)}
				</Modal>
			</div>
		);
	}
}

export default PoolRolesList;

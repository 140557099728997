import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {
	/**
	 * Initialize the Application Insights class
	 * @param {string} instrumentationKey - Application Insights Instrumentation Key
	 * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
	 * @return {void}
	 */
	const initialize = (instrumentationKey, browserHistory) => {
		if (!browserHistory) {
			throw new Error('Could not initialize Telemetry Service');
		}
		if (!instrumentationKey) {
			throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx');
		}

		reactPlugin = new ReactPlugin();

		appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: instrumentationKey,
				maxBatchInterval: 0,
				disableFetchTracking: false,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: {
						history: browserHistory,
					},
				},
			},
		});

		appInsights.loadAppInsights();

		const telemetryInitializer = envelope => {
			const { tags } = envelope;
			tags['ai.cloud.role'] = 'amwell.web';
		};
		appInsights.addTelemetryInitializer(telemetryInitializer);
	};

	return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;

import { OverviewActionTypes } from 'constants/action-types';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

const initialState = {
	breadcrumb: [],
	allHealthSystems: [],
	currentHospitalId: null,
	currentDepartmentId: null,
	currentHealthSystemId: null,
	currentHealthSystemName: '',
	ehrId: '',
	ehrTypeName: '',
	tenantId: '',
	currentRegionId: null,
	treeData: {
		tree: [],
		preSelected: [],
	},
	currentHelloDevice: null,
	currentSectorLocation: [],
	showAssignDeviceModalData: {
		show: false,
		deviceId: null,
		assignCompleted: false,
	},
	isDarkThemeEnabled: JSON.parse(getStorage().getItem('isDarkThemeEnabled')) || true,
	isLeftNavigationExpanded: false,
	isTreeVisible: true,
	hsLogo: null,
};

const organization = (state, action) => {
	const newState = state ? { ...state } : { ...initialState };
	if (action.type === OverviewActionTypes.updateBreadcrumb) {
		return { ...newState, breadcrumb: action.payload };
	}

	if (action.type === OverviewActionTypes.toggleTreeVisibility) {
		return { ...newState, isTreeVisible: action.payload };
	}

	if (action.type === OverviewActionTypes.toggleAssignDeviceModal) {
		return {
			...newState,
			showAssignDeviceModalData: {
				show: action.payload.show,
				deviceId: action.payload.deviceId,
				assignCompleted: action.payload.assignCompleted,
			},
		};
	}

	if (action.type === OverviewActionTypes.setDarkTheme) {
		return {
			...newState,
			isDarkThemeEnabled: action.payload,
		};
	}

	if (action.type === OverviewActionTypes.setAllHealthSystems) {
		return {
			...newState,
			allHealthSystems: action.payload.healthSystems,
			currentHealthSystemId: action.payload.currentHealthSystem.currentHealthSystemId,
			currentRegionId: action.payload.currentHealthSystem.currentRegionId,
			currentHealthSystemName: action.payload.currentHealthSystem.currentHealthSystemName,
			ehrId: action.payload.currentHealthSystem.ehrId,
			ehrTypeName: action.payload.currentHealthSystem.ehrTypeName,
			tenantId: action.payload.currentHealthSystem.tenantId,
		};
	}

	if (action.type === OverviewActionTypes.setCurrentHealthSystemInfo) {
		return {
			...newState,
			currentHealthSystemId: action.payload.currentHealthSystemId,
			currentRegionId: action.payload.currentRegionId,
			currentHealthSystemName: action.payload.currentHealthSystemName,
			ehrId: action.payload.ehrId,
			ehrTypeName: action.payload.ehrTypeName,
			tenantId: action.payload.tenantId,
		};
	}

	if (action.type === OverviewActionTypes.createNewDepartment) {
		return {
			...newState,
			treeData: {
				tree: [...newState.treeData.tree, action.payload],
			},
		};
	}
	if (action.type === OverviewActionTypes.createNewHospital) {
		return {
			...newState,
			treeData: {
				tree: [...newState.treeData.tree, action.payload],
			},
		};
	}
	if (action.type === OverviewActionTypes.setCurrentHospitalId) {
		return { ...newState, currentHospitalId: action.payload };
	}

	if (action.type === OverviewActionTypes.setCurrentDepartmentId) {
		return { ...newState, currentDepartmentId: action.payload };
	}

	if (action.type === OverviewActionTypes.setHospitalTree) {
		return { ...newState, hospitalTree: action.payload };
	}

	if (action.type === OverviewActionTypes.setTreeData) {
		return { ...newState, treeData: action.payload };
	}

	if (action.type === OverviewActionTypes.setCurrentSectorLocation) {
		return { ...newState, currentSectorLocation: action.payload };
	}

	if (action.type === OverviewActionTypes.setCurrentHSLogo) {
		return { ...newState, hsLogo: action.payload };
	}

	return newState;
};

export default organization;

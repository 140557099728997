import React, { Component } from 'react';
import { If, Then, Else } from 'react-if';

import { Grid, Form, Input, Button, Select, Modal } from 'components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionCreators } from 'state/organization/actions';
import { bindActionCreators } from 'redux';
import { createHealthSystem } from 'api/healthSystems';
import { getEhrTypes } from 'api/ehrTypes';
import { saveLogo, uploadLogo } from 'api/organization';
import ReactCrop from 'react-image-crop';
import Autocomplete from 'react-autocomplete';

class CreateHealthSystem extends Component {
	state = {
		healthSystemName: '',
		healthSystemNameError: '',
		ehrTypeNameError: '',

		tenantId: null,
		tenantError: '',

		ehrId: null,
		ehrIdError: '',

		healthSystemLogo: '',
		healthSystemLogoError: false,

		ehrTypes: [],
		ehrTypeOther: '',
		ehrTypeOtherError: '',

		isHealthSystemCreated: false,
		isLoading: false,
		isHealthSystemLogoModalLoading: false,
		isUploadLogoModalOpen: false,
		logoData: null,
		crop: {
			width: 200,
			height: 200,
		},
	};

	maxHealthSystemCharacters = 127;

	convertEHRTypeKey(ehrTypes) {
		return ehrTypes.map(ehr => {
			return {
				label: ehr.name,
			};
		});
	}

	async componentDidMount() {
		const { ehrTypes } = await getEhrTypes();
		if (ehrTypes) {
			const convertedEHRTypes = this.convertEHRTypeKey(ehrTypes);
			this.setState({ ehrTypes: convertedEHRTypes });
		} else this.setState({ ehrTypesError: 'Could not fetch EHR types !!' });
	}

	setHealthSystemName = event => {
		this.setState({
			healthSystemName: event.target.value,
		});
	};

	checkIfHealthSystemExists = hsName => {
		return this.props.allHealthSystems.some(healthSystem => {
			return healthSystem.name.toLowerCase() === hsName.toLowerCase();
		});
	};

	createHealthSystem = async event => {
		event.preventDefault();

		if (this.isValidHealthSystem() && this.isValidEhrType()) {
			this.setState({
				isLoading: true,
			});

			const { healthSystemName, ehrId, tenantId, ehrTypeOther } = this.state;

			const newHS = await createHealthSystem(healthSystemName, ehrId, ehrTypeOther, tenantId);
			const {
				data: {
					data: { healthSystem },
				},
			} = newHS;

			if (this.state.logoData) {
				await saveLogo(healthSystem.id, this.state.logoData);
			}

			this.setState({
				isHealthSystemCreated: true,
				healthSystemNameError: '',
				ehrTypeNameError: '',
				ehrId: null,
				ehrTypeOther: null,
				tenantId: null,
				isLoading: false,
			});
		}
	};

	isValidHealthSystem = () => {
		let hsExists = this.checkIfHealthSystemExists(this.state.healthSystemName);

		let healthSystemNameError = '';
		if (this.state.healthSystemName.length > this.maxHealthSystemCharacters) {
			healthSystemNameError = `The length of Health System name must be ${this.maxHealthSystemCharacters} characters or fewer.`;
		}
		if (hsExists) {
			healthSystemNameError = 'Health System with this name already exists';
		}

		this.setState({
			healthSystemNameError,
		});
		return !healthSystemNameError;
	};

	isValidEhrType = () => {
		const maxEhrTypeNameCharacters = 63;
		let ehrTypeNameError = '';
		if (this.state.ehrTypeOther.length > maxEhrTypeNameCharacters) {
			ehrTypeNameError = `The length of Ehr Type Name must be ${maxEhrTypeNameCharacters} characters or fewer.`;
		}
		this.setState({
			ehrTypeNameError,
		});
		return !ehrTypeNameError;
	};

	handleCancelClick = () => {
		this.props.history.push('/');
	};

	handleChange = evt => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};

	onSelectFile = ({ target }) => {
		const [file] = target.files;
		if (!file) {
			return;
		}

		if (file.size > 600000 || !file.type.includes('png')) {
			this.setState({ healthSystemLogoError: true, healthSystemLogo: '' });
			return;
		}
		const reader = new FileReader();
		reader.addEventListener('load', () => this.setState({ src: reader.result }));
		reader.readAsDataURL(file);

		this.setState({ healthSystemLogo: file, isUploadLogoModalOpen: true, healthSystemLogoError: false });
	};

	getImageCrop = () => {
		const { crop } = this.state;

		const image = this.imageRef;
		const xScale = image.naturalWidth > image.width ? image.naturalWidth / image.width : 1;
		const yScale = image.naturalHeight > image.height ? image.naturalHeight / image.height : 1;

		return {
			width: crop.width * xScale,
			height: crop.height * yScale,
			x: crop.x * xScale,
			y: crop.y * yScale,
		};
	};

	uploadLogo = async () => {
		const { data, error } = await uploadLogo(this.state.healthSystemLogo, this.getImageCrop());
		let errorStateParameters = null;

		if (error) {
			errorStateParameters = {
				fileError: error.request.status === 400,
				isEditSectorModalOpen: true,
			};
		}

		this.setState({
			isHealthSystemLogoModalLoading: false,
			isUploadLogoModalOpen: false,
			src: '',
			...errorStateParameters,
		});

		return data;
	};

	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropChange = crop => {
		this.setState({ crop });
	};

	makeClientCrop = async () => {
		this.setState({
			isHealthSystemLogoModalLoading: true,
			crop: {
				width: 200,
				height: 200,
			},
		});
		if (this.imageRef && this.state.crop.width && this.state.crop.height) {
			const imageData = await this.uploadLogo();
			if (imageData) {
				const logoData = {
					picture: imageData.originalUrl,
				};
				this.setState({ logoData });
			}
		}
	};

	toggleHealthSystemLogoCrop = () => {
		this.setState({
			isUploadLogoModalOpen: !this.state.isUploadLogoModalOpen,
			crop: {
				width: 200,
				height: 200,
			},
		});
	};

	handleAutocompleteChange = evt => {
		this.setState({
			ehrTypeOther: evt.target.value,
		});
	};

	handleAutocompleteSelect = val => {
		this.setState({
			ehrTypeOther: val,
		});
	};

	renderEhrType = (state, val) => {
		return state.label.toLowerCase().indexOf(val.toLowerCase()) !== -1;
	};

	render() {
		return (
			<If condition={this.state.isHealthSystemCreated === false}>
				<Then>
					<Grid className='create-hs' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' rows='auto'>
						<Form title='Create New Health System' onSubmit={event => this.createHealthSystem(event)}>
							<Input
								type='text'
								label='Health System Name'
								validationOptions={{ required: true }}
								onKeyUp={this.setHealthSystemName}
								placeholder='Health System Name'
								variant='filled'
								bottomSpace='15px'
								error={this.state.healthSystemNameError}
							/>
							<Input
								type='file'
								label='Health System Logo'
								validationOptions={{ required: false }}
								onChange={this.onSelectFile}
								name='healthSystemLogo'
								placeholder='Health System Logo'
								variant='filled'
								bottomSpace='15px'
								accept='.png'
								error={this.state.healthSystemLogoError ? `Picture size can't exceed 600KB and file type should be .PNG.` : ''}
							/>

							<div className='input'>
								<p className='label'>EHR Type</p>
								<Autocomplete
									getItemValue={item => item.label}
									items={this.state.ehrTypes}
									renderItem={(item, isHighlighted) => (
										<div
											style={{
												padding: 4,
												fontSize: 14,
												background: isHighlighted ? 'var(--dark-theme-gray-2)' : 'var(--dark-theme-gray-3)',
												color: 'var(--gray-0)',
											}}>
											{item.label}
										</div>
									)}
									shouldItemRender={this.renderEhrType}
									value={this.state.ehrTypeOther}
									wrapperStyle={{ width: '100' }}
									onChange={this.handleAutocompleteChange}
									menuStyle={{ position: 'initial', overflow: 'auto', maxHeight: 200 }}
									inputProps={{ placeholder: 'EHR Type' }}
									onSelect={val => this.handleAutocompleteSelect(val)}
								/>
								<p>{this.state.ehrTypeOtherError}</p>
								<p>{this.state.ehrTypeNameError}</p>
							</div>
							<Input
								type='text'
								label='EHR ID'
								validationOptions={{ maxLength: 127 }}
								onChange={this.handleChange}
								value={this.state.ehrId}
								name='ehrId'
								placeholder='EHR ID'
								variant='filled'
								bottomSpace='15px'
								error={this.state.ehrIdError}
							/>
							<Input
								type='text'
								label='Tenant ID'
								validationOptions={{ maxLength: this.maxHealthSystemCharacters }}
								onChange={this.handleChange}
								value={this.state.tenantId}
								name='tenantId'
								placeholder='Tenant ID'
								variant='filled'
								bottomSpace='15px'
								error={this.state.tenantError}
							/>

							<Modal
								modalSelector='uploadHospitalLogo'
								className='crop-modal'
								display={this.state.isUploadLogoModalOpen}
								position='center'
								submitButtonText='Save'
								onModalSubmit={this.makeClientCrop}
								onModalClose={this.toggleHealthSystemLogoCrop}
								isLoading={this.state.isHealthSystemLogoModalLoading}>
								{this.state.src && (
									<div>
										<ReactCrop
											src={this.state.src}
											crop={this.state.crop}
											ruleOfThirds={true}
											onImageLoaded={this.onImageLoaded}
											onChange={this.onCropChange}
										/>
									</div>
								)}
							</Modal>

							<div className='create-hs__add' data-cy='createHSActions'>
								<Button text='Cancel' variant='white' onClick={this.handleCancelClick} />
								&nbsp;&nbsp;
								<Button type='submit' text='Save' isLoading={this.state.isLoading} />
							</div>
						</Form>
					</Grid>
				</Then>
				<Else>
					<div className='create-hs-success' data-cy='createHSMessage'>
						<span>
							<i className='material-icons create-hs-success-icon'>done</i>
						</span>
						<h3>Your health system profile has been created successfully.</h3>
						<span>
							<Button text=' Go Back to AmWell App' variant='blue' onClick={this.handleCancelClick} />
						</span>
					</div>
				</Else>
			</If>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(CreateHealthSystem));

import React from 'react';
import { Modal } from 'components';
import { postUserIdleMessage, onUserIdleMessage, closeUserIdleChannel } from 'infrastructure/helpers/userIdleChannel';
import { getUserRoleId } from 'infrastructure/auth';
import { getUserIdleConfigurationMinutes } from 'api/userIdleConfigurations';

export default class UserIdleCheck extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogoutAlertOn: false,
			secondCounter: null,
			minutes: null,
		};
	}

	userIdleTimeout = null;

	alertDisplayTime = 30; // seconds

	postMessages = {
		CONFIRMED: 'CONFIRMED',
		IN_CALL: 'IN_CALL',
	};

	componentDidMount = async () => {
		const roleId = getUserRoleId();
		if (this.props.healthSystemId && roleId) {
			this.unbindListeners();
			await this.getUserIdleConfiguration(this.props.healthSystemId, roleId);
			this.startTimeout();
			this.bindListeners();
		}
	};

	componentDidUpdate = async prevProps => {
		if (prevProps.healthSystemId !== this.props.healthSystemId) {
			const roleId = getUserRoleId();
			await this.getUserIdleConfiguration(this.props.healthSystemId, roleId);
			this.startTimeout();
			this.resetTimeout();
		}
	};

	getUserIdleConfiguration = async (healthSystemId, roleId) => {
		try {
			const result = await getUserIdleConfigurationMinutes(healthSystemId, roleId);
			this.setState({
				minutes: result ? result.minutes : null,
			});
		} catch (e) {
			this.setState({
				minutes: null,
			});
		}
	};

	componentWillUnmount = () => {
		this.unbindListeners();
		clearTimeout(this.userIdleTimeout);
		clearInterval(this.logoutInterval);
		closeUserIdleChannel();
	};

	setActiveUser = () => {
		postUserIdleMessage();
		this.resetTimeout();
	};

	resetTimeout = () => {
		if (this.userIdleTimeout && !this.state.isLogoutAlertOn) {
			clearTimeout(this.userIdleTimeout);
			this.startTimeout();
		}
	};

	bindListeners = () => {
		window.addEventListener('mousemove', this.setActiveUser);
		window.addEventListener('scroll', this.setActiveUser);
		window.addEventListener('keydown', this.setActiveUser);
		window.addEventListener('resize', this.setActiveUser);
		window.addEventListener('click', this.setActiveUser);
		onUserIdleMessage(this.tabsListener);
	};

	tabsListener = message => {
		if (message.data === this.postMessages.CONFIRMED) {
			this.resetActivity();
		} else {
			this.resetTimeout();
		}
	};

	unbindListeners = () => {
		window.removeEventListener('mousemove', this.setActiveUser);
		window.removeEventListener('scroll', this.setActiveUser);
		window.removeEventListener('keydown', this.setActiveUser);
		window.removeEventListener('resize', this.setActiveUser);
		window.removeEventListener('click', this.setActiveUser);
	};

	startTimeout = () => {
		if (this.state.minutes) {
			this.userIdleTimeout = setTimeout(() => {
				this.openLogoutAlert();
			}, this.state.minutes * 60000);
		}
	};

	openLogoutAlert = () => {
		clearTimeout(this.userIdleTimeout);
		clearInterval(this.logoutInterval);

		this.setState({ isLogoutAlertOn: true, secondCounter: this.alertDisplayTime });

		this.logoutInterval = setInterval(() => {
			const { secondCounter } = this.state;
			if (secondCounter > 0) {
				this.setState({ secondCounter: this.state.secondCounter - 1 }, () => {
					if (this.state.secondCounter === 0) {
						clearInterval(this.logoutInterval);
						const { pathname } = window.location;
						const conferencePaths = ['/talk-to-patient', '/view-patient', '/monitoring'];

						if (conferencePaths.includes(pathname)) {
							this.setState({ isLogoutAlertOn: false });
							window.postMessage(this.postMessages.IN_CALL, window.location.href);
						} else {
							window.location.href = '/logout';
						}
					}
				});
			}
		}, 1000);
	};

	closeAlert = () => {
		postUserIdleMessage(this.postMessages.CONFIRMED);
		this.resetActivity();
	};

	resetActivity = () => {
		this.setState({
			isLogoutAlertOn: false,
		});
		clearInterval(this.logoutInterval);
		this.setActiveUser();
	};

	render() {
		return (
			<Modal
				className='user-idle-modal'
				display={this.state.isLogoutAlertOn}
				position='center'
				onModalSubmit={this.closeAlert}
				onModalClose={this.closeAlert}
				hideCloseButton={true}
				submitButtonText={`I'm here`}>
				<>
					<h4>You have been idle for {this.state.minutes} minutes</h4>
					<p>You will be logged out in case you don&rsquo;t confirm your presence within the next {this.state.secondCounter} seconds</p>
				</>
			</Modal>
		);
	}
}

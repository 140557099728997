import React from 'react';
import Grid from 'components/Grid';
import { StreamError } from 'constants/enums';

const StreamPermissions = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='stream-permissions' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				{props.reason.type === StreamError.PERMISSION_DISMISSED.type && (
					<div>
						<h2>Click “Allow” to Make a Call</h2>
						<br />
						<p>
							To make a call on Amwell, you need to allow permissions for microphone and camera.
							<br />
							This will let the other participants hear and see you.
						</p>
						<br />
						<p>You can always turn off your microphone or camera later.</p>
					</div>
				)}

				{props.reason.type === StreamError.STREAM_NOT_ALLOWED.type && (
					<div>
						<h3>Turn on Microphone and Camera</h3>
						<br />
						<p>To make a call on Amwell, you need to allow permissions for microphone and camera.</p>
					</div>
				)}

				{props.reason.type === StreamError.DEVICE_NOT_FOUND.type && (
					<div>
						<h2>Device not found</h2>
						<br />
						<p>
							To make a video call on Amwell, you need to turn on your camera. For
							<br />
							an audio call, you need to turn on your microphone. Try again by refreshing
							<br />
							the page.
						</p>
					</div>
				)}

				{props.reason.type === StreamError.NOT_READABLE_ERROR.type && (
					<div>
						<h2>Device may be in use</h2>
						<br />
						<p>
							Camera or microphone may be in use by another application.
							<br />
							Please close the other application and refresh the page.
						</p>
					</div>
				)}
			</div>
		</Grid>
	</div>
);

export default StreamPermissions;

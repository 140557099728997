import React, { Component } from 'react';
import { DeviceCommands, AudioOutputDevice, SerialTVCommands } from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';

class AudioOutputs extends Component {
	constructor(props, socket) {
		super(props);
		this.socket = socket;
	}

	componentDidMount() {
		this.socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, this.onInitialState);
		this.socket.on(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, this.nAudioOutputDevice);
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, this.onInitialState);
		this.socket.off(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, this.nAudioOutputDevice);
	}

	onInitialState = data => {
		if (!data || data.helloDeviceId !== this.props.participant.objectId) {
			return;
		}

		const { audioOutputDevice, tvHdmiPort } = data;
		Object.assign(this.props.participant, { audioOutputDevice: audioOutputDevice, tvHdmiPort: SerialTVCommands.HDMI[`SWITCH_HDMI${tvHdmiPort}`] });
		this.props.onOutputChange(audioOutputDevice);
		this.setState({});
	};

	nAudioOutputDevice = data => {
		if (!data.isSwitchSuccessful) {
			// eslint-disable-next-line no-console
			console.error(
				`Switch was not successful! Current audio device is ${AudioOutputDevice.HELLO === data.audioOutputDevice ? 'Amwell Bridge' : 'Pillow Speaker'}`,
				data.message
			);
		}
		Object.assign(this.props.participant, { audioOutputDevice: data.audioOutputDevice });
		this.setState({});
	};

	changeAudioOutput = async audioOutputDevice => {
		const dynamicData = JSON.stringify({ audioOutputDevice: audioOutputDevice });
		const response = await this.props.participant.deviceCommand(DeviceCommands.SWITCH_AUDIO_OUTPUT, dynamicData);
		if (!response?.deviceCommandSent) {
			return;
		}
		Object.assign(this.props.participant, {
			audioOutputDevice,
		});
		this.props.onOutputChange(audioOutputDevice);
		this.setState({});
	};

	render() {
		const { participant } = this.props;
		if (participant.audioOutputDevice == null && participant.tvHdmiPort == null) {
			return <></>;
		}

		const callButtonIcon = participant.audioOutputDevice === AudioOutputDevice.HELLO ? 'tv' : 'radio_button_unchecked';
		return (
			participant.tvStatus && (
				<button
					type='button'
					className={participant?.hdmiStatus?.tvState?.tvStatus !== participant?.tvHdmiPort ? 'disabled' : ''}
					onClick={() => this.changeAudioOutput(participant.audioOutputDevice === AudioOutputDevice.HELLO ? AudioOutputDevice.TV : AudioOutputDevice.HELLO)}>
					<i className='material-icons'>{callButtonIcon}</i>
					{participant.audioOutputDevice === AudioOutputDevice.HELLO ? 'Switch audio to TV' : 'Switch audio to TV Kit'}
				</button>
			)
		);
	}
}

AudioOutputs.contextType = SocketContext;
export default AudioOutputs;

import { DeviceActionTypes } from 'constants/action-types';
/* eslint-disable import/prefer-default-export */

export const actionCreators = {
	setBusyDevice: deviceId => async dispatch => {
		dispatch({ type: DeviceActionTypes.SET_DEVICE_BUSY, payload: deviceId });
	},
	removeBusyDevice: deviceId => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_DEVICE_BUSY, payload: deviceId });
	},
	setBulkDevicesBusy: devices => async dispatch => {
		dispatch({ type: DeviceActionTypes.SET_BULK_DEVICES_BUSY, payload: devices });
	},
	removeBulkDevicesBusy: () => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_BULK_DEVICES_BUSY });
	},
};

const { Offer, Answer, IceCandidate, SubscriberRestartIce } = require('@solaborate/webrtc');
const { default: SocketEvents } = require('constants/socket-events');

export default class ConnectionSignaling {
	// actioneeParticipantId == null -> mediaserver
	constructor(socket, conferenceId, participantId, actioneeParticipantIdOrMsDirection) {
		this.socket = socket;
		this.conferenceId = conferenceId;
		this.participantId = participantId;
		this.actioneeParticipantId = actioneeParticipantIdOrMsDirection;
		this.msDirection = actioneeParticipantIdOrMsDirection;
		this.ms = ['subscriber', 'publisher'].includes(actioneeParticipantIdOrMsDirection);
		this.sip = actioneeParticipantIdOrMsDirection === 'sip';
	}

	on = signal => console.log(signal);

	emit = signal => {
		let event;
		const eventData = {
			conferenceId: this.conferenceId,
			participantId: this.participantId,
			actioneeParticipantId: this.actioneeParticipantId,
			msDirection: this.msDirection,
		};

		if (this.sip) {
			switch (signal.constructor) {
				case Answer:
					event = SocketEvents.Conference.SEND_SIP_ANSWER;
					delete eventData.actioneeParticipantId;
					break;
				default:
					return;
			}
		} else if (this.ms) {
			switch (signal.constructor) {
				case Offer:
					event = SocketEvents.MediaServer.OUTGOING_SEND_OFFER;
					break;
				case Answer:
					event = SocketEvents.MediaServer.INCOMING_SEND_ANSWER;
					break;
				case IceCandidate:
					event = this.msDirection === 'publisher' ? SocketEvents.MediaServer.OUTGOING_SEND_ICE : SocketEvents.MediaServer.INCOMING_SEND_ICE;
					break;
				case SubscriberRestartIce:
					event = SocketEvents.MediaServer.INCOMING_SEND_RESTART_ICE;
				default:
			}
		} else {
			switch (signal.constructor) {
				case Offer:
					event = SocketEvents.Conference.SEND_OFFER;
					break;
				case Answer:
					event = SocketEvents.Conference.SEND_ANSWER;
					break;
				case IceCandidate:
					event = SocketEvents.Conference.SEND_ICE_CANDIDATE;
					// TODO change this
					// eslint-disable-next-line no-param-reassign
					signal = {
						candidate: {
							sdp: signal.candidate.candidate,
							sdpMLineIndex: signal.candidate.sdpMLineIndex,
							sdpMid: signal.candidate.sdpMid,
						},
					};
					break;
				default:
					return;
			}
		}

		Object.assign(eventData, signal);
		// do not cache events
		if (this.socket.connected) {
			this.socket.emit(event, eventData);
		} else {
			console.log('Skip webrtc event, socket not connected', event);
		}
	};
}

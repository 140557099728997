import React from 'react';
import Select from 'react-select';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers';
import { Input } from 'components';

const Location = props => {
	const getCountryValue = () => props.transformArray(props.countries).filter(option => option.value === props.values.country);
	const getStateValue = () => props.transformArray(props.states).filter(option => option.value === props.values.stateCode);
	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;

	return (
		<div className='margin-top-xl'>
			<p style={{ fontWeight: 500, margin: 0, fontSize: '14px' }}>Country</p>
			<Select
				value={getCountryValue()}
				placeholder='Country'
				classNamePrefix='custom-select'
				options={props.transformArray(props.countries)}
				onChange={event => props.formikProps?.setFieldValue('country', event.value)}
				components={{ DropdownIndicator }}
				isSearchable={false}
			/>
			<small className='red-error'>{props.errors.country}</small>
			{props.values.country === 'US' && (
				<>
					<p style={{ fontWeight: 500, margin: 0 }}>State</p>
					<Select
						value={getStateValue()}
						placeholder='Select State'
						classNamePrefix='custom-select'
						options={props.transformArray(props.states)}
						onChange={event => props.formikProps?.setFieldValue('stateCode', event.value)}
						components={{ DropdownIndicator }}
						isSearchable={false}
					/>
					<small className='red-error'>{props.errors.stateCode}</small>
				</>
			)}
			<Input
				type='text'
				label='City'
				placeholder='City'
				value={props.values.city}
				onChange={props.onChange}
				name='city'
				validationOptions={{ required: true, minLength: 2, maxLength: 30 }}
			/>
			<Input
				type='number'
				label='Postal Code'
				placeholder='Postal Code'
				value={props.values.zipCode}
				onChange={props.onChange}
				name='zipCode'
				validationOptions={{ required: true, minLength: 2, maxLength: 10 }}
				className='number-input-wo-arrows'
				onKeyDown={event => validateMaxLength(event, 10)}
			/>
			<Input
				type='text'
				label='Address'
				placeholder='Address'
				value={props.values.address}
				onChange={props.onChange}
				name='address'
				validationOptions={{ required: true, minLength: 2, maxLength: 50 }}
			/>
		</div>
	);
};
export default Location;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Form from 'components/Form';
import Modal from 'components/Modal';
import { CallTypes, KeyCodes, MediaControlCommands, MediaTypes } from 'constants/enums';

const StyledCameraBookmarks = styled.div`
	width: ${props => (props.callType === CallTypes.MONITORING ? '200px' : '240px')};
	margin-top: 10px;
	position: absolute;
	bottom: ${props => (props.callType === CallTypes.MONITORING ? 'unset' : '-12px')};
	right: ${props => (props.callType === CallTypes.MONITORING ? 'unset' : '195px')};
	left: ${props => (props.callType === CallTypes.MONITORING ? '0' : 'unset')};
	top: ${props => (props.callType === CallTypes.MONITORING ? '170px' : 'unset')};

	button:first-child {
		width: 100%;
		margin: 0;
		display: flex;
		align-items: center;
		background: rgba(52, 58, 64, 0.8);
		border: none;
		border-radius: ${props => (props.isBookmarksOpen ? '0px' : '20px')};
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		padding: 5px 10px;
		color: var(--gray-5);
		display: flex;
		cursor: pointer;
		justify-content: space-between;
		font-size: 14px !important;

		div {
			text-align: left;

			div {
				display: flex;
				align-items: center;
				margin-top: var(--spacing-s);
				i {
					margin-left: 0;
					color: rgba(255, 255, 255, 0.75);
				}
				span {
					color: rgba(255, 255, 255, 0.75);
				}
			}
		}
	}

	input {
		width: 100%;
		border: none;
		outline: none;
		padding: 2px;
	}

	aside {
		background: rgba(52, 58, 64, 0.8);
		padding: 10px;
		border-radius: ${props => (props.callType !== CallTypes.MONITORING ? '20px' : '0')}
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		position: relative !important;
		display: block !important;
		right: unset !important;
		top: unset !important;
	}

	i {
		font-size: 18px;
		margin-left: var(--spacing-s);
		color: var(--gray-0);
		cursor: pointer;
	}

	small {
		display: block;
    	color: var(--red-1);
    	margin-top: var(--spacing-m);
	}
`;

const CameraBookmarks = props => {
	const [newBookmarkInput, setNewBookmarkInput] = useState(false);
	const [newBookmark, setNewBookmark] = useState('');
	const [maxReachedError, setMaxReachedError] = useState(false);
	const [showEditDeleteButtons, setShowEditDeleteButtons] = useState(false);
	const bookmarksLimit = 9;

	useEffect(() => {
		if (newBookmarkInput) {
			setMaxReachedError(false);
		}
	}, [newBookmarkInput]);

	const addNewBookmark = evt => {
		if (evt.which === KeyCodes.ENTER && props.bookmarks.length <= bookmarksLimit) {
			if (props.bookmarks.length >= bookmarksLimit) {
				setMaxReachedError(true);
				setNewBookmark('');
				return;
			}
			props.sendCameraEvents(MediaControlCommands.ADD_BOOKMARK, MediaTypes.CAMERA, evt.target.value);

			setNewBookmarkInput(false);
			setNewBookmark('');
		}
	};

	const handleSubmit = () => {
		if (props.bookmarks.length >= bookmarksLimit) {
			setMaxReachedError(true);
			setNewBookmark('');
			return;
		}
		props.sendCameraEvents(MediaControlCommands.ADD_BOOKMARK, MediaTypes.CAMERA, newBookmark);
		setNewBookmarkInput(false);
		setNewBookmark('');
	};

	const deleteBookmark = item => {
		props.sendCameraEvents(MediaControlCommands.DELETE_BOOKMARK, MediaTypes.CAMERA, item.id);
	};

	const updatePosition = (command, item) => {
		props.sendCameraEvents(command, MediaTypes.CAMERA, item.id);
	};

	return (
		<StyledCameraBookmarks callType={props.callType} isBookmarksOpen={props.isBookmarksOpen}>
			{props.callType === CallTypes.MONITORING && (
				<button type='button' onClick={() => props.setIsBookmarksOpen(!props.isBookmarksOpen)}>
					<div>
						<span>Presets</span>
						<div>
							<i className='material-icons'>bookmark</i> <span>{props.selectedBookmark?.name || 'No active Preset'}</span>
						</div>
					</div>

					<i className='material-icons'>{props.isBookmarksOpen ? 'expand_less' : 'expand_more'}</i>
				</button>
			)}
			{(props.callType !== CallTypes.MONITORING || props.isBookmarksOpen) && (
				<aside>
					<section className='bookmark-list'>
						{props.bookmarks.map(bookmark => (
							<div className='bookmark-item' key={bookmark.id}>
								<span onClick={() => updatePosition(MediaControlCommands.UPDATE_TO_CURRENT_POSITION, bookmark)}>{bookmark.name}</span>
								<div className='bookmark-buttons'>
									<i
										className='material-icons'
										data-tooltip='Go to preset'
										data-position='bottom'
										onClick={() => updatePosition(MediaControlCommands.UPDATE_TO_CURRENT_POSITION, bookmark)}>
										my_location
									</i>
									{showEditDeleteButtons && (
										<>
											<i
												className='material-icons'
												data-tooltip='Edit preset'
												data-position='bottom'
												onClick={() => updatePosition(MediaControlCommands.EDIT_BOOKMARK, bookmark)}>
												edit
											</i>
											<i className='material-icons' data-tooltip='Delete preset' data-position='bottom' onClick={() => deleteBookmark(bookmark)}>
												delete
											</i>
										</>
									)}
								</div>
							</div>
						))}
						<div className='bookmark-item'>
							<span onClick={() => setNewBookmarkInput(true)}>Add a new Preset</span>
							<div className='bookmark-buttons'>
								<i className='material-icons' data-tooltip='Add a new Preset' data-position='bottom' onClick={() => setNewBookmarkInput(true)}>
									add
								</i>
								<i
									className='material-icons-outlined'
									data-tooltip={`${showEditDeleteButtons ? 'Hide' : 'Show'} Edit/Delete`}
									data-position='bottom'
									onClick={() => setShowEditDeleteButtons(prevState => !prevState)}>
									{showEditDeleteButtons ? 'visibility' : 'visibility_off'}
								</i>
							</div>
						</div>
					</section>
				</aside>
			)}
			<Modal
				isLoading={false}
				display={newBookmarkInput}
				position='center'
				submitButtonText={
					<div className='submit-buttons'>
						<span className='material-icons'>add</span>
						<p>Create new Preset</p>
					</div>
				}
				closeButtonText={
					<div className='submit-buttons'>
						<span className='material-icons'>close</span>
						<p>Cancel</p>
					</div>
				}
				isSubmitDisabled={!newBookmark}
				onModalSubmit={handleSubmit}
				className='bookmark-form-wrapper'
				onModalClose={() => setNewBookmarkInput(false)}>
				<Form title='Create Preset' onSubmit={event => event.preventDefault()}>
					<span>Preset Name</span>
					<span className='material-icons-outlined' data-tooltip='The Presets maximum limit is 9.' data-position='bottom'>
						info
					</span>

					<input
						type='text'
						placeholder='Type preset description'
						value={newBookmark}
						onChange={e => setNewBookmark(e.target.value)}
						onKeyDown={addNewBookmark}
					/>
					<span>You can change it anytime</span>
					{maxReachedError && <small>You have reached the maximum limit of presets. Please delete a preset to create a new one.</small>}
				</Form>
			</Modal>
		</StyledCameraBookmarks>
	);
};

export default CameraBookmarks;

import { gatewayApi, APP_CONFIG } from '../constants/global-variables';

export const getPastConferences = async params => {
	try {
		const { data } = await gatewayApi.get(`/v1/conferences/past/organization/${APP_CONFIG.companyId}`, {
			params,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export function getDeviceOngoingConferencesByLevel(level, id, pageSize = 10, pageIndex = 0) {
	let params = {
		level: level,
		id: id || APP_CONFIG.companyId,
		pageSize: pageSize,
		pageIndex: pageIndex,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1.1/conferences/ongoing/organization-devices/${APP_CONFIG.companyId}`, {
				params: params,
			})
			.then(response => {
				res(response.data.result);
			})
			.catch(e => rej(e));
	});
}

export const startExportLogsTask = async ({ level, id, start, end }) => {
	try {
		let params = {
			level: level,
			id: id || APP_CONFIG.companyId,
			start: Math.round(new Date(start).getTime() / 1000),
			end: Math.round(new Date(end).getTime() / 1000),
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		};

		const { data } = await gatewayApi.get(`v1/tasks/companies/${APP_CONFIG.companyId}/conferences`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getOrgConferenceOngoingParticipants = async conferenceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organization/${APP_CONFIG.companyId}/conferences/ongoing/${conferenceId}/participants`);
		return data;
	} catch (error) {
		return { error };
	}
};

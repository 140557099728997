import React from 'react';
import { AuthConsumer } from '../../providers/authProvider';
import Loader from 'components/Loader';
import Grid from 'components/Grid';

export const Callback = () => (
	<AuthConsumer>
		{({ signinRedirectCallback }) => {
			signinRedirectCallback();
			return (
				<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			);
		}}
	</AuthConsumer>
);

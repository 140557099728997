import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import queryString from 'query-string';

import { Grid, Button, Loader } from 'components';
import Input from 'components/Common/FormElements/Input';
import { Redirect } from 'react-router-dom';
import { registerUser, getInvite, checkEmailByInviteId, acceptInvite, sendPasswordRecoveredEmail } from 'api/users';
import { OrganizationErrorCodes, GeneralErrorCodes, ChangePasswordUseCases } from 'constants/enums';
import { isAuthenticated, getUserProfile } from 'infrastructure/auth';
import LogOutUser from './LogOutUser';
import { passwordRegEx } from 'constants/global-variables';

class CompleteProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inviteId: 0,
			email: '',
			inviteValidation: {
				loading: false,
				successResponse: {},
				error: null,
			},
			submitUserRegistration: {
				loading: false,
				error: false,
				success: false,
			},
		};
	}

	async componentDidMount() {
		const params = queryString.parse(window.location.search);
		const inviteId = params.id;
		const email = params.e;
		const companyId = params.cid;
		const code = params.code ? params.code : '';
		const token = params.itoken;

		this.setState({
			inviteId,
			email,
			companyId,
			code,
			token,
			inviteValidation: {
				...this.state.inviteValidation,
				loading: true,
			},
		});

		if (!isAuthenticated()) {
			getInvite(inviteId, email, token)
				.then(async response => {
					const accountResponse = await checkEmailByInviteId(inviteId, email, token);
					if (accountResponse.exists) {
						this.setState({
							inviteValidation: {
								...this.state.inviteValidation,
								loading: false,
								accountExists: true,
								existingUser: accountResponse.user,
							},
						});
					} else {
						this.setState({
							inviteValidation: {
								...this.state.inviteValidation,
								loading: false,
								accountExists: false,
								response: response,
							},
						});
					}
				})
				.catch(error => {
					this.setState({
						inviteValidation: {
							...this.state.inviteValidation,
							error: error.code,
							errorMessage: error.message,
							loading: false,
						},
					});
				});
		}
	}

	async submitForm(values) {
		this.setState({
			submitUserRegistration: {
				...this.state.submitUserRegistration,
				loading: true,
			},
		});

		if (!this.state.inviteValidation.accountExists) {
			registerUser(values, this.state.inviteId, this.state.token, this.state.companyId)
				.then(response => {
					if (response.result) {
						this.setState({
							submitUserRegistration: {
								...this.state.submitUserRegistration,
								loading: false,
								success: response.signUpResult && response.signUpResult.succeed,
							},
						});
					} else {
						this.setState({
							submitUserRegistration: {
								...this.state.submitUserRegistration,
								loading: false,
								success: response.hasSucceeded,
							},
						});
					}
				})
				.catch(error => {
					this.setState({
						submitUserRegistration: {
							...this.state.submitUserRegistration,
							loading: false,
							error: true,
						},
					});
				});
		} else {
			await Promise.all([acceptInvite(this.state.inviteId, this.state.email), sendPasswordRecoveredEmail(this.state.email, values.password, this.state.code)]);

			this.setState({
				submitUserRegistration: {
					...this.state.submitUserRegistration,
					loading: false,
					success: true,
				},
			});
		}
	}

	getAppropriateErrorMessage(error) {
		switch (error) {
			case GeneralErrorCodes.BadRequest: {
				return (
					<p data-cy='invalidInvitation'>
						This is not a valid invitation. Go back to your email and make sure that the link you have clicked is valid, or contact your administrator.
					</p>
				);
			}
			case OrganizationErrorCodes.InviteCancelled: {
				return <p data-cy='cancelledInvitation'>This invitation has been cancelled by the system's administrator. Ask the administrator to reinvite you.</p>;
			}
			case OrganizationErrorCodes.InviteAlreadyAccepted: {
				return <p data-cy='acceptedInvitation'>This invitation has already been accepted.</p>;
			}
			case OrganizationErrorCodes.InviteExpired: {
				return <p data-cy='expiredInvitation'>This invitation has expired. Ask the system's administrator to invite you again.</p>;
			}
		}
	}

	render() {
		if (isAuthenticated() && this.state.email === getUserProfile().email) {
			return <Redirect to='/' />;
		}

		if (isAuthenticated()) {
			return <LogOutUser />;
		}

		if (this.state.submitUserRegistration.loading) {
			return (
				<Grid className='completing-registration' width='100%' stretch='100vh' horizAlign='center' vertAlign='center' rows='auto'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
						<p>Your account is being created. This may take a few minutes!</p>
					</div>
				</Grid>
			);
		}

		if (this.state.inviteValidation.loading) {
			return (
				<Grid width='100%' stretch='100vh' horizAlign='center' vertAlign='center' rows='auto'>
					<Loader />
				</Grid>
			);
		}

		if (this.state.submitUserRegistration.success) {
			return <Redirect to='/' />;
		}

		return (
			<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' minContent>
				<Grid width='380px' className='login__wrapper'>
					<div style={{ width: '250px' }}>
						<img src='https://static.solaborate.com/americanwell/amwell-full-logo-white.svg' alt='amwell-logo' />
					</div>
					<div className='login-form-wrapper registration-form'>
						{this.state.inviteValidation.error && (
							<div className='invalid-invite'>
								<i className='material-icons'>warning</i>
								<h3> {this.state.inviteValidation.errorMessage} </h3>
								{this.getAppropriateErrorMessage(this.state.inviteValidation.error)}
							</div>
						)}
						{!this.state.inviteValidation.error && !this.state.inviteValidation.loading && (
							<Formik
								enableReinitialize={true}
								initialValues={{
									firstName: this.state.inviteValidation.accountExists ? this.state.inviteValidation.existingUser.firstName : '',
									lastName: this.state.inviteValidation.accountExists ? this.state.inviteValidation.existingUser.lastName : '',
									email: this.state.email,
									password: '',
									passwordConfirm: '',
								}}
								onSubmit={values => this.submitForm(values)}
								validationSchema={Yup.object().shape({
									password: Yup.string()
										.required('Password is required')
										.min(8, 'At least 8 characters!')
										.max(32, 'No more than 32 characters!')
										.matches(passwordRegEx, ChangePasswordUseCases.strongPasswordCheck),
									passwordConfirm: Yup.string()
										.required('Password confirmation is required')
										.oneOf([Yup.ref('password')], 'Must match password')
										.matches(passwordRegEx, ChangePasswordUseCases.strongPasswordCheck),
								})}
								render={() => (
									<Form>
										<Field
											name='firstName'
											disabled={this.state.inviteValidation.accountExists}
											type='text'
											label='First Name'
											placeholder='First Name'
											variant='filled'
											component={Input}
										/>
										<Field
											name='lastName'
											disabled={this.state.inviteValidation.accountExists}
											type='text'
											label='Last Name'
											placeholder='Last Name'
											variant='filled'
											component={Input}
										/>
										<Field name='email' disabled type='email' label='Email' placeholder='Email' value={this.state.email} variant='filled' component={Input} />
										<Field name='password' type='password' label='Password' placeholder='Password' variant='filled' component={Input} />
										<Field name='passwordConfirm' type='password' label='Confirm Password' placeholder='Confirm Password' variant='filled' component={Input} />
										<br />
										<Button type='submit' text='Save' display='block' />
									</Form>
								)}
							/>
						)}
					</div>
					<br />
					<br />
				</Grid>
			</Grid>
		);
	}
}

export default CompleteProfile;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CallButton from 'calls/components/CallButton';
import { SocketContext } from 'io-client/SocketContext';
import { CameraType, CameraEventTypes } from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { amwellIconLink } from 'constants/global-variables';

class NightVisionControl extends Component {
	constructor(props, socket) {
		super(props, socket);
		this.socket = socket;
	}

	componentDidMount() {
		this.socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, this.onInitialState);
		this.socket.on(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, this.onCameraResponse);
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, this.onInitialState);
		this.socket.off(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, this.onCameraResponse);
	}

	onInitialState = data => {
		if (!data || data.helloDeviceId !== this.props.participant.objectId) {
			return;
		}

		const { isNightVision } = data;
		Object.assign(this.props.participant, { nightVisionMode: isNightVision });
		this.setState({});
	};

	onCameraResponse = data => {
		if (data.objectId !== this.props.participant.objectId) {
			return;
		}

		if (data.event === CameraEventTypes.NIGHT_VISION) {
			Object.assign(this.props.participant, { nightVisionMode: data.isSuccessful });
			this.setState({});
		}
	};

	render() {
		const { participant, isDisabled, showButton } = this.props;

		return (
			<>
				{showButton && (
					<CallButton
						className='night-vision'
						imgIcon={`${amwellIconLink}${participant.nightVisionMode ? 'night-vision-line.svg' : 'night-vision.svg'}`}
						isActive={participant.nightVisionMode}
						onClick={!isDisabled && participant.toggleNightVision}
						isDisabled={isDisabled || participant.cameraType === CameraType.HUDDLE}
						text={`${participant.nightVisionMode ? 'Disable' : 'Enable'} Night Vision`}
						wrapperClassName='call-footer-btn-wrapper'
					/>
				)}
			</>
		);
	}
}

NightVisionControl.defaultProps = {
	showButton: true,
};

NightVisionControl.propTypes = {
	participant: PropTypes.object,
	showButton: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

NightVisionControl.contextType = SocketContext;
export default NightVisionControl;

import { DetectionTypes } from 'constants/enums';

const detectionColors = ['#0000FF', '#498EF5'];

function addRect(ctx, x, y, width, height, detectionType, color) {
	ctx.beginPath();
	ctx.strokeStyle = color;
	ctx.lineWidth = '4';
	ctx.rect(x, y, width, height);
	ctx.stroke();

	ctx.beginPath();
	ctx.font = '18px Arial';
	ctx.lineWidth = 4;
	ctx.strokeStyle = 'black';
	ctx.strokeText(detectionType, x + 3, y - 10);
	ctx.fillStyle = 'white';
	ctx.fillText(detectionType, x + 3, y - 10);
}

function drawObjectDetections(ctx, objectList, detectType, width, height) {
	if (!ctx) {
		return;
	}
	ctx.clearRect(0, 0, width, height);
	objectList.forEach((d, index) => {
		if (index <= objectList.length - 1) {
			if (!detectType) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					d.detectionType,
					detectionColors[d.detectionType.includes(DetectionTypes.RAIL) ? 0 : 1]
				);
			} else if ([DetectionTypes.PERSON, DetectionTypes.BABY].includes(detectType) && d.detectionType.toLowerCase().startsWith(detectType)) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					d.detectionType,
					detectionColors[d.detectionType.includes(DetectionTypes.PERSON) ? 1 : 0]
				);
			} else if (
				detectType.toLowerCase() === DetectionTypes.COMBINE ||
				detectType.toLowerCase().startsWith(DetectionTypes.BED) ||
				detectType.toLowerCase().startsWith(DetectionTypes.RAIL)
			) {
				addRect(
					ctx,
					d.leftCoordinate * width,
					d.topCoordinate * height,
					(d.rightCoordinate - d.leftCoordinate) * width,
					(d.bottomCoordinate - d.topCoordinate) * height,
					d.detectionType,
					detectionColors[d.detectionType.includes(DetectionTypes.RAIL) ? 0 : 1]
				);
			}
		}
	});
}

export default drawObjectDetections;

import React, { Component } from 'react';
import { Modal, Form, Alert, Grid } from 'components';
import Checkbox from 'components/Common/CheckBox';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { assignRolesToNurse, updateNursePoolRolePriority, deleteGeneralNurse, addGeneralNurse } from 'api/nursePooling';
import { getUserId } from 'infrastructure/auth';
import Select from 'react-select';
import { default as FormSelect } from 'components/Common/FormElements/Select';
import Loader from 'components/Loader';

class VirtualCareProviderRoleModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			extraFields: 0,
			updatedPriorities: new Map(),
			selectedPoolRoles: [],
			assignPoolRole: {
				loading: false,
				loaded: false,
				error: false,
			},
			loading: {},
			updatePriority: {
				loaded: false,
				error: false,
				message: '',
			},
			formIsVisible: false,
			isGeneralNurse: this.props.fetchPoolRoles.isGeneralNurse,
		};
	}

	priorityOptions = [
		{ id: 1, value: 'Primary' },
		{ id: 2, value: 'Secondary' },
		{ id: null, value: 'Blank' },
	];

	submitMyForm = null;

	componentDidUpdate(prevProps) {
		if (this.props.fetchPoolRoles.isGeneralNurse !== prevProps.fetchPoolRoles.isGeneralNurse) {
			this.setState({ isGeneralNurse: this.props.fetchPoolRoles.isGeneralNurse });
		}
	}

	onLoading = () => {
		this.setState({
			assignPoolRole: {
				...this.state.assignPoolRole,
				loading: true,
			},
		});
	};

	onSuccess = async () => {
		await this.props.updatePoolRoles();
		this.setState({
			assignPoolRole: {
				...this.state.assignPoolRole,
				loading: false,
				loaded: true,
			},
		});
		setTimeout(() => {
			this.setState({
				assignPoolRole: {
					...this.state.assignPoolRole,
					loaded: false,
				},
			});
		}, 3000);
	};

	handleSubmitMyForm = e => {
		if (this.submitMyForm) {
			this.submitMyForm(e);
		}
	};

	bindSubmitForm = submitForm => {
		this.submitMyForm = submitForm;
	};

	onCloseAlert = () => {
		this.setState({
			assignPoolRole: {
				...this.state.assignPoolRole,
				loading: false,
				loaded: false,
				error: false,
			},
		});
	};

	onPrioritySelected = (selectedPriority, e, name) => {
		const healthSystemId = this.props.currentHealthSystem.currentHealthSystemId;
		const poolRoleId = e.name;
		const nurseId = getUserId();
		this.setState({
			loading: {
				...this.state.loading,
				[poolRoleId]: true,
			},
		});
		updateNursePoolRolePriority(healthSystemId, poolRoleId, nurseId, selectedPriority.value).then(() => {
			this.setState(prevState => ({
				updatedPriorities: prevState.updatedPriorities.set(poolRoleId, selectedPriority),
				loading: {
					...this.state.loading,
					[poolRoleId]: false,
				},
				updatePriority: {
					...this.state.updatePriority,
					message: `Priority of ${name} was successfully updated!`,
					loaded: true,
				},
			}));
			setTimeout(() => {
				this.setState({
					updatePriority: {
						...this.state.updatePriority,
						message: '',
						loaded: false,
					},
				});
			}, 3000);
		});
	};

	showErrorMessage = () => {
		this.setState({
			error: 'Device ID not found',
		});

		setTimeout(() => {
			this.setState({
				error: '',
			});
		}, 3000);
	};

	displayNursePoolRoles = () => {
		if (this.props.fetchPoolRoles.nursePoolRoles.length === 0) {
			return (
				<span>You are currently not assigned to any pool roles in this health system. Use the form below to assign yourself to one of the pool roles</span>
			);
		}
		const getPriority = npr => {
			if (npr.priority === 1) return { value: 1, label: 'Primary' };
			if (npr.priority === 2) return { value: 2, label: 'Secondary' };
			if (!npr.priority) return { value: null, label: 'Blank' };
		};

		const priorityOptions = [
			{ value: 1, label: 'Primary' },
			{ value: 2, label: 'Secondary' },
			{ value: null, label: 'Blank' },
		];
		return (
			<div>
				{this.props.fetchPoolRoles.nursePoolRoles.map((nursePoolRole, index) => {
					return (
						<div key={`poolRole${index}`} style={{ paddingBottom: '10px' }}>
							<Grid columns='1fr 1fr' vertAlign='center'>
								<span>{nursePoolRole.poolRole.name}</span>
								{this.state.loading[nursePoolRole.poolRole.id] ? (
									<Loader />
								) : (
									<Select
										name={nursePoolRole.poolRole.id}
										value={
											this.state.updatedPriorities.has(`${nursePoolRole.poolRole.id}`)
												? this.state.updatedPriorities.get(`${nursePoolRole.poolRole.id}`)
												: getPriority(nursePoolRole)
										}
										placeholder='Select Priority'
										classNamePrefix='custom-select'
										options={priorityOptions}
										isDisabled={this.state.isGeneralNurse}
										isSearchable={false}
										onChange={(value, e, name) => this.onPrioritySelected(value, e, nursePoolRole.poolRole.name)}
									/>
								)}
							</Grid>
						</div>
					);
				})}
			</div>
		);
	};

	getUnassignedPoolRoles = () => {
		const allPoolRolesIds = this.props.fetchPoolRoles.poolRoles;
		const nursePoolRoles = this.props.fetchPoolRoles.nursePoolRoles;

		const unassignedPoolRoles = allPoolRolesIds.filter(obj => {
			return !nursePoolRoles.some(obj2 => {
				return obj.id === obj2.poolRole.id;
			});
		});

		return unassignedPoolRoles;
	};

	getUnselectedPoolRoles = name => {
		const unassignedPoolRoles = this.getUnassignedPoolRoles();

		const availablePoolRoles = unassignedPoolRoles.filter(obj => {
			return !this.state.selectedPoolRoles.some(obj2 => {
				return obj.id === obj2.value && obj2.name !== name;
			});
		});

		return availablePoolRoles;
	};

	handleCheckBoxChange = e => {
		const healthSystemId = this.props.currentHealthSystem.currentHealthSystemId;
		if (this.state.isGeneralNurse) {
			deleteGeneralNurse(healthSystemId).then(() => {
				this.setState({
					assignPoolRole: {
						...this.state.assignPoolRole,
						loaded: false,
						loading: false,
					},
					isGeneralNurse: !this.state.isGeneralNurse,
				});
			});
		} else {
			addGeneralNurse(healthSystemId).then(() => {
				this.setState({
					assignPoolRole: {
						...this.state.assignPoolRole,
						loaded: false,
						loading: false,
					},
					isGeneralNurse: !this.state.isGeneralNurse,
				});
			});
		}
	};

	displayExtraFields = () => {
		let table = [];
		for (let i = 1; i <= this.state.extraFields; i++) {
			table.push(
				<div className='input-horizontal-collection'>
					<Grid columns='1fr 1fr' vertAlign='center'>
						<Field
							name={`role${i}`}
							type='select'
							label='Pool Role'
							onChange={this.selectFieldOnChange}
							items={this.getUnselectedPoolRoles(`role${i}`)}
							component={FormSelect}
						/>
						<Field name={`priority${i}`} type='select' label='Priority' items={this.priorityOptions} component={FormSelect} />
					</Grid>
				</div>
			);
		}
		return table;
	};

	addExtraInputField = () => {
		const updatedExtraFields = this.state.extraFields + 1;
		this.setState({ extraFields: updatedExtraFields });
	};

	selectFieldOnChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		const elementsIndex = this.state.selectedPoolRoles.findIndex(element => element.name == name);
		if (elementsIndex === -1) {
			this.setState({ selectedPoolRoles: [...this.state.selectedPoolRoles, { name: name, value: value }] });
		} else {
			let newArray = [...this.state.selectedPoolRoles];
			newArray[elementsIndex] = { ...newArray[elementsIndex], value: value };
			this.setState({
				selectedPoolRoles: newArray,
			});
		}
	};

	render() {
		return (
			<>
				<Modal
					modalSelector='vcpRoleModal'
					display={this.props.isVCPRoleModalOpen}
					isLoading={this.props.fetchPoolRoles.loading || this.state.assignPoolRole.loading}
					position='right'
					onModalSubmit={this.handleSubmitMyForm}
					onModalClose={this.props.toggleVCPRoleModal}>
					<div>
						<Formik
							enableReinitialize={true}
							initialValues={{
								role0: '',
								priority0: '',
								role1: '',
								priority1: '',
								role2: '',
								priority2: '',
							}}
							onSubmit={(values, { resetForm }) => {
								let i;
								const rolesObjs = [];
								for (i = 0; i < 3; i++) {
									let role = {
										PoolRoleId: values['role' + i],
									};
									if (values['priority' + i] !== 'Blank') role.PriorityId = Number(values['priority' + i]);
									if (role.PoolRoleId !== '') rolesObjs.push(role);
								}
								this.onLoading();
								let nurseId = getUserId();
								assignRolesToNurse(this.props.currentHealthSystem.currentHealthSystemId, rolesObjs, nurseId).then(() => {
									resetForm({
										role0: '',
										priority0: '',
										role1: '',
										priority1: '',
										role2: '',
										priority2: '',
									});
									this.onSuccess();
								});
							}}
							validationSchema={Yup.object().shape({
								role0: Yup.string().required('Required field'),
								priority0: Yup.string().required('Required field'),
							})}>
							{formikProps => {
								this.bindSubmitForm(formikProps.submitForm);
								return (
									<Form>
										<h3>Assign Role To Virtual Care Provider</h3>
										<div style={{ paddingTop: '15px' }}>
											<span>General nurses do not have a role and receive all calls in case prioritized nurses miss the call. </span>
											<br />
											<br />
											<Checkbox
												id='general-nurse-checkbox'
												onChange={e => this.handleCheckBoxChange(e)}
												name='General Virtual Care Provider'
												checked={this.state.isGeneralNurse}
											/>
										</div>
										<div style={{ paddingTop: '15px' }}>{this.displayNursePoolRoles()}</div>
										{!this.state.isGeneralNurse && (
											<div>
												<br />
												<a
													data-cy='showForm'
													style={{ textDecoration: 'underline' }}
													className='action'
													onClick={() => this.setState({ formIsVisible: !this.state.formIsVisible })}>
													Show Form
												</a>
												<br />
												<br />
												{this.state.formIsVisible && (
													<div>
														{this.getUnassignedPoolRoles().length !== 0 ? (
															<div>
																<div className='input-horizontal-collection'>
																	<Grid columns='1fr 1fr' vertAlign='center'>
																		<Field
																			name='role0'
																			type='select'
																			label='Pool Role'
																			onChange={this.selectFieldOnChange}
																			items={this.getUnselectedPoolRoles('role0')}
																			component={FormSelect}
																		/>
																		<Field name='priority0' type='select' label='Priority' items={this.priorityOptions} component={FormSelect} />
																	</Grid>
																</div>
																{this.displayExtraFields()}
																<div>
																	{this.state.extraFields === 2 ? (
																		<span>You can only add a maximum of 3 pool roles at once!</span>
																	) : (
																		<a
																			className='action'
																			data-cy='addNewField'
																			onClick={() => this.addExtraInputField()}
																			data-tooltip={'Add New Field'}
																			data-position='right'>
																			<i className='material-icons-outlined'>add_box</i>
																		</a>
																	)}
																</div>
															</div>
														) : (
															<span>There are no available pool roles to be assigned to.</span>
														)}
													</div>
												)}
												<Alert
													display={this.state.assignPoolRole.loaded || this.state.updatePriority.message}
													hideCloseButton={true}
													message='Changes saved.'
													variant='success'
													// onClose={this.onCloseAlert}
												/>
												<Alert
													display={this.state.assignPoolRole.error}
													message='Something went wrong! Please try again. '
													variant='error'
													onClose={this.onCloseAlert}
												/>
											</div>
										)}
									</Form>
								);
							}}
						</Formik>
					</div>
					{/* <Alert
						alertSelector='priorityDidNotUpdate'
						display={this.state.error}
						fixed={true}
						hideCloseButton={true}
						message={this.state.error}
						variant='error'
					/>
					<Alert
						alertSelector='priorityUpdatedSuccessfully'
						display={this.state.updatePriority.message}
						fixed={true}
						hideCloseButton={true}
						message={this.state.updatePriority.message}
						variant='success'
					/> */}
				</Modal>
			</>
		);
	}
}

export default VirtualCareProviderRoleModal;

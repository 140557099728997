import SocketEvents from 'constants/socket-events';

const { LocalTrackFactoryWithConstraints, ErrorCanceled } = require('@solaborate/webrtc/lib/Tracks');

export default class LocalTrackFactoryWithConstraintsAndSignalingErrors extends LocalTrackFactoryWithConstraints {
	constructor(constraints, socket, conferenceId, participantId) {
		super(constraints);
		this.socket = socket;
		this.conferenceId = conferenceId;
		this.participantId = participantId;
	}

	async createTracks(types) {
		try {
			return await super.createTracks(types);
		} catch (err) {
			// if no permission ignore
			if (ErrorCanceled !== err.constructor) {
				this.socket.emit(SocketEvents.Conference.LOG, {
					conferenceId: this.conferenceId,
					participantId: this.participantId,
					message: err.constructor.name + '/' + err.error,
				});
			}
			throw err;
		}
	}
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

const LeftNavigation = props => {
	const getUrl = link => window.location.href.indexOf(link) > -1;

	return (
		<ul className={classNames('left-navigation', props.expanded ? 'expanded' : '')}>
			{props.links.map(item => (
				<li key={item.linkTo}>
					<NavLink
						to={!getUrl(item.linkTo) ? item.linkTo : '#'}
						exact={item.exact}
						activeClassName='active'
						onClick={event => (getUrl(item.linkTo) || item.linkTo === '#' ? props.onItemClicked(item, event) : null)}>
						<div className={`nav-link-wrapper${getUrl(item.linkTo) ? ' active' : ''} ${item.color ? item.color : ''}`}>
							{!item.img && <i className='material-icons-outlined'>{item.icon}</i>}
							{getUrl(item.linkTo) && item.activeImg && <img src={item.activeImg} alt='icon' />}
							{!getUrl(item.linkTo) && item.img && <img src={item.img} alt='logo' />}
						</div>
						<span>{item.title}</span>
					</NavLink>
				</li>
			))}
		</ul>
	);
};

export default LeftNavigation;

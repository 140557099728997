import React, { Component } from 'react';
import Table from 'components/Table';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter } from 'react-router-dom';
import { If, Then, Else, Default } from 'react-if';
import { Modal, Form, Alert, Input, DescriptionBox, SkeletonLoader, TreeView } from 'components';
import { DeviceListLevel, DeviceCommands, UserRoles, DefaultCameraTypes, SettingTypes } from 'constants/enums';
import { gatewayApi, APP_CONFIG, amwellIconLink } from 'constants/global-variables';
import {
	getDeviceList,
	getUnassociatedDevices,
	deleteDevice,
	sendCommand,
	getLatestAppRelease,
	forceUpdateDevice,
	getDeviceSetting,
	updateDeviceSetting,
	deletePatientDeviceOwner,
} from 'api/devices';
import { deleteOrgUnit, getSpecialties } from 'api/organization';
import { findSectorById, getParentSector, getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { assignPoolToDevice } from 'api/nursePooling';
import { CSVLink } from 'react-csv';
import EditSectorModal from 'components/Common/EditSectorModal';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';
import DefaultCameraModal from 'components/DefaultCameraModal';
import AssignPatientModal from 'components/Common/AssignPatientModal';

class Room extends Component {
	state = {
		isEditSectorModalOpen: false,
		isAssignPatientModalOpen: false,
		isUnassignPatientModalOpen: false,
		isDevicesModalOpen: false,
		isAssignDevicePoolOpen: false,
		isRoomLoaded: false,
		preSelected: null,
		devices: [],
		helloDeviceId: null,
		unassociatedDevicesToSelect: [],
		isAddDeviceModalLoading: true,
		selectedDevice: {},
		selectedOrg: {},

		isDeviceAutoAnswerModalOpen: false,
		isSettingsModalLoading: false,

		isDeleteDeviceModalOpen: false,
		isRebootDeviceModalOpen: false,
		isUpdateDeviceModalOpen: false,
		isDeleteOrgUnitModalOpen: false,
		isSelectDefaultCameraModalOpen: false,
		parentSectorName: '',
		rebootReason: '',
		parentSectorType: '',
		isAddDeviceModalSubmitDisabled: true,
		isDeleteDeviceModalLoading: false,
		latestAppRelease: null,
		deviceIdInput: '',
		isSpecialtiesModalOpen: false,
		specialties: [],

		alert: {
			message: false,
			messageAlert: '',
			variantAlert: '',
		},
		// isAssignPoolModalLoading: false,
		// isAssignPoolModalSubmitDisabled: true,
		// selectedPoolIndex: -1,
		// selectedPool: {},
		// healthSystemPools: [],
		// assignedPool: null,
		sectorData: {},
	};

	async componentDidMount() {
		this.loadRoom();
	}

	loadSpecialties = async () => {
		const { roomId } = this.props.match.params;
		let { specialties } = await getSpecialties(roomId);
		if (specialties) {
			this.setState({
				specialties,
			});
		} else {
			this.toggleAlert('message', `Couldn't load specialties..`, 'error');
		}
	};

	componentDidUpdate = prevProps => {
		const { roomId } = this.props.match.params;
		if (
			roomId !== prevProps.match.params.roomId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadRoom();
		}
	};

	exportAsCsv() {
		return this.state.devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision }) => ({
			serialNumber,
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			specialties: this.state.specialties.map(s => s.specialty).toString(),
		}));
	}

	loadRoom = async () => {
		let devicesList = [];
		let devicesRes = await getDeviceList(DeviceListLevel.ROOM, this.props.match.params.roomId);
		let unassociatedDevicesRes = await getUnassociatedDevices();
		await this.loadSpecialties();
		let currentSector = this.props.currentSectorLocation;
		let preSelected = {};
		if (currentSector && currentSector.length) {
			preSelected = {
				[currentSector[0].name]: {
					[currentSector[0].subOptions[0].name]: currentSector[0].subOptions[0].subOptions && {
						[currentSector[0].subOptions[0].subOptions[0].name]: {
							[currentSector[0].subOptions[0].subOptions[0].subOptions[0]]: {},
						},
					},
				},
			};
		}
		unassociatedDevicesRes.map(device => {
			devicesList.push({
				value: device.uid,
				id: device.solHelloDeviceId,
			});
		});

		const latestAppRelease = await getLatestAppRelease(APP_CONFIG.deviceAppId);

		// let currentHealthSystem = getCurrentHealthSystemInfo();
		// let healthSystemPools = await getPoolsByHealthSystemId(currentHealthSystem.currentHealthSystemId);
		// let poolsTransofrmedArray = healthSystemPools.map(pool => {
		// 	return {
		// 		value: pool.name,
		// 		id: pool.id,
		// 	};
		// });

		// let assignedPool = null;
		// let selectedPoolIndex = -1;
		// if (devicesRes.length !== 0) {
		// 	try {
		// 		assignedPool = await getDevicePool(devicesRes[0].solHelloDeviceId);
		// 		selectedPoolIndex = poolsTransofrmedArray.findIndex(pool => pool.id === assignedPool.poolId);
		// 	} catch (err) {
		// 		assignedPool = null;
		// 	}
		// }

		this.setState({
			isRoomLoaded: true,
			preSelected,
			devices: devicesRes,
			unassociatedDevicesToSelect: devicesList,
			isAddDeviceModalLoading: false,
			latestAppRelease,
			// healthSystemPools: poolsTransofrmedArray,
			// assignedPool: assignedPool,
			// selectedPoolIndex: selectedPoolIndex,
		});
	};

	// TO DO: Make alerts/errors same as every other modal in the app
	toggleAlert = (type, messageAlert, variantAlert) => {
		this.setState(prevState => ({
			...prevState.alert,
			[type]: true,
			messageAlert,
			variantAlert,
		}));

		setTimeout(() => {
			this.setState({
				[type]: false,
			});
		}, 3000);
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}
		const userRole = getUserRole();
		return this.state.devices.map(({ serialNumber, solHelloDeviceId, appVersion, osVersion, firmwareRevision, macAddress, isDefaultOwner, owner }) => {
			const { short_version: shortVersion, min_os: minOS } = this.state.latestAppRelease;
			const deviceOsVersion = +osVersion;
			const minOsVersion = +minOS;
			return {
				owner: this.getDeviceOwner(isDefaultOwner, owner),
				serialNumber,
				macAddress,
				appVersion: (
					<div className='app-version'>
						{appVersion}
						{/* <If condition={shortVersion === appVersion || deviceOsVersion < minOsVersion}>
							<Then>
								<span>{appVersion}</span>
								{deviceOsVersion < minOsVersion && <small>Newer version is available, but device OS is not compatible.</small>}
							</Then>
							<Else>
								<button
									type='button'
									data-tooltip='Click here to force update'
									data-position='top'
									onClick={() => this.toggleUpdateDeviceModal({ deviceId: solHelloDeviceId, serialNumber, appVersion })}>
									{appVersion} <i className='material-icons-outlined'>update</i>
								</button>
								<small>Newer version is available</small>
							</Else>
						</If> */}
					</div>
				),
				firmwareRevision,
				// pool: this.state.assignedPool ? this.state.assignedPool.poolName : 'N/A',
				actions: (
					<div className='wrapped'>
						{/* {userRole === UserRoles.ADMIN && (
							<i
								className='material-icons-outlined boxed-icon pool-icon'
								id={solHelloDeviceId}
								data-tooltip='Assign a pool'
								data-position='top'
								style={{ backgroundColor: 'var(--yellow-1)' }}
								onClick={() => this.toggleAssignDevicePoolModal({ deviceId: solHelloDeviceId, serialNumber })}>
								supervisor_account
							</i>
						)} */}
						<i
							className='material-icons-outlined boxed-icon'
							id={solHelloDeviceId}
							data-tooltip='Default Camera'
							data-position='top'
							style={{ background: '#30BB7D', userSelect: 'none', WebkitUserSelect: 'none' }}
							onClick={() => this.toggleSelectDefaultCamera(solHelloDeviceId)}>
							video_call
						</i>
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='autoAnswerBtn'
							id={solHelloDeviceId}
							data-tooltip='Auto answer'
							data-position='top'
							style={{ background: '#D4AB60', userSelect: 'none', WebkitUserSelect: 'none' }}
							onClick={() => this.openAutoAnswerSettingModal(solHelloDeviceId)}>
							<img src={`${amwellIconLink}auto-answer.svg`} alt='auto answer icon' />
						</i>
						&nbsp;
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='rebootDeviceBtn'
							id={solHelloDeviceId}
							data-tooltip='Reboot device'
							data-position='top'
							style={{ background: '#718093', userSelect: 'none', WebkitUserSelect: 'none' }}
							onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
							refresh
						</i>
						&nbsp;
						<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
						&nbsp;
						{(userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
							<>
								<i
									className='material-icons boxed-icon'
									data-cy='reassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Reassign Device'
									data-position='top'
									style={{ backgroundColor: 'var(--blue-2)' }}
									onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
									swap_vert
								</i>
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									data-cy='unassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Unassign device'
									data-position='top'
									onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
									delete
								</i>
							</>
						)}
					</div>
				),
			};
		});
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});
			await deleteDevice(deviceId);
		}

		this.loadRoom();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		const response = await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		if (!response.error) {
			this.toggleDeleteOrgUnitModal();
			this.props.getTreeData();
			this.updateBreadcrumb();
		}
	};

	moveToParentSector = () => {
		const breadCrumb = [this.props.breadcrumb[0]];
		this.props.updateBreadcrumb(breadCrumb);
		this.props.history.push(this.props.breadcrumb[0].link);
	};

	updateBreadcrumb = () => {
		this.props.updateBreadcrumb([]);
		this.props.history.push('/health-system');
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	updateDeviceSettings = async type => {
		const { isAutoAnswerOn, deviceId, defaultCamType } = this.state.selectedDevice;

		let value;
		if (type === SettingTypes.AUTO_ANSWER) {
			value = !isAutoAnswerOn;
		} else {
			value = defaultCamType;
		}

		const { error } = await updateDeviceSetting(deviceId, value.toString(), type);
		if (error) {
			this.toggleAlert('message', 'Could not be saved!', 'error');
		} else {
			this.setState(
				prevState => ({
					selectedDevice: {
						...prevState.selectedDevice,
						...(type === SettingTypes.AUTO_ANSWER && { isAutoAnswerOn: !prevState.selectedDevice.isAutoAnswerOn }),
					},
				}),
				() => this.toggleAlert('message', 'Changes Saved', 'dark')
			);
		}
		this.setState({
			isSettingsModalLoading: false,
			...(type === SettingTypes.CAMERA_TYPE && { isSelectDefaultCameraModalOpen: false }),
		});
	};

	closeAutoAnswerSettingModal = () => {
		this.setState({
			selectedDevice: {},
			isDeviceAutoAnswerModalOpen: false,
			isAutoAnswerSettingLoading: false,
		});
	};

	openAutoAnswerSettingModal = async deviceId => {
		this.setState({
			isAutoAnswerSettingLoading: true,
			isDeviceAutoAnswerModalOpen: true,
		});
		let { deviceSettings, error } = await getDeviceSetting(deviceId, SettingTypes.AUTO_ANSWER);
		if (error) {
			this.toggleAlert('message', 'Failed to load Auto answer setting!', 'error');
			return;
		}
		let isAutoAnswerOn = false;
		if (deviceSettings.length) {
			isAutoAnswerOn = deviceSettings[0].value === 'true';
		}
		this.setState({
			selectedDevice: { deviceId, isAutoAnswerOn },
			isAutoAnswerSettingLoading: false,
		});
	};

	toggleAssignDevicePoolModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isAssignDevicePoolOpen: !this.state.isAssignDevicePoolOpen,
		});
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = (selectedOrg = {}) => {
		this.setState({
			selectedOrg,
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	findSelectedDeviceById = (devices, id) => {
		let device;
		devices.forEach((item, index) => {
			if (item.value === id) {
				device = {
					id: item.id,
					value: item.value,
					index: index,
				};
			}
		});
		return device;
	};

	setDeviceId = event => {
		const device = this.findSelectedDeviceById(this.state.unassociatedDevicesToSelect, event.target.value);
		if (!device) {
			this.setState({
				helloDeviceId: null,
				selectedDeviceId: null,
				isAddDeviceModalSubmitDisabled: false,
				deviceIdInput: event.target.value,
			});
			return;
		}
		this.setState({
			helloDeviceId: device.id,
			selectedDeviceId: device.index,
			isAddDeviceModalSubmitDisabled: false,
			deviceIdInput: event.target.value,
		});
	};

	showErrorMessage = () => {
		this.setState({
			error: 'Device ID not found',
		});

		setTimeout(() => {
			this.setState({
				error: '',
			});
		}, 3000);
	};

	assignDevice = async evt => {
		evt.preventDefault();
		if (!this.state.helloDeviceId) {
			this.showErrorMessage();
			return;
		}

		this.setState({
			isAddDeviceModalLoading: true,
		});
		const { hospitalId, departmentId, floorId, roomId } = this.props.match.params;
		const url = `/v1/organizations/${APP_CONFIG.companyId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices`;
		await gatewayApi.put(url, {
			devices: [this.state.helloDeviceId],
		});
		this.setState({
			isAddDeviceModalLoading: false,
			deviceIdInput: '',
		});
		this.toggleDevicesModal();
		this.props.getTreeData();
		this.loadRoom();
	};

	selectPool = (selection, index) => {
		this.setState({
			selectedPool: selection,
			selectedPoolIndex: index,
			isAssignPoolModalSubmitDisabled: false,
		});
	};

	assignPoolSubmit = async () => {
		this.setState({
			isAssignPoolModalLoading: true,
		});

		let selectedPool = this.state.selectedPool;
		let selectedDevice = this.state.selectedDevice;
		let currentHealthSystem = getCurrentHealthSystemInfo();
		assignPoolToDevice(currentHealthSystem.currentHealthSystemId, selectedPool.id, selectedDevice.deviceId).then(() => {
			this.setState({
				isAssignPoolModalLoading: false,
			});
			this.toggleAssignPoolModal();
			this.props.getTreeData();
			this.loadRoom();
		});
	};

	toggleRoomModal = () => {
		this.setState(
			{
				isRoomModalOpen: !this.state.isRoomModalOpen,
			},
			() => {
				if (this.state.isRoomModalOpen) {
					let sector = findSectorById(this.props.treeData.tree, this.props.match.params.roomId);
					let sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				}
			}
		);
	};

	toggleDevicesModal = () => {
		this.setState(
			{
				isDevicesModalOpen: !this.state.isDevicesModalOpen,
			},
			() => {
				if (this.state.isDevicesModalOpen) {
					this.setState({
						isAddDeviceModalSubmitDisabled: true,
						selectedDeviceId: -1,
					});
				} else {
					this.setState({
						deviceIdInput: '',
					});
				}
			}
		);
	};

	toggleAssignPoolModal = () => {
		this.setState(
			{
				isAssignDevicePoolOpen: !this.state.isAssignDevicePoolOpen,
			},
			() => {
				if (this.state.isAssignDevicePoolOpen) {
					this.setState({
						isAssignPoolModalSubmitDisabled: true,
						selectedDeviceId: -1,
					});
				}
			}
		);
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	getCurrentRoom() {
		let hospitals = this.props.treeData.tree;
		let room = findSectorById(hospitals, this.props.match.params.roomId);
		return {
			sectorName: room ? room.name : '',
			sectorId: room ? room.roomId : '',
			roomName: room ? room.name : '',
			hospitalId: room ? room.hospitalId : '',
			departmentId: room ? room.departmentId : '',
			floorId: room ? room.floorId : '',
			roomId: room ? room.roomId : '',
			sectorType: room ? room.type : '',
		};
	}

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
			},
			() => {
				if (this.state.isEditSectorModalOpen) {
					const sector = findSectorById(this.props.treeData.tree, data[data.sectorType + 'Id']);
					if (!sector) {
						this.toggleAlert('message', 'Sector is not found..', 'error');
						return;
					}
					this.props.setCurrentSectorLocation(sector.treeLocation);
					const sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						sectorType: data.sectorType,
						sectorName: data.sectorName,
						sectorData: data,
						sectorLocation: sector.treeLocation,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				} else {
					this.loadSpecialties();
				}
			}
		);
	};

	toggleAssignPatientModal = () => {
		this.setState(prevState => ({
			isAssignPatientModalOpen: !prevState.isAssignPatientModalOpen,
		}));
	};

	toggleUnassignPatientModal = () => {
		this.setState(prevState => ({
			isUnassignPatientModalOpen: !prevState.isUnassignPatientModalOpen,
		}));
	};

	updateTree = () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	getCameraTypeDeviceSetting = async deviceId => {
		if (deviceId) {
			let { deviceSettings, error } = await getDeviceSetting(deviceId, SettingTypes.CAMERA_TYPE);
			if (error) {
				this.toggleAlert('message', 'Failed to load default camera setting!', 'error');
				return;
			}
			let defaultCamType = DefaultCameraTypes.HELLO.toString();
			if (deviceSettings.length) {
				defaultCamType = deviceSettings[0].value.toString();
			}
			this.setState(prevState => ({
				selectedDevice: { ...prevState, deviceId, defaultCamType },
			}));
		}
	};

	toggleSelectDefaultCamera = async deviceId => {
		this.setState({
			isSelectDefaultCameraModalOpen: !!deviceId,
			isSettingsModalLoading: true,
		});
		await this.getCameraTypeDeviceSetting(deviceId);
		this.setState({
			isSettingsModalLoading: false,
		});
	};

	onChangeDefaultCamera = defaultCamType => {
		if (defaultCamType) {
			this.setState(prevState => ({
				selectedDevice: { ...prevState.selectedDevice, defaultCamType },
			}));
		}
	};

	getDeviceOwner = (isDefaultOwner, owner) => {
		const room = this.getCurrentRoom();
		return <div className='flex'>{isDefaultOwner ? room.roomName : owner || ''}</div>;
	};

	unAssignPatient = async () => {
		this.setState({
			isUnassignPatientModalLoading: true,
		});

		await deletePatientDeviceOwner(this.state.devices[0].solHelloDeviceId);

		this.setState({
			isUnassignPatientModalLoading: false,
			isUnassignPatientModalOpen: false,
		});

		this.props.getTreeData();
		this.loadRoom();
	};

	render() {
		const room = this.getCurrentRoom();
		const userRole = getUserRole();
		return (
			<div className='users'>
				<If condition={!!this.state.isRoomLoaded}>
					<Then>
						<h3>
							<span>{room.roomName}</span>
							{[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) && (
								<>
									<Button text='Edit Room Details' onClick={() => this.toggleEditSectorModal(room)} />
									&nbsp;
									<Button
										text='Delete Room'
										variant='red'
										onClick={() => this.toggleDeleteOrgUnitModal({ level: DeviceListLevel.ROOM, id: this.props.match.params.roomId, name: room.roomName })}
									/>
									{this.state.devices.length > 0 && (
										<Button
											text={this.state.devices[0].isDefaultOwner ? 'Assign Patient' : 'Unassign Patient'}
											onClick={this.state.devices[0].isDefaultOwner ? this.toggleAssignPatientModal : this.toggleUnassignPatientModal}
										/>
									)}
									{!this.state.devices.length && <Button text='Add Device' buttonSelector='addDeviceBtn' variant='white' onClick={this.toggleDevicesModal} />}
								</>
							)}
							{this.state.devices.length > 0 && (
								<CSVLink
									data-cy='exportAsCSVBtn'
									className='button white'
									data={this.exportAsCsv()}
									filename={`${room.roomName}-${(+new Date()).toString()}.csv`}>
									Export as CSV
								</CSVLink>
							)}
						</h3>
						<DescriptionBox data={[{ title: 'Enrolled Devices', description: this.state.devices ? this.state.devices.length : 0 }]} />
						<span className='toggleRoomSpecialties' data-cy='toggleRoomSpecialties' onClick={() => this.setState({ isSpecialtiesModalOpen: true })}>
							Room Specialties: {` ${this.state.specialties.length}`}
						</span>
						<br />
						<br />
						<br />
						<Tabs
							links={[{ link: 'Devices', active: true }]}
							components={[
								<Table
									headers={[
										{ title: 'Room/Patient' },
										{ title: 'Serial Number' },
										{ title: 'MAC Address' },
										{ title: 'App Version' },
										{ title: 'OS Version' },
										{ title: '' },
									]}
									rows={this.displayDevices()}
									isEditable={false}
								/>,
							]}
						/>
					</Then>
					<Else>
						<SkeletonLoader rows={10} padding='20px 0' />
					</Else>
				</If>
				{this.state.isEditSectorModalOpen && (
					<EditSectorModal
						onToggleRoomAlert={this.toggleAlert}
						onEditSector={this.updateTree}
						onModalClose={this.toggleEditSectorModal}
						sectorLocation={this.state.sectorLocation}
						sectorData={this.state.sectorData}
						parentSectorName={this.state.parentSectorName}
						parentSectorType={this.state.parentSectorType}
						roomId={this.props.match.params.roomId}
					/>
				)}
				{this.state.isAssignPatientModalOpen && (
					<AssignPatientModal
						display={this.state.isAssignPatientModalOpen}
						onModalClose={this.toggleAssignPatientModal}
						loadRoom={() => {
							this.props.getTreeData();
							this.loadRoom();
						}}
						devices={this.state.devices}
						setDevices={devices =>
							this.setState({
								devices,
							})
						}
						healthSystemId={getCurrentHealthSystemInfo()?.currentHealthSystemId}
					/>
				)}
				<Modal
					modalSelector='assignDeviceModal'
					isLoading={this.state.isAddDeviceModalLoading}
					display={this.state.isDevicesModalOpen}
					position='right'
					onModalClose={this.toggleDevicesModal}
					onModalSubmit={this.assignDevice}
					isSubmitDisabled={this.state.isAddDeviceModalSubmitDisabled}>
					<Form title='Room Device' onSubmit={this.assignDevice}>
						<div className='input'>
							<p className='label'>Assign Device</p>
							<p>Select a serial number to associate with this room.</p>
							<input value={this.state.deviceIdInput} placeholder='Select Serial Number' list='devices' onChange={this.setDeviceId} />
							<datalist id='devices'>
								{this.state.unassociatedDevicesToSelect.map(({ value, id }) => (
									<option>{value}</option>
								))}
							</datalist>
						</div>
						{this.state.preSelected && <TreeView data={this.props.currentSectorLocation} preSelected={this.state.preSelected} isRoom={true} />}
						<Alert display={this.state.message} fixed={true} hideCloseButton={true} message={this.state.messageAlert} variant={this.state.variantAlert} />
					</Form>
				</Modal>

				<Modal
					modalSelector='viewSpecialties'
					display={this.state.isSpecialtiesModalOpen}
					position='center'
					submitButtonText=''
					onModalClose={() => this.setState({ isSpecialtiesModalOpen: false })}>
					<form className='view-specialties'>
						<h3>Specialties</h3>
						{this.state.specialties.length === 0 ? (
							<p>There is no specialty..</p>
						) : (
							this.state.specialties.map((specialty, index) => (
								<p key={specialty.specialty}>
									{index + 1}. {specialty.specialty}
								</p>
							))
						)}
					</form>
				</Modal>

				{/* <Modal
					isLoading={this.state.isAssignPoolModalLoading}
					display={this.state.isAssignDevicePoolOpen}
					position='right'
					onModalClose={this.toggleAssignPoolModal}
					onModalSubmit={this.assignPoolSubmit}
					isSubmitDisabled={this.state.isAssignPoolModalSubmitDisabled}>
					<Form title='Health System Pools'>
						<Select
							type='text'
							defaultValue={this.state.selectedPoolIndex}
							onSelect={this.selectPool}
							label='Pool'
							name='pool'
							items={this.state.healthSystemPools}
							description='Select a pool to associate with this device.'
							placeholder='Select Pool'
						/>
					</Form>
				</Modal> */}

				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
							inputSelector='rebootReasonInput'
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>
				<Modal
					modalSelector='toggleDeviceAutoAnswerSettingModal'
					display={this.state.isDeviceAutoAnswerModalOpen}
					position='right'
					hideCloseButton={true}
					submitButtonText=''
					isLoading={this.state.isSettingsModalLoading}
					onModalClose={this.closeAutoAnswerSettingModal}>
					<Form title='Enable Auto-answer' onSubmit={event => event.preventDefault()}>
						<div className='auto-answer-container'>
							<p>Auto-answer</p>
							{this.state.selectedDevice?.isAutoAnswerOn ? (
								<i
									className='material-icons'
									data-cy='autoAnswerOn'
									onClick={() => this.updateDeviceSettings(SettingTypes.AUTO_ANSWER)}
									data-tooltip='On'
									data-position='center'>
									toggle_on
								</i>
							) : (
								<i
									className='material-icons-outlined'
									data-cy='autoAnswerOff'
									onClick={() => this.updateDeviceSettings(SettingTypes.AUTO_ANSWER)}
									data-tooltip='Off'
									data-position='center'>
									toggle_off
								</i>
							)}
						</div>
						<p>If you enable this option, as soon as a provider makes a call, the device will ring for a few seconds and it will auto answer.</p>
					</Form>
				</Modal>
				<Modal
					display={this.state.isUnassignPatientModalOpen}
					isLoading={this.state.isUnassignPatientModalLoading}
					position='center'
					primaryButtonLabel='Unassign Patient'
					onModalSubmit={this.unAssignPatient}
					onModalClose={this.toggleUnassignPatientModal}>
					<Form title='Unassign Patient' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign patient</p>
					</Form>
				</Modal>
				<DefaultCameraModal
					display={this.state.isSelectDefaultCameraModalOpen}
					isLoading={this.state.isSettingsModalLoading}
					onModalClose={() => this.toggleSelectDefaultCamera()}
					onModalSubmit={this.updateDeviceSettings}
					onChange={this.onChangeDefaultCamera}
					selectedDevice={this.state.selectedDevice}
				/>

				<Alert display={this.state.message} fixed={true} hideCloseButton={true} message={this.state.messageAlert} variant={this.state.variantAlert} />
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Room));

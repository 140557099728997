import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

/**
 * @function getAllHealthSystems
 * @desc Gets the user's health systems
 */
export function getAllHealthSystems() {
	return new Promise((res, rej) => {
		const url = `/v1.1/organizations/${APP_CONFIG.companyId}/health-systems`;
		gatewayApi
			.get(url)
			.then(response => res(response.data))
			.catch(error => rej(error));
	});
}

/**
 * @function createHealthSystem
 * @desc Creates a new health system
 * @param {string} newName of the new health system example: "Florida Health System"
 * @param {string} eHRId id of EHR
 * @param {string} eHRTypeName this can be Epic or Cerner or another ehr type filled by the user
 * @param {string} tenantId id of tenant.
 *
 */
export async function createHealthSystem(newName, eHRId, eHRTypeName, tenantId) {
	try {
		const url = `/v1.2/organizations/${APP_CONFIG.companyId}/health-systems`;
		const data = await gatewayApi.post(url, {
			name: newName,
			eHRId,
			eHRTypeName,
			tenantId,
			regions: [],
		});
		return { data };
	} catch (error) {
		return { error };
	}
}

/**
 * @function editHealthSystem
 * @desc Edites a health system
 * @param {string} healthSystemId of healthSystem
 * @param {string} name of new health system.
 * @param {string} eHRId id of eHRId
 * @param {string} eHRTypeName  can be Epic or Cerner. => 1 or 2.
 * @param {string} tenantId id of tenant.
 *
 */
export async function editHealthSystem(healthSystemId, name, eHRId, eHRTypeName, tenantId, aiDisabled) {
	try {
		const url = `/v1.1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}`;
		const data = await gatewayApi.put(url, {
			name,
			eHRId,
			eHRTypeName,
			tenantId,
			aiDisabled,
		});
		return { data };
	} catch (error) {
		return { error };
	}
}

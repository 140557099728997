import React, { useEffect, useState } from 'react';
import { Button, Loader, Select } from 'components';
import CameraSettings from 'calls/components/calls/CameraSettings';
import { amwellIconLink } from 'constants/global-variables';
import AudioMeter from 'calls/components/calls/AudioMeter';
import { MediaDeviceKinds, MediaDevicePermissionsState } from 'constants/enums';
import classNames from 'classnames/bind';
import mediaSettingsFunctions from 'calls/helpers/media-settings-functions';

const TechCheck = props => {
	const {
		selectedMediaDevices,
		setSelectedMediaDevices,
		audioRef,
		isLoading,
		navigatorMediaDevices,
		audioIOFromSameDevice,
		getMediaDevices,
		getDefaultCam,
		getDefaultMic,
		getDefaultSpeakers,
		handleSpeakersChange,
		setDefaultDevicesFromStore,
		isCamLoading,
		setIsCamLoading,
		playErrorPromiseResolveCallback,
		isSoundPermissionTechCheck,
	} = props;
	const { [MediaDeviceKinds.AUDIO_INPUT]: activeMic, [MediaDeviceKinds.VIDEO_INPUT]: activeCamera } = selectedMediaDevices;

	const [isAudioPlaying, setIsAudioPlaying] = useState(false);

	/** @type {[{ camera: PermissionState, microphone: PermissionState }, import('react').Dispatch<import('react').SetStateAction<{ camera: PermissionState, microphone: PermissionState }>>]} */
	const [devicePermissions, setDevicePermissions] = useState({ camera: MediaDevicePermissionsState.GRANTED, microphone: MediaDevicePermissionsState.GRANTED });

	let audioTimeout = null;

	/** @type {MediaStream} */
	let audioStream = null;

	let boundPermissionsListeners = new Map();

	const resetAudio = () => {
		if (audioRef.current.currentTime > 0) {
			audioRef.current.pause();
			audioRef.current.currentTime = 0;
			setIsAudioPlaying(false);
			if (audioTimeout) {
				clearTimeout(audioTimeout);
			}
		}
	};

	const testSpeakers = async () => {
		if (!audioRef.current) {
			return;
		}

		try {
			await audioRef.current.play();
			setIsAudioPlaying(true);
			audioTimeout = setTimeout(resetAudio, 3500);
		} catch (error) {
			setIsAudioPlaying(false);
			console.warn(error);
		}
	};

	const mediaDevicePermissionsCheck = async () => {
		const [cameraPermissions, micPermissions] = await Promise.all([
			navigator.permissions.query({ name: 'camera' }),
			navigator.permissions.query({ name: 'microphone' }),
		]);

		const updateListsAndStopTracks = async () => {
			if (cameraPermissions.state === MediaDevicePermissionsState.DENIED || micPermissions.state === MediaDevicePermissionsState.DENIED) {
				setIsCamLoading(false);
			}
			await getMediaDevices();
			setDefaultDevicesFromStore();
			if (audioStream) {
				audioStream.getTracks().forEach(track => {
					track.stop();
				});
			}
		};

		if (micPermissions.state === 'prompt') {
			try {
				audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
				await updateListsAndStopTracks();
			} catch (error) {
				// eslint-disable-next-line no-console
				console.warn(error);
			}
		}

		const onPermissionsChange = async (ev, permissionsType = 'camera') => {
			setDevicePermissions(prevState => ({ ...prevState, ...{ [permissionsType]: ev.currentTarget.state } }));
			updateListsAndStopTracks();
		};
		const onMicPermissionsChange = ev => onPermissionsChange(ev, 'microphone');

		cameraPermissions.addEventListener('change', onPermissionsChange);
		boundPermissionsListeners.set(cameraPermissions, onPermissionsChange);
		micPermissions.addEventListener('change', onMicPermissionsChange);
		boundPermissionsListeners.set(micPermissions, onMicPermissionsChange);

		setDevicePermissions({ camera: cameraPermissions.state, microphone: micPermissions.state });
	};

	useEffect(() => {
		mediaDevicePermissionsCheck();

		return () => {
			if (audioStream) {
				audioStream.getTracks().forEach(track => {
					track.stop();
				});
			}

			if (boundPermissionsListeners) {
				[...boundPermissionsListeners.entries()].forEach(([permissions, listener]) => permissions.removeEventListener('change', listener));
				boundPermissionsListeners.clear();
			}
		};
	}, []);

	return (
		<div className={isSoundPermissionTechCheck ? 'sound-permission-popup' : ''}>
			<div className={isSoundPermissionTechCheck && (isLoading || isCamLoading || !selectedMediaDevices) ? 'hidden' : ''}>
				<div className='cam-settings-header'>
					<h3>Test Audio &amp; Video</h3>
				</div>
				{!isLoading && (
					<div className='flex tech-check-content'>
						<div>
							<CameraSettings
								onCameraChange={device => {
									setSelectedMediaDevices({ [MediaDeviceKinds.VIDEO_INPUT]: device });
								}}
								cameraList={navigatorMediaDevices.cameraList}
								activeCameraId={activeCamera ? activeCamera.deviceId : null}
								defaultValue={getDefaultCam()}
								isTechCheck={true}
								setVideoLoading={setIsCamLoading}
								camPermissionState={devicePermissions.camera}
								selectDisabled={devicePermissions.camera !== MediaDevicePermissionsState.GRANTED}>
								{devicePermissions.camera !== MediaDevicePermissionsState.GRANTED && (
									<p className='flex different-audio-selected'>
										<i className='material-icons-outlined'>info</i>Camera permissions needed.
									</p>
								)}
							</CameraSettings>
						</div>
						<div>
							<div className='input'>
								<p className='label custom-label'>
									<img src={`${amwellIconLink}select-mic.svg`} alt='icon' />
									Microphone
								</p>
							</div>
							{activeMic && <AudioMeter activeMicId={activeMic.deviceId} micPermissionState={devicePermissions.microphone} />}
							<Select
								label='Select Microphone'
								name='microphone'
								items={navigatorMediaDevices.micList}
								placeholder='Microphone'
								optionValueKey='label'
								onSelect={device => {
									setSelectedMediaDevices({ [MediaDeviceKinds.AUDIO_INPUT]: device });
								}}
								defaultValue={getDefaultMic()}
								disabled={devicePermissions.microphone !== MediaDevicePermissionsState.GRANTED}
							/>
							{devicePermissions.microphone !== MediaDevicePermissionsState.GRANTED && (
								<p className='flex different-audio-selected'>
									<i className='material-icons-outlined'>info</i>Microphone permissions needed.
								</p>
							)}
							<div className='input'>
								<p className='label custom-label'>
									<img src={`${amwellIconLink}select-speaker.svg`} alt='icon' />
									Speaker
								</p>
							</div>
							<div
								className={classNames(
									'media-controls play-btn flex',
									isAudioPlaying || devicePermissions.microphone !== MediaDevicePermissionsState.GRANTED ? 'audio-control-playing' : ''
								)}>
								<div onClick={devicePermissions.microphone === MediaDevicePermissionsState.GRANTED ? testSpeakers : null}>
									<img src={`${amwellIconLink}play-btn.svg`} alt='icon' />
								</div>
								<div className='play-btn-text'>
									<p>{isAudioPlaying ? 'Playing' : 'Speaker'}</p>
								</div>
							</div>
							<audio id='audio' ref={audioRef} />
							<Select
								label='Select Speaker'
								name='speakers'
								items={navigatorMediaDevices.speakersList}
								placeholder='Select Speakers'
								optionValueKey='label'
								onSelect={item => handleSpeakersChange(item)}
								defaultValue={getDefaultSpeakers()}
								disabled={isAudioPlaying || devicePermissions.microphone !== MediaDevicePermissionsState.GRANTED}
							/>
							{(!audioIOFromSameDevice || devicePermissions.microphone !== MediaDevicePermissionsState.GRANTED) && (
								<p className='flex different-audio-selected'>
									<i className='material-icons-outlined'>info</i>
									{devicePermissions.microphone === MediaDevicePermissionsState.GRANTED &&
										!audioIOFromSameDevice &&
										'Using different mic and speaker devices may cause echo.'}
									{devicePermissions.microphone !== MediaDevicePermissionsState.GRANTED && 'Microphone permissions needed.'}
								</p>
							)}
							{isSoundPermissionTechCheck && (
								<>
									<br />
									<Button onClick={playErrorPromiseResolveCallback} text='Continue to initiate call' size='large' />
								</>
							)}
						</div>
					</div>
				)}
			</div>
			{isSoundPermissionTechCheck && (isLoading || isCamLoading || !selectedMediaDevices) && <Loader />}
		</div>
	);
};

export default mediaSettingsFunctions(TechCheck);

import React from 'react';
import classNames from 'classnames/bind';

const Select = props => (
	<div
		className={classNames(`input select-wrapper${props.dialOut ? '-dialOut' : ''}`, props.error ? 'select-has-errors' : '')}
		style={{ marginBottom: props.bottomSpace, marginRight: props.marginRight, ...props.style }}>
		{props.label && <p className='label'>{props.label}</p>}
		<p>{props.description}</p>
		<select
			data-cy={props.dropdownSelector}
			name={props.name}
			onChange={e => props.onSelect(props.items[e.target.selectedIndex - 1], e.target.selectedIndex - 1)}
			value={props.defaultValue}
			disabled={props.disabled}>
			<option data-test-id='select-placeholder' value={-1} disabled>
				{props.placeholder}
			</option>
			{props.items.map((item, index) => (
				<option data-test-id='select-option' key={index} value={index}>
					{item[`${props.optionValueKey || 'value'}`]}
				</option>
			))}
		</select>
		<small>{props.error}</small>
	</div>
);

export default Select;

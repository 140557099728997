import { legacyApi } from 'constants/global-variables';

export function changePassword(data) {
	return new Promise((res, rej) => {
		const url = `/v2.1/api/account/password`;
		legacyApi
			.post(url, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';
import { createInvite, checkIfCompanyMemberExists } from 'api/users';
import { If, Then, Else } from 'react-if';
import Alert from 'components/Alert';

class InviteAdmins extends Component {
	constructor(props) {
		super(props);

		this.state = {
			extraFields: 0,
		};
	}

	addExtraInputField = () => {
		var updatedExtraFields = this.state.extraFields + 1;
		this.setState({ extraFields: updatedExtraFields });
	};

	displayExtraFields = () => {
		let table = [];
		for (let i = 0; i < this.state.extraFields; i++) {
			table.push(<Field name={'email' + i} type='email' placeholder='Email' component={Input} />);
		}
		return table;
	};

	async checkEmail(val) {
		if (!val) {
			return true;
		}

		let response = false;
		let schema = Yup.string()
			.email()
			.required();
		if (await schema.isValid(val)) {
			try {
				await checkIfCompanyMemberExists(val)
					.then(() => (response = false))
					.catch(() => (response = true));
			} catch (error) {
				return false;
			}
		}
		return response;
	}

	render() {
		return (
			<div>
				<Formik
					initialValues={{
						email: '',
						email0: '',
						email1: '',
						email2: '',
						email3: '',
					}}
					onSubmit={(values, { resetForm }) => {
						this.props.onLoading();
						let emails = Object.values(values).filter(val => {
							return val !== '';
						});
						createInvite(emails, this.props.inviteTypeId).then(() => {
							this.props.onSuccess();
							resetForm({
								email: '',
								email0: '',
								email1: '',
								email2: '',
								email3: '',
							});
						});
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string()
							.required('Email is a required field')
							.email()
							.test('existing-email', 'This user already exists', val => this.checkEmail(val)),
						email0: Yup.string()
							.email()
							.test('existing-email', 'This user already exists', val1 => this.checkEmail(val1)),
						email1: Yup.string()
							.email()
							.test('existing-email', 'This user already exists', val => this.checkEmail(val)),
						email2: Yup.string()
							.email()
							.test('existing-email', 'This user already exists', val => this.checkEmail(val)),
						email3: Yup.string()
							.email()
							.test('existing-email', 'This user already exists', val => this.checkEmail(val)),
					})}>
					{formikProps => {
						// bind the submission handler remotely
						this.props.bindSubmitForm(formikProps.submitForm);
						return (
							<Form>
								<h3>Add Admin Users</h3>
								<Field
									name='email'
									type='email'
									label='Enter Email'
									placeholder='Email'
									description='Please write the emails of the admin users you wish to invite.'
									component={Input}
								/>
								{this.displayExtraFields()}
								<div>
									<If condition={this.state.extraFields === 4}>
										<Then>
											<span data-cy='limitedInvitationNum'>You can only invite a maximum of 5 people at once!</span>
										</Then>
										<Else>
											<a
												className='action'
												data-cy='addNewField'
												onClick={() => this.addExtraInputField()}
												data-tooltip={'Add New Field'}
												data-position='right'>
												<i className='material-icons-outlined'>add_box</i>
											</a>
										</Else>
									</If>
								</div>
								<Alert
									display={this.props.loaded}
									message='The users were successfully invited to AmWell !'
									variant='success'
									onClose={this.props.onCloseAlert}
								/>
								<Alert display={this.props.error} message='Something went wrong! Please try again. ' variant='error' onClose={this.props.onCloseAlert} />
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default InviteAdmins;

import Participant from 'calls/scripts/participant';
import SocketEvents from 'constants/socket-events';
import {
	AudioOutputDevice,
	DeviceCommands,
	CallTypes,
	SerialTVCommands,
	TvBrands,
	/* eslint-disable-next-line no-unused-vars */
	CameraCommands,
} from 'constants/enums';
import { sendCommand } from 'api/devices';
import ParticipantEvent from 'calls/scripts/participant-events.enum';

export default class PatientParticipant extends Participant {
	/**
	 *
	 * @param {Object} socket
	 * @param {String} conferenceId
	 * @param {Object} data
	 * @param {String} localParticipantId
	 * @param {Object} connection
	 * @param {Object} localTrackController
	 * @param {Object} remoteTrackController
	 */
	constructor(socket, conferenceId, data, localParticipantId, connection, localTrackController, remoteTrackController) {
		super(data, localParticipantId, connection, localTrackController, remoteTrackController);
		this.conferenceId = conferenceId;
		this.socket = socket;
		this.showPTZ = data.callType === CallTypes.SECURITYCAM;
		this.disabledTiltDirections = {};
		// TODO my suggestion is that deviceControllsLocked should be initilized from helloDevice.initialState event
		this.deviceControlsLocked = false;
		// TODO patientId should not be objectId or helloDeviceId
		this.patientId = null;
		this.cameraType = null;
		this.cameraZoomLevel = 0;
		this._zoomRange = 0;
		this.nightVisionMode = false;
		this.tvStatus = null;
		this.volumeStatus = null;
		this.hdmiStatus = null;
		this.expandMonitoringFeed = false; // TODO
		this.feedColor = null; // TODO
		this.audioOutputDevice = AudioOutputDevice.HELLO;
		this.tvBrand = TvBrands.UNKNOWN;
		this.isCecSupported = false;
		this.bookmarkList = [];
		this.events = [];
		this.isHuddleCamConnected = true;
		this.isCameraPrivacyOn = false;
		this.isMicPrivacyOn = false;
		this.isUsingHightBitrate = false;
		this.isAiAlertModalOpen = false;
		this.warning = null;
		this.inactiveTimeValue = '';

		const onInitialState = data => {
			if (!data || data.helloDeviceId !== this.objectId) {
				return;
			}

			this.cameraType = data.cameraType;
			this.cameraZoomLevel = data.zoomLevel;
			this._zoomRange = parseInt(data.zoomLevel, 10) / 20;
			this.isHuddleCamConnected = data.isHuddleCamConnected;
			this.isCameraPrivacyOn = data.isCameraPrivacyOn;
			this.isMicPrivacyOn = data.isMicPrivacyOn;
			this.nightVisionMode = data.isNightVision;
			this.tvBrand = data.tvBrand;

			if (data.bookmarkList) {
				this.bookmarkList = data.bookmarkList;
			}

			const { tvHdmiPort } = data;
			if (tvHdmiPort) {
				this.hdmiStatus = { isSuccessful: true, tvState: { isVolume: false, tvStatus: SerialTVCommands.HDMI[`SWITCH_HDMI${tvHdmiPort}`] } };
			}
		};

		const onTvResponse = data => {
			if (!data.isSuccessful || !data.tvState) {
				return;
			}
			this.isCecSupported = data.tvState.isCecSupported;

			if (data.tvState.isVolume) {
				this.volumeStatus = data.tvState.tvStatus;
				return;
			}

			if ([SerialTVCommands.INITIAL_TV_POWER, SerialTVCommands.POWER.POWER_ON, SerialTVCommands.POWER.POWER_OFF].includes(data.tvState.tvStatus)) {
				this.tvStatus = SerialTVCommands.INITIAL_TV_POWER ? true : data.tvState.tvStatus !== SerialTVCommands.POWER.POWER_OFF;
			}
		};

		this.socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
		this.socket.on(SocketEvents.HelloDevice.ON_TV_RESPONSE, onTvResponse);

		this.destroy = () => {
			super.destroy();
			this.socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
			this.socket.off(SocketEvents.HelloDevice.ON_TV_RESPONSE, onTvResponse);
		};
	}

	/**
	 * @param {string} val
	 */
	set zoomRange(val) {
		this._zoomRange = parseInt(val, 10);
	}

	/**
	 * @type {number}
	 */
	get zoomRange() {
		return this._zoomRange;
	}

	setDeviceControlsLocked = result => {
		if (typeof result?.deviceControlsLocked !== 'boolean') {
			return;
		}

		this.deviceControlsLocked = result.deviceControlsLocked ?? false;
		this.emit(ParticipantEvent.DEVICE_CONTROLS_LOCKED, this.deviceControlsLocked);
	};

	/**
	 * Method used to toggle night vision for patient participant
	 */
	toggleNightVision = async () => {
		// this.nightVisionMode will be changed if the event is successful using the cameraEventResponse event
		const result = await this.socket.emitWithPromise(SocketEvents.HelloDevice.TOGGLE_NIGHTVISION, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			helloDeviceId: this.objectId,
			toggleNightVision: !this.nightVisionMode,
		});
		this.setDeviceControlsLocked(result);
		return result;
	};

	/**
	 * Method used to send a tv command to patient participant
	 * @param {Number} serialCommand
	 * @param {Boolean} isVolume
	 */
	serialCommandsTv = (serialCommand, isVolume) => {
		return this.socket.emit(SocketEvents.HelloDevice.ON_TOGGLE_TV, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			helloDeviceId: this.objectId,
			tvStatus: serialCommand,
			isVolume: isVolume,
		});
	};

	/**
	 * Method used to switch patient's participant camera
	 */
	switchCamera = async () => {
		// throttling
		if (new Date().getTime() - this.timeout < 1000) {
			return undefined;
		}
		this.timeout = new Date().getTime();

		const result = await this.socket.emitWithPromise(SocketEvents.HelloDevice.SWITCH_CAMERA, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			helloDeviceId: this.objectId,
		});
		this.setDeviceControlsLocked(result);
		return result;
	};

	/**
	 * Method used to zoom patient's participant camera
	 * @param {Number} level
	 */
	zoomCamera = async level => {
		const result = await this.socket.emitWithPromise(SocketEvents.HelloDevice.ZOOM_CAMERA, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			helloDeviceId: this.objectId,
			level,
		});
		this.setDeviceControlsLocked(result);
		return result;
	};

	/**
	 * Method used to pan and tilt patient's participant camera
	 * @param {String} direction
	 * @param {String} action
	 */
	panTiltCamera = async (direction, action) => {
		const result = await this.socket.emitWithPromise(SocketEvents.HelloDevice.MOVE_CAMERA, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			helloDeviceId: this.objectId,
			direction,
			action,
		});
		this.setDeviceControlsLocked(result);
		return result;
	};

	/**
	 * Method used to send hello device command
	 * @param {number} command
	 * @param {string} [dynamicData]
	 * @param {string} [reason]
	 */
	deviceCommand = async (command, dynamicData, reason) => {
		const sendData = {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			helloDeviceId: this.objectId,
			command,
		};

		if (dynamicData) {
			sendData.dynamicData = dynamicData;
		}

		if (command === DeviceCommands.REBOOT_HUDDLE_CAM || command === DeviceCommands.SWITCH_AUDIO_OUTPUT) {
			const result = await this.socket.emitWithPromise(SocketEvents.HelloDevice.COMMAND, sendData);
			this.setDeviceControlsLocked(result);
			return result;
		}

		return sendCommand(this.objectId, command, reason, dynamicData);
	};

	/**
	 * Method used to request to change bitrate per RTCPeerConnection
	 * @param {Object} settings
	 * @param {Number} settings.minBitrate
	 * @param {Number} settings.maxBitrate
	 */
	requestToChangeBitrate = settings => {
		return this.socket.emit(SocketEvents.Conference.REQUEST_TO_CHANGE_BITRATE, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			actioneeparticipantId: this.id,
			settings,
		});
	};

	/**
	 * @param {typeof CameraCommands[keyof typeof CameraCommands]} command
	 * @param {string} data
	 * @returns {Promise}
	 */
	sendCameraCommand = async (command, data = null) => {
		const result = await this.socket.emitWithPromise(SocketEvents.Conference.CAMERA_COMMAND, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			actioneeParticipantId: this.id,
			command,
			...(data ? { data } : null),
		});
		this.setDeviceControlsLocked(result);
		return result;
	};

	/**
	 * @param {string} command
	 * @param {string} type
	 * @param {any} data
	 */
	sendMediaControlsEvent = async (command, type, data) => {
		const result = await this.socket.emitWithPromise(SocketEvents.Conference.MEDIA_CONTROLS, {
			actioneeParticipantId: this.id,
			participantId: this.localParticipantId,
			conferenceId: this.conferenceId,
			data,
			command,
			type,
		});
		this.setDeviceControlsLocked(result);
		return result;
	};
}

/* eslint max-classes-per-file: ["error", 4] */
export class ConferenceError {
	/**
	 *
	 * @param {string} message
	 */
	constructor(message) {
		this.message = message;
	}
}

export class HasActiveConferenceError extends ConferenceError {}
export class WrongParticipantState extends ConferenceError {}
export class NullConference extends ConferenceError {}

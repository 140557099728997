import React from 'react';
import { Modal, Select } from 'components';
import CameraSettings from 'calls/components/calls/CameraSettings';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { amwellIconLink } from 'constants/global-variables';
import { MediaDeviceKinds } from 'constants/enums';
import mediaSettingsFunctions from 'calls/helpers/media-settings-functions';

const MediaSettingsModal = props => {
	const {
		selectedMediaDevices,
		setSelectedMediaDevices,
		changeMediaConstraints,
		audioRef,
		isLoading,
		navigatorMediaDevices,
		getDefaultCam,
		getDefaultMic,
		getDefaultSpeakers,
		handleSpeakersChange,
		audioIOFromSameDevice,
		onModalClose,
	} = props;
	const { [MediaDeviceKinds.VIDEO_INPUT]: activeCamera } = selectedMediaDevices;

	const onMediaInputDeviceChange = device => {
		const constraints = new Map();

		if (device.kind === MediaDeviceKinds.VIDEO_INPUT) {
			setSelectedMediaDevices({ [MediaDeviceKinds.VIDEO_INPUT]: device });
			constraints.set(TrackType.VIDEO, { deviceId: { exact: device.deviceId } });
		} else if (device.kind === MediaDeviceKinds.AUDIO_INPUT) {
			setSelectedMediaDevices({ [MediaDeviceKinds.AUDIO_INPUT]: device });
			constraints.set(TrackType.AUDIO, { deviceId: { exact: device.deviceId } });
		}

		changeMediaConstraints(constraints);
	};

	return (
		<Modal
			display={true}
			className='invite-people cam-settings-modal'
			position='center'
			primaryButtonLabel='Media settings'
			onModalClose={onModalClose}
			submitButtonText='Save'
			isLoading={isLoading}>
			<div>
				<div className='cam-settings-header'>
					<h3>Device Settings</h3>
				</div>
				{!isLoading && (
					<div className='media-settings-header'>
						<p>Select the source for your inputs.</p>
						<div className='input'>
							<p className='label custom-label'>
								<img src={`${amwellIconLink}select-cam.svg`} alt='icon' />
								Select Video
							</p>
						</div>
						<CameraSettings
							onCameraChange={onMediaInputDeviceChange}
							cameraList={navigatorMediaDevices.cameraList}
							activeCameraId={activeCamera ? activeCamera.deviceId : null}
							defaultValue={getDefaultCam()}
						/>
						<div className='input'>
							<p className='label custom-label'>
								<img src={`${amwellIconLink}select-mic.svg`} alt='icon' />
								Select Microphone
							</p>
						</div>
						<Select
							label='Microphone'
							name='microphone'
							items={navigatorMediaDevices.micList}
							placeholder='Select Microphone'
							optionValueKey='label'
							onSelect={onMediaInputDeviceChange}
							defaultValue={getDefaultMic()}
						/>
						{/* will be needed in the future {activeMicId && <AudioMeter activeMicId={activeMicId.deviceId} />} */}
						<div className='input'>
							<p className='label custom-label'>
								<img src={`${amwellIconLink}select-speaker.svg`} alt='icon' />
								Select Speaker
							</p>
						</div>
						<Select
							label='Speaker'
							name='speakers'
							items={navigatorMediaDevices.speakersList}
							placeholder='Select Speakers'
							optionValueKey='label'
							onSelect={e => handleSpeakersChange(e)}
							defaultValue={getDefaultSpeakers()}
						/>

						<audio ref={audioRef} />
						{!audioIOFromSameDevice && (
							<p className='flex different-audio-selected'>
								<i className='material-icons-outlined'>info</i>Using different mic and speaker devices may cause echo.
							</p>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default mediaSettingsFunctions(MediaSettingsModal);

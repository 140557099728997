import React from 'react';
import Grid from 'components/Grid';
import { getUserProfile } from 'infrastructure/auth';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers';
import { Avatar } from 'components';

function signOut() {
	window.location.href = '/logout';
}

const LogOutUser = props => (
	<Grid className='log-out-user' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
		<div className='member-error'>
			<h3>You are currently logged in as: </h3>
			<div className='edit-member-form' style={{ textAlign: 'center' }}>
				<Avatar
					src={getUserProfile().profilePicture ? getUserProfile().profilePicture.url : 'https://maxcdn.solaborate.com/media/profile-pictures/duser.jpg'}
					fullName={decodeHtml(getUserProfile().firstName + ' ' + getUserProfile().lastName)}
				/>
				<p>{decodeHtml(getUserProfile().firstName + ' ' + getUserProfile().lastName)}</p>
			</div>
			<p>Please log out from this account and go back to your email to accept this invitation.</p>
			<span className='logout-link' onClick={signOut}>
				{' '}
				Log out here
			</span>
		</div>
	</Grid>
);

export default LogOutUser;

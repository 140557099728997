/**
 * Enums for Participant event to make UI changes
 * @enum {String}
 * @readonly
 */
const ParticipantEvent = {
	LEFT: 'LEFT',
	REMOVED: 'REMOVED',
	NOT_ASWERING: 'NOT_ASNWERING',
	ADDED: 'ADDED',
	STATE_CHANGED: 'STATE_CHANGED',
	CAMERA_PRIVACY_STATE_CHANGED: 'CAMERA_PRIVACY_STATE_CHANGED',
	OWNER_CHANGED: 'OWNER_CHANGED',
	PIN_FEED: 'PIN_FEED',
	VOLUME_CHANGED: 'VOLUME_CHANGED',
	DEVICE_CONTROLS_LOCKED: 'DEVICE_CONTROLS_LOCKED',
	PATIENT_OBJECT_DETECTIONS: 'PATIENT_OBJECT_DETECTIONS',
};

export default ParticipantEvent;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import Grid from 'components/Grid';
import HelloFeatureBlock from 'components/HelloFeatureBlock';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { withRouter } from 'react-router-dom';
import { SocketContext } from 'io-client/SocketContext';
import { DeviceStatus, CallTypes, MicStatus, CameraStatus, ConferenceAction, MediaDeviceKinds } from 'constants/enums';
import { If, Then, Else } from 'react-if';
import { findSectorById, findDeviceById } from 'infrastructure/helpers/commonHelpers';
import SocketEvents from 'constants/socket-events';
import { amwellIconLink } from 'constants/global-variables';
import TechCheck from 'calls/components/TechCheck';
import { getStoredDefaultMediaDevices, storeDefaultMediaDevices } from 'calls/helpers/conference-utils';
import _ from 'lodash';

class CallPatient extends Component {
	state = {
		isDeviceUnavailableModalOpen: false,
		isTechCheckModalActive: false,
		selectedMediaDevices: {
			[MediaDeviceKinds.AUDIO_INPUT]: null,
			[MediaDeviceKinds.AUDIO_OUTPUT]: null,
			[MediaDeviceKinds.VIDEO_INPUT]: null,
		},
		isCamLoading: true,
	};

	socket = this.context;

	componentDidMount() {
		this.socket.on(SocketEvents.HelloDevice.ON_STATE_CHANGED, this.onHelloStatusUpdate);
		this.setDefaultDevicesFromStore();
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.HelloDevice.ON_STATE_CHANGED, this.onHelloStatusUpdate);
	}

	onHelloStatusUpdate = ({ name, value, deviceId }) => {
		const treeData = { ...this.props.treeData };
		const room = findDeviceById(treeData.tree, deviceId);
		if (room) {
			room[name] = value;
			this.props.setTreeData(treeData);
			this.setState({});
		}
	};

	toggleDeviceUnavailable = () => {
		this.setState({
			isDeviceUnavailableModalOpen: !this.state.isDeviceUnavailableModalOpen,
		});
	};

	/**
	 * @param {number} callType
	 */
	redirect = callType => {
		const room = findSectorById(this.props.treeData.tree, this.props.match.params.roomId);
		if (!room) {
			return;
		}

		if (room.status === DeviceStatus.OFFLINE) {
			this.setState({ isDeviceUnavailableModalOpen: true });
			return;
		}

		const { breadcrumb, helloDeviceId, roomName } = this.getCurrentRoom();
		const roomBreadcrumb = breadcrumb.reduce((acc, value) => `${acc}${acc && ' > '}${value.name}`, '');

		// Convert MediaDeviceInfo toJSON otherwise History.push won't be able to clone the state on history.push
		let { selectedMediaDevices } = this.state;
		selectedMediaDevices = Object.values(selectedMediaDevices).reduce((acc, device) => {
			if (device) {
				acc[device.kind] = device.toJSON();
			}
			return acc;
		}, selectedMediaDevices);

		this.props.history.push(`/${callType === CallTypes.SECURITYCAM ? 'view' : 'talk-to'}-patient`, {
			conferenceAction: ConferenceAction.START,
			roomName,
			roomBreadcrumb,
			patientId: +helloDeviceId,
			callType,
			selectedMediaDevices,
			from: this.props.location.pathname,
		});
	};

	getCurrentRoom() {
		let room = findSectorById(this.props.treeData.tree, this.props.match.params.roomId);
		if (!room) {
			this.props.history.push('/health-system');
		}
		return {
			breadcrumb: room ? room.breadcrumb : null,
			sectorName: room ? room.name : '',
			roomName: room ? room.name : '',
			helloDeviceId: room ? room.helloDeviceId : null,
			isHelloOnline: room?.status === DeviceStatus.ONLINE,
			microphoneMuted: room?.microphone === MicStatus.OFF,
			cameraOff: room?.camera === CameraStatus.OFF,
		};
	}

	setSelectedMediaDevices = selectedMediaDevices => {
		if (Object.keys(selectedMediaDevices).some(v => !selectedMediaDevices[v].deviceId)) {
			return;
		}
		this.setState(prevState => ({ selectedMediaDevices: { ...prevState.selectedMediaDevices, ...selectedMediaDevices } }));
		storeDefaultMediaDevices(selectedMediaDevices);
	};

	setDefaultDevicesFromStore = async () => {
		const defaultMediaDevices = await getStoredDefaultMediaDevices();
		if (_.isEmpty(defaultMediaDevices)) {
			return;
		}
		this.setSelectedMediaDevices(defaultMediaDevices);
	};

	render() {
		const helloBlocksMessages = {
			patientView:
				'This option will enable you to see and hear the patient. By default the patient can neither see or hear you, however you can enable your microphone during the call if you need to interact with the patient. ',
			talkToPatient: 'This option will initiate a video call with the patient, you and the patient will be able to see and hear each other.',
		};
		const { roomName, helloDeviceId, isHelloOnline, microphoneMuted, cameraOff } = this.getCurrentRoom();

		return (
			<div className='room'>
				<div className='hello-device-description'>
					<h3>{roomName}</h3>
					<p>{this.props.description}</p>
				</div>
				{helloDeviceId && (
					<ul className='hello-properties'>
						<li className='flex'>
							<img src={`${amwellIconLink}${isHelloOnline ? 'available.svg' : 'unavailable-red.svg?v2'}`} alt='icon' />
							<span>{isHelloOnline ? 'Available' : 'Unavailable'}</span>
						</li>
						<li className='flex'>
							<img src={`${amwellIconLink}${microphoneMuted ? 'mic-off.svg' : 'mic-on.svg'}`} alt='icon' />
							<span>Microphone {microphoneMuted ? 'Muted' : 'Unmuted'}</span>
						</li>
						<li className='flex'>
							<img src={`${amwellIconLink}${cameraOff ? 'cam-off.svg' : 'cam-on.svg'}`} alt='icon' />
							<span>Camera {cameraOff ? 'Off' : 'On'}</span>{' '}
						</li>
					</ul>
				)}
				<If condition={!!helloDeviceId}>
					<Then>
						<Grid columns='repeat(2, 1fr)' gridGap='15px'>
							<HelloFeatureBlock
								onClick={() => this.redirect(CallTypes.SECURITYCAM)}
								icon='remove_red_eye'
								title='View Patient'
								className='view-patient-block'
								tooltip={helloBlocksMessages.patientView}
							/>
							<HelloFeatureBlock
								onClick={() => this.redirect(CallTypes.VIDEO)}
								icon='videocam'
								title='Talk to Patient'
								className='talk-patient-block'
								tooltip={helloBlocksMessages.talkToPatient}
							/>
						</Grid>
					</Then>
					<Else>
						<Grid width='100%' gridGap='15px' padding='var(--spacing-sl)'>
							<p style={{ margin: 0, padding: 0 }}>
								This room doesn't have any assigned devices.
								<br /> Please contact your administrator.
							</p>
						</Grid>
					</Else>
				</If>
				<div
					className='blue'
					onClick={() =>
						this.setState({
							isTechCheckModalActive: true,
						})
					}>
					<p className='cursor-pointer'>Tech Check</p>
				</div>
				<Modal
					modalSelector='deviceUnavailableModal'
					display={this.state.isDeviceUnavailableModalOpen}
					position='center'
					submitButtonText='OK'
					closeButtonText='Discard'
					onModalClose={this.toggleDeviceUnavailable}
					onModalSubmit={this.toggleDeviceUnavailable}>
					<Form title='Device unavailable'>
						<p>Call could not be started from {roomName}. Device offline. Please try again later.</p>
					</Form>
				</Modal>
				{this.state.isTechCheckModalActive && (
					<Modal
						display={true}
						className='invite-people cam-settings-modal tech-check-modal'
						position='center'
						primaryButtonLabel='Media settings'
						onModalClose={() =>
							this.setState({
								isTechCheckModalActive: false,
							})
						}
						submitButtonText=''
						isLoading={this.state.isCamLoading}>
						<TechCheck
							selectedMediaDevices={this.state.selectedMediaDevices}
							setSelectedMediaDevices={this.setSelectedMediaDevices}
							setDefaultDevicesFromStore={this.setDefaultDevicesFromStore}
							isCamLoading={this.state.isCamLoading}
							setIsCamLoading={isCamLoading => {
								this.setState({
									isCamLoading,
								});
							}}
							isSoundPermissionTechCheck={false}
						/>
					</Modal>
				)}
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(CallPatient));

CallPatient.contextType = SocketContext;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getUserRole } from 'infrastructure/auth';
import { AlertTypes, CareEventTypes, DetectionTypes, UserRoles } from 'constants/enums';
import WarningDetection from 'components/WarningDetection';
import CareEventsModal from 'calls/components/monitoring/modals/CareEventsModal';
import { saveManualAlert } from 'api/alerts';
import Alert from 'components/Alert';
import { healthCareCdnUrl } from 'constants/global-variables';
import styled from 'styled-components';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';
import drawObjectDetections from 'services/aiDetections/objectDetection';

const VideoFeedAlertOptions = styled.div`
	position: absolute;
	bottom: var(--spacing-m);
	left: var(--spacing-m);
	display: flex;
	flex-direction: row !important;
	z-index: 10000;
	display: grid !important;
	grid-template-columns: repeat(2, 1fr);

	> div {
		padding: var(--spacing-s);
		background: rgba(52, 58, 64, 0.8);
		margin-right: var(--spacing-s);
		border-radius: 10px;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		width: 115px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: var(--spacing-s);

		&:hover {
			background: rgba(52, 58, 64, 1);
		}

		> p {
			font-size: 14px;
			margin: 0;
			padding: 0;
			color: var(--gray-2);
			margin-top: var(--spacing-s);
		}
	}
`;

const FeedWarningItems = ({ participant, numberOfFeeds, hierarchyNaming, stopVoiceOver, conferenceInfo, tools, detectionMode, isAiDisabled }) => {
	const [isManualReportLoading, setIsManualReportLoading] = useState(false);
	const [careEventsForNurses, setCareEventsForNurses] = useState(false);
	const [isRaiseAlertOpen, setIsRaiseAlertOpen] = useState(false);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	const careEventsForNursesRef = useRef(null);
	const raiseAlertRef = useRef(null);
	const canvasRef = useRef(null);
	let ctx = useRef(null);

	const prepareCanvas = useCallback((shouldClearCanvas = true) => {
		ctx.current = canvasRef.current?.getContext('2d');
		if (!ctx?.current) {
			return;
		}

		if (shouldClearCanvas) {
			ctx.current.clearRect(0, 0, window.innerWidth, (window.innerWidth * 9) / 16);
		}
	}, []);

	useEffect(() => {
		prepareCanvas();
	}, [tools, detectionMode, prepareCanvas]);

	useEffect(() => {
		const shouldShowDetections = data =>
			(ctx?.current || detectionMode) && conferenceInfo.id === data.conferenceId && participant.id === data.participantId && participant.isAiDetection;

		const objectDetectionsListener = data => {
			const bedOrRail = data.detections.find(item => item.detectionType === DetectionTypes.BED || item.detectionType === DetectionTypes.RAIL_DOWN)
				?.detectionType;

			prepareCanvas(false);
			if (shouldShowDetections(data)) {
				drawObjectDetections(ctx?.current, data.detections, bedOrRail || detectionMode || null, window.innerWidth, (window.innerWidth * 9) / 16);
			}
			if (!participant.isAiDetection && ctx?.current) {
				ctx.current.clearRect(0, 0, window.innerWidth, (window.innerWidth * 9) / 16);
			}
		};
		socket.on(SocketEvents.HelloDevice.PATIENT_OBJECT_DETECTIONS, objectDetectionsListener);
		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_OBJECT_DETECTIONS, objectDetectionsListener);
		};
	}, [socket, detectionMode, conferenceInfo, participant, prepareCanvas]);

	useEffect(() => {
		const handleCanvasResize = () => {
			if (ctx?.current) {
				ctx.current.canvas.height = (window.innerWidth * 9) / 16;
				ctx.current.canvas.width = window.innerWidth;
			}
		};
		window.addEventListener('resize', handleCanvasResize);
		return () => {
			window.removeEventListener('resize', handleCanvasResize);
		};
	}, []);

	const onAlertCloseClick = () => {
		if (participant.warning?.value) {
			stopVoiceOver(participant.objectId);
			prepareCanvas();
		}
		if (participant.warning?.type) {
			socket.emit(SocketEvents.HelloDevice.CANCEL_AI_ALERT, {
				helloDeviceId: participant.objectId,
				conferenceId: conferenceInfo.id,
				participantId: participant.id,
				type: participant.warning?.type,
			});
		}
	};

	const toggleCareEventsForNurses = () => {
		setCareEventsForNurses(prevState => !prevState);
		setIsRaiseAlertOpen(false);
		onAlertCloseClick();
	};

	const toggleRaiseAlert = () => {
		setCareEventsForNurses(false);
		setIsRaiseAlertOpen(prevState => !prevState);
		onAlertCloseClick();
	};

	const onCareEventSave = async (alertId, comment) => {
		const dataToSend = {
			patientId: participant.deviceOwnerId,
			deviceId: participant.objectId,
			manualAlertTypeId: alertId,
			comment,
		};
		setIsManualReportLoading(true);
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			setIsManualReportLoading(false);
			return;
		}
		setIsManualReportLoading(false);
		setCareEventsForNurses(false);
		setIsRaiseAlertOpen(false);
	};

	const canvasHeight = (window.innerWidth * 9) / 16;

	return (
		<>
			{getUserRole() === UserRoles.NURSE && !isAiDisabled && !participant.isDefaultOwner && (
				<>
					<canvas
						ref={canvasRef}
						height={canvasHeight}
						width={window.innerWidth}
						style={{
							position: 'absolute',
							top: 'calc(50% + 15px)',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							maxWidth: '100%',
							maxHeight: 'calc(100% - 30px)',
						}}
					/>
					{participant.warning?.value && <div className='fall-detected-red-warning fall-is-detected' />}
					<WarningDetection
						warningObject={participant.warning || null}
						alertType={AlertTypes.DANGER}
						onAlertCloseClick={onAlertCloseClick}
						isFeedExpanded={participant.expandMonitoringFeed || false}
						numberOfFeeds={numberOfFeeds}
					/>
					{careEventsForNurses && (
						<CareEventsModal
							eventType={CareEventTypes.Nurses}
							onModalClose={() => {
								setCareEventsForNurses(false);
								setIsRaiseAlertOpen(false);
							}}
							className='position-relative'
							hierarchyNaming={hierarchyNaming}
							onSubmitModal={(alertId, comment) => onCareEventSave(alertId, comment)}
							isManualReportLoading={isManualReportLoading}
						/>
					)}
					{isRaiseAlertOpen && (
						<CareEventsModal
							eventType={CareEventTypes.RaiseAlert}
							onModalClose={() => {
								setCareEventsForNurses(false);
								setIsRaiseAlertOpen(false);
							}}
							className='position-relative'
							hierarchyNaming={hierarchyNaming}
							onSubmitModal={(alertId, comment) => onCareEventSave(alertId, comment)}
							isManualReportLoading={isManualReportLoading}
						/>
					)}
				</>
			)}
			{(numberOfFeeds === 1 || participant.expandMonitoringFeed) && getUserRole() === UserRoles.NURSE && !isAiDisabled && !participant.isDefaultOwner && (
				<VideoFeedAlertOptions>
					<div className='cursor-pointer' onClick={toggleCareEventsForNurses} ref={careEventsForNursesRef}>
						<img src={`${healthCareCdnUrl}monitoring/video-feed/report-as-false.svg`} alt='icon' />
						<p>Care Event</p>
					</div>
					<div className='cursor-pointer' onClick={toggleRaiseAlert} ref={raiseAlertRef}>
						<img src={`${healthCareCdnUrl}monitoring/video-feed/report-as-false.svg`} alt='icon' />
						<p>Raise Alert</p>
					</div>
				</VideoFeedAlertOptions>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default FeedWarningItems;

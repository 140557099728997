import { ConferenceErrorType } from 'constants/enums';
import SocketEvents from 'constants/socket-events';

export default class ConnectionLogger {
	constructor(socket, conferenceId, participantId, actioneeParticipantId) {
		this.socket = socket;
		this.conferenceId = conferenceId;
		this.participantId = participantId;
		this.actioneeParticipantId = actioneeParticipantId;
		this.ms = !actioneeParticipantId;
		this.logPrefix = actioneeParticipantId.split('-')[0] + '->';
	}

	conferenceError(message, ...params) {
		const data = {
			conferenceId: this.conferenceId,
			participantId: this.participantId,
			actioneeParticipantId: this.actioneeParticipantId,
			method: message,
			message: JSON.stringify(params),
			// TODO map this from method or smthg
			errorType: ConferenceErrorType.NEGOTIATION_FAILURE,
		};
		this.socket.emit(SocketEvents.Conference.ERROR, data);
	}

	conferenceLog(message, ...params) {
		const data = {
			conferenceId: this.conferenceId,
			participantId: this.participantId,
			actioneeParticipantId: this.actioneeParticipantId,
			message: message + ': ' + JSON.stringify(params),
		};
		this.socket.emit(SocketEvents.Conference.LOG, data);
	}

	log(message, ...params) {
		console.log(this.logPrefix + message, params);
	}

	warn(message, ...params) {
		console.warn(this.logPrefix + message, params);
		this.conferenceLog(message, ...params);
	}

	debug(message, ...params) {
		console.debug(this.logPrefix + message, params);
		this.conferenceLog(message, ...params);
	}

	error(message, ...params) {
		console.error(this.logPrefix + message, params);
		this.conferenceError(message, ...params);
	}
}

import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { SocketContext } from 'io-client/SocketContext';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import hark from 'hark';
import { ObjectType, HarkEvents, CameraEventTypes } from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { TrackAdded, TrackRemoved } from '@solaborate/webrtc/lib/Connection';
import Participant from 'calls/scripts/participant';
import ParticipantAudio from 'calls/components/calls/ParticipantAudio';
import ParticipantCamera from 'calls/components/calls/ParticipantCamera';
import ParticipantScreen from 'calls/components/calls/ParticipantScreen';
import classNames from 'classnames/bind';

export default function ParticipantView(props) {
	const {
		participant,
		activeTrackType,
		disableButtons,
		onRemoteParticipantTracksChanged,
		onClick: onParticipantClick,
		localParticipantIsInitiator,
		onRemoveParticipant,
		onTransferOwnership,
		onPinnedFeed,
		onParticipantStateChanged,
		pinnedParticipantId,
		activeParticipant,
	} = props;

	const [participantVolume, setParticipantVolume] = useState(null);
	const [remoteTracks, setRemoteTracks] = useState(participant.remoteTrackController.tracks);
	let harkIn = useRef(null);
	const socket = useContext(SocketContext);

	const onClick = trackType => {
		onParticipantClick(participant, trackType);
	};

	const setSelectedParticipantVolume = ev => {
		if (!ev || !ev.track || ev.track.type !== TrackType.AUDIO) {
			return;
		}

		if (ev.constructor === TrackAdded) {
			if (harkIn.current) {
				harkIn.current.stop();
				harkIn.current = null;
			}

			const audioStream = new MediaStream([ev.track.track]);
			harkIn.current = hark(audioStream, {});

			harkIn.current.on(HarkEvents.STOPPED_SPEAKING, () => {
				setParticipantVolume(0);
				participant.emit(ParticipantEvent.VOLUME_CHANGED, 0);
			});

			harkIn.current.on(HarkEvents.VOLUME_CHANGE, (volume, threshold) => {
				if (volume < threshold) {
					return;
				}
				const calcVolume = Math.round(((volume - threshold) * -10) / threshold);
				setParticipantVolume(calcVolume);
				participant.emit(ParticipantEvent.VOLUME_CHANGED, calcVolume);
			});
		} else {
			if (harkIn.current) {
				harkIn.current.stop();
				harkIn.current = null;
			}
			participant.emit(ParticipantEvent.VOLUME_CHANGED, 0);
		}
	};

	const onTrackChanged = ev => {
		// Needed to trigger re-render on remote track change
		setRemoteTracks(new Map(participant.remoteTrackController.tracks));
		onRemoteParticipantTracksChanged();
		if (
			ev.constructor === TrackAdded &&
			(ev.track.type === TrackType.SCREEN ||
				(activeTrackType !== TrackType.SCREEN && participant.objectType === ObjectType.HELLO_DEVICE && ev.track.type === TrackType.VIDEO))
		) {
			onClick(ev.track.type);
		} else if (ev.constructor === TrackRemoved && activeTrackType === TrackType.SCREEN && participant.remoteTrackController.tracks.has(TrackType.VIDEO)) {
			onClick(TrackType.VIDEO);
		}
		setSelectedParticipantVolume(ev);
	};

	/**
	 * @param {number} changedState
	 */
	const participantStateChangeHandler = changedState => {
		onParticipantStateChanged(changedState, participant.name);
	};

	const onInitialState = ({ cameraType, isCameraPrivacyOn, isMicPrivacyOn }) => {
		Object.assign(participant, {
			cameraType,
			isCameraPrivacyOn,
			isMicPrivacyOn,
		});
		participant.emit(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED);
	};

	const cameraResponseListener = ({ event, isSuccessful }) => {
		if (event === CameraEventTypes.HELLO_CAMERA_PRIVACY_STATE) {
			participant.isCameraPrivacyOn = isSuccessful;
		} else if (event === CameraEventTypes.HELLO_MIC_PRIVACY_STATE) {
			participant.isMicPrivacyOn = isSuccessful;
			if (participant.isMicPrivacyOn) {
				participant.emit(ParticipantEvent.VOLUME_CHANGED, 0);
			}
		}
		participant.emit(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED);
	};

	useEffect(() => {
		participant.on(ParticipantEvent.STATE_CHANGED, participantStateChangeHandler);
		participant.remoteTrackController.on(onTrackChanged);

		if (participant.objectType === ObjectType.HELLO_DEVICE) {
			socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
			socket.on(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, cameraResponseListener);
		}

		return () => {
			participant.off(participantStateChangeHandler);
			participant.remoteTrackController.off(onTrackChanged);

			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
				socket.off(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, cameraResponseListener);
			}
		};
	}, [participant]);

	const hasVideoTrack = !!remoteTracks.get(TrackType.VIDEO);
	const screenTrack = remoteTracks.get(TrackType.SCREEN);
	const hasScreenTrack = !!screenTrack;
	const showParticipantCamera = hasVideoTrack || (!hasVideoTrack && !hasScreenTrack);

	return (
		<>
			<div className={classNames('participant-wrapper', activeParticipant && participant.id === activeParticipant.id ? ' active-participant-wrapper' : '')}>
				<ParticipantAudio participant={participant} activeSpeakersId={props.activeSpeakersId} />
				{showParticipantCamera && (
					<ParticipantCamera
						onRemoveParticipant={() => onRemoveParticipant(participant)}
						onTransferOwnership={() => onTransferOwnership(participant.id)}
						onPinnedFeed={() => onPinnedFeed(participant, TrackType.VIDEO)}
						localParticipantIsInitiator={localParticipantIsInitiator}
						participant={participant}
						disableButtons={disableButtons}
						onClick={onClick}
						participantVolume={participantVolume}
						isPinned={participant.id === pinnedParticipantId && activeTrackType === TrackType.VIDEO}
					/>
				)}
				{hasScreenTrack && !hasVideoTrack && (
					<ParticipantScreen
						onPinnedFeed={() => onPinnedFeed(participant, TrackType.SCREEN)}
						isPinned={participant.id === pinnedParticipantId && activeTrackType === TrackType.SCREEN}
						participant={participant}
						localParticipantIsInitiator={localParticipantIsInitiator}
						track={screenTrack}
						onClick={onClick}
					/>
				)}
			</div>
			{hasScreenTrack && hasVideoTrack && (
				<div className='participant-wrapper'>
					<ParticipantScreen
						onPinnedFeed={() => onPinnedFeed(participant, TrackType.SCREEN)}
						isPinned={participant.id === pinnedParticipantId && activeTrackType === TrackType.SCREEN}
						participant={participant}
						localParticipantIsInitiator={localParticipantIsInitiator}
						track={screenTrack}
						onClick={onClick}
					/>
				</div>
			)}
		</>
	);
}

ParticipantView.defaultProps = {
	disableButtons: false,
	localParticipantIsInitiator: false,
	pinnedParticipantId: '',
};

ParticipantView.propTypes = {
	participant: PropTypes.instanceOf(Participant).isRequired,
	pinnedParticipantId: PropTypes.string,
	activeTrackType: PropTypes.oneOf(Object.values(TrackType)).isRequired,
	disableButtons: PropTypes.bool,
	localParticipantIsInitiator: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	onRemoteParticipantTracksChanged: PropTypes.func.isRequired,
	onRemoveParticipant: PropTypes.func.isRequired,
	onPinnedFeed: PropTypes.func.isRequired,
	onParticipantStateChanged: PropTypes.func.isRequired,
	activeSpeakersId: PropTypes.string.isRequired,
};

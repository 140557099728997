import React from 'react';
import Tabs from 'components/Tabs';
import styled from 'styled-components';
import AiAlertsHistory from 'calls/components/monitoring/AiAlertsHistory';
import AiAlertsSetup from 'calls/components/monitoring/AiAlertsSetup';

const AiAlertsWrapper = styled.div`
	> div {
		> ul.tabs {
			margin: var(--spacing-l) 0;
			background: none;
			border-bottom: none;
			box-shadow: none;

			> li {
				flex: 0;
				border-radius: 2px;

				&.active {
					background: var(--blue-2);
				}

				&:not(:first-of-type) {
					margin-left: var(--spacing-l);
				}

				a {
					padding: var(--spacing-s) var(--spacing-l);
				}
			}
		}
	}
`;

const AiAlerts = ({ feed, onAiSettingsClick, setInactiveTimeToFeed, isAiDisabled }) => {
	return (
		<AiAlertsWrapper>
			<Tabs
				links={[{ link: 'History', active: true }, { link: 'Setup' }]}
				components={[
					<AiAlertsHistory objectId={feed?.objectId} />,
					<AiAlertsSetup isAiDisabled={isAiDisabled} feed={feed} onAiSettingClick={onAiSettingsClick} setInactiveTimeToFeed={setInactiveTimeToFeed} />,
				]}
			/>
		</AiAlertsWrapper>
	);
};

export default AiAlerts;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from 'state/organization/actions';
import { Route } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { UserRoles } from 'constants/enums';

import Grid from 'components/Grid';
import Layout from 'components/Common/Layout';
import Header from 'components/Common/Header';
import SectorList from 'components/Common/SectorList';

import AssignDeviceModal from 'components/Common/AssignDeviceModal';

import CallPatient from 'views/Organization/Partials/CallPatient';
import Hospital from 'views/Organization/Partials/Hospital';
import Department from 'views/Organization/Partials/Department';
import Floor from 'views/Organization/Partials/Floor';
import Room from 'views/Organization/Partials/Room';
import UnassignedNurse from 'views/UnassignedNurse';

import { getUserRole } from 'infrastructure/auth';
import { getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';

const Organization = () => {
	const dispatch = useDispatch();
	const orgProps = useSelector(state => state.organization);
	const toggleTree = status => dispatch(actionCreators.toggleTreeVisibility(status));
	const createDepartment = status => dispatch(actionCreators.createDepartment(status));
	const createRoom = (currentHealthSystemId, hospitalId, departmentId) => dispatch(actionCreators.createRoom(currentHealthSystemId, hospitalId, departmentId));
	const toggleAssignDeviceModal = status => dispatch(actionCreators.toggleAssignDeviceModal(status));
	const getTreeData = () => dispatch(actionCreators.getTreeData());

	useEffect(() => {
		toggleTree(true);
	}, []);

	const hasHealthSystems = () => {
		return orgProps.allHealthSystems.length > 0;
	};

	const isRoomURL = () => {
		let pathName = window.location.pathname.split('/');
		return pathName.some((path, index) => path.toLowerCase() === 'room' && pathName[index + 1]);
	};

	if (!hasHealthSystems()) {
		return <UnassignedNurse />;
	}

	const userRole = getUserRole();

	return (
		<Layout twoColumns={true} isSecondColumnCollapsed={!orgProps.isTreeVisible}>
			<aside className='hello-list' data-cy='sectorsList' style={getMobileFullHeightStyle()}>
				<SectorList />
				<div className='collapse-second-column' onClick={() => toggleTree(false)}>
					<i className='material-icons-outlined'>close</i>
				</div>
			</aside>
			{[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) && !orgProps.isTreeVisible && (
				<div className='collapse-second-column organization-hamburger-menu' onClick={() => toggleTree(true)}>
					<i className='material-icons-outlined'>menu</i>
				</div>
			)}
			<main className='main-view organization-view' style={getMobileFullHeightStyle()}>
				<Header />
				<section>
					<Grid width='100%' vertAlign={[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) ? 'start' : 'center'} horizAlign='center' rows='auto'>
						<If condition={[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole)}>
							<Then>
								<Route exact path='/health-system/:hospitalId' component={Hospital} />
								<Route exact path='/health-system/:hospitalId/department/:departmentId' component={Department} />
								<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId' component={Floor} />
								<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId' component={Room} />
							</Then>
							<Else>
								{!isRoomURL() && <p>Please select one of the rooms to make a call.</p>}
								{orgProps.treeData.tree.length > 0 && (
									<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId' component={() => <CallPatient />} />
								)}
							</Else>
						</If>
					</Grid>
					<AssignDeviceModal
						{...orgProps}
						toggleAssignDeviceModal={toggleAssignDeviceModal}
						getTreeData={getTreeData}
						createDepartment={createDepartment}
						createRoom={createRoom}
					/>
				</section>
			</main>
		</Layout>
	);
};

export default Organization;

export { Organization }; //Exported for Unit Tests only!

import { Field } from 'formik';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers';
import React from 'react';
import Select from 'react-select';
import Input from 'components/Common/FormElements/Input';

const PersonalInformation = props => {
	const genders = [
		{ code: 1, name: 'Male' },
		{ code: 2, name: 'Female' },
	];
	const getValue = () => props.transformArray(genders).filter(option => option.value === props.values?.genderId);
	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;

	return (
		<div className='margin-top-xl'>
			<Field name='firstName' type='text' label='First Name' placeholder='First Name' component={Input} maxLength={30} />
			<Field name='lastName' type='text' label='Last Name' placeholder='Last Name' component={Input} maxLength={30} />
			<Field
				name='idNumber'
				type='number'
				label='ID Number'
				placeholder='ID Number'
				component={Input}
				className='number-input-wo-arrows'
				maxLength={10}
				onKeyDown={event => validateMaxLength(event, 10)}
			/>
			<Field name='birthDate' type='date' label='Birth Date' placeholder='Birth Date' component={Input} />
			<p style={{ fontWeight: 500, margin: 0, fontSize: '14px' }}>Sex</p>
			<Select
				value={getValue()}
				placeholder='Sex'
				classNamePrefix='custom-select'
				options={props.transformArray(genders)}
				onChange={event => props.formikProps?.setFieldValue('genderId', event.value)}
				components={{ DropdownIndicator }}
				isSearchable
			/>
			<small className='red-error'>{props.errors.genderId}</small>
		</div>
	);
};
export default PersonalInformation;

import { Grid, Tabs } from 'components';
import Header from 'components/Common/Header';
import Layout from 'components/Common/Layout';
import React from 'react';
import AiHistory from 'views/AlertHistory/AiHistory';
import ManualAlerts from 'views/AlertHistory/ManualAlerts';

const AlertHistory = () => (
	<Layout>
		<div className='call-logs-view'>
			<Grid columns='0 3fr' stretch='100%'>
				<aside className='hello-list' />
				<main className='main-view'>
					<Header />
					<section>
						<div style={{ width: '100%' }}>
							<h3>Alert History</h3>
							<Tabs links={[{ link: 'AI Alerts', active: true }, { link: 'Manual Alerts' }]} components={[<AiHistory />, <ManualAlerts />]} />
						</div>
					</section>
				</main>
			</Grid>
		</div>
	</Layout>
);

export default AlertHistory;

import React, { Component } from 'react';
import Table from 'components/Table';
import { findSectorById, getSectorPath } from 'infrastructure/helpers/commonHelpers';
import SmallModal from 'components/SmallModal';
import AssignDropdown from 'components/UserManagement/AssignDropdown';
import { If, Then, Else } from 'react-if';
import Grid from 'components/Grid';
import Loader from 'components/Loader';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'components/Common/FormElements/Select';
import Alert from 'components/Alert';
import classNames from 'classnames/bind';
import { addToHealthSystem, getNurseHealthSystems, getHealthSystemSubTree, assignMember, deleteHealthSystemMember } from 'api/users';

class ManageHealthSystems extends Component {
	constructor(props) {
		super(props);

		this.state = {
			healthsystems: [],
			showHealthSystemForm: false,
			loadingHealthSystems: false,
			currentHealthSystem: {},
			currentUserSubTree: [],
			assigningUser: false,
		};
	}

	async componentDidMount() {
		this.setState({ loadingHealthSystems: true });
		let healthsystems = await getNurseHealthSystems(this.props.member.memberId);
		this.setState({
			healthsystems: healthsystems,
			loadingHealthSystems: false,
		});
	}

	displayHealthSystems() {
		return this.state.healthsystems.map(hs => {
			return {
				name: hs.name,
				assignation: this.Assignation(hs),
				edit: this.getEditButtons(hs.id),
			};
		});
	}

	getEditButtons(healthSystemId) {
		return (
			<div className='wrapped'>
				<i className='material-icons-outlined boxed-icon' onClick={() => this.deleteHealthSystem(healthSystemId)}>
					delete
				</i>
			</div>
		);
	}

	deleteHealthSystem(healthSystemId) {
		deleteHealthSystemMember(healthSystemId, this.props.member.memberId).then(() => {
			this.setState({
				healthsystems: this.state.healthsystems.filter(hs => {
					return hs.id !== healthSystemId;
				}),
			});
		});
	}

	Assignation = healthSystem => {
		let sector = healthSystem.location ? findSectorById(this.state.currentUserSubTree, healthSystem.location.id) : null;
		let preselected = !!sector ? getSectorPath(sector) : {};
		return (
			<div style={{ position: 'relative' }}>
				<div className={classNames('assign-btn', { unassigned: !healthSystem.location })} onClick={() => this.getTree(healthSystem)}>
					{healthSystem.location ? (
						healthSystem.location.name
					) : (
						<span>
							<i className='material-icons'>how_to_reg</i>Assign Level
						</span>
					)}
				</div>
				{this.state.currentHealthSystem.id === healthSystem.id && (
					<SmallModal display={true} onModalClose={() => this.setState({ currentHealthSystem: {} })}>
						<If condition={this.state.fetchingSubTree || this.state.assigningUser}>
							<Then>
								<Grid columns='1fr' rows='1fr' stretch='auto' horizAlign='center' vertAlign='center'>
									<div style={{ textAlign: 'center' }}>
										<Loader />
									</div>
								</Grid>
							</Then>
							<Else>
								<AssignDropdown
									onSelectLocation={level => this.onSelectLocation(level)}
									data={this.state.currentUserSubTree}
									preSelected={preselected}
									selectedId={healthSystem.location ? healthSystem.location.id : null}
								/>
							</Else>
						</If>
					</SmallModal>
				)}
			</div>
		);
	};

	async onSelectLocation(level) {
		this.setState({ assigningUser: true });
		let userId = this.props.member.userInfo.id;
		let healthSystemIndex = this.state.healthsystems.findIndex(hs => hs.id === this.state.currentHealthSystem.id);

		assignMember(this.state.currentHealthSystem.id, level.id, userId)
			.then(() => {
				let items = [...this.state.healthsystems];
				let item = {
					...items[healthSystemIndex],
					location: {
						...items[healthSystemIndex].location,
						id: level.id,
						name: level.name,
					},
				};

				items[healthSystemIndex] = item;
				this.setState({ healthsystems: items, currentHealthSystem: {}, assigningUser: false });
			})
			.catch(error => console.log(error));
	}

	async getTree(healthSystem) {
		this.setState({ currentHealthSystem: healthSystem, fetchingSubTree: true });
		let subtree = await getHealthSystemSubTree(healthSystem.id);
		this.setState({ fetchingSubTree: false, currentUserSubTree: subtree });
	}

	getHealthSystems(allHealthSystems, assignedHealthSystems) {
		return allHealthSystems.filter(function(e) {
			let f = assignedHealthSystems.find(a => a.id == e.id);
			return f ? false : true;
		});
	}

	render() {
		let usersTableHeaders = [{ title: 'Health System' }, { title: 'Assignation' }, { edit: '' }];

		return (
			<Formik
				initialValues={{
					healthSystem: '',
				}}
				onSubmit={(values, { resetForm }) => {
					if (this.state.showHealthSystemForm) {
						this.props.onLoading('healthSystemAddition');
						addToHealthSystem(values.healthSystem, this.props.member.memberId).then(res => {
							let healthSystemName = this.props.healthSystems.find(hs => hs.id == values.healthSystem).value;
							this.props.onSuccess('healthSystemAddition');
							this.setState(prevState => ({
								healthsystems: [...prevState.healthsystems, { id: values.healthSystem, name: healthSystemName }],
							}));
							resetForm({
								healthSystem: '',
							});
						});
					} else {
						this.props.onSuccess('healthSystemAddition');
					}
				}}
				validationSchema={() => {
					if (this.state.showHealthSystemForm) {
						return Yup.object().shape({
							healthSystem: Yup.string().required('Health system is required'),
						});
					}
				}}>
				{formikProps => {
					// bind the submission handler remotely
					this.props.bindSubmitForm(formikProps.submitForm);
					return (
						<Form>
							<h3>Manage Health Systems</h3>
							<Table
								isLoading={this.state.loadingHealthSystems}
								headers={usersTableHeaders}
								rows={this.state.loadingHealthSystems ? [] : this.displayHealthSystems()}
							/>
							<a
								className='action'
								onClick={() => {
									this.setState({ showHealthSystemForm: !this.state.showHealthSystemForm });
								}}
								data-tooltip='Add New Health System'
								data-position='right'>
								<i className='material-icons-outlined'>add_box</i>
							</a>
							{this.state.showHealthSystemForm && !this.state.loadingHealthSystems && (
								<div style={{ paddingTop: '15px' }}>
									<Field
										name='healthSystem'
										type='select'
										label='Enter Health System'
										placeholder='Select Health System'
										description='Please select the health system you want to assign this nurse to.'
										items={this.getHealthSystems(this.props.healthSystems, this.state.healthsystems)}
										component={Select}
									/>
								</div>
							)}
							<Alert
								alertSelector='assignNurseToAnotherHSMessage'
								display={this.props.loaded}
								message='Nurse was added successfully to the health system.'
								variant='success'
								onClose={() => this.props.onCloseAlert('healthSystemAddition')}
							/>
							<Alert
								display={this.props.error}
								message='Something went wrong! Please try again. '
								variant='error'
								onClose={() => this.props.onCloseAlert('healthSystemAddition')}
							/>
						</Form>
					);
				}}
			</Formik>
		);
	}
}

export default ManageHealthSystems;

import React, { Component } from 'react';
import { Button, Table, Tabs, Form, Modal, DescriptionBox, Input, Alert } from 'components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { getDeviceList, deleteDevice, sendCommand, getLatestAppRelease, forceUpdateDevice } from 'api/devices';
import { deleteOrgUnit } from 'api/organization';
import { DeviceListLevel, DeviceCommands, UserRoles } from 'constants/enums';
import EditSectorModal from 'components/Common/EditSectorModal';
import {
	findSectorById,
	mapSectionsToRender,
	getParentSector,
	getCurrentHealthSystemInfo,
	getHealthSystemPools,
	getLevelDevices,
} from 'infrastructure/helpers/commonHelpers';
import { APP_CONFIG } from 'constants/global-variables';
import { CSVLink } from 'react-csv';
import { assignPoolToDevices, getDevicesPools } from 'api/nursePooling';
import DeviceSelection from 'components/NursePooling/DeviceSelection';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';

class Floor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			devices: [],
			preSelected: null,
			selectedDevice: {},
			selectedOrg: {},
			isDeleteDeviceModalOpen: false,
			isRebootDeviceModalOpen: false,
			isUpdateDeviceModalOpen: false,
			isDeleteOrgUnitModalOpen: false,
			isEditSectorModalOpen: false,
			sectorData: {},
			sectorLocation: [],
			parentSectorName: '',
			parentSectorType: '',
			rebootReason: '',
			isDeleteDeviceModalLoading: false,
			latestAppRelease: null,
			alert: {
				message: false,
				messageAlert: '',
				variantAlert: '',
			},
			// checkedDevices: new Map(),
			// selectedPoolIndex: -1,
			// isAssignPoolModalOpen: false,
			// isAssignPoolModalLoading: false,
			// isAssignPoolModalSubmitDisabled: true,
			// selectedPool: {},
			// healthSystemPools: [],
		};
	}

	async componentDidMount() {
		this.loadFloor();
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { floorId } = this.props.match.params;
		if (
			floorId !== prevProps.match.params.floorId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadFloor();
		}
	};

	exportAsCsv() {
		return this.state.devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision }) => ({
			serialNumber,
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
		}));
	}

	getCurrentFloor() {
		let hospitals = this.props.treeData.tree;
		let floor = findSectorById(hospitals, this.props.match.params.floorId);
		return {
			sectorName: floor ? floor.name : '',
			sectorId: floor ? floor.floorId : '',
			floorName: floor ? floor.name : '',
			hospitalId: floor ? floor.hospitalId : '',
			departmentId: floor ? floor.departmentId : '',
			floorId: floor ? floor.floorId : '',
			sectorType: floor ? floor.type : '',
		};
	}

	toggleAlert = (type, messageAlert, variantAlert) => {
		this.setState(prevState => ({
			...prevState.alert,
			[type]: true,
			messageAlert,
			variantAlert,
		}));

		setTimeout(() => {
			this.setState({
				[type]: false,
			});
		}, 3000);
	};

	loadFloor = async () => {
		const devices = await getDeviceList(DeviceListLevel.FLOOR, this.props.match.params.floorId);
		const devicesIds = devices.map(device => device.solHelloDeviceId);
		const latestAppRelease = await getLatestAppRelease(APP_CONFIG.deviceAppId);
		// const assignedPools = await getDevicesPools(devicesIds);
		// const healthSystemPools = await getHealthSystemPools();
		this.setState({
			devices,
			latestAppRelease,
			// healthSystemPools,
			// assignedPools: assignedPools.devices,
		});
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}
		const userRole = getUserRole();
		return this.state.devices.map(({ serialNumber, solHelloDeviceId, firmwareRevision, appVersion, osVersion, macAddress }) => {
			const { short_version: shortVersion, min_os: minOS } = this.state.latestAppRelease;
			const deviceOsVersion = +osVersion;
			const minOsVersion = +minOS;
			//const devicePool = this.state.assignedPools.find(devicePool => devicePool.deviceId === solHelloDeviceId);
			return {
				serialNumber,
				macAddress,
				appVersion: (
					<div className='app-version'>
						{appVersion}
						{/* <If condition={shortVersion === appVersion || deviceOsVersion < minOsVersion}>
							<Then>
								<span>{appVersion}</span>
								{deviceOsVersion < minOsVersion && <small>Newer version is available, but device OS is not compatible.</small>}
							</Then>
							<Else>
								<button
									type='button'
									data-tooltip='Click here to force update'
									data-position='top'
									onClick={() => this.toggleUpdateDeviceModal({ deviceId: solHelloDeviceId, serialNumber, appVersion })}>
									{appVersion} <i className='material-icons-outlined'>update</i>
								</button>
								<small>Newer version is available</small>
							</Else>
						</If> */}
					</div>
				),
				firmwareRevision,
				//pool: devicePool ? devicePool.poolName : 'N/A',
				actions: (
					<div className='wrapped'>
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='rebootDeviceBtn'
							id={solHelloDeviceId}
							data-tooltip='Reboot device'
							data-position='top'
							style={{ background: '#718093' }}
							onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
							refresh
						</i>
						<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
						&nbsp;
						{(userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
							<>
								&nbsp;
								<i
									className='material-icons boxed-icon'
									data-cy='reassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Reassign Device'
									style={{ backgroundColor: 'var(--blue-2)' }}
									data-position='top'
									onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
									swap_vert
								</i>
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									data-cy='unassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Unassign device'
									data-position='top'
									onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
									delete
								</i>
							</>
						)}
					</div>
				),
			};
		});
	};

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
				roomId: data ? data.roomId : null,
			},
			() => {
				if (this.state.isEditSectorModalOpen) {
					const sector = findSectorById(this.props.treeData.tree, data[data.sectorType + 'Id']);
					if (!sector) {
						this.toggleAlert('message', 'Sector is not found..', 'error');
						return;
					}
					this.props.setCurrentSectorLocation(sector.treeLocation);
					const sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						sectorType: data.sectorType,
						sectorName: data.sectorName,
						sectorData: data,
						sectorLocation: sector.treeLocation,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				}
			}
		);
	};

	initDeleteOrg = data => {
		this.setState({
			selectedOrg: data,
		});
		this.toggleDeleteOrgUnitModal();
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});

			await deleteDevice(deviceId);
		}

		this.loadFloor();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		const response = await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		if (!response.error) {
			this.moveToParentSector(this.state.selectedOrg.id);
			this.toggleDeleteOrgUnitModal();
			this.props.getTreeData();
		}
	};

	moveToParentSector = currentSectorId => {
		let selectedSector = findSectorById(this.props.treeData.tree, currentSectorId);
		let parentSector = getParentSector(this.props.treeData.tree, selectedSector);
		parentSector.isSelected = true;
		this.props.history.push(parentSector.link);
		this.props.updateBreadcrumb(parentSector.breadcrumb);
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = () => {
		this.setState({
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	updateTree = () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	toggleAssignPoolModal = () => {
		this.setState(
			{
				isAssignPoolModalOpen: !this.state.isAssignPoolModalOpen,
			},
			() => {
				this.loadFloor();
			}
		);
	};

	selectPool = (selection, index) => {
		this.setState({
			selectedPool: selection,
			selectedPoolIndex: index,
			isAssignPoolModalSubmitDisabled: false,
		});
	};

	async assignPoolToDevices() {
		this.setState({
			isAssignPoolModalLoading: true,
		});
		let devicesMap = this.state.checkedDevices;
		let selectedDevices = [];
		for (let k of devicesMap.entries()) {
			if (k[1] === true) selectedDevices.push(Number(k[0]));
		}
		const currentHealthSystem = getCurrentHealthSystemInfo();
		await assignPoolToDevices(currentHealthSystem.currentHealthSystemId, this.state.selectedPool.id, selectedDevices);
		this.setState({ isAssignPoolModalLoading: false });
		this.toggleAssignPoolModal();
	}

	handleCheckBoxChange = (e, option) => {
		if (option.type !== 'room') {
			const devices = getLevelDevices(option);
			const newCheckedDevices = this.state.checkedDevices;
			devices.forEach(device => {
				const item = device;
				const isChecked = e.target.checked;
				newCheckedDevices.set(item, isChecked);
			});
			this.setState({ checkedDevices: newCheckedDevices });
		} else {
			const item = e.target.id;
			const isChecked = e.target.checked;
			this.setState(prevState => ({ checkedDevices: prevState.checkedDevices.set(item, isChecked) }));
		}
	};

	render() {
		const floor = this.getCurrentFloor();
		const sections = mapSectionsToRender(this.props.treeData.tree, floor, this.toggleEditSectorModal, this.initDeleteOrg);
		const userRole = getUserRole();
		return (
			<div className='organization'>
				<h3>
					<span>{floor.floorName}</span>
					{userRole === UserRoles.ADMIN && (
						<>
							<Button text='Edit Floor Details' onClick={() => this.toggleEditSectorModal(floor)} />
							<Button
								text='Delete Floor'
								variant='red'
								onClick={() => this.initDeleteOrg({ level: DeviceListLevel.FLOOR, id: this.props.match.params.floorId, name: floor.floorName })}
							/>
							{/* {this.state.devices.length > 0 && (
								<Button text='Assign Pool' icon='supervisor_account' variant='yellow' onClick={() => this.toggleAssignPoolModal()} />
							)} */}
						</>
					)}
					{this.state.devices.length > 0 && (
						<CSVLink
							data-cy='exportAsCSVBtn'
							className='button white'
							data={this.exportAsCsv()}
							filename={`${floor.floorName}-${(+new Date()).toString()}.csv`}>
							Export as CSV
						</CSVLink>
					)}
				</h3>
				<DescriptionBox
					data={[
						{ title: 'Rooms in this floor', description: sections.rooms.length },
						{ title: 'Enrolled Devices', description: this.state.devices ? this.state.devices.length : 0 },
					]}
				/>
				<Tabs
					onTabIconClick={this.props.toggleModal}
					links={[{ link: 'Devices' }, { link: 'Rooms', active: true }]}
					components={[
						<Table
							headers={[
								{ title: 'Serial Number' },
								{ title: 'MAC Address' },
								{ title: 'App Version' },
								{ title: 'OS Version' },
								// { title: 'Pool' },
								{ title: '' },
							]}
							rows={this.displayDevices()}
							isEditable={false}
						/>,
						<Table headers={[{ title: 'Room Names' }, { title: '' }]} rows={sections.rooms} isEditable={false} />,
					]}
				/>

				{/* <Modal
					display={this.state.isAssignPoolModalOpen}
					isLoading={this.state.isAssignPoolModalLoading}
					position='right'
					onModalSubmit={() => this.assignPoolToDevices()}
					onModalClose={this.toggleAssignPoolModal}>
					<Form title='Assign Pool' onSubmit={event => event.preventDefault()}>
						<p>
							Select the pool and all the devices you wish to assign to this pool. Please be aware that any device that is already assigned to another pool will
							be overriden with this new pool.
						</p>
						<Select
							type='text'
							defaultValue={this.state.selectedPoolIndex}
							onSelect={this.selectPool}
							label='Pool'
							name='pool'
							items={this.state.healthSystemPools}
							description='Select a pool to associate with this device.'
							placeholder='Select Pool'
						/>
						<div className='input'>
							<p className='label'>Devices</p>
							<p>Select the devices you wish to assign to this pool</p>
							<DeviceSelection
								data={this.props.treeData.tree}
								preSelected={this.state.treeItems}
								checkedDevices={this.state.checkedDevices}
								handleCheckBoxChange={this.handleCheckBoxChange}
							/>
						</div>
					</Form>
				</Modal> */}

				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
							inputSelector='rebootReasonInput'
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>
				{this.state.isEditSectorModalOpen && (
					<EditSectorModal
						onToggleRoomAlert={this.toggleAlert}
						onEditSector={this.updateTree}
						onModalClose={this.toggleEditSectorModal}
						sectorLocation={this.state.sectorLocation}
						isEditSectorModalOpen={this.state.isEditSectorModalOpen}
						sectorData={this.state.sectorData}
						parentSectorName={this.state.parentSectorName}
						parentSectorType={this.state.parentSectorType}
						roomId={this.state.roomId}
					/>
				)}
				<Alert display={this.state.message} fixed={true} hideCloseButton={true} message={this.state.messageAlert} variant={this.state.variantAlert} />
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Floor));

import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Loader } from 'components';

class CallButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDropdownOpen: false,
		};
		this.isCallButtonClicked = false;
	}
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	setWrapperRef = node => {
		this.wrapperRef = node;
	};
	handleClickOutside = event => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			if (this.state.isDropdownOpen) {
				this.toggleDropdown(event, true);
			}
		}
	};
	toggleDropdown = (event, isButtonClick) => {
		if (this.props.children && isButtonClick) {
			this.setState({
				isDropdownOpen: !this.state.isDropdownOpen,
			});
			if (this.props.onClick) {
				this.props.onClick(this.props.name);
			}
		} else {
			// Delay toggle event for half a second before it can be clicked again
			if (!this.isCallButtonClicked) {
				this.isCallButtonClicked = true;
				setTimeout(() => {
					if (this.props.onClick) {
						this.props.onClick(this.props.name);
					}
					this.isCallButtonClicked = false;
				}, 500);
			}
		}
	};

	render() {
		return (
			<div
				className={classNames('call-button-wrapper', this.props.wrapperClassName ? `${this.props.wrapperClassName}` : '')}
				onClick={event => this.toggleDropdown(event, this.props.isDropdown)}>
				<div
					className={classNames(
						'call-button',
						this.props.className,
						this.props.isActive ? 'active ' : '',
						this.props.variant,
						this.props.isDisabled ? 'disabled' : '',
						this.props.isLoading ? 'call-button-loading' : '',
						this.state.isDropdownOpen ? 'tooltip-hidden' : ''
					)}
					name={this.props.name}
					onClick={event => this.toggleDropdown(event, this.props.isDropdown)}
					data-tooltip={this.props.tooltip}
					data-position={this.props.tooltipPosition}>
					{!this.props.isLoading && (
						<>
							{!this.props.imgIcon && (
								<i className='material-icons' style={{ color: this.props.iconColor }}>
									{this.props.icon}
								</i>
							)}
							{this.props.imgIcon && <img src={this.props.imgIcon} alt='icon' />}
						</>
					)}
					{this.props.isLoading && <Loader />}
				</div>
				{this.props.text && <span>{this.props.text}</span>}
				{this.props.showToggleBtn && (
					<label className='switch'>
						<input type='checkbox' checked={this.props.isActive} />
						<span className='slider round' />
					</label>
				)}
				{this.state.isDropdownOpen && <div className='call-button-dropdown'>{this.props.children}</div>}
			</div>
		);
	}
}

export default CallButton;

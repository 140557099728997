import React, { Component } from 'react';
import Select from 'react-select';

class MultiSelect extends Component {
	handleChange = value => {
		const { onChange, name } = this.props;

		onChange(name, value);
	};

	handleBlur = () => {
		const { onBlur, name } = this.props;

		onBlur(name, true);
	};

	render() {
		const { id, name, label, placeholder, options, value, isMulti, isDisabled, description, touched, error, isClearable, backspaceRemovesValue } = this.props;
		const InputFeedback = ({ children }) => <small>{children}</small>;
		const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;
		return (
			<div className={`input select-wrapper${error ? ' error select-has-errors' : ''}`}>
				<p className='label'>{label}</p>
				<p>{description}</p>
				<Select
					id={id}
					name={name}
					classNamePrefix='form-custom-select'
					placeholder={placeholder}
					options={options}
					value={value}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					touched={touched}
					error={error}
					isMulti={isMulti}
					isDisabled={isDisabled}
					isClearable={isClearable}
					backspaceRemovesValue={backspaceRemovesValue}
					components={{ DropdownIndicator }}
				/>
				{touched && error && <InputFeedback>{error}</InputFeedback>}
			</div>
		);
	}
}

export default MultiSelect;

import React, { useState, useEffect } from 'react';
import Layout from 'components/Common/Layout';
import { Button, Grid, Table, Tabs } from 'components';
import Header from 'components/Common/Header';
import Pagination from 'components/Common/Pagination';
import { getDeviceConfigurationHistory, getUserIdleConfigurationHistory } from 'api/userIdleConfigurations';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';
import { ConfigHistoryTypes, TeamTypes } from 'constants/enums';
import { getNtpConfigurationHistory } from 'api/ntpConfig';

const ConfigurationsHistory = props => {
	const [isLoading, setLoading] = useState(true);

	const [currentTab, setCurrentTab] = useState(0);

	const [pagination, setPagination] = useState({
		size: { value: 10, label: 10 },
		index: 0,
	});
	const [activeConfig, setActiveConfig] = useState(null);

	const [configurations, setConfigurations] = useState({
		total: 0,
		configs: [],
		title: '',
	});
	const endCallSource = [
		{ id: 0, value: 'TV Channels' },
		{ id: 1, value: 'HDMI 1' },
		{ id: 2, value: 'HDMI 2' },
		{ id: 3, value: 'HDMI 3' },
	];
	const userIdleHeaders = [
		{ title: 'Health System' },
		{ title: 'Role' },
		{ title: 'Timeout Minutes' },
		{ title: 'Created by' },
		{ title: 'Date Created' },
		{ title: '' },
	];
	const deviceConfigHeaders = [
		{ title: 'Hospital' },
		{ title: 'HDMI Port' },
		{ title: 'Default Source' },
		{ title: 'Ringtone Volume' },
		{ title: 'TV' },
		{ title: 'Created by' },
		{ title: 'Date Created' },
		{ title: '' },
	];

	const ntpConfigHeaders = [{ title: 'Name' }, { title: 'Type' }, { title: 'NTP URL' }, { title: 'Created By' }, { title: 'Date Created' }, { title: '' }];

	const getHeaders = () => {
		switch (props.match.params.type) {
			case ConfigHistoryTypes.UserIdle:
				return userIdleHeaders;
			case ConfigHistoryTypes.Devices:
				return deviceConfigHeaders;
			case ConfigHistoryTypes.Network:
				return ntpConfigHeaders;
			default:
				return [];
		}
	};

	useEffect(() => {
		const getConfigurationHistory = async () => {
			const { id, type } = props.match.params;
			const params = { id: id, pageSize: pagination.size.value, pageIndex: pagination.index };

			let configs = [];
			let total = 0;
			let title = '';

			if (type === ConfigHistoryTypes.UserIdle) {
				setCurrentTab(+ConfigHistoryTypes.UserIdle);
				const result = await getUserIdleConfigurationHistory(params);
				if (!result.error) {
					configs = result.history;
					total = result.total;
					title = `History for ${result.userIdleConfiguration.team.name}, role ${result.userIdleConfiguration.role.name}`;
				}
			} else if (type === ConfigHistoryTypes.Devices) {
				setCurrentTab(+ConfigHistoryTypes.Devices);
				const result = await getDeviceConfigurationHistory(id, {
					pageSize: params.pageSize,
					pageIndex: params.pageIndex,
					teamTypeId: TeamTypes.HOSPITAL,
				});
				if (!result.error) {
					configs = result.deviceConfigurations;
					total = result.total;
					title = `History for ${result.deviceConfiguration.team.name}`;
				}
			} else if (type === ConfigHistoryTypes.Network) {
				setCurrentTab(+ConfigHistoryTypes.Network);
				const result = await getNtpConfigurationHistory(params);
				if (!result.error) {
					setActiveConfig(result.teamNetworkNtpConfiguration);
					configs = params.pageIndex === 0 ? [result.teamNetworkNtpConfiguration, ...result.history] : result.history;
					total = result.total;
					title = `History for ${result.teamNetworkNtpConfiguration.team.name}`;
				}
			}

			setConfigurations({
				configs,
				total,
				title,
			});
			setLoading(false);
		};
		getConfigurationHistory();
	}, [pagination, props.match.params]);

	const onPaginationChange = (size, index) => {
		setLoading(true);
		setPagination({
			index,
			size,
		});
	};

	const getStatus = config => {
		if (config.isActive) {
			return 'Current';
		}
		if (config.isDeleted) {
			return 'Deleted';
		}
		return '';
	};

	const transformUserIdleConfigurations = () => {
		if (isLoading) {
			return [];
		}

		const configs = configurations.configs.map(item => {
			switch (props.match.params.type) {
				case ConfigHistoryTypes.UserIdle:
					return {
						healthSystem: item.team.name,
						role: item.role.name,
						timeoutMinutes: item.timeoutMinutes,
						createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
						dateCreated: moment(utcToLocalTime(item.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
						current: getStatus(item),
					};
				case ConfigHistoryTypes.Devices:
					return {
						hospital: item.team.name,
						hdmiPort: item.hdmiPort,
						defaultSource: endCallSource.find(x => x.id === item.defaultSource)?.value,
						ringtoneVolume: item.ringtoneVolume,
						tv: item.tv,
						createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
						dateCreated: moment(utcToLocalTime(item.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
						current: getStatus(item),
					};
				case ConfigHistoryTypes.Network:
					return {
						name: item.team.name,
						type: item.team.typeId === TeamTypes.HEALTHSYSTEM ? 'Health System' : 'Hospital',
						ntpUrl: item.ntpUrls.map(el => (
							<>
								<span>{el}</span>
								<br />
							</>
						)),
						createdBy: !item.isActive
							? `${item.userEdited.firstName} ${item.userEdited.lastName}`
							: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
						dateCreated: moment(utcToLocalTime(!item.isActive ? item.dateEdited : item.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
						current: getStatus(item),
					};

				default:
					return null;
			}
		});

		return configs;
	};

	const getConfigurationTitle = () => {
		const { type } = props.match.params;
		switch (type) {
			case ConfigHistoryTypes.UserIdle:
				return 'Idle';
			case ConfigHistoryTypes.Network:
				return 'NTP';
			case ConfigHistoryTypes.Devices:
				return 'Device';
			default:
				return '';
		}
	};

	return (
		<Layout>
			<div className='users-view'>
				<Grid stretch='100%'>
					<main className='main-view' style={getMobileFullHeightStyle()}>
						<Header />
						<Grid width='100%' vertAlign='start' padding='0 20px'>
							<h3>History Configurations</h3>
							<section>
								<Tabs
									onTabChange={index => props.history.push(`/configurations?activeTab=${index}`)}
									key={currentTab}
									links={[
										{ link: 'User idle timeout', active: currentTab === 0 },
										{ link: 'Device', active: currentTab === 1 },
										{ link: 'Network', active: currentTab === 2 },
									]}
								/>
								<Table isLoading={isLoading} headers={getHeaders()} rows={transformUserIdleConfigurations()}>
									<Grid width='100%' vertAlign='center'>
										<div className='flex configurations-history'>
											<p className='no-margin no-padding'>{getConfigurationTitle()} Configuration History</p>
											<Button text={`Back to ${getConfigurationTitle()} Configurations`} horizAlign='end' onClick={() => window.history.back()} />
										</div>
									</Grid>
								</Table>
								<Pagination
									totalCount={configurations.total}
									pageSize={pagination.size}
									pageIndex={pagination.index}
									onChange={(size, index) => onPaginationChange(size, index)}
								/>
							</section>
						</Grid>
					</main>
				</Grid>
			</div>
		</Layout>
	);
};

export default ConfigurationsHistory;

import React from 'react';
import classNames from 'classnames/bind';

const Input = props => {
	return (
		<div className={classNames('input', props.variant)} style={{ marginBottom: props.bottomSpace }}>
			<p className='label'>{props.label}</p>
			<p>{props.description}</p>
			{props.type && (
				<input
					data-cy={props.inputSelector}
					type={props.type}
					name={props.name}
					onChange={props.onChange ? props.onChange : null}
					onKeyUp={props.onKeyUp ? props.onKeyUp : null}
					placeholder={props.placeholder}
					readOnly={props.readonly}
					value={props.value}
					minLength={props.validationOptions.minLength}
					maxLength={props.validationOptions.maxLength}
					required={props.validationOptions.required}
					pattern={props.validationOptions.pattern}
					disabled={props.disabled}
					autoComplete={props.autoComplete}
					accept={props.accept}
					className={props.className}
					style={props.icon && { paddingRight: '40px' }}
					list={props.list}
					onKeyDown={props.onKeyDown}
					max={props.max}
				/>
			)}
			{props.icon && <i className='material-icons'>{props.icon}</i>}
			<small>{props.error}</small>
		</div>
	);
};

export default Input;

import React, { Component } from 'react';

import Pagination from 'components/Common/Pagination';
import { Grid, Input, Table, Tabs, Button, Badge, Modal } from 'components';

import { getMembers, deleteMember, getHealthSystemMembers, sendRecoveryEmail } from 'api/users';
import { UserTypes, InviteTypes, UserRoles } from 'constants/enums';
import { decodeHtml, getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { getUserProfile, getUserRole, getUserId } from 'infrastructure/auth';
import moment from 'moment';

import ManageHealthSystems from 'components/UserManagement/Lists/EditUser/ManageHealthSystems';
import UserInformation from 'components/UserManagement/Lists/EditUser/UserInformation';
import { SocketContext } from 'io-client/SocketContext';
import SocketEvents from 'constants/socket-events';
import SuperUserHealthSystem from 'components/UserManagement/Lists/EditUser/SuperUserHealthSystem';

class UsersList extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			pageSize: { value: 10, label: '10' },
			pageIndex: 0,
			totalCount: 0,
			members: [],
			loading: true,
			currentMemberObj: null,
			currentMemberId: null,
			searchValue: '',
			isDeleteModalOpen: false,
			deletingMember: false,
			isEditMemberModalOpen: false,
			editingMember: false,
			manageUsersSelectedTab: 0,
			healthSystemAddition: {
				loading: false,
				loaded: false,
				error: false,
			},
			updateNurse: {
				loading: false,
				loaded: false,
				error: false,
			},
			showAccountRecoveryModal: false,
			accountRecoveryMailSent: false,
			memberId: null,
			accountRecoveryMailFailed: false,
		};

		this.submitMyForm = null;
		this.socket = context;
	}

	async componentDidMount() {
		let response = {};
		let healthSystem = getCurrentHealthSystemInfo();

		if (getUserRole() === UserRoles.ADMIN) {
			response = await getMembers();
		} else {
			response = await getHealthSystemMembers(healthSystem.currentHealthSystemId);
		}

		this.setState({
			loading: false,
			members: response.members,
			totalCount: response.totalCount,
			currentHealthSystemId: healthSystem.currentHealthSystemId,
		});

		this.socket.on(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, this.handleUpdatedMemberPresence);
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, this.handleUpdatedMemberPresence);
	}

	handleSubmitMyForm = e => {
		if (this.submitMyForm) {
			this.submitMyForm(e);
		}
	};

	bindSubmitForm = submitForm => {
		this.submitMyForm = submitForm;
	};

	handleDeleteMember = async () => {
		this.setState({ deletingMember: true });
		deleteMember(this.state.currentMemberId).then(() => {
			this.setState({
				members: this.state.members.filter(member => {
					return member.memberId !== this.state.currentMemberId;
				}),
				currentMemberId: null,
				deletingMember: false,
				isDeleteModalOpen: false,
			});
		});
	};

	async onChange(pageSize, pageIndex) {
		this.setState({ loading: true });

		let members = {};
		if (getUserRole() === UserRoles.ADMIN) {
			members = await getMembers(pageSize.value, pageIndex, this.state.searchValue);
		} else {
			members = await getHealthSystemMembers(this.state.currentHealthSystemId, pageSize.value, pageIndex, this.state.searchValue);
		}

		this.setState({
			loading: false,
			members: members.members,
			pageSize: pageSize,
			pageIndex: pageIndex,
			totalCount: members.totalCount,
		});
	}

	getLocations(locations) {
		if (locations.length === 0) return 'N/A';

		return locations
			.map(location => {
				return location.name;
			})
			.join(',');
	}

	getRoles(roles) {
		if (!roles) return '';
		if (roles.length === 1) return this.getRole(roles[0].id);
		return roles.map(role => this.getRole(role.id)).join(', ');
	}

	getRole(id) {
		switch (id) {
			case UserTypes.OWNER: {
				return 'Owner';
			}
			case UserTypes.ADMIN: {
				return 'Admin';
			}
			case UserTypes.VIRTUALCAREPROVIDER: {
				return 'Virtual Care Provider';
			}
			case UserTypes.VIRTUALSITTER: {
				return 'Virtual Sitter';
			}
			case UserTypes.SUPERUSER: {
				return 'Super User';
			}
			case UserTypes.PATIENT: {
				return 'Patient';
			}
			default:
				return '';
		}
	}

	shouldAllowHealthSystemChange = roles => roles?.some(role => ![UserTypes.ADMIN, UserTypes.PATIENT].includes(role.id));

	onRecoverPassword = async (email, memberId, tokenId) => {
		const response = await sendRecoveryEmail(email, memberId, tokenId);
		this.setState(
			{
				accountRecoveryMailSent: !!response.Result,
				memberId,
				email,
				tokenId,
				accountRecoveryMailFailed: false,
			},
			() => {
				this.toggleAccountRecoveryModal();
			}
		);
	};

	getInviter = user => {
		if (user.inviteTypeId === InviteTypes.ADUSER.type) return 'AD User';
		return user.inviter
			? this.getProfileBox(decodeHtml(`${user.inviter.firstName} ${user.inviter.lastName}`), getUserProfile().userId === user.inviter.userId)
			: 'N/A';
	};

	displayUsers() {
		if (this.state.members.length === 0) return [];
		return this.state.members.map(user => {
			let presence;
			let presenceColor;
			if (user.userInfo.presenceStatusTypeId === 1) {
				presence = 'Available';
				presenceColor = 'green';
			} else {
				presence = 'Unavailable';
				presenceColor = 'red';
			}
			let current = getUserProfile().userId === user.userInfo.userId;
			return {
				profileBox: this.getProfileBox(decodeHtml(`${user.userInfo.firstName} ${user.userInfo.lastName}`), current),
				email: user.userInfo.email,
				invitedBy: this.getInviter(user),
				dateInvited: user.dateInvited ? moment(utcToLocalTime(user.dateInvited)).format('MM/DD/YYYY-hh:mm A') : 'N/A',
				dateAdded: user.dateAdded ? moment(utcToLocalTime(user.dateAdded)).format('MM/DD/YYYY-hh:mm A') : 'N/A',
				healthsystem: user.healthSystems.length > 0 ? this.displayHealthSystems(user.healthSystems) : 'N/A',
				// status: <Badge text={presence} variant={presenceColor} />,
				role: this.getRoles(user.roles),
				edit:
					!current && getUserRole() === UserRoles.ADMIN && this.getEditButtons(user.memberId, user.roles, user.pendingRecoveryInviteToken, user.userInfo.email),
			};
		});
	}

	displayHealthSystems(healthsystems) {
		return healthsystems.map(hs => hs.name).join(', ');
	}

	getProfileBox(name, current) {
		return (
			<div className='div-container'>
				<span className={current && 'bold'}>
					{name} {current && '(You)'}
				</span>
			</div>
		);
	}

	getEditButtons(memberId, roles, pendingRecoveryInviteToken, email) {
		const userId = getUserId();
		return (
			<div className='wrapped'>
				{pendingRecoveryInviteToken && (
					<i
						className='material-icons-outlined boxed-icon'
						onClick={() => this.onRecoverPassword(email, userId, pendingRecoveryInviteToken)}
						data-tooltip='Send reset password link'
						data-position='top'>
						email
					</i>
				)}

				<i className='material-icons-outlined boxed-icon' data-cy='editMemberBtn' onClick={() => this.openEditMemberModal(memberId)}>
					edit
				</i>

				<i className='material-icons-outlined boxed-icon' data-cy='removeMemberBtn' onClick={() => this.openDeleteMemberModal(memberId)}>
					delete
				</i>
			</div>
		);
	}

	openEditMemberModal(memberId) {
		let member = this.state.members.find(m => m.memberId === memberId);
		this.setState({
			isEditMemberModalOpen: true,
			currentMemberId: memberId,
			currentMemberObj: member,
			updateNurse: {
				loading: false,
				loaded: false,
				error: false,
			},
			healthSystemAddition: {
				loading: false,
				loaded: false,
				error: false,
			},
		});
	}

	openDeleteMemberModal(memberId) {
		this.setState({ isDeleteModalOpen: true, currentMemberId: memberId });
	}

	handleSearchChanges = async event => {
		let searchValue = event.target.value;
		this.setState({ loading: true, pageIndex: 0, searchValue: searchValue });

		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(
			async function() {
				let response = {};
				if (getUserRole() === UserRoles.ADMIN) {
					response = await getMembers(this.state.pageSize.value, 0, searchValue);
				} else {
					response = await getHealthSystemMembers(this.state.currentHealthSystemId, this.state.pageSize.value, 0, searchValue);
				}

				this.setState({
					loading: false,
					members: response.members,
					totalCount: response.totalCount,
				});
			}.bind(this),
			500
		);
	};

	onLoading = tab => {
		this.setState({
			[tab]: {
				...this.state[tab],
				loading: true,
			},
		});
	};

	onSuccess = async tab => {
		this.setState({
			loading: true,
		});
		let members = {};
		if (getUserRole() === UserRoles.ADMIN) {
			members = await getMembers(this.state.pageSize.value, this.state.pageIndex, this.state.searchValue);
		} else {
			members = await getHealthSystemMembers(this.state.currentHealthSystemId, this.state.pageSize.value, this.state.pageIndex, this.state.searchValue);
		}
		this.setState({
			[tab]: {
				...this.state[tab],
				loading: false,
				loaded: true,
				error: false,
			},
			loading: false,
			members: members.members,
			totalCount: members.totalCount,
			currentMemberObj: members.members.find(m => m.memberId === this.state.currentMemberObj.memberId),
		});
	};

	onError = tab => {
		this.setState({
			[tab]: {
				...this.state[tab],
				loading: false,
				error: true,
			},
		});
	};

	onCloseAlert = tab => {
		this.setState({
			[tab]: {
				...this.state[tab],
				loading: false,
				loaded: false,
				error: false,
			},
		});
	};

	userUpdated = (userId, firstName, lastName, role = {}) => {
		let userIndex = this.state.members.findIndex(member => member.userInfo.userId === userId);

		let items = [...this.state.members];
		const roles = [role];

		let item = {
			...items[userIndex],
			userInfo: {
				...items[userIndex].userInfo,
				firstName: firstName,
				lastName: lastName,
			},
			roles,
		};

		items[userIndex] = item;
		this.setState({
			members: items,
			currentMemberObj: {
				...this.state.currentMemberObj,
				userInfo: {
					...this.state.currentMemberObj.userInfo,
					firstName: firstName,
					lastName: lastName,
				},
			},
		});
	};

	handleUpdatedMemberPresence = ({ objectId, presenceStatusTypeId }) => {
		const { members } = this.state;

		const member = members.find(({ userInfo }) => userInfo.userId === +objectId);
		if (!member) {
			return;
		}

		member.userInfo.presenceStatusTypeId = presenceStatusTypeId;

		this.setState({ members });
	};

	toggleAccountRecoveryModal = () => {
		this.setState({
			showAccountRecoveryModal: !this.state.showAccountRecoveryModal,
		});
	};

	sendNewLink = async () => {
		try {
			await sendRecoveryEmail(this.state.email, this.state.memberId, this.state.tokenId);
			this.toggleAccountRecoveryModal();
			this.setState({
				accountRecoveryMailFailed: false,
			});
		} catch (error) {
			this.setState({
				accountRecoveryMailFailed: true,
			});
		}
	};

	render() {
		const usersTableHeaders = [
			{ title: 'User Name' },
			{ title: 'Email' },
			{ title: 'Invited By' },
			{ title: 'Date Invited' },
			{ title: 'Date Added' },
			{ title: 'Health Systems' },
			// { title: 'Status' },
			{ title: 'Role' },
			{ edit: '' },
		];
		const userRole = getUserRole();
		return (
			<div className='users-list-wrapper'>
				<Table isLoading={this.state.loading} headers={usersTableHeaders} rows={this.state.loading ? [] : this.displayUsers()} isEditable={false}>
					<Grid columns={userRole === UserRoles.ADMIN ? '250px 10fr 1fr 1fr 1fr' : '250px 10fr 1fr'} vertAlign='center'>
						<Input
							validationOptions={{}}
							type='text'
							placeholder='Search Users'
							bottomSpace='0'
							variant='filled'
							name='searchUsers'
							onChange={this.handleSearchChanges}
							icon='search'
						/>
						<Button
							text='Add Virtual Sitter'
							variant='white'
							horizAlign='end'
							marginRight='10px'
							onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.VIRTUALSITTER.type)}
						/>
						<Button
							text='Add Virtual Care Provider'
							variant='white'
							horizAlign='end'
							marginRight='10px'
							onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.VIRTUALCAREPROVIDER.type)}
						/>
						{userRole === UserRoles.ADMIN && (
							<Button text='Add Super User' horizAlign='end' onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.SUPERUSER.type)} />
						)}
						{userRole === UserRoles.ADMIN && (
							<Button text='Add Admin' horizAlign='end' onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.ADMIN.type)} />
						)}
					</Grid>
				</Table>
				<Pagination
					totalCount={this.state.totalCount}
					pageSize={this.state.pageSize}
					pageIndex={this.state.pageIndex}
					onChange={(pageSize, pageIndex) => this.onChange(pageSize, pageIndex)}
				/>
				<Modal
					display={this.state.isDeleteModalOpen}
					position='center'
					onModalSubmit={() => this.handleDeleteMember()}
					onModalClose={() => this.setState({ isDeleteModalOpen: false })}>
					<form>
						<h3>Warning</h3>
						<p>Are you sure you want to delete this member? This member will be removed from all health systems and devices associated with them.</p>
					</form>
				</Modal>
				<Modal
					submitButtonText={this.state.accountRecoveryMailSent && !this.state.accountRecoveryMailFailed ? 'Send new link' : ''}
					closeButtonText='Close'
					display={this.state.showAccountRecoveryModal}
					position='center'
					onModalSubmit={() => this.sendNewLink()}
					onModalClose={() => this.toggleAccountRecoveryModal()}>
					<form>
						{this.state.accountRecoveryMailFailed && (
							<>
								<h3>Error</h3>
								<p>Sending the recovery link failed.</p>
							</>
						)}
						{this.state.accountRecoveryMailSent && !this.state.accountRecoveryMailFailed && (
							<>
								<h3>Info</h3>
								<p>A recovery link was already sent. Would you like to send another?</p>
							</>
						)}
						{!this.state.accountRecoveryMailSent && !this.state.accountRecoveryMailFailed && (
							<>
								<h3>Account recovery</h3>
								<p>Reset password link sent successfully.</p>
							</>
						)}
					</form>
				</Modal>
				<Modal
					modalSelector='editMemberModal'
					display={this.state.isEditMemberModalOpen}
					position='right'
					onModalClose={() => this.setState({ isEditMemberModalOpen: false, currentMemberId: null, currentMemberObj: null })}
					onModalSubmit={this.handleSubmitMyForm}
					isLoading={this.state.healthSystemAddition.loading || this.state.updateNurse.loading}>
					{this.state.currentMemberObj && (
						<>
							{this.shouldAllowHealthSystemChange(this.state.currentMemberObj.roles) && (
								<Tabs
									links={[{ link: 'User Info', active: true }, { link: 'Health Systems' }]}
									onTabClick={index => this.setState({ manageUsersSelectedTab: index })}
									components={[
										<UserInformation
											healthSystems={this.props.healthSystems}
											bindSubmitForm={this.bindSubmitForm}
											currentMemberObj={this.state.currentMemberObj}
											loaded={this.state.updateNurse.loaded}
											error={this.state.updateNurse.error}
											onLoading={this.onLoading}
											onSuccess={this.onSuccess}
											onCloseAlert={this.onCloseAlert}
											userUpdated={this.userUpdated}
											onError={this.onError}
										/>,
										<SuperUserHealthSystem
											onSuccess={this.onSuccess}
											onCloseAlert={this.onCloseAlert}
											onLoading={this.onLoading}
											loaded={this.state.healthSystemAddition.loaded}
											error={this.state.healthSystemAddition.error}
											member={this.state.currentMemberObj}
											healthSystems={this.props.healthSystems}
											bindSubmitForm={this.bindSubmitForm}
											onError={this.onError}
										/>,
									]}
								/>
							)}
							{!this.shouldAllowHealthSystemChange(this.state.currentMemberObj.roles) && (
								<UserInformation
									healthSystems={this.props.healthSystems}
									bindSubmitForm={this.bindSubmitForm}
									currentMemberObj={this.state.currentMemberObj}
									loaded={this.state.updateNurse.loaded}
									error={this.state.updateNurse.error}
									onLoading={this.onLoading}
									onSuccess={this.onSuccess}
									onCloseAlert={this.onCloseAlert}
									userUpdated={this.userUpdated}
									onError={this.onError}
								/>
							)}
						</>
					)}
				</Modal>
			</div>
		);
	}
}

UsersList.contextType = SocketContext;
export default UsersList;

import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export const getRoles = async (companyId, userId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/organizations/${companyId}/members/${userId}/roles`);
		return data.roles;
	} catch (error) {
		return { error };
	}
};

export function getAllRoles() {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/roles`)
			.then(response => {
				res(response.data.roles);
			})
			.catch(e => rej(e));
	});
}

export const getUserRoles = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/roles`);
		return data.result;
	} catch (error) {
		return { error };
	}
};

import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import queryString from 'query-string';

import Grid from 'components/Grid';
import Input from 'components/Common/FormElements/Input';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { sendOrganizationPasswordRecoveryEmail } from 'api/users';

class Recover extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loaded: false,
			error: false,
		};
	}

	submitForm(values) {
		this.setState({ loading: true });
		sendOrganizationPasswordRecoveryEmail(values.email)
			.then(response => {
				this.setState({
					loading: false,
					loaded: true,
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: true,
				});
			});
	}

	render() {
		return (
			<Grid className='login' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' rows='auto'>
				<Grid width='500px' className='login__wrapper'>
					<div style={{ width: '250px' }}>
						<img src='https://static.solaborate.com/americanwell/amwell-full-logo-white.svg' alt='amwell-logo' />
					</div>
					<div className='login-form-wrapper recover-password'>
						{this.state.loading && !this.state.loaded && (
							<Grid width='100%' stretch='100%' horizAlign='center' vertAlign='center' rows='auto'>
								<div style={{ textAlign: 'center' }}>
									<Loader />
								</div>
							</Grid>
						)}
						{this.state.loaded && !this.state.loading && (
							<div className='warning-message'>
								<span className='material-icons'>error_outline</span>
								<div>
									<span>
										<b>A message with link to reset your password was sent to your email account. Please go to your email account to reset your password.</b>
									</span>
									<span style={{ paddingTop: '15px', fontSize: '14px', display: 'block' }}>
										If you don't receive instructions within a minute or two, check your spam and junk filters, or try
										<span className='link' onClick={() => this.setState({ loaded: false })}>
											{' '}
											resending your request
										</span>
									</span>
								</div>
							</div>
						)}
						{!this.state.loaded && !this.state.loading && (
							<div>
								<div className='warning-message'>
									<span className='material-icons'>error_outline</span>
									<span>Please enter the email address you used to create your AmWell account, and we will send you a link to reset your password.</span>
								</div>
								<div style={{ paddingTop: '25px' }}>
									<Formik
										initialValues={{ email: '' }}
										onSubmit={values => this.submitForm(values)}
										validationSchema={Yup.object().shape({
											email: Yup.string()
												.email()
												.required('Email is required'),
										})}
										render={() => (
											<Form>
												<Field name='email' type='email' label='Email' placeholder='Email' variant='filled' component={Input} />
												<br />
												<Button type='submit' text='Continue' display='block' />
											</Form>
										)}
									/>
								</div>
							</div>
						)}
					</div>
					<br />
					<br />
				</Grid>
			</Grid>
		);
	}
}

export default Recover;

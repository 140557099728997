import { validateMaxLength } from 'infrastructure/helpers/commonHelpers';
import { Input } from 'components';
import React from 'react';

const ContactInformation = props => (
	<div className='margin-top-xl'>
		<Input
			type='email'
			label='Email Address'
			placeholder='Email Address'
			value={props.values.emailAddress}
			onChange={props.onChange}
			name='emailAddress'
			validationOptions={{ required: true, minLength: 2, maxLength: 50 }}
			error={props.errors.emailAddress}
			readonly={true}
		/>
		<Input
			type='number'
			label='Phone Number'
			placeholder='Phone Number'
			value={props.values.phoneNumber}
			onChange={props.onChange}
			name='phoneNumber'
			validationOptions={{ required: true, minLength: 2, maxLength: 10 }}
			onKeyDown={event => validateMaxLength(event, 10)}
			className='number-input-wo-arrows'
			error={props.errors.phoneNumber}
		/>
	</div>
);

export default ContactInformation;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Toggle, Tilt } from 'components';
import Modal from 'components/Modal';
import Form from 'components/Form';
import {
	CameraType,
	DeviceCommands,
	CameraEventTypes,
	CameraTiltDirection,
	CameraTiltAction,
	SerialTVCommands,
	CameraZoomLevels,
	CameraCommands,
	MediaControlCommands,
	CallTypes,
} from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import classNames from 'classnames/bind';
import CameraBookmarks from './CameraBookmarks';

class CameraControls extends Component {
	zoomLevelToInputRangeDifferenceNumber = 20;

	constructor(props, socket) {
		super(props, socket);
		this.socket = socket;
		this.state = {
			isRebootCameraModalOpen: false,
			huddleCamTransitionState: false,
			isCamSwitchingInProgress: false,
			bookmarkList: this.props.participant.bookmarkList,
			selectedBookmark: this.props.participant.bookmarkList.find(item => item.isActive) || null,
			isBookmarksOpen: false,
		};
	}

	componentDidMount() {
		this.socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, this.onInitialState);
		this.socket.on(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, this.onCameraResponse);
		this.socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, this.handleBookmarksResponse);
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, this.onInitialState);
		this.socket.off(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, this.onCameraResponse);
		this.socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, this.handleBookmarksResponse);
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = event => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.participant.callType === CallTypes.MONITORING) {
			this.setState({
				isBookmarksOpen: false,
			});
		}
	};

	handleBookmarksResponse = data => {
		if (data.command === MediaControlCommands.BOOKMARK_LIST) {
			this.setState({ bookmarkList: data.data, selectedBookmark: data.data.find(item => item.isActive) || null }, () => {
				this.props.participant.bookmarkList = data.data;
			});
		}
	};

	onInitialState = data => {
		if (!data || data.helloDeviceId !== this.props.participant.objectId) {
			return;
		}

		const { cameraType, zoomLevel, isHuddleCamConnected, isCameraPrivacyOn, isMicPrivacyOn, tvHdmiPort, bookmarkList } = data;
		let hdmiStatus = null;
		if (tvHdmiPort) {
			hdmiStatus = { isSuccessful: true, tvState: { isVolume: false, tvStatus: SerialTVCommands.HDMI[`SWITCH_HDMI${tvHdmiPort}`] } };
		}
		Object.assign(this.props.participant, {
			cameraType,
			cameraZoomLevel: zoomLevel,
			isHuddleCamConnected,
			isCameraPrivacyOn,
			isMicPrivacyOn,
			hdmiStatus,
			bookmarkList,
		});

		const selectedBookmark = bookmarkList.find(item => item.isActive) || null;
		this.setState({ bookmarkList, selectedBookmark });
		this.props.participant.emit(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED);
	};

	onCameraResponse = ({ event, message, isSuccessful, objectId }) => {
		if (objectId !== this.props.participant.objectId) {
			return;
		}

		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					this.props.participant.cameraType = message;
					this.props.participant.cameraZoomLevel = 0;
					this.props.participant.zoomRange = 0;
				}
				break;
			case CameraEventTypes.ZOOM:
				this.props.participant.cameraZoomLevel = +message;
				if (this.props.participant.zoomRange * this.zoomLevelToInputRangeDifferenceNumber !== parseInt(this.props.participant.cameraZoomLevel, 10)) {
					const maxLevel = this.props.cameraType === CameraType.HELLO ? CameraZoomLevels.HELLO_MAX : CameraZoomLevels.HUDDLE_MAX;
					const zoomValue = parseInt(this.props.participant.cameraZoomLevel, 10) / this.zoomLevelToInputRangeDifferenceNumber;
					if (zoomValue <= maxLevel) {
						this.props.participant.zoomRange = zoomValue;
					}
				}
				break;
			case CameraEventTypes.RECENTER:
				this.props.participant.disabledTiltDirections = {};
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = this.props.participant;
				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.props.participant.panTiltCamera(message, CameraTiltAction.STOP);
				}
				break;
			}
			case CameraEventTypes.HUDDLE_CONNECTED_STATE:
				if (this.props.participant.isHuddleCamConnected && !isSuccessful) {
					this.setState({
						huddleCamTransitionState: true,
					});
					setTimeout(() => {
						this.setState({
							huddleCamTransitionState: false,
						});
					}, 3000);
				}
				this.props.participant.isHuddleCamConnected = isSuccessful;
				break;
			case CameraEventTypes.HELLO_CAMERA_PRIVACY_STATE:
				this.props.participant.isCameraPrivacyOn = isSuccessful;
				this.props.participant.emit(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED);
				break;
			case CameraEventTypes.HELLO_MIC_PRIVACY_STATE:
				this.props.participant.isMicPrivacyOn = isSuccessful;
				this.props.participant.emit(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED);
				break;
			default:
		}
		this.setState({});
	};

	onUpdateZoomLevel = async e => {
		const maxLevel = this.props.cameraType === CameraType.HELLO ? CameraZoomLevels.HELLO_MAX : CameraZoomLevels.HUDDLE_MAX;
		if (e.target.value > maxLevel) {
			return;
		}
		const result = await this.props.participant.zoomCamera(parseInt(e.target.value, 10) * this.zoomLevelToInputRangeDifferenceNumber);
		if (result.deviceControlsLocked) {
			const zoomValue = parseInt(this.props.participant.cameraZoomLevel, 10) / this.zoomLevelToInputRangeDifferenceNumber;
			if (zoomValue <= maxLevel) {
				this.props.participant.zoomRange = zoomValue;
			}
		}
	};

	onRebootHuddleCamClick = async () => {
		if (!this.state.isRebootCameraModalOpen) {
			return;
		}

		this.props.participant.deviceCommand(DeviceCommands.REBOOT_HUDDLE_CAM);

		this.setState({ isRebootCameraModalOpen: false });
	};

	onResetCameraButtonClick = () => {
		if (this.props.participant.cameraType === CameraType.HUDDLE) {
			this.setState({ isRebootCameraModalOpen: true });
			return;
		}
		this.props.participant.sendCameraCommand(CameraCommands.RECENTER);
	};

	onCamSwitch = () => {
		this.props.participant.switchCamera();
		this.setState({
			isCamSwitchingInProgress: true,
		});
		setTimeout(() => {
			this.setState({
				isCamSwitchingInProgress: false,
			});
		}, 5000);
	};

	toggleBookmarkPopUp = bookmark => {
		this.setState(prevState => ({
			bookmarkList: prevState.bookmarkList.map(item => ({ ...item, isDropDownOpen: item.id === bookmark?.id && !item.isDropDownOpen })),
		}));
	};

	setWrapperRef = node => {
		this.wrapperRef = node;
	};

	render() {
		const { state, props } = this;
		const { isCloseBtnAvailable, onPtzClose } = props;
		const {
			cameraType,
			showPTZ,
			disabledTiltDirections,
			isHuddleCamConnected,
			panTiltCamera,
			// TODO shouldShowSwitchToHelloCamError
			isCameraPrivacyOn,
			sendCameraCommand,
			sendMediaControlsEvent,
			callType,
		} = props.participant;
		const shouldDisableTilt = props.isDisabled || (isCameraPrivacyOn && cameraType === CameraType.HELLO) || state.isCamSwitchingInProgress;
		return (
			<>
				{showPTZ && (
					<>
						<div
							className={classNames('patient-view-control', !isHuddleCamConnected && !state.huddleCamTransitionState ? 'no-huddle-wrapper' : '')}
							ref={this.setWrapperRef}>
							{isCloseBtnAvailable && (
								<i className='material-icons close-ptz' onClick={onPtzClose}>
									close
								</i>
							)}
							<Tilt
								tiltHandler={panTiltCamera}
								cameraType={cameraType}
								updateZoomLevel={this.onUpdateZoomLevel}
								disabledDirections={disabledTiltDirections}
								disabled={shouldDisableTilt}
								sendCameraCommand={sendCameraCommand}
								sendCameraEvent={sendMediaControlsEvent}
								bookmarkList={this.state.bookmarkList}
								onResetCameraButtonClick={this.onResetCameraButtonClick}
								zoomRange={this.props.participant.zoomRange}
								callType={callType}
								setZoomRange={val => {
									this.props.participant.zoomRange = val;
									this.setState({});
								}}
								isBookmarksOpen={this.state.isBookmarksOpen}
								setIsBookmarksOpen={val =>
									this.setState({
										isBookmarksOpen: val,
									})
								}
								selectedBookmark={this.state.selectedBookmark}
							/>
							{this.props.participant.cameraType === CameraType.HUDDLE && callType === CallTypes.MONITORING && (
								<CameraBookmarks
									callType={callType}
									sendCameraEvents={sendMediaControlsEvent}
									bookmarks={this.state.bookmarkList}
									toggleBookmarkPopUp={this.toggleBookmarkPopUp}
									isBookmarksOpen={this.state.isBookmarksOpen}
									setIsBookmarksOpen={val =>
										this.setState({
											isBookmarksOpen: val,
										})
									}
									selectedBookmark={this.state.selectedBookmark}
								/>
							)}
							{(isHuddleCamConnected || state.huddleCamTransitionState) && (
								<Toggle
									className={classNames('toggle-feed', !isHuddleCamConnected && !state.huddleCamTransitionState ? 'only-bridge-connected' : '')}
									toggleOnText='5x Cam'
									toggleOffText='20x Cam'
									toggledOn={cameraType === CameraType.HELLO}
									onToggle={this.onCamSwitch}
									isLoading={state.isCamSwitchingInProgress}
									disabled={!isHuddleCamConnected || props.isDisabled || state.huddleCamIdlestate || state.isCamSwitchingInProgress}
								/>
							)}
							{cameraType === CameraType.HUDDLE && (
								<Modal
									display={state.isRebootCameraModalOpen}
									position='center'
									submitButtonText='Reboot'
									onModalSubmit={this.onRebootHuddleCamClick}
									onModalClose={() => this.setState({ isRebootCameraModalOpen: false })}>
									<Form title='Reboot 20x Camera' onSubmit={event => event.preventDefault()}>
										<p>Are you sure you want to reboot 20x Camera?</p>
									</Form>
								</Modal>
							)}
						</div>
					</>
				)}
			</>
		);
	}
}

CameraControls.defaultProps = {
	isDisabled: false,
};

CameraControls.propTypes = {
	participant: PropTypes.shape({}).isRequired,
	isDisabled: PropTypes.bool,
};

CameraControls.contextType = SocketContext;
export default CameraControls;

import React from 'react';
import classNames from 'classnames';

import { amwellIconLink } from 'constants/global-variables';

const links = [
	{
		id: 0,
		title: 'Invite',
		img: `${amwellIconLink}invite-blue.svg`,
		activeImg: `${amwellIconLink}invite.svg`,
	},
];

const CallLeftMenu = props => (
	<aside className='call-left-menu'>
		<ul className='left-navigation'>
			{links.map(item => (
				<li key={item.id}>
					<div
						className={classNames('nav-link-wrapper', 'cursor-pointer', props.isActive ? ' active' : '', props.disableButtons ? 'disabled' : '')}
						onClick={!props.disableButtons ? props.toggleView : null}>
						{!item.img && <i className='material-icons-outlined'>{item.icon}</i>}
						{props.isActive && item.activeImg && <img src={item.activeImg} alt='icon' />}
						{!props.isActive && item.img && <img src={item.img} alt='logo' />}
					</div>
					<span>{item.title}</span>
				</li>
			))}
		</ul>
	</aside>
);

export default CallLeftMenu;

import React from 'react';
import classNames from 'classnames/bind';

const HelloFeatureBlock = props => (
	<div className={classNames('hello-feature-block', props.className ? props.className : '')} onClick={props.onClick}>
		<i className='material-icons-outlined'>{props.icon}</i>
		<p>{props.title}</p>
		<span className='info' data-tooltip={props.tooltip} data-position='top'>
			<i className='material-icons-outlined'>info</i>
		</span>
	</div>
);

export default HelloFeatureBlock;

import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { AiAlertTypes, ParticipantState, PatientWarnings } from 'constants/enums';
import { dangerSound, fallAttentionSound, personPositionSound, railSound } from 'constants/alert-sounds';
import moment from 'moment';
import { SocketContext } from 'io-client/SocketContext';
import SocketEvents from 'constants/socket-events';
import AlertsModal from 'calls/components/monitoring/AlertsModal';

const AiControls = props => {
	let playingAlert = useRef(null);
	const reRender = useRef(props.reRender);
	const stopSoundBasedOnWarning = useRef(props.stopPlayBasedOnWarning);
	const stopVoiceOver = useRef(props.stopVoiceOver);
	const socket = useContext(SocketContext);

	const isAnyFeedMicActive = useCallback(
		() => [...props.conference.participants.values()].some(feed => feed.localTrackController.tracks.get(TrackType.AUDIO)),
		[props.conference.participants]
	);

	const tryToPlayAlertSound = async () => {
		try {
			if (playingAlert.current) {
				playingAlert.current.loop = true;
				await playingAlert.current.play();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Autoplay failed:', error);
		}
	};

	const getAlertInformation = type => {
		let text = null;
		let imgIcon = null;
		switch (type) {
			case PatientWarnings.FALL_DETECTED: {
				playingAlert.current = fallAttentionSound;
				text = 'Please check the patient.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_SITTING: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected inactive in sitting position.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_STANDING: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected inactive in standing position.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_LAYING_FRONT: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected inactive in laying front position.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_LAYING_SIDE: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected inactive in laying side position.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_FACE_DOWN: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected inactive with face down position.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_INACTIVE: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected with face down position.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_GETTING_OUT_OF_BED: {
				playingAlert.current = personPositionSound;
				text = 'Patient is getting out of bed';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.RAIL_DOWN: {
				playingAlert.current = railSound;
				text = 'Bed Rail is down. Please check the patient.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_STANDING_WALKING: {
				playingAlert.current = personPositionSound;
				text = 'Patient was detected walking. Please check the patient!';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.BABY_CRYING: {
				playingAlert.current = personPositionSound;
				text = 'Baby crying detected';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			default: {
				playingAlert.current = dangerSound;
				text = 'Please check the patient.';
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
		}
		return { text, imgIcon };
	};

	const onAiAlert = useCallback(
		data => {
			const playVoiceOver = async ({ type, deviceId, alertId, isAiAlert }) => {
				const selectedFeedIndex = [...props.conference.participants?.values()].findIndex(item => item.objectId === deviceId);
				if (selectedFeedIndex === -1 || [...props.conference.participants?.values()][selectedFeedIndex].state !== ParticipantState.CONNECTED.type) {
					return;
				}
				const time = moment
					.utc()
					.local()
					.format('hh:mm:ss A');
				if (playingAlert.current) {
					playingAlert.current.currentTime = 0;
					playingAlert.current.pause();
				}
				const { text, imgIcon } = getAlertInformation(type);
				if (!isAnyFeedMicActive()) {
					tryToPlayAlertSound();
				}

				const [selectedFeed] = [...props.conference.participants.values()].splice(selectedFeedIndex, 1);
				selectedFeed.warning = { value: true, type };
				selectedFeed.warning.text = text;
				selectedFeed.warning.alertId = alertId;
				selectedFeed.warning.isAiAlert = isAiAlert;
				selectedFeed.events.unshift({ text, time, type, imgIcon });
				[...props.conference.participants.values()].splice(selectedFeedIndex, 0, selectedFeed);
			};

			const mapEventsToFeed = values => {
				const selectedFeedIndex = [...props.conference.participants.values()].findIndex(item => item.objectId === values.helloId);
				if (![...props.conference.participants.values()][selectedFeedIndex]) {
					return;
				}
				const [selectedFeed] = [...props.conference.participants.values()].splice(selectedFeedIndex, 1);
				selectedFeed.events.unshift({
					type: values.type,
					time: moment
						.utc()
						.local()
						.format('hh:mm:ss A'),
					text: values.type,
					imgIcon: 'otherData.svg',
					alertId: values.alertId,
				});
				[...props.conference.participants.values()].splice(selectedFeedIndex, 0, selectedFeed);
			};

			const { type, deviceId, alertId } = data;
			if ([...props.conference?.participants?.values()]?.length) {
				playVoiceOver({ ...data, isAiAlert: true });
				mapEventsToFeed({ deviceId, type, alertId, isAiAlert: true });
			}
			reRender.current();
		},
		[isAnyFeedMicActive, props.conference.participants]
	);

	useEffect(() => {
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT, onAiAlert);
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT, onAiAlert);
			if (playingAlert.current) {
				playingAlert.current.pause();
				playingAlert.current.currentTime = 0;
			}
		};
	}, [onAiAlert, socket]);

	useEffect(() => {
		if (props.feedToStopVoiceOver) {
			const selectedFeedIndex = [...props.conference?.participants?.values()].findIndex(item => item.objectId === props.feedToStopVoiceOver);
			const [selectedFeed] = [...props.conference?.participants?.values()].splice(selectedFeedIndex, 1);

			if (playingAlert.current) {
				playingAlert.current.currentTime = 0;
				playingAlert.current.pause();
			}
			selectedFeed.warning = {};
			[...props.conference.participants.values()].splice(selectedFeedIndex, 0, selectedFeed);
			stopVoiceOver.current();
		}
	}, [props.conference.participants, props.feedToStopVoiceOver]);

	useEffect(() => {
		if (props.shouldPlayVoiceOverBasedOnWarning) {
			if ([...props.conference.participants.values()].some(feed => feed.warning && Object.keys(feed.warning).length > 0)) {
				if (!isAnyFeedMicActive()) {
					tryToPlayAlertSound();
				}
				if (isAnyFeedMicActive() && playingAlert.current) {
					playingAlert.current.currentTime = 0;
					playingAlert.current.pause();
				}
			}
			stopSoundBasedOnWarning.current();
		}
	}, [isAnyFeedMicActive, props.conference.participants, props.shouldPlayVoiceOverBasedOnWarning]);

	const onSetInactiveTimeToFeed = feed => {
		const selectedFeedIndex = [...props.conference.participants.values()].findIndex(item => +item.objectId === +feed.objectId);
		const [selectedFeed] = [...props.conference.participants.values()].splice(selectedFeedIndex, 1);
		selectedFeed.inactiveTimeValue = feed.value;
		[...props.conference.participants.values()].splice(selectedFeedIndex, 0, selectedFeed);
	};

	return (
		<>
			{props.activeAlertFeed && (
				<AlertsModal
					isAiDisabled={props.isAiDisabled}
					feed={props.activeAlertFeed}
					onAiSettingsClick={props.onAiSettingClick}
					setInactiveTimeToFeed={onSetInactiveTimeToFeed}
				/>
			)}
		</>
	);
};

export default AiControls;

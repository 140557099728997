import { getAiAlertActivities, getPatientAiAlerts } from 'api/alerts';
import { Alert, Modal, Table } from 'components';
import Pagination from 'components/Common/Pagination';
import { getUserId } from 'infrastructure/auth';
import { getAiAlertType } from 'infrastructure/helpers/alertsHelper';
import { formattedDate } from 'infrastructure/helpers/dateHelper';
import React, { useEffect, useState } from 'react';
import AlertHistoryModal from 'views/AlertHistory/AlertHistoryModal';

const AiHistory = () => {
	const [aiAlerts, setAiAlerts] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: { value: 10, label: '10' }, pageIndex: 0, totalCount: 0 });
	const [alertDetails, setAlertDetails] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		const getaiAlerts = async (pageSize, pageIndex) => {
			const params = {
				pageSize: pageSize.value,
				pageIndex,
				userId: getUserId(),
			};
			const response = await getPatientAiAlerts(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setAiAlerts(response.aiAlerts);
			}
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, pageSize, pageIndex, totalCount: response.totalCount || 0 }));
		};
		getaiAlerts(pagination.pageSize, pagination.pageIndex);
	}, [pagination.pageIndex, pagination.pageSize]);

	const tableHeaders = [{ title: 'Description' }, { title: 'Date/Time' }, { title: 'View Details' }];

	const viewAlertDetails = async id => {
		const response = await getAiAlertActivities(id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAlertDetails(response.aiAlertActivities);
	};

	const getTableRows = () => {
		if (aiAlerts.length === 0) return [];
		return aiAlerts.map(item => {
			return {
				name: getAiAlertType(item.aiAlertType.name),
				dateCreated: formattedDate(item.dateCreated),
				viewDetails: (
					<span data-cy='callDetails' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => viewAlertDetails(item.id)}>
						View Details
					</span>
				),
			};
		});
	};

	const onPageChange = async (pageSize, pageIndex) => {
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	return (
		<div>
			<Table isLoading={isLoading} headers={tableHeaders} rows={isLoading ? [] : getTableRows()} tableEmptyText='No ai alerts at the moment.' />
			<Pagination
				totalCount={pagination.totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
			/>
			{alertDetails && (
				<Modal
					display={true}
					position='center'
					onModalSubmit={() => setAlertDetails(null)}
					onModalClose={() => setAlertDetails(null)}
					className='standard-modal-wrapper modal-wrapper-wo-btn'>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner view-request-history-modal'>
						<div className={`view-request-history-wrapper ${!alertDetails.length ? 'flex-align-center' : ''}`}>
							<div className='standard-modal-title alert-history full-width'>
								<h3>Alert Details</h3>
							</div>
							<AlertHistoryModal alertDetails={alertDetails} />
						</div>
					</div>
				</Modal>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default AiHistory;

import React from 'react';
import classNames from 'classnames';

export default class CallCorrelationInfo extends React.Component {
	state = {
		copied: false,
	};

	constructor(props) {
		super(props);
		this.correlationIdRef = React.createRef();
	}

	copyToClipboard = event => {
		event.stopPropagation();
		let textArea = document.createElement('textarea');
		textArea.value = this.props.correlationId;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('Copy');
		textArea.remove();

		this.setState({
			copied: true,
		});

		setTimeout(() => {
			this.setState({
				copied: false,
			});
		}, 3000);
	};

	render() {
		return (
			<div className={classNames('call-button-wrapper call-footer-btn-wrapper', this.props.className)} onClick={this.copyToClipboard}>
				<div className='call-button'>
					<i className='material-icons-outlined'>{this.state.copied ? 'check_circle_outline' : 'file_copy'}</i>
				</div>
				<span>{this.props.correlationId && <span>{this.state.copied ? 'Copied' : 'Copy Room ID'}</span>}</span>
			</div>
		);
	}
}

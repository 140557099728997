/* eslint-disable import/prefer-default-export */
import { gatewayFileUploadApi } from 'constants/global-variables';

export function uploadProfilePic(fileInput, { x, y, width, height }) {
	const formData = new FormData();
	formData.append('file', fileInput, fileInput.name);
	formData.append('cropOffsetX', x.toFixed());
	formData.append('cropOffsetY', y.toFixed());
	formData.append('cropWidth', width.toFixed());
	formData.append('cropHeight', height.toFixed());
	return new Promise((res, rej) => {
		gatewayFileUploadApi
			.post(`/v1/media/pictures/profile`, formData)
			.then(response => {
				res(response);
			})
			.catch(e => rej(e));
	});
}

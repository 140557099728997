import React from 'react';
import PropTypes from 'prop-types';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { Dropdown, ListGroup } from 'components';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import Video from 'calls/components/Video';
import ProfilePicture from 'components/ProfilePicture';
import Audio from 'calls/components/calls/Audio';
import LocalParticipant from 'calls/components/calls/LocalParticipant';

export default function ParticipantScreen(props) {
	const { isPinned, track } = props;
	const { name, remoteTrackController } = props.participant;

	const onPeerMediaToggleClicked = (event, item) => {
		if (item.id === ParticipantEvent.PIN_FEED) {
			props.onPinnedFeed();
		}
	};

	return (
		<div className='participant'>
			<header>
				<p title={name || ''}>
					{isPinned && <i className='material-icons-outlined'>push_pin</i>}
					{`${name}(Presentation)`}
				</p>
				<div>
					<Dropdown closeDropdownOnItemClick={true} position='bottom' icon='more_horiz'>
						<ListGroup
							onItemClick={onPeerMediaToggleClicked}
							lists={[
								{
									id: ParticipantEvent.PIN_FEED,
									title: isPinned ? 'Unpin from main screen' : 'Pin to main screen',
									icon: 'push_pin',
								},
							]}
						/>
					</Dropdown>
				</div>
			</header>
			<main onClick={() => props.onClick(TrackType.SCREEN)}>
				{!(props.participant instanceof LocalParticipant) && remoteTrackController.tracks.get(TrackType.SCREENAUDIO) && (
					<Audio track={remoteTrackController.tracks.get(TrackType.SCREENAUDIO)} />
				)}
				<Video track={track.track} muted />
				<div>
					<ProfilePicture fullName={name} size='small-to-medium' />
				</div>
			</main>
		</div>
	);
}

ParticipantScreen.propTypes = {
	participant: PropTypes.object,
	onClick: PropTypes.func,
};

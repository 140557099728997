import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Alert, Tabs } from 'components';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createPatient, searchPatient } from 'api/patients';
import { updatePatientDeviceOwner } from 'api/devices';
import styled from 'styled-components';

const ExistingPatientWrapper = styled.div`
	position: relative;

	> div:not(.input) {
		position: absolute;
		top: 100%;
		width: 100%;
		color: var(--gray-0);
		background: var(--dark-theme-black-005);

		p {
			margin: 0;
			margin-top: var(--spacing-s);
			padding: var(--spacing-s);
			margin: 0;
			cursor: pointer;
			border-bottom: 1px solid var(--dark-theme-gray-2-08);
			font-size: 14px;
			background: var(--dark-theme-black-005);
		}

		> div {
			&:last-of-type {
				p {
					border-bottom: none;
				}
			}
		}
	}

	> p {
		font-size: 14px;
		font-weight: 700;
		> span {
			font-weight: 400;
		}
	}
`;

const AssignPatientModal = props => {
	const [error, setError] = useState(null);
	const [assignPatientSuccess, setAssignPatientSuccess] = useState(false);
	const [searchedPatients, setSearchedPatients] = useState([]);
	const [searchPatientValue, setSearchPatientValue] = useState('');
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);

	useEffect(() => {
		const timeout = setTimeout(async () => {
			if (searchPatientValue.length < 2) {
				setSearchedPatients([]);
			} else {
				const response = await searchPatient(searchPatientValue, pageIndex);
				if (response.error) {
					setError(response.error.message);
					return;
				}
				const searchedPatientsList = response.patients.map(item => ({ value: `${item.firstName} ${item.lastName}`, id: item.userId }));
				setSearchedPatients(prevState => prevState.concat(searchedPatientsList));
				setTotalCount(response.totalCount);
			}
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchPatientValue, pageIndex]);

	const validateForm = () => {
		if (currentTab === 0) {
			return Yup.object().shape({
				firstName: Yup.string()
					.required('Please write first name')
					.min(2, 'First name must be at least 2 characters')
					.max(30, 'Max character length is exceeded')
					.trim(),
				lastName: Yup.string()
					.required('Please write last name')
					.min(2, 'Last name must be at least 2 characters')
					.max(30, 'Max character length is exceeded')
					.trim(),
				email: Yup.string()
					.email('Provided e-mail is invalid')
					.required('Please write your e-mail'),
			});
		}
		return null;
	};

	const updateTheDeviceOwner = async (userId, deviceId) => {
		setError(null);
		const deviceParams = {
			userId,
			deviceName: '',
			deviceId,
		};
		const deviceOwner = await updatePatientDeviceOwner(deviceParams);
		if (deviceOwner.error || !deviceOwner.hasSucceeded) {
			const errorMessage = deviceOwner.error?.message || deviceOwner?.message;
			setError(errorMessage);
			setAssignPatientSuccess(false);
		} else {
			setAssignPatientSuccess(true);
		}
	};

	const updateDeviceOwnerListItem = (deviceId, { firstName, lastName }) => {
		const devices = [...props.devices];
		devices.forEach((device, i) => {
			if (device.solHelloDeviceId === deviceId) {
				const updatedDevice = device;
				updatedDevice.owner = `${firstName} ${lastName}`;
				devices[i] = updatedDevice;
			}
		});
		props.setDevices(devices);
	};

	const onSubmit = async (values, { resetForm }) => {
		const { firstName, lastName, email } = values;

		const formData = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			healthSystemId: props.healthSystemId,
		};

		const deviceId = props.devices.length > 0 ? props.devices[0].solHelloDeviceId : null;

		if (currentTab === 0) {
			const response = await createPatient(formData);

			const emailExistsErrorCode = 1008;
			if (response.error) {
				if (response.error.response.data.code === emailExistsErrorCode) {
					setError('Email exists');
					return;
				}
				setError(response.error.message);
				return;
			}

			if (response.data?.result) {
				await updateTheDeviceOwner(response.data.result.userId, deviceId);
			}
		} else {
			await updateTheDeviceOwner(selectedPatient.id, deviceId);
		}

		resetForm();
		updateDeviceOwnerListItem(deviceId, values);

		props.onModalClose();
		props.loadRoom();
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		const hasReachedEnd = totalCount - searchedPatients.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const handlePatientSearch = event => {
		setPageIndex(0);
		setSearchedPatients([]);
		setSearchPatientValue(event.target.value);
	};

	return (
		<Formik
			initialValues={{
				firstName: '',
				lastName: '',
				email: '',
				searchPatientValue: '',
			}}
			onSubmit={onSubmit}
			validationSchema={validateForm}
			validateOnBlur={false}
			validateOnChange={false}>
			{formikProps => {
				const { values, errors, handleSubmit, handleChange, isSubmitting } = formikProps;
				return (
					<Modal
						isLoading={isSubmitting}
						modalSelector='editSectorModal'
						className='edit-sector-modal'
						display={props.display}
						position='right'
						onModalSubmit={handleSubmit}
						onModalClose={props.onModalClose}>
						<Form title='Assign Patient' onSubmit={event => event.preventDefault()}>
							<Tabs
								key={currentTab}
								onTabChange={i => setCurrentTab(i)}
								links={[
									{ link: 'New Patient', active: currentTab === 0 },
									{ link: 'Existing Patient', active: currentTab === 1 },
								]}
								components={[
									<>
										<br />
										<Input
											type='text'
											validationOptions={{}}
											label='Patient First Name'
											value={values.firstName}
											onChange={handleChange}
											name='firstName'
											placeholder='Patient First Name'
											maxLength={127}
											error={errors.firstName}
										/>
										<Input
											type='text'
											validationOptions={{}}
											label='Patient Last Name'
											value={values.lastName}
											onChange={handleChange}
											name='lastName'
											placeholder='Patient Last Name'
											maxLength={127}
											error={errors.lastName}
										/>
										<Input
											type='email'
											validationOptions={{}}
											label='Patient Email'
											value={values.email}
											onChange={handleChange}
											name='email'
											placeholder='Patient Email'
											maxLength={127}
											error={errors.email}
										/>
										<Alert
											display={assignPatientSuccess}
											message='Patient successfully assigned as device owner'
											variant='success'
											onClose={() => setAssignPatientSuccess(false)}
										/>
									</>,
									<>
										<br />
										<ExistingPatientWrapper>
											<Input
												type='search'
												validationOptions={{}}
												label='Select existing patient'
												value={searchPatientValue}
												onChange={handlePatientSearch}
												name='email'
												placeholder='Search existing patient'
												maxLength={127}
												list='patients'
											/>
											{searchedPatients.length > 0 && (
												<div className='patient-search-result' onScroll={handleScroll}>
													{searchedPatients.map(item => (
														<div
															onClick={() => {
																setSearchedPatients([]);
																setSearchPatientValue('');
																setSelectedPatient(item);
															}}>
															<p>{item.value}</p>
														</div>
													))}
												</div>
											)}
											{selectedPatient && (
												<p>
													Selected Patient: <span>{selectedPatient.value}</span>
												</p>
											)}
										</ExistingPatientWrapper>
										<Alert
											display={assignPatientSuccess}
											message='Patient successfully assigned as device owner'
											variant='success'
											onClose={() => setAssignPatientSuccess(false)}
										/>
									</>,
								]}
							/>
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AssignPatientModal;

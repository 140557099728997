import React from 'react';
import 'react-day-picker/lib/style.css';
import Layout from 'components/Common/Layout';
import Header from 'components/Common/Header';
import { Tabs, Grid } from 'components';

import PastCallsList from 'components/CallLogs/List/PastCallsList';
import OngoingCallsList from 'components/CallLogs/List/OngoingCallsList';
import { getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';

function CallLogs() {
	return (
		<Layout>
			<div className='call-logs-view'>
				<Grid columns='0 3fr' stretch='100%'>
					<aside className='hello-list' />
					<main className='main-view' style={getMobileFullHeightStyle()}>
						<Header />
						<section>
							<div style={{ width: '100%' }}>
								<h3>Call Logs</h3>
								<Tabs links={[{ link: 'ONGOING', active: true }, { link: 'PAST' }]} components={[<OngoingCallsList />, <PastCallsList />]} />
							</div>
						</section>
					</main>
				</Grid>
			</div>
		</Layout>
	);
}

export default CallLogs;

import { localApi } from 'constants/global-variables';

export function checkActiveConference(conferenceId, invitationKey) {
	return new Promise((res, rej) => {
		localApi
			.get(`conferences/${conferenceId}/link/${invitationKey}`)
			.then(r => res(r))
			.catch(e => rej(e));
	});
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TrackAdded } from '@solaborate/webrtc/lib/Connection';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { MediaDeviceKinds } from 'constants/enums';
import { attachSinkId, getMediaDeviceUId } from 'calls/helpers/conference-utils';

export default class ParticipantAudio extends Component {
	constructor(props) {
		super(props);
		this.audioRef = React.createRef();
	}

	componentDidMount() {
		this.props.participant.remoteTrackController.on(this.onTrackChanged);
		this.setSpeakers();
	}

	setSpeakers = async () => {
		const mediaDevices = await navigator.mediaDevices.enumerateDevices();
		const speakers = mediaDevices.filter(x => x.kind === MediaDeviceKinds.AUDIO_OUTPUT);
		if (speakers && speakers.length > 0) {
			if (this.audioRef.current) {
				this.audioRef.current.pause();
			}
			const sinkId = await getMediaDeviceUId(
				speakers.find(s => s.deviceId === (this.props.activeSpeakersId || 'default')),
				mediaDevices
			);

			await attachSinkId(this.audioRef.current, sinkId);
		}
	};

	componentWillUnmount() {
		this.props.participant.remoteTrackController.off(this.onTrackChanged);
	}

	componentDidUpdate = async prevProps => {
		const { activeSpeakersId } = this.props;

		if (prevProps.activeSpeakersId !== activeSpeakersId && activeSpeakersId) {
			await this.setSpeakers();
			this.audioRef.current.play();
		}
	};

	onTrackChanged = ev => {
		this.setState({}, () => {
			if (ev.type === TrackType.AUDIO || (ev.track && ev.track.type === TrackType.AUDIO)) {
				if (ev.constructor === TrackAdded) {
					this.audioRef.current.srcObject = new MediaStream([ev.track.track]);
				} else {
					this.audioRef.current.srcObject = null;
				}
			}
		});
	};

	render() {
		return <audio ref={this.audioRef} autoPlay />;
	}
}

ParticipantAudio.propTypes = {
	participant: PropTypes.object,
	onClick: PropTypes.func,
};

// #region npm package imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 } from 'uuid';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { SocketContext } from 'io-client/SocketContext';
import { Prompt } from 'react-router-dom';
import { getUserRole } from 'infrastructure/auth';
import _ from 'lodash';
// #endregion

// #region state imports
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions';
// #endregion

// #region infrastructure imports
import { findDeviceById, findSectorById, getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';
// #endregion

// #region constants imports
import { CallTypes, ParticipantRemoveReason, ObjectType, SectorTypes, ConferenceEndReason, PatientsAiSettings, UserRoles } from 'constants/enums';
import SocketEvents from 'constants/socket-events';
// #endregion

// #region components imports
import { Grid, Loader } from 'components';
import Layout from 'components/Common/Layout';
import SectorList from 'components/Common/SectorList';
import Header from 'components/Common/Header';
import VideoFeed from 'calls/components/monitoring/VideoFeed';
import DeviceControlsLockedModal from 'calls/components/monitoring/modals/DeviceControlsLockedModal';
import MonitoringEndedModal from 'calls/components/monitoring/modals/MonitoringEndedModal';
import UnassignedNurse from 'views/UnassignedNurse';
// #endregion

// #region scripts imports
import Conference from 'calls/scripts/conference';
import ConferenceEvent from 'calls/scripts/conference-events.enum';
import {
	ParticipantBusy,
	ParticipantDeclined,
	ParticipantOffline,
	ParticipantNotAnswering,
	ParticipantRemoved,
	ParticipantLeft,
} from 'calls/scripts/participant-state';
import { HasActiveConferenceError } from 'calls/scripts/conference-errors';
import ConferenceParticipant from 'calls/base/ConferenceParticipant';
import { getAiSettings, getCompanyDeviceOwner, getDeviceOwner, updateAiSettings } from 'api/devices';
import AiControls from 'calls/components/monitoring/AiControls';
// #endregion

class MonitoringNewRefactored extends Component {
	constructor(props, socket) {
		super(props, socket);
		this.socket = socket;
		this.state = {
			disableButtons: false,
			showFeedsLimitModal: false,
			feedsLimit: 16, // TODO maybe get this variable from DB
			hasZoomedFeed: false, // TODO state isFeedZoomed maybe in participant

			roomHasDeviceAssigned: true,
			currentRoomName: '',

			conferenceEndReason: null,
			hasActiveConference: null,
			deviceControlsLockedModal: false,
			logo: '',

			// TODO
			shouldShowSwitchToHelloCamError: false,
			activeAlertFeed: null,
			feedToStopVoiceOver: null,
			shouldPlayVoiceOverBasedOnWarning: false,
		};

		this.bitrate = {
			low: {
				min: 400,
				max: 600,
			},
			high: {
				min: 700,
				max: 900,
			},
		};
		this.startConferenceInProgress = false;
		this.lowerBitrateFeedCountThreshold = 4;
		this.requestedFeeds = [];
		this.playingAlert = null;

		// TODO remove this approach;
		this.mapOfRoomIds = new Map();
	}

	componentDidMount() {
		this.socket.on(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceControllsLocked);
		this.bindWindowListeners();
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceControllsLocked);

		if (this.conference) {
			this.conference.leave();
		}

		this.unBindWindowListeners();
	}

	/**
	 * Method used to prevent tab refresh or close in ongoing conference
	 * @param {Object} event
	 */
	beforeUnloadEvent = event => {
		if (this.conference) {
			event.preventDefault();
			// eslint-disable-next-line no-param-reassign
			event.returnValue = '';
		}
	};

	onUnloadEvent = () => {
		if (this.conference) {
			this.conference.leave();
		}
	};

	onWindowMessage = message => {
		if (message.data === 'IN_CALL') {
			window.removeEventListener('beforeunload', this.beforeUnloadEvent);
			// TODO leave with reason
			this.conference.leave(ParticipantRemoveReason.IDLE);
		}
	};

	/**
	 * Method used to bind window listners
	 */
	bindWindowListeners = () => {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);

		window.addEventListener('unload', this.onUnloadEvent);

		window.addEventListener('message', this.onWindowMessage);
	};

	unBindWindowListeners = () => {
		window.removeEventListener('beforeunload', this.beforeUnloadEvent);

		window.removeEventListener('unload', this.onUnloadEvent);

		window.removeEventListener('message', this.onWindowMessage);
	};

	/**
	 * Method used to handle on device controlls locked event
	 * @param {Object} data
	 * @param {Number} data.deviceId
	 */
	onDeviceControllsLocked = ({ deviceId }) => {
		const participant = [...this.conference.participants.values()].find(p => p.objectId === deviceId);
		participant.deviceControllsLocked = true;

		const hasLocalAudioTrack = participant.localTrackController.tracks.get(TrackType.AUDIO);
		if (hasLocalAudioTrack) {
			// this.toggleLocalTrack(TrackType.AUDIO, participant.id);
			this.setState({ deviceControlsLockedModal: true });
		}

		// left commented code for other devs to understand why we don't need to toggle remotes audio
		// no need because hello toggle it's audio by itself
		// const hasRemoteAudioTrack = participant.remoteTrackController.tracks.get(TrackType.AUDIO);
		// if (hasRemoteAudioTrack) {
		// 	participant.remoteTrackController.toggle(TrackType.AUDIO);
		// }
	};

	/**
	 * Method used to log something in the console using [Monitoring] as a key
	 * @param  {...any} other
	 */
	logDebug = (...other) => {
		return console.debug(`[Monitoring] - `, other);
	};

	/**
	 * Method used to add a feed in a monitoring conference
	 * @param {Object} data
	 */
	addFeed = async data => {
		// if conference was ended by maintenance
		// remove end reason from state
		if (this.state.conferenceEndReason === ConferenceEndReason.CLEANUP) {
			this.setState({ conferenceEndReason: null });
		}

		// check if feed was requested but not added yet
		const feedInProgess = this.requestedFeeds.some(rf => rf.helloDeviceId === data.helloDeviceId);
		if (feedInProgess) {
			// feed was requested but due to some latencies it wasn't added yet then
			return this.logDebug('[AddFeed] Feed in progress');
		}

		// if conference is null -> start a new conference
		if (!this.conference) {
			await this.startConference();
		}

		if (this.conference?.participants.size === 1 && !this.state.hasZoomedFeed) {
			this.hideActivePTZ();
		}

		const arrayOfParticipants = [...this.conference.participants.values()];

		if (arrayOfParticipants.some(participant => participant.objectId === +data.helloDeviceId)) {
			return this.logDebug('[AddFeed] Feed already exists!');
		}

		// TODO maybe add a loading to the room icon ??
		this.requestedFeeds.push(data);

		// TODO number of exiting participant + number of request participants should be lower than feedsLimit
		// if it is higher
		// 		we can pop some off
		//		or only display error message
		if (this.conference.participants.size + this.requestedFeeds.length > this.state.feedsLimit) {
			return this.setState({ showFeedsLimitModal: true });
		}

		// NOTE: things here should be synchronous othervise there may be concurrency issues
		// if only add feed is called once -> conference will start with one feed
		// if clicked multiple times -> conference will start with many feeds in the requesteFeeds.
		// this approach can be used to start sessions(multiple feeds at once)
		// 		just call addFeed multiple times foreach device and conference will start with mutiple feeds
		this.requestedFeeds.forEach(rf => {
			const exists = arrayOfParticipants.some(p => p.objectId === rf.helloDeviceId);
			if (exists) {
				return console.log(`Participant exists in the conference`);
			}

			this.changeRoomAddDeviceIcon(true, rf.roomId);
			const participantId = v4();
			// save roomId to access it when feed is removed
			// TODO save roomId in PatientParticipant for easier access
			this.mapOfRoomIds.set(participantId, rf.roomId);
			const newParticipant = new ConferenceParticipant(participantId, +data.helloDeviceId, ObjectType.HELLO_DEVICE);

			// after invite we should prepare/create the participants to receive socket events and display them in the UI
			this.conference.inviteParticipants([newParticipant]);
			this.conference.participants.set(newParticipant.id, this.conference.createParticipant(newParticipant));
		});
		// reset requesteFeeds
		this.requestedFeeds = [];
		this.setState({
			roomHasDeviceAssigned: !!data.helloDeviceId,
			currentRoomName: data.name,
		});
	};

	onAiSettingClick = (status, feed) => {
		const selectedFeedIndex = [...this.conference.participants.values()].findIndex(item => +item.objectId === +feed.objectId);
		const [selectedFeed] = [...this.conference.participants.values()].splice(selectedFeedIndex, 1);
		selectedFeed.isAiDetection = status;
		[...this.conference.participants.values()].splice(selectedFeedIndex, 0, selectedFeed);
	};

	/**
	 * Method used to remove a feed from conference
	 * @param {Object} participant
	 */
	removeFeed = async ({ id, removeReason }) => {
		const participant = this.conference.participants.get(id);
		if (!participant) {
			this.setState({});
			return this.logDebug(`[removeFeed] Participant is undefined`);
		}
		const { expandMonitoringFeed, isAiAlertModalOpen } = participant;
		// TODO add room id, floor id, hospital id and departament id to PatientParticipant
		// const { roomId } = participant;
		const roomId = this.mapOfRoomIds.get(id);
		this.mapOfRoomIds.delete(id);
		this.changeRoomAddDeviceIcon(false, roomId); // TODO move this on participant removed listener ??
		// despite the number of active feeds, handle bitrate will send request to changed the bitrate where needed
		this.handleBitrateChange();
		if (removeReason === ParticipantRemoveReason.CONFERENCE_TERMINATED_BY_ADMINISTRATOR) {
			this.conference.participants.delete(id);
			if (!this.conference.participants.size) {
				this.conference.leave();
				this.conference = null;
			}
		} else {
			this.conference.removeParticipant(id);
		}

		if (participant.expandMonitoringFeed) {
			participant.expandMonitoringFeed = false;
			participant.showPTZ = false;
		}

		if (participant.isAiAlertModalOpen) {
			participant.isAiAlertModalOpen = false;
		}

		if (participant.warning?.value && this.playingAlert) {
			this.playingAlert.currentTime = 0;
			this.playingAlert.pause();
		}

		const isAiDisabled = this.props.organization.allHealthSystems[0].aiDisabled === 'true' || !this.props.organization.allHealthSystems[0].aiDisabled;

		if (getUserRole() === UserRoles.NURSE && !participant.isDefaultOwner && !isAiDisabled) {
			const filteredSettings = this.props.aiSettings.filter(item => item.deviceId === participant.objectId)[0]?.settings;
			const found = filteredSettings.find(item => !['false', undefined, null].includes(item.value));
			if (found) {
				let dataToSend = {
					settingTypeId: found.settingTypeId,
					value: [PatientsAiSettings.PERSON_INACTIVE, PatientsAiSettings.PERSON_GETTING_OUT_OF_BED].includes(found.settingTypeId) ? null : 'false',
				};
				const response = await updateAiSettings(participant.deviceOwnerId, participant.objectId, dataToSend);

				if (response.error) {
					this.setState({ error: response.error.message });
				}
			}
		}

		// if expandMonitoringFeed then set hasZoomedFeed false in state
		// else rerender {}
		this.setState({ ...(expandMonitoringFeed && { hasZoomedFeed: false }), ...(isAiAlertModalOpen && { activeAlertFeed: null }) });
	};

	/**
	 * Method used to send change bitrate event for participants by id
	 * @param {String} participantId
	 * @param {Boolean} useHighBitrate
	 */
	changeBitrate = (participantId, useHighBitrate) => {
		const participant = this.conference.participants.get(participantId);
		if (!participant) {
			return this.logDebug('[changeBitrate] participant is undefined');
		}

		const { min: minBitrate, max: maxBitrate } = useHighBitrate ? this.bitrate.high : this.bitrate.low;
		participant.requestToChangeBitrate({ minBitrate, maxBitrate });
	};

	/**
	 * Method used to handle bitrate changes for participants
	 */
	handleBitrateChange = () => {
		const participantsSize = this.conference.participants.size;
		const expandedParticipant = [...this.conference.participants.values()].find(p => p.expandMonitoringFeed);
		// use always high bitrate for expanded participant
		// use high bitrate if the number of participants is lower or equal to feed count threshold
		this.conference.participants.forEach(p => {
			const useHighBitrate =
				(expandedParticipant && expandedParticipant.id === p.id) || (!expandedParticipant && participantsSize <= this.lowerBitrateFeedCountThreshold);
			// only change bitrate if currect state is different from previous state
			if (p.isUsingHightBitrate !== useHighBitrate) {
				p.isUsingHightBitrate = useHighBitrate;
				this.changeBitrate(p.id, useHighBitrate);
			}
		});
	};

	/**
	 * Method used to change room icon
	 * @param {Boolean} deviceAdded
	 * @param {String} roomId
	 */
	changeRoomAddDeviceIcon = (deviceAdded, roomId) => {
		const treeData = { ...this.props.organization.treeData };
		const sector = findSectorById(treeData.tree, roomId);

		if (deviceAdded) {
			sector.customActionIcon = {
				iconColor: '#4cd137',
				icon: 'check_circle',
				className: 'disabled-click',
			};
		} else {
			delete sector.customActionIcon;
		}

		this.props.organizationActionCreators.setTreeData(treeData);
	};

	/**
	 * Method used to handle on tree view click event
	 * @param {Object} sector
	 */
	onTreeViewLinkClick = sector => {
		if (this.conference && this.conference.participants.size) {
			return;
		}

		// used to show current room name and if room has any assigned device
		if (sector.type === SectorTypes.ROOM) {
			this.setState({
				roomHasDeviceAssigned: !!sector.helloDeviceId,
				currentRoomName: sector.name,
			});
		}
	};

	/**
	 * Method used to start a conference
	 * @async
	 */
	startConference = async () => {
		this.logDebug('Conference undefined. Starting new conference...');
		try {
			// blocks multiple calls to this methods
			if (this.startConferenceInProgress) {
				return Promise.reject(new Error('Conference start in progress...'));
			}
			this.startConferenceInProgress = true;
			this.setState({ startConferenceInProgess: true, hasActiveConference: false });
			// TODO can throw error;
			// Has active conference...
			Conference.setAbortConference(false);
			this.conference = await Conference.start(this.socket, CallTypes.MONITORING, [], 'Monitoring');
			this.bindConferenceListeners();
			this.startConferenceInProgress = false;
			await new Promise(resolve => this.setState({}, resolve));
		} catch (error) {
			this.startConferenceInProgress = false;
			if (error.constructor === HasActiveConferenceError) {
				// reject after setState callback to render the error
				return new Promise((resolve, reject) => this.setState({ hasActiveConference: true }, reject));
			}
			return console.error(error);
		}
	};

	/**
	 * Method used to get conference ended text
	 */
	getConferenceEndedText = () => {
		const { conferenceEndReason } = this.state;
		if (conferenceEndReason === ConferenceEndReason.DROPPED) {
			return 'Failed to reconnect to the network.';
		}

		if (conferenceEndReason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
			return 'This session was ended by a Amwell admin.';
		}

		if (conferenceEndReason === ConferenceEndReason.CLEANUP) {
			return 'This session was ended by maintenance.';
		}

		return undefined;
	};

	hideActivePTZ = () => {
		this.conference.participants.forEach(p => {
			if (p.showPTZ) {
				p.showPTZ = false;
			}
		});
	};

	setAiSettingsOnAddFeed = async participant => {
		if (getUserRole() === UserRoles.NURSE) {
			const ownerResponse = await getCompanyDeviceOwner(+participant.objectId);
			if (!ownerResponse.error) {
				participant.isDefaultOwner = ownerResponse.isDefaultOwner;
			}

			await this.props.organizationActionCreators.getAllHealthSystems();
			const isAiDisabled = this.props.organization.allHealthSystems[0].aiDisabled === 'true' || !this.props.organization.allHealthSystems[0].aiDisabled;
			if (!isAiDisabled && !participant.isDefaultOwner) {
				const deviceOwnerResponse = await getDeviceOwner(participant.objectId);
				const response = await getAiSettings(deviceOwnerResponse?.id, participant.objectId);
				if (response.error) {
					this.setState({ error: response.error.message });
				} else {
					const list = _.cloneDeep(this.props.aiSettings);
					let found = list.find(item => +item.deviceId === +participant.objectId);
					if (found) {
						found.settings = response.patientAiSettings;
					} else {
						list.push({ deviceId: +participant.objectId, settings: response.patientAiSettings });
					}

					const isAiSettingEnabled = response.patientAiSettings.some(item => !['false', undefined, null].includes(item.value));
					this.props.aiSettingsActions.setPatientAiSettings(list);
					participant.deviceOwnerId = deviceOwnerResponse?.id;
					this.onAiSettingClick(isAiSettingEnabled, participant);
				}
			}
		}
	};

	/**
	 * Method used to bind conference listeners
	 */
	bindConferenceListeners = () => {
		this.conference.on(ConferenceEvent.ON_NEW_PARTICIPANT, participant => {
			this.setAiSettingsOnAddFeed(participant);
			if (this.conference.participants.size === 1) {
				participant.showPTZ = true;
			}
			// only rerender
			this.setState({});
		});

		this.conference.on(ConferenceEvent.ON_NEW_PARTICIPANT_STATE, event => {
			// TODO maybe set this listener in a participant state component -> video feeds component
			switch (event.constructor) {
				case ParticipantBusy: {
					// get the data for the active conference
					break;
				}
				case ParticipantDeclined: {
					// maybe set a timer to remove feed
					break;
				}
				case ParticipantOffline: {
					// maybe set a timer to remove feed
					break;
				}
				case ParticipantNotAnswering: {
					// maybe set a timer to remove feed
					break;
				}
				case ParticipantRemoved: {
					// check for conference participants size
					break;
				}
				case ParticipantLeft: {
					// check for conference participants size
					break;
				}
			}

			if (!this.conference.participants.size) {
				this.conference.leave();
				this.conference = null;
			}
			this.setState({});
		});

		this.conference.on(ConferenceEvent.ON_ENDED, ({ reason }) => {
			if (reason === ConferenceEndReason.CLEANUP) {
				this.conference.participants.forEach(p => this.removeFeed(p));
				this.conference.participants.clear();
				this.setState({});
			}
			// clear some variables here
			this.conference = null;

			if ([ConferenceEndReason.DROPPED, ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR, ConferenceEndReason.CLEANUP].includes(reason)) {
				this.setState({
					conferenceEndReason: reason,
				});
			}
		});

		this.conference.on(ConferenceEvent.ON_CHANGED_STATE, () => {
			if (!this.conference.participants.size) {
				this.conference.leave();
				this.conference = null;
			} else if (this.conference.participants.size === 1) {
				let participant = this.conference.participants.values().next().value;
				participant.showPTZ = true;
			}
			this.setState({});
		});
	};

	/**
	 * Method used to tell if feeds should be rendered
	 */
	shouldRenderFeeds = () => {
		const { conferenceEndReason, hasActiveConference, roomHasDeviceAssigned } = this.state;
		return this.conference?.participants?.size > 0 && !conferenceEndReason && !hasActiveConference && roomHasDeviceAssigned && !this.startConferenceInProgress;
	};

	/**
	 * Method used to render a full screen view if a condition is met
	 */
	renderCallStates = () => {
		if (this.state.conferenceEndReason) {
			return (
				<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
					<div style={{ textAlign: 'center' }}>
						<p>
							{this.getConferenceEndedText()} <br />
						</p>
					</div>
				</Grid>
			);
		}

		if (this.state.hasActiveConference) {
			return (
				<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
					<div style={{ textAlign: 'center' }}>
						<p>
							You&apos;re already in a call or monitoring patients. <br />
							Please try again after you end that session.
						</p>
					</div>
				</Grid>
			);
		}

		if (!this.state.roomHasDeviceAssigned) {
			return (
				<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
					<div style={{ textAlign: 'center' }}>
						<h3 style={{ textAlign: 'center' }}>{this.state.currentRoomName}</h3>
						<p style={{ margin: 0, padding: 0 }}>
							This room doesn't have any assigned devices.
							<br /> Please contact your administrator.
						</p>
					</div>
				</Grid>
			);
		}

		if (!this.conference || !this.conference.participants.size) {
			if (this.startConferenceInProgress) {
				return (
					<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
						<div style={{ textAlign: 'center' }}>
							<div className='video-feed-skeleton'>
								<header />
								<div>
									<p>Starting monitoring...</p>
									<Loader />
								</div>
							</div>
						</div>
					</Grid>
				);
			}

			return (
				<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
					<div style={{ textAlign: 'center' }}>
						<h3 style={{ textAlign: 'center' }}>{this.state.currentRoomName}</h3>
						<p>Please select one of the rooms in order to monitor it.</p>
					</div>
				</Grid>
			);
		}
	};

	/**
	 * Method used to toggle expand a feed by participant id
	 * @param {String} participantId
	 */
	toggleExpand = participantId => {
		if (this.conference.participants.size === 1) {
			return;
		}

		this.conference.participants.forEach(p => {
			if (p.id === participantId) {
				p.expandMonitoringFeed = !p.expandMonitoringFeed;
				// TODO find another way here ??
				p.showPTZ = p.expandMonitoringFeed;
			} else {
				p.expandMonitoringFeed = false;
				p.showPTZ = false;
			}
		});
		// after each toggle click handle the bitrate
		this.handleBitrateChange();

		const participant = this.conference.participants.get(participantId);
		const newState = { hasZoomedFeed: participant.expandMonitoringFeed };
		this.setState(newState);
	};

	toggleAiAlertsModal = participantId => {
		this.conference.participants.forEach(p => {
			if (p.id === participantId) {
				p.isAiAlertModalOpen = !p.isAiAlertModalOpen;
			} else {
				p.isAiAlertModalOpen = false;
			}
		});

		this.setState({
			activeAlertFeed: [...this.conference.participants.values()].find(p => p.isAiAlertModalOpen) || null,
		});
	};

	/**
	 * Method used to toggle local participant track for a feed
	 * @param {TrackType} type
	 * @param {string} participantId
	 */
	toggleLocalTrack = async (type, participantId) => {
		const response = await Promise.all(
			[...this.conference.participants.values()].map(p => {
				const track = p.localTrackController.tracks.get(type);
				if (p.id !== participantId && !track) {
					// Do nothing if feed other than the current participantId has no track
					return null;
				}

				if (p.id !== participantId || track) {
					// Stop and remove track other than the current participantId or current participant has an active track
					track.stop();
					return p.localTrackController.remove([type]);
				}

				// Add track for this participantId
				return p.localTrackController.add(type);
			})
		);

		const deviceControlsLocked = response.some(res => res?.deviceControlsLocked);

		if (!deviceControlsLocked) {
			this.setState({});
		}

		return { deviceControlsLocked };
	};

	/**
	 * Method used to toggle a remote track
	 * @param {TrackType} type
	 * @param {String} participantId
	 */
	toggleRemoteTrack = async (type, participantId) => {
		const response = await Promise.all(
			[...this.conference.participants.values()].map(p => {
				// change this condition if you want to accept audio from multiple feeds.
				const shouldRemoveTrack = p.remoteTrackController.tracks.get(type);
				const shouldToggle = p.id === participantId || shouldRemoveTrack;
				return shouldToggle ? p.remoteTrackController.toggle(type) : Promise.resolve();
			})
		);

		const deviceControlsLocked = response.some(res => res?.deviceControlsLocked);

		if (!deviceControlsLocked) {
			this.setState({});
		}

		return { deviceControlsLocked };
	};

	setHealthSystemLogo = logo => {
		this.setState({
			logo,
		});
	};

	getHierarchyNaming = deviceId => {
		const newTree = [...this.props.organization.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb, treeLocation } = device;
		return {
			hospital: treeLocation[0].name,
			room: breadcrumb[0].name,
		};
	};

	render() {
		if (!this.props.organization.allHealthSystems.length) {
			return <UnassignedNurse />;
		}

		const shouldRenderFeeds = this.shouldRenderFeeds();
		const { disableButtons, hasZoomedFeed, conferenceEndReason, deviceControlsLockedModal } = this.state;
		return (
			<Layout twoColumns={true} isSecondColumnCollapsed={!this.props.organization.isTreeVisible}>
				<aside className='hello-list monitoring-list' style={getMobileFullHeightStyle()}>
					<div className='collapse-second-column' onClick={() => this.props.organizationActionCreators.toggleTreeVisibility(false)}>
						<i className='material-icons-outlined'>close</i>
					</div>
					<SectorList setLogo={this.setHealthSystemLogo} isMonitoring={true} addFeed={this.addFeed} onTreeViewLinkClick={this.onTreeViewLinkClick} />
				</aside>

				<div className='users-view'>
					<Grid stretch='100%'>
						<main className='main-view monitoring-view' style={getMobileFullHeightStyle()}>
							<Header logo={this.state.logo} />
							<section>
								{shouldRenderFeeds && (
									<>
										<Grid columns={this.state.activeAlertFeed ? '1fr 350px' : '1fr'}>
											<Grid className={`monitoring-feeds feeds-${this.conference.participants.size} ${hasZoomedFeed ? `zoomed-feed` : ``}`} gridGap='10px'>
												{[...this.conference.participants.values()].map(participant => (
													<VideoFeed
														key={participant.id}
														participant={participant}
														disableButtons={disableButtons}
														onPatientBusyNurse={/**TODO  */ {}}
														showDeviceControllsLockedModal={() => {
															this.setState({ deviceControlsLockedModal: true });
														}}
														toggleAiAlertsModal={this.toggleAiAlertsModal}
														toggleLocalTrack={this.toggleLocalTrack}
														toggleRemoteTrack={this.toggleRemoteTrack}
														expand={this.toggleExpand}
														close={() => this.removeFeed(participant)}
														aiSettings={this.props.aiSettings}
														setAiSettings={this.props.aiSettingsActions.setPatientAiSettings}
														numberOfFeeds={[...this.conference.participants.values()].length}
														conferenceInfo={this.conference}
														hierarchyNaming={this.getHierarchyNaming(participant.objectId)}
														onAiSettingClick={this.onAiSettingClick}
														stopVoiceOver={id => {
															this.setState({
																feedToStopVoiceOver: id,
															});
														}}
														checkForWarning={() => {
															this.setState({
																shouldPlayVoiceOverBasedOnWarning: true,
															});
														}}
														hasZoomedFeed={hasZoomedFeed}
														isAiDisabled={
															this.props.organization.allHealthSystems[0].aiDisabled === 'true' || !this.props.organization.allHealthSystems[0].aiDisabled
														}
													/>
												))}
											</Grid>
											<AiControls
												conference={this.conference}
												reRender={() => this.setState({})}
												feedToStopVoiceOver={this.state.feedToStopVoiceOver}
												shouldPlayVoiceOverBasedOnWarning={this.state.shouldPlayVoiceOverBasedOnWarning}
												stopVoiceOver={() => {
													this.setState({
														feedToStopVoiceOver: null,
													});
												}}
												stopPlayBasedOnWarning={() => {
													this.setState({
														shouldPlayVoiceOverBasedOnWarning: false,
													});
												}}
												onAiSettingClick={this.onAiSettingClick}
												activeAlertFeed={this.state.activeAlertFeed}
												isAiDisabled={
													this.props.organization.allHealthSystems[0].aiDisabled === 'true' || !this.props.organization.allHealthSystems[0].aiDisabled
												}
											/>
										</Grid>
									</>
								)}
								{!shouldRenderFeeds && this.renderCallStates()}
							</section>
						</main>
					</Grid>
				</div>

				{deviceControlsLockedModal && <DeviceControlsLockedModal close={() => this.setState({ deviceControlsLockedModal: false })} />}
				{[ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR, ConferenceEndReason.DROPPED].includes(conferenceEndReason) && (
					<MonitoringEndedModal conferenceEndReason={conferenceEndReason} />
				)}

				<Prompt when={!!this.conference} message='Monitoring session will end upon leaving this page. Are you sure you want to leave?' />
			</Layout>
		);
	}
}

MonitoringNewRefactored.contextType = SocketContext;

const mapStateToProps = state => {
	return {
		organization: state.organization,
		aiSettings: state.aiSettingsList.aiSettings,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActionCreators: bindActionCreators(organizationActionCreators, dispatch),
		aiSettingsActions: bindActionCreators(aiSettingsActionCreators, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringNewRefactored);

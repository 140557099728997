import React, { useEffect, useRef, useState } from 'react';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import Participant from 'calls/components/calls/Participant';
import { useForceComponentUpdate, screenSize } from 'infrastructure/helpers/commonHelpers';
import LocalParticipantView from 'calls/components/calls/LocalParticipant';
import { ParticipantState } from 'constants/enums';
import classNames from 'classnames/bind';

export default function CallAside(props) {
	const {
		localParticipant,
		participants,
		showRightColumn,
		disableButtons,
		isGridView,
		activeTrackType,
		setActiveParticipant,
		activeParticipant,
		activeSpeakersId,
		isMaximizedFrame,
		onRemoveParticipant,
		onTransferOwnership,
		onPinnedFeed,
		pinnedParticipantId,
		setInviteVisible,
		isInviteModal,
	} = props;

	const [participantState, setParticipantState] = useState(null);
	const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 992);
	const forceUpdate = useForceComponentUpdate();
	const timeOutRef = useRef(null);

	const handleResize = () => {
		timeOutRef.current = setTimeout(() => {
			setIsMobileDevice(window.innerWidth <= 992);
		}, 500);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			clearTimeout(timeOutRef.current);
			timeOutRef.current = null;
		};
	}, []);

	const hasLocalScreenTrack = localParticipant.localTrackController.tracks.get(TrackType.SCREEN);
	const participantsTrackSize = [...participants.values()].reduce(
		(acc, item) => {
			if (item.remoteTrackController.tracks.has(TrackType.VIDEO) && item.remoteTrackController.tracks.has(TrackType.SCREEN)) {
				return acc + 2;
			}
			return acc + 1;
		},
		hasLocalScreenTrack ? 2 : 1
	);

	/**
	 * @param {number} state
	 * @param {string} participantName
	 */
	const onParticipantStateChanged = (state, participantName) => {
		if (state === ParticipantState.LEFT_CALL.type) {
			setParticipantState(`${participantName} left the call.`);
		} else if (ParticipantState.CONNECTED.type) {
			setParticipantState('');
		}
	};

	const stopPropagation = event => {
		if (!isGridView && screenSize.isSmallScreen) {
			event.stopPropagation();
		}
	};

	const participantsLength = isGridView ? participants.size : participantsTrackSize - 1;

	const maxVisibleParticipants = isMobileDevice ? 2 : 3;

	return (
		<aside
			onClick={event => stopPropagation(event)}
			className={classNames(
				isGridView && !isInviteModal ? 'grid-view' : '',
				!showRightColumn ? 'hide-col' : '',
				participantsLength <= maxVisibleParticipants ? 'participants-length-low' : 'participants-length-high',
				participantsLength > 1 ? 'participants-multiple' : '',
				isInviteModal ? 'invite-modal-participants' : ''
			)}>
			{isMaximizedFrame && (
				<section className={isGridView && !isInviteModal && `grid-${participantsTrackSize}`}>
					{[...participants.values()].map(participant => (
						<Participant
							key={participant.id}
							participant={participant}
							pinnedParticipantId={pinnedParticipantId}
							activeTrackType={activeTrackType}
							localParticipantIsInitiator={localParticipant.isInitiator}
							disableButtons={disableButtons}
							onClick={setActiveParticipant}
							onRemoteParticipantTracksChanged={forceUpdate}
							onRemoveParticipant={onRemoveParticipant}
							onTransferOwnership={onTransferOwnership}
							onPinnedFeed={onPinnedFeed}
							onParticipantStateChanged={onParticipantStateChanged}
							activeSpeakersId={activeSpeakersId}
							activeParticipant={activeParticipant}
						/>
					))}
					{!isGridView && (
						<div className='participants-number' onClick={setInviteVisible}>
							<p>+{participantsLength - maxVisibleParticipants}</p>
						</div>
					)}
					<LocalParticipantView
						participant={localParticipant}
						type={TrackType.VIDEO}
						onClick={() => setActiveParticipant(localParticipant, TrackType.VIDEO)}
						activeParticipant={activeParticipant}
						isInviteModal={isInviteModal}
					/>
					{hasLocalScreenTrack && (
						<LocalParticipantView
							participant={localParticipant}
							type={TrackType.SCREEN}
							onClick={() => setActiveParticipant(localParticipant, TrackType.SCREEN)}
							activeParticipant={activeParticipant}
							isInviteModal={isInviteModal}
						/>
					)}
				</section>
			)}
			<div className='notification'>{participantState}</div>
		</aside>
	);
}

import { APP_CONFIG } from 'constants/global-variables';

export const getPictureUrl = size => {
	return size ? `${APP_CONFIG.profilePicBaseUrl + size}/${size}/` : APP_CONFIG.profilePicBaseUrl;
};

export const buildProfilePicUrl = (profilePic, size) => {
	if (!profilePic) return null;

	if (profilePic.startsWith('http://') || profilePic.startsWith('https://')) return profilePic;
	return getPictureUrl(size) + profilePic;
};

export const buildProfilePic = profilePic => {
	if (!profilePic) {
		return null;
	}

	return APP_CONFIG.profilePicBaseUrl + profilePic;
};

export const onErrorDefaultSrc = e => {
	e.target.src = APP_CONFIG.defaultPicUrl;
	e.target.onerror = null;
};

export const onErrorInitialsSrc = e => {
	e.target.style.display = 'none';
	e.target.onerror = null;
};

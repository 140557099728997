import React from 'react';
import { Modal, Form } from 'components';
import { ConferenceEndReason } from 'constants/enums';

const getConferenceEndedText = conferenceEndReason => {
	if (conferenceEndReason === ConferenceEndReason.DROPPED) {
		return 'Failed to reconnect to the network.';
	}

	if (conferenceEndReason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
		return 'This session was ended by an Amwell admin.';
	}

	return undefined;
};

export default function MonitoringEndedModal(props) {
	return (
		<Modal
			display={true}
			position='center'
			onModalClose={() => {
				window.location.reload();
			}}
			hideActionButtons={true}>
			<Form title={getConferenceEndedText(props.conferenceEndReason)}>
				<p>Please refresh the page.</p>
			</Form>
		</Modal>
	);
}

import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { DetectionTypes, PatientsAiSettings, UserRoles } from 'constants/enums';
import { getUserRole } from 'infrastructure/auth';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions';
import _ from 'lodash';
import { updateAiSettings } from 'api/devices';
import Alert from 'components/Alert';

const VideoFeedAiButtons = ({ participant, onAiSettingClick, prepareCanvas, isAiDisabled }) => {
	const [error, setError] = useState(null);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const dispatch = useDispatch();
	const aiSettings = useSelector(state => state.aiSettingsList.aiSettings);
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	const toggleAiTools = async (settingTypeId, tool, detectionMode) => {
		const midSensitivity = '2';
		const feedSettings = _.cloneDeep(aiSettings.find(item => +item.deviceId === +participant.objectId));
		const feedinitialSettings = _.cloneDeep(aiSettings.find(item => +item.deviceId === +participant.objectId));
		const selectedSetting = feedSettings.settings.find(item => item.settingTypeId === settingTypeId);
		let value = selectedSetting?.value === 'true' ? 'false' : 'true';
		const enabledAiTypes = feedSettings.settings.filter(type => type.settingTypeId !== settingTypeId && !['false', undefined, null].includes(type.value));
		if (settingTypeId === PatientsAiSettings.PERSON_INACTIVE && !selectedSetting.value) {
			value = participant.inactiveTimeValue || '10';
		}
		if (settingTypeId === PatientsAiSettings.PERSON_INACTIVE && selectedSetting.value) {
			value = null;
		}
		if (settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && !selectedSetting.value) {
			value = midSensitivity;
		}
		if (settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && selectedSetting.value) {
			value = null;
		}

		if (enabledAiTypes.length >= 1 && !['false', undefined, null].includes(value)) {
			setIsMoreThanOneAi(true);
			return;
		}
		prepareCanvas(tool, detectionMode);

		const dataToSend = {
			settingTypeId,
			value,
		};
		onAiSettingClick(!['false', undefined, null].includes(value), participant);

		const newSettings = feedSettings.settings.map(obj => {
			if (obj.settingTypeId === settingTypeId) {
				return { settingTypeId, value };
			}
			return obj;
		});
		const list = _.cloneDeep(aiSettings);
		let found = list.find(item => +item.deviceId === +participant.objectId);
		if (found) {
			found.settings = newSettings;
		} else {
			list.push({ deviceId: +participant.objectId, settings: newSettings });
		}
		setAiSettingsAction(list);

		const response = await updateAiSettings(participant.deviceOwnerId, participant.objectId, dataToSend);
		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction(feedinitialSettings);
		}
	};

	return (
		getUserRole() === UserRoles.NURSE &&
		!isAiDisabled &&
		!participant.isDefaultOwner && (
			<>
				<div className='flex'>
					<div
						id='patient-position-detection'
						className={classNames(
							'call-button',
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.PERSON_INACTIVE
								? 'active'
								: ''
						)}
						onClick={() => toggleAiTools(PatientsAiSettings.PERSON_INACTIVE, 'isPatientPositionOn', null)}
						data-position='top'
						data-tooltip={
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.PERSON_INACTIVE
								? 'Turn off inactive time'
								: 'Turn on inactive time'
						}
					/>
					<div
						id='fall-detection'
						className={classNames(
							'call-button',
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED
								? 'active'
								: ''
						)}
						onClick={() => toggleAiTools(PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, 'isGettingOutOfBedOn', null)}
						data-position='top'
						data-tooltip={
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED
								? 'Turn off getting out of bed'
								: 'Turn on getting out of bed'
						}
					/>
					<div
						id='bed-and-rails-detection'
						className={classNames(
							'call-button',
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.RAIL_DOWN
								? 'active'
								: ''
						)}
						onClick={() => toggleAiTools(PatientsAiSettings.RAIL_DOWN, 'isBedAndRailsOn', DetectionTypes.RAIL)}
						data-position='top'
						data-tooltip={
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.RAIL_DOWN
								? 'Turn off bed and rails'
								: 'Turn on bed and rails'
						}
					/>
					<div
						id='skeleton-detection'
						className={classNames(
							'call-button',
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.SKELETON_PRIVATE_MODE
								? 'active'
								: ''
						)}
						onClick={() => toggleAiTools(PatientsAiSettings.SKELETON_PRIVATE_MODE, 'isSkeletonPrivacyModeOn', DetectionTypes.SKELETON_AND_PRIVACY)}
						data-position='top'
						data-tooltip={
							aiSettings.find(item => +item.deviceId === +participant.objectId)?.settings.find(item => !['false', undefined, null].includes(item.value))
								?.settingTypeId === PatientsAiSettings.SKELETON_PRIVATE_MODE
								? 'Turn off privacy mode'
								: 'Turn on privacy mode'
						}
					/>
				</div>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				<Alert
					fixed={true}
					persist={true}
					position='right'
					variant='dark'
					onClose={() => setIsMoreThanOneAi(false)}
					display={isMoreThanOneAi}
					message="You can't enable more than one AI settings at the same time."
				/>
			</>
		)
	);
};

export default VideoFeedAiButtons;

import { healthCareCdnUrl } from 'constants/global-variables';

export const fallAttentionSound = new Audio(`${healthCareCdnUrl}sound/fall-detection-attention.mp3`);

export const railSound = new Audio(`${healthCareCdnUrl}sound/rail-down-attention.mp3`);

export const personPositionSound = new Audio(`${healthCareCdnUrl}sound/patient-at-risk.mp3`);

export const extremelyHighPressure = new Audio(`${healthCareCdnUrl}sound/extremely-high-pressure.mp3`);

export const extremelyLowPressure = new Audio(`${healthCareCdnUrl}sound/extremely-low-pressure.mp3`);

export const extremelyHighTemperature = new Audio(`${healthCareCdnUrl}sound/extremely-high-temperature.mp3`);

export const highBloodGlucose = new Audio(`${healthCareCdnUrl}sound/high-blood-glucose.mp3`);

export const highBloodPressure = new Audio(`${healthCareCdnUrl}sound/high-blood-pressure.mp3`);

export const highHeartRate = new Audio(`${healthCareCdnUrl}sound/high-heart-rate.mp3`);

export const highTemperature = new Audio(`${healthCareCdnUrl}sound/high-temperature.mp3`);

export const lowBloodPressure = new Audio(`${healthCareCdnUrl}sound/low-blood-pressure.mp3`);

export const lowHeartRate = new Audio(`${healthCareCdnUrl}sound/low-heart-rate.mp3`);

export const lowTemperature = new Audio(`${healthCareCdnUrl}sound/low-temperature.mp3`);

export const obese = new Audio(`${healthCareCdnUrl}sound/obese.mp3`);

export const overWeight = new Audio(`${healthCareCdnUrl}sound/over-weight.mp3`);

export const sleepDeficient = new Audio(`${healthCareCdnUrl}sound/sleep-deficient.mp3`);

export const underWeight = new Audio(`${healthCareCdnUrl}sound/under-weight.mp3`);

export const dangerSound = new Audio(`${healthCareCdnUrl}sound/alert-danger-sound.mp3`);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as mainLayoutActionCreators } from 'state/mainLayout/actions';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { setPoolingCurrentHealthSystem } from 'state/nursePooling/actions';

import { Grid, Tabs } from 'components';
import Layout from 'components/Common/Layout';
import Header from 'components/Common/Header';

import PoolRolesList from 'components/NursePooling/PoolRolesList';
import PoolsList from 'components/NursePooling/PoolsList';

import { getMobileFullHeightStyle } from 'infrastructure/helpers/commonHelpers';

class Pools extends Component {
	componentDidMount() {
		const currentHealthSystem = {
			value: this.props.organization.allHealthSystems[0].id,
			label: this.props.organization.allHealthSystems[0].name,
		};
		this.props.setPoolingCurrentHealthSystem(currentHealthSystem);
	}

	transformHealthSystemArray(healthSystems) {
		return healthSystems.map(hs => {
			return { value: hs.id, label: hs.name };
		});
	}

	render() {
		return (
			<Layout>
				<div className='pools-view'>
					<Grid stretch='100%'>
						<main className='main-view' style={getMobileFullHeightStyle()}>
							<Header />
							<section>
								<Grid width='100%' vertAlign='start'>
									<h3>Nurse Pooling Management</h3>
									<Tabs
										links={[{ link: 'Pool Roles', active: true }, { link: 'Pools' }]}
										components={[
											<PoolRolesList
												healthSystems={this.transformHealthSystemArray(this.props.organization.allHealthSystems)}
												selectedHealthSystem={this.props.nursePooling.currentHealthSystem}
												setPoolingCurrentHealthSystem={this.props.setPoolingCurrentHealthSystem}
											/>,
											<PoolsList
												healthSystems={this.transformHealthSystemArray(this.props.organization.allHealthSystems)}
												selectedHealthSystem={this.props.nursePooling.currentHealthSystem}
												setPoolingCurrentHealthSystem={this.props.setPoolingCurrentHealthSystem}
											/>,
										]}
									/>
								</Grid>
							</section>
						</main>
					</Grid>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = state => {
	return {
		mainLayout: state.mainLayout,
		organization: state.organization,
		nursePooling: state.nursePooling,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		mainLayoutActions: bindActionCreators(mainLayoutActionCreators, dispatch),
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		setPoolingCurrentHealthSystem: data => dispatch(setPoolingCurrentHealthSystem(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pools);

export { Pools }; // Exported for unit tests only

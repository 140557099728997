import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { Modal, Form, Input, Alert } from 'components';
import { getCurrentHealthSystemInfo, isValidSector, findSectorById } from 'infrastructure/helpers/commonHelpers';
import { getSpecialties, updateSpecialties } from 'api/organization';
import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import { SectorTypes } from 'constants/enums';
import _ from 'lodash';

class EditSector extends Component {
	state = {
		sectorName: '',
		isLoading: false,
		error: '',
		alert: {
			message: false,
			messageAlert: '',
			variantAlert: '',
		},
		specialtiesError: {
			error: false,
			message: '',
		},

		specialties: [],
		fetchingSpecialtiesError: false,
		showModal: false,
	};

	uploadLogoInputRef = React.createRef();

	async componentDidMount() {
		setTimeout(() => {
			this.setState({
				showModal: true,
			});
		}, 0);

		const { roomId } = this.props;
		if (roomId) {
			await this.loadSpecialties(roomId);
		}
	}

	loadSpecialties = async roomId => {
		this.setState({ isLoading: true });
		let { specialties } = await getSpecialties(roomId);
		if (specialties) {
			this.setState({
				specialties,
				isLoading: false,
			});
			return;
		}
		this.setState({ isLoading: false, specialtiesError: { error: true, message: 'Specialties could not be loaded..!' } });
	};

	componentDidUpdate = prevProps => {
		if (this.uploadLogoInputRef.current && this.props.isEditSectorModalOpen === true && prevProps.isEditSectorModalOpen === false) {
			this.uploadLogoInputRef.current.value = '';
		}
	};

	capitalizeFirstLetter = string => {
		return string && string.charAt(0).toUpperCase() + string.slice(1);
	};

	editSector = async () => {
		const sector = findSectorById(this.props.treeData.tree, this.props.sectorData.sectorId);
		const isRoom = this.props.sectorData.sectorType === SectorTypes.ROOM;
		const isDifferentName = this.state.sectorName !== this.props.sectorData.sectorName;
		const maxSectorCharacters = 127;

		if (isRoom) {
			const isAnyEmptySpecialty = this.state.specialties.some(spec => !spec.specialty.trim());

			if (isAnyEmptySpecialty) {
				this.toggleAlert('message', `Please don't leave empty fields..`, 'error');
				return;
			}
		}

		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const { hospitalId, departmentId, floorId, roomId, sectorType } = this.props.sectorData;

		let url;
		switch (sectorType) {
			case SectorTypes.HOSPITAL: {
				url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/regions/${currentRegionId}/hospitals/${hospitalId}`;
				break;
			}
			case SectorTypes.DEPARTMENT: {
				url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}`;
				break;
			}
			case SectorTypes.FLOOR: {
				url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}`;
				break;
			}
			case SectorTypes.ROOM: {
				url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}`;
				break;
			}
			default: {
				console.log('Wrong Sector type');
			}
		}

		this.setState({
			isLoading: true,
		});

		if (isDifferentName && this.state.sectorName.trim()) {
			const isValid = isValidSector(sector, this.props.treeData.tree, this.state.sectorName);

			const isCharactersLimitExceeded = this.state.sectorName.length > maxSectorCharacters;

			if (!isValid || isCharactersLimitExceeded) {
				let error = `${this.props.sectorData.sectorType} with this name already exists`;

				if (isCharactersLimitExceeded) {
					error = `The length of ${this.props.sectorData.sectorData} name must be ${maxSectorCharacters} characters or fewer.`;
				}
				this.setState({
					isLoading: false,
					error,
				});
				return;
			}
			if (this.state.sectorName.length)
				await gatewayApi.put(url, {
					name: this.state.sectorName,
				});
		}
		if (isRoom) {
			if (this.state.specialties.some(item => item.specialty?.length > maxSectorCharacters)) {
				this.setState({
					isLoading: false,
					specialtiesError: { error: true, message: `The length of specialties must be ${maxSectorCharacters} characters or fewer.` },
				});
				return;
			}
			const { error } = await updateSpecialties(roomId, this.state.specialties);
			if (error) {
				this.setState({ isLoading: false, specialtiesError: { error: true, message: 'Specialties could not be saved..' } });
				return;
			}
		}

		this.setState(
			{
				sectorName: '',
				error: '',
				isLoading: false,
				showModal: false,
			},
			() => {
				setTimeout(() => {
					this.props.onEditSector();
					if (isRoom) this.props.onToggleRoomAlert('message', 'Changes saved successfully', 'dark');
				}, 200);
			}
		);
	};

	onModalClose = () => {
		this.setState({
			sectorName: '',
			error: '',
			showModal: false,
		});
		setTimeout(() => {
			this.props.onModalClose();
		}, 200);
	};

	addFieldSpeciality = () => {
		let specialties = _.cloneDeep(this.state.specialties);

		specialties.push({
			id: null,
			specialty: '',
		});

		this.setState({
			specialties,
		});
	};

	setSpecialityInputValue = (evt, index) => {
		let specialties = _.cloneDeep(this.state.specialties);
		specialties[index].specialty = evt.target.value;
		this.setState({ specialties });
	};

	toggleConfirmDeleteModal = specialtyParam => {
		const specialties = _.cloneDeep(this.state.specialties.filter(specialty => specialty !== specialtyParam));
		this.setState({
			specialties,
		});
	};

	// TO DO: Make alerts/errors same as every other modal in the app
	toggleAlert = (type, messageAlert, variantAlert) => {
		this.setState(prevState => ({
			...prevState.alert,
			[type]: true,
			messageAlert,
			variantAlert,
		}));

		setTimeout(() => {
			this.setState({
				[type]: false,
			});
		}, 2000);
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	setDescription = () => {
		const { sectorType } = this.props.sectorData;
		if (sectorType === 'room') {
			return `The ${this.capitalizeFirstLetter(this.props.treeData.tree[0].type)} this ${this.capitalizeFirstLetter(sectorType)} is located at.`;
		}
		return `The Health System this ${this.capitalizeFirstLetter(sectorType)} is located at.`;
	};

	render() {
		const { sectorType, sectorName } = this.props.sectorData;
		return (
			<Modal
				modalSelector='editSectorModal'
				className='edit-sector-modal'
				display={this.state.showModal}
				position='right'
				onModalSubmit={this.editSector}
				onModalClose={this.onModalClose}
				isLoading={this.state.isLoading}>
				<Form title={sectorName} onSubmit={event => event.preventDefault()}>
					<Input
						type='text'
						validationOptions={{}}
						label='Name'
						value={this.state.sectorName}
						onChange={this.setInputValues}
						name='sectorName'
						bottomSpace='10px'
						placeholder={sectorName}
						error={this.capitalizeFirstLetter(this.state.error)}
					/>
					<Input label='Location' validationOptions={{}} description={this.setDescription()} />
					<p>{sectorType === 'room' ? this.props.currentSectorLocation?.[0].name : this.props.currentHealthSystemName}</p>

					{sectorType === SectorTypes.ROOM && (
						<>
							<Input
								label='Specialties'
								description='These are the available specialties on this health system. Create more, rename, or delete a speciality.'
								bottomSpace='10px'
							/>
							{this.state.specialtiesError.error && <p className='--red-color '>{this.state.specialtiesError.message}</p>}
							{this.state.specialties.map((specialty, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<div key={index} className='hs-list'>
									<input data-cy='specialtyName' onChange={evt => this.setSpecialityInputValue(evt, index)} type='text' value={specialty.specialty} />
									<span onClick={() => this.toggleConfirmDeleteModal(specialty)}>
										<i data-cy='deleteSpecialty' className='material-icons-outlined delete'>
											delete
										</i>
									</span>
								</div>
							))}
							<div className='region-actions'>
								<p>
									<span data-cy='addSpecialtyBtn' onClick={this.addFieldSpeciality}>
										+ Add new speciality
									</span>
								</p>
							</div>
							<Alert display={this.state.message} fixed={true} hideCloseButton={true} message={this.state.messageAlert} variant={this.state.variantAlert} />
						</>
					)}
				</Form>
			</Modal>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(EditSector);

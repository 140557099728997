import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';

import Alert from 'components/Alert';
import { editPoolRole } from 'api/nursePooling';

class EditPoolRole extends Component {
	render() {
		return (
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={{
						updatedName: this.props.currentPoolRole.name,
					}}
					onSubmit={values => {
						this.props.onLoading('editPoolRole');
						editPoolRole(this.props.healthSystemId, this.props.currentPoolRole.id, values.updatedName).then(() => {
							this.props.onSuccess(this.props.currentPoolRole.id, values.updatedName);
						});
					}}
					validationSchema={Yup.object().shape({
						updatedName: Yup.string()
							.required('Pool role name is required')
							.min(3, 'New name must be at least 3 characters')
							.max(50, 'New name must be at most 50 characters'),
					})}>
					{formikProps => {
						// bind the submission handler remotely
						this.props.bindSubmitForm(formikProps.submitForm);
						return (
							<Form>
								<h3>Edit Pool Role</h3>
								<Field name='updatedName' type='text' label='Enter Name' placeholder='Name' description='Type the name for this pool role' component={Input} />
								<Alert
									display={this.props.loaded}
									message='The pool role was successfully added to this health system !'
									variant='success'
									onClose={() => this.props.onCloseAlert('editPoolRole')}
								/>
								<Alert
									display={this.props.error}
									message='Something went wrong! Please try again. '
									variant='error'
									onClose={() => this.props.onCloseAlert('editPoolRole')}
								/>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default EditPoolRole;

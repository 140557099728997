import React, { useEffect, useRef } from 'react';
import { isSafari, isMobile } from 'react-device-detect';

// Component used to fix video flickering bug while toggle on and off
const Video = React.forwardRef(
	(
		/** @type {{ track: MediaStreamTrack, styleTransform: string } & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>} */
		props,
		/** @type {React.ForwardedRef<HTMLVideoElement>} */
		ref
	) => {
		const videoRef = useRef(null);
		const bufferedVideoRef = useRef(null);

		const attachStyleTransform = video => {
			const { facingMode } = props.track.getSettings();
			if (props.styleTransform && (!isMobile || (isMobile && facingMode === 'user'))) {
				// eslint-disable-next-line no-param-reassign
				video.style.transform = props.styleTransform;
			}
		};

		useEffect(() => {
			let videoToPlay = null;
			let videoToStop = null;

			if (!isSafari) {
				videoToPlay = videoRef.current.paused ? videoRef.current : bufferedVideoRef.current;
				videoToStop = !videoRef.current.paused ? videoRef.current : bufferedVideoRef.current;
			} else {
				videoToPlay = videoRef.current;
				attachStyleTransform(videoToPlay);
			}

			if (!props.styleTransform) {
				videoToPlay.style.removeProperty('transform');
			}

			videoToPlay.srcObject = props.track ? new MediaStream([props.track]) : null;

			const onCanPlay = async () => {
				if (!videoToPlay.paused || !props.track) {
					return;
				}

				try {
					await videoToPlay.play();

					if (videoToStop) {
						attachStyleTransform(videoToPlay);
						videoToPlay.style.display = 'unset';
						videoToStop.style.display = 'none';
						videoToStop.srcObject = null;
						videoToStop.removeEventListener('canplay', onCanPlay);
					}
					if (!props.styleTransform) {
						videoToPlay.style.removeProperty('transform');
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.warn(`Video didn't play`, error);
				}
			};

			videoToPlay.addEventListener('canplay', onCanPlay);

			if (ref) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				ref.current = videoToPlay;
			}
			return () => {
				videoRef.current.removeEventListener('canplay', onCanPlay);
				if (bufferedVideoRef.current) {
					bufferedVideoRef.current.removeEventListener('canplay', onCanPlay);
				}
			};
		}, [props.track, ref]);

		return (
			<>
				<video {...props} ref={videoRef} playsInline muted /> {/* playsInline is put for Safari in iOS */}
				{!isSafari && <video {...props} style={{ display: 'none' }} ref={bufferedVideoRef} playsInline muted />} {/* playsInline is put for Safari in iOS */}
			</>
		);
	}
);
Video.displayName = 'Video';

export default Video;

import React, { Component } from 'react';
import { Form, Input, Button, Checkbox } from 'components';
import QRCode from 'qrcode.react';

class SignUpHello extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ssid: null,
			password: null,
			qrCode: null,
			step: 0,
			skipStep: false,
		};

		this.step = {
			WELCOME: 0,
			WIFI_CONNECT: 1,
			SCAN_QR_CODE: 2,
			SETUP_COMPLETE: 3,
			SETUP_ERROR: 4,
			EXPIRED_LINK: 5,
		};
	}

	componentDidMount = () => {
		document.querySelector('html').style.overflow = 'auto';
		document.querySelector('body').style.overflow = 'auto';
	};

	generateQRCode = evt => {
		evt.preventDefault();
		this.setState(
			{
				qrCode: JSON.stringify({
					s: this.state.ssid,
					p: this.state.password,
				}),
			},
			() => {
				if (this.state.skipStep) {
					this.showStep(this.step.SETUP_COMPLETE);
				} else {
					this.changeStep(true);
				}
			}
		);
	};

	setInputValue = evt => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};

	changeStep = increment => {
		this.setState({
			step: increment ? this.state.step + 1 : this.state.step - 1,
		});
	};

	showStep = step => {
		this.setState({
			step,
		});
	};

	onSkipStep = event => {
		this.setState({
			skipStep: event.target.checked,
		});
	};

	render() {
		return (
			<div className={`sign-up-flow step-${this.state.step}`}>
				{this.state.step === this.step.WELCOME && (
					<main>
						<img src='https://static.solaborate.com/americanwell/hello-device-amwell.png' alt='Hello Device' />
						<h1>Welcome</h1>
						<p>Conveniently connect to your care team. Please follow the next steps to set up your device.</p>
						<Button onClick={() => this.changeStep(true)} text={`Let's Get Started`} display='block' />
						<small>
							By continuing, you agree to our{' '}
							<a href='https://business.amwell.com/terms-of-use/' target='_blank'>
								Terms of Use & Privacy Policy.
							</a>
						</small>
					</main>
				)}
				{this.state.step === this.step.WIFI_CONNECT && (
					<main>
						<img src='https://static.solaborate.com/americanwell/amwell-icons/wifi-amwell.svg' alt='Wifi icon' />
						<h1>Step 1 - Connect</h1>
						<p>
							Please enter your WiFi credentials below so we can connect your device to your network. The WiFi network name & password are case sensitive. We
							will not save your credentials.
						</p>
						<Form onSubmit={this.generateQRCode}>
							<Input
								type='text'
								name='ssid'
								autoComplete='off'
								value={this.state.ssid}
								onChange={this.setInputValue}
								validationOptions={{ required: !this.state.skipStep }}
								placeholder='Enter Your Wifi Network'
								bottomSpace='20px'
							/>
							<Input
								type='password'
								name='password'
								autoComplete='off'
								value={this.state.password}
								onChange={this.setInputValue}
								validationOptions={{ required: !this.state.skipStep }}
								placeholder='Enter Your Wifi Password'
								bottomSpace='15px'
							/>
							<div className='checkbox-wrapper'>
								<Checkbox onChange={this.onSkipStep} name='Skip step' checked={this.state.skipStep} />
								<span>Skip this step, I’d prefer using Ethernet Cable for Internet Connection</span>
							</div>
							<Button type='submit' text='Next' display='block' />
						</Form>

						<footer>Please contact us on our 24/7 live chat or email us at support@solaborate.com.</footer>
					</main>
				)}
				{this.state.step === this.step.SCAN_QR_CODE && (
					<main>
						<img src='https://static.solaborate.com/americanwell/amwell-icons/scan-amwell.svg' alt='Scan icon' />
						<h1>Step 2 - Scan QR Code</h1>
						<p>Please hold this QR code up to your device to complete set up.</p>
						<QRCode includeMargin size={300} value={this.state.qrCode} />
						<div>
							<button className='back-link' onClick={() => this.changeStep(false)}>
								<img src='https://static.solaborate.com/americanwell/amwell-icons/back-icon.svg' alt='Back icon' />
								Go back
							</button>
						</div>
						<footer>Please contact us on our 24/7 live chat or email us at support@solaborate.com.</footer>
					</main>
				)}
				{this.state.step === this.step.SETUP_COMPLETE && (
					<main>
						<img src='https://static.solaborate.com/americanwell/info-amwell-blue.svg' width='72' height='72' alt='Done icon' />
						<h1>Wired Connection</h1>
						<p>Plug an ethernet cable into the Gigabit Ethernet port on the HELLO 2+ device. You may close this window.</p>
						<footer>Please contact us on our 24/7 live chat or email us at support@solaborate.com.</footer>
					</main>
				)}
				{this.state.step === this.step.SETUP_ERROR && (
					<main>
						<img src='https://static.solaborate.com/americanwell/amwell-icons/error-amwell.svg' alt='Error icon' />
						<h1>Try Again!</h1>
						<p>Something went wrong with setting up your device. Please try again.</p>
						<Button text='Try Again' display='block' />
						<footer>Please contact us on our 24/7 live chat or email us at support@solaborate.com.</footer>
					</main>
				)}
				{this.state.step === this.step.EXPIRED_LINK && (
					<main>
						<img src='https://static.solaborate.com/americanwell/amwell-icons/expired-amwell.svg' alt='Error icon' />
						<h1>Expired Link</h1>
						<p>This link was already used to set up a device, or this link expired. If you think this is a mistake, please contact support.</p>
						<footer>Please contact us on our 24/7 live chat or email us at support@solaborate.com.</footer>
					</main>
				)}
			</div>
		);
	}
}

export default SignUpHello;

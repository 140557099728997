import React, { Component } from 'react';
import CallButton from 'calls/components/CallButton';
import AudioOutputs from 'calls/components/calls/AudioOutputs';
import { SerialTVCommands, CallTypes, TvBrands, AudioOutputDevice } from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';
import { Dropdown, ListGroup } from 'components';
import { amwellIconLink } from 'constants/global-variables';

class TvControls extends Component {
	constructor(props, socket) {
		super(props, socket);

		this.socket = socket;
		this.state = {
			volumeRange: 0,
			powerTv: props.participant.tvStatus,
			isHdmiLoading: false,
			isTvMuted: false,
			audioOutput: AudioOutputDevice.TV,
		};

		this.hdmiTypeButtons = [
			{
				title: "Toggle patient's TV to HDMI1",
				icon: 'looks_one',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI1,
			},
			{
				title: "Toggle patient's TV to HDMI2",
				icon: 'looks_two',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI2,
			},
			{
				title: "Toggle patient's TV to HDMI3",
				icon: 'looks_3',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI3,
			},
		];

		this.tenSeconds = 10000;
	}

	amwellImgUrl = 'https://static.solaborate.com/americanwell/';

	componentDidMount() {
		this.socket.on(SocketEvents.HelloDevice.ON_TV_RESPONSE, this.onTvResponse);
		this.socket.on(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, this.nAudioOutputDevice);
	}

	componentWillUnmount() {
		this.socket.off(SocketEvents.HelloDevice.ON_TV_RESPONSE, this.onTvResponse);
		this.socket.off(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, this.nAudioOutputDevice);
	}

	nAudioOutputDevice = data => {
		if (!data.isSwitchSuccessful) {
			// eslint-disable-next-line no-console
			console.error(
				`Switch was not successful! Current audio device is ${AudioOutputDevice.HELLO === data.audioOutputDevice ? 'Amwell Bridge' : 'Pillow Speaker'}`,
				data.message
			);
		}
		this.setState({ audioOutput: data.audioOutputDevice });
	};

	getTvControlsItems = () => {
		const isSamsungOrLGTV = [TvBrands.SAMSUNG, TvBrands.LG].includes(this.props.participant.tvBrand);
		const isUnknownOrCECTV = this.props.participant.tvBrand === TvBrands.UNKNOWN || this.props.participant.tvBrand === TvBrands.CEC_TV;

		const TVItem = {
			title: `Power ${this.state.powerTv ? 'OFF' : 'ON'} TV`,
			image: `${amwellIconLink}power-tv.svg`,
			id: isSamsungOrLGTV ? 'exLink_mpi_teleHealth_tv' : 'tv',
			isDisabled: this.props.isDisabled,
		};

		if (!this.state.powerTv) {
			return [TVItem];
		}

		if (isSamsungOrLGTV) {
			return [
				{
					title: (
						<div className='flex'>
							<span onClick={this.toggleMute}>
								<img src={`${amwellIconLink}${this.state.isTvMuted ? 'muted.svg?v3' : 'volume.svg'}`} alt='volume icon' />
							</span>

							<div className='volume-dropdown mpi-volume-dropdown'>
								<span>{this.state.volumeRange}</span>
								<input
									type='range'
									id='start'
									name='volume'
									min='0'
									max='100'
									value={this.state.volumeRange}
									onChange={event => this.setState({ volumeRange: event.target.value })}
									onMouseUp={event => {
										// @ts-ignore
										this.serialCommandsTv('Volume', +event.target.value);
									}}
								/>
								<i className='material-icons-outlined'>volume_up</i>
							</div>
						</div>
					),
					id: 'exLink_mpi_teleHealth_volume',
					isDisabled: (this.props.isDisabled || this.state.audioOutput === AudioOutputDevice.HELLO) && this.props.callType !== CallTypes.SECURITYCAM,
				},
				{
					title: 'Switch to HDMI 1',
					image: `${amwellIconLink}switch-hello-src.svg`,
					id: 'exLink_mpi_teleHealth_hdmi1',
					isDisabled: this.props.isDisabled,
					classNames: this.props.participant?.hdmiStatus?.tvState.tvStatus === SerialTVCommands.HDMI.SWITCH_HDMI1 ? 'active' : '',
				},
				{
					title: 'Switch to HDMI 2',
					image: `${amwellIconLink}switch-hello-src.svg`,
					id: 'exLink_mpi_teleHealth_hdmi2',
					isDisabled: this.props.isDisabled,
					classNames: this.props.participant?.hdmiStatus?.tvState.tvStatus === SerialTVCommands.HDMI.SWITCH_HDMI2 ? 'active' : '',
				},
				{
					title: 'Switch to HDMI 3',
					image: `${amwellIconLink}switch-hello-src.svg`,
					id: 'exLink_mpi_teleHealth_hdmi3',
					isDisabled: this.props.isDisabled,
					classNames: this.props.participant?.hdmiStatus?.tvState.tvStatus === SerialTVCommands.HDMI.SWITCH_HDMI3 ? 'active' : '',
				},
				TVItem,
			];
		}

		if (isUnknownOrCECTV) {
			return [
				TVItem,
				{
					image: `${amwellIconLink}volume.svg`,
					title: (
						<div className='flex volume-dropdown'>
							<button
								type='button'
								onClick={() =>
									this.checkIfCecSupported(() => {
										this.changeVolume(false);
									})
								}>
								<i className='material-icons'>remove</i>
							</button>
							<span>Volume</span>
							<button
								type='button'
								onClick={() =>
									this.checkIfCecSupported(() => {
										this.changeVolume(true);
									})
								}>
								<i className='material-icons'>add</i>
							</button>
						</div>
					),
					id: 'volume',
					isDisabled: this.props.isDisabled || this.state.audioOutput === AudioOutputDevice.HELLO,
				},
				{
					title: 'Switch to TV Kit',
					image: `${amwellIconLink}switch-hello-src.svg`,
					id: 'switch_hdmi',
					isDisabled: this.props.isDisabled,
				},
			];
		}
		return [];
	};

	getTvControlsFunctions = (evt, { id }) => {
		switch (id) {
			case 'exLink_mpi_teleHealth_tv': {
				return this.serialCommandsTv('Power');
			}
			case 'exLink_mpi_teleHealth_hdmi1': {
				return this.onHdmiTypeChanged(SerialTVCommands.HDMI.SWITCH_HDMI1);
			}
			case 'exLink_mpi_teleHealth_hdmi2': {
				return this.onHdmiTypeChanged(SerialTVCommands.HDMI.SWITCH_HDMI2);
			}
			case 'exLink_mpi_teleHealth_hdmi3': {
				return this.onHdmiTypeChanged(SerialTVCommands.HDMI.SWITCH_HDMI3);
			}
			case 'tv': {
				return this.checkIfCecSupported(() => {
					this.serialCommandsTv('Power');
				});
			}
			case 'switch_hdmi': {
				return this.checkIfCecSupported(() => {
					this.onHdmiTypeChanged();
				});
			}
			default: {
				return null;
			}
		}
	};

	onTvResponse = data => {
		if (!data.isSuccessful) {
			return;
		}

		if (data.tvState.isVolume) {
			this.props.participant.volumeStatus = data;

			this.setState({
				volumeRange: Math.abs(data.tvState.tvStatus),
				isTvMuted: data.tvState.tvStatus < 0,
			});
			return;
		}

		switch (data.tvState.tvStatus) {
			case SerialTVCommands.INITIAL_TV_POWER:
				this.setState({
					powerTv: true,
				});
				break;
			case SerialTVCommands.POWER.POWER_ON:
			case SerialTVCommands.POWER.POWER_OFF:
				this.setState({
					powerTv: data.tvState.tvStatus !== SerialTVCommands.POWER.POWER_OFF,
				});
				break;
			case SerialTVCommands.HDMI.SWITCH_HDMI1:
			case SerialTVCommands.HDMI.SWITCH_HDMI2:
			case SerialTVCommands.HDMI.SWITCH_HDMI3:
				this.props.participant.hdmiStatus = data;
				this.setState({}); //  TODO
				break;
			case SerialTVCommands.TV_CHANNELS:
				this.props.participant.hdmiStatus = null;
				this.setState({});
				break;
			default:
				break;
		}

		this.props.participant.isCecSupported = data.tvState.isCecSupported;
	};

	serialCommandsTv = async (commandType, volumeRange) => {
		switch (commandType) {
			case 'Power': {
				let tvPowerStatus = this.state.powerTv ? SerialTVCommands.POWER.POWER_OFF : SerialTVCommands.POWER.POWER_ON;
				await this.props.participant.serialCommandsTv(tvPowerStatus, false);
				break;
			}
			case 'Volume': {
				this.setState({
					volumeRange: Math.abs(volumeRange),
				});

				this.props.participant.serialCommandsTv(volumeRange, true);

				break;
			}
			default:
				break;
		}
	};

	onHdmiTypeChanged = async hdmiType => {
		await this.props.participant.serialCommandsTv(hdmiType || SerialTVCommands.HDMI.SWITCH_HDMI1, false);
	};

	changeVolume = increaseVolume => {
		this.setState(
			{
				volumeRange: increaseVolume ? 1 : 0,
			},
			() => {
				this.serialCommandsTv('Volume', this.state.volumeRange);
			}
		);
	};

	toggleMute = async event => {
		event.stopPropagation();
		await this.serialCommandsTv('Volume', !this.state.isTvMuted ? -Math.abs(this.state.volumeRange) : this.state.volumeRange);
		this.setState(prevState => ({
			isTvMuted: !prevState.isTvMuted,
		}));
	};

	checkIfCecSupported = next => {
		if (this.props.participant.isCecSupported) {
			if (next instanceof Function) {
				next();
			}
		} else {
			this.props.toggleCecNotSupportedModal();
		}
	};

	getHDMIButton = (source, hdmiType) => (
		<CallButton
			imgIcon={`${amwellIconLink}switch-hello-src.svg`}
			buttonSelector='TVPower'
			icon='power_settings_new'
			isActive={!this.state.powerTv}
			onClick={() => this.onHdmiTypeChanged(hdmiType)}
			wrapperClassName='call-footer-btn-wrapper'
			text={`Switch to HELLO Source ${source}`}
			isDisabled={this.props.isDisabled}
			isLoading={this.state.isHdmiLoading}
		/>
	);

	render() {
		const shouldHideOnPatientView = this.props.callType !== CallTypes.SECURITYCAM;
		return (
			<>
				<Dropdown
					dropdownInnerTitle='TV Controls'
					position='top'
					isDropdownTitleVisible={true}
					title='TV Controls'
					imageUrl={`${amwellIconLink}aw-tv.svg`}
					className='call-dropdown-btn call-button-wrapper tv-controls-dropdown'>
					<div>
						<div>
							<ListGroup onItemClick={this.getTvControlsFunctions} lists={this.getTvControlsItems()} />
							{shouldHideOnPatientView && (
								<AudioOutputs
									onOutputChange={output => {
										this.setState({ audioOutput: output });
									}}
									participant={this.props.participant}
								/>
							)}
						</div>
					</div>
				</Dropdown>
			</>
		);
	}
}

TvControls.contextType = SocketContext;
export default TvControls;

import React from 'react';
import Grid from 'components/Grid';

function signOut() {
	window.location.href = '/logout';
}

const UserError = props => (
	<Grid className='user-error' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
		<div className='member-error'>
			<h3>You are not authorized to access this page. </h3>
			<img src='https://static.solaborate.com/banyan/member-error.svg' alt='member-error' />
			<p>If you think there has been a mistake, please contact your administrator. </p>
			<span className='logout-link' onClick={signOut}>
				Click here to login with another account.
			</span>
		</div>
	</Grid>
);

export default UserError;

import React from 'react';
import { Route } from 'react-router-dom';
import { AuthConsumer } from 'providers/authProvider';
import Loader from 'components/Loader';
import Grid from 'components/Grid';
import { memberExists, getUserRole } from 'infrastructure/auth';
import UserError from 'views/UserError';
import { UserRoles } from 'constants/enums';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

const PrivateRoute = ({ component, roles, ...rest }) => {
	// eslint-disable-next-line react/display-name
	const renderFn = Component => props => (
		<AuthConsumer>
			{({ isAuthenticated, signinRedirect }) => {
				if (!!Component && isAuthenticated()) {
					let canAccessRoute = true;
					let userRole;
					try {
						userRole = getUserRole();
					} catch (err) {
						return <UserError />;
					}

					const companyMemberExists = memberExists();
					if (roles) {
						canAccessRoute = roles.some(r => r === userRole);
					}

					if ((companyMemberExists || props.match.url === '/logout' || userRole === UserRoles.GUEST) && canAccessRoute) {
						return <Component {...props} />;
					}
					return <UserError />;
				}

				let refToken = getStorage().getItem('ref_token');
				if (refToken) {
					signinRedirect(refToken);

					return (
						<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
							<div style={{ textAlign: 'center' }}>
								<Loader />
							</div>
						</Grid>
					);
				}

				signinRedirect();
				return (
					<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				);
			}}
		</AuthConsumer>
	);

	return <Route render={renderFn(component)} {...rest} />;
};

export default PrivateRoute;

const SocketEvents = {
	Client: {
		AUTHORIZE: 'client.authorize',
		UPDATE_USER_PRESENCE: 'client.updateUserPresence',
		PING: 'client.ping',
		//subscribers
		ON_USER_ONLINE: 'client.userOnline',
		ON_USER_OFFLINE: 'client.userOffline',
		ON_AUTHENTICATED: 'client.authenticated',
		ON_UNAUTHORIZED: 'client.unauthorized',
		ON_CONNECT: 'connect',
		ON_CONNECT_FAILED: 'connect_failed',
		ON_RECONNECT: 'reconnect',
		ON_RECONNECTING: 'reconnecting',
		ON_DISCONNECT: 'disconnect',
		ON_DEVICE_ONLINE: 'client.deviceOnline',
		ON_DEVICE_OFFLINE: 'client.deviceOffline',
		ON_UPDATED_USER_PRESENCE: 'client.updatedUserPresence',
		ON_NOTIFY_UPDATED_USER_PRESENCE: 'client.notifyUpdatedUserPresence',
	},
	Conference: {
		START: 'conference.start',
		JOIN: 'conference.join',
		LEAVE: 'conference.leave',
		TRANSFER_OWNERSHIP: 'conference.transferOwnership',
		END: 'conference.end',
		TOGGLE_STREAMS: 'conference.toggleStreams',
		ERROR: 'conference.error',
		SEND_OFFER: 'conference.sendOffer',
		SEND_ANSWER: 'conference.sendAnswer',
		SEND_ICE_CANDIDATE: 'conference.sendIceCandidate',
		SEND_SIP_OFFER: 'conference.sip.sendOffer',
		SEND_SIP_ANSWER: 'conference.sip.sendAnswer',
		NOT_ANSWERING: 'conference.notAnswering',
		CLOSING_CLIENT: 'conference.closingClient',
		DECLINE: 'conference.decline',
		BROWSER_NOT_SUPPORTED: 'conference.browserNotSupported',
		SDP: 'conference.sdp',
		TRACK_PARTICIPANT_TOGGLE: 'conference.toggleParticipantTrack',
		TRACKS_ADDED: 'conference.tracks.added',
		TRACKS_REMOVED: 'conference.tracks.removed',
		UA: 'conference.ua',
		DATA_RECEIVED: 'conference.data.received',
		CLOSED: 'conference.closed',
		HELLODEVICE_LEAVE: 'conference.helloDevice.leave',
		INVITE_PARTICIPANTS: 'conference.inviteParticipants',
		REMOVE_PARTICIPANT: 'conference.removeParticipant',
		PARTICIPANT_RECONNECT: 'conference.participantReconnect',
		REQUEST_TO_CHANGE_BITRATE: 'conference.requestToChangeBitrate',
		PEER_CONNECTION_STATE_CHANGED: 'conference.peerConnectionStateChanged',
		ICE_GATHERING_STATE_CHANGED: 'conference.iceGatheringStateChanged',
		ICE_CONNECTION_STATE_CHANGED: 'conference.iceConnectionStateChanged',
		LOG: 'conference.log',
		TERMINATE_REQUEST: 'conference.terminateRequest',
		TERMINATE_REQUEST_ACCEPT: 'conference.terminateRequest.accept',
		TERMINATE_REQUEST_CANCEL: 'conference.terminateRequest.cancel',
		FORCE_TERMINATE: 'conference.forceTerminate',
		PARTICIPANT_FORCE_TERMINATE: 'conference.participantForceTerminate',

		// subscribers
		ON_PARTICIPANT_NOT_ANSWERING: 'conference.participantNotAnswering',
		ON_PARTICIPANT_DECLINED: 'conference.participantDeclined',
		ON_PARTICIPANT_LEFT: 'conference.participantLeft',
		ON_PARTICIPANT_STREAMS_TOGGLED: 'conference.participantStreamsToggled',
		ON_NEW_ANSWER: 'conference.newAnswer',
		ON_NEW_OFFER: 'conference.newOffer',
		ON_NEW_ICE_CANDIDATE: 'conference.newIceCandidate',
		ON_NEW_SIP_OFFER: 'conference.sip.newOffer',
		ON_NEW_SIP_ANSWER: 'conference.sip.newAnswer',
		ON_SIP_PARTICIPANT_STREAM_READY: 'conference.sip.participantStreamReady',
		ON_SIP_SESSION_ENDED: 'conference.sip.sessionEnded',
		ON_NEW_PARTICIPANT: 'conference.newParticipant',
		ON_REMOVE_SCREENSHARE_PEER: 'conference.helloDevice.removeScreenSharePeer',
		ON_RINGING: 'conference.ringing',
		ON_INCOMING: 'conference.incoming',
		ON_PARTICIPANT_OFFLINE: 'conference.participantOffline',
		ON_PARTICIPANT_BUSY: 'conference.participantBusy',
		ON_INITIATOR_LEFT: 'conference.initiatorLeft',
		ON_UPDATE_PARTICIPANTS: 'conference.updateParticipants',
		ON_MISSED_CALL: 'conference.missedCall',
		ON_DEVICE_CONTROLS_LOCKED: 'conference.deviceControlsLocked',
		ON_ENDED: 'conference.ended',
		ON_ANSWERED_ELSEWHERE: 'conference.answeredElsewhere',
		ON_PARTICIPANT_REMOVED: 'conference.participantRemoved',
		ON_TRANSFERRED_TO_ANOTHER_CLIENT: 'conference.transferredToAnotherClient',
		ON_TERMINATE_REQUEST: 'conference.newTerminateRequest',
		ON_TERMINATE_REQUEST_ACCEPTED: 'conference.terminateRequest.accepted',
		ON_SWITCH_TO_MEDIASERVER: 'conference.switchToMediaServer',
		ON_REMOVED: 'conference.removed',
		ON_TOGGLE_TRACK: 'conference.toggleTrack',
		ON_OWNER_CHANGED: 'conference.ownerChanged',
		CAMERA_COMMAND: 'conference.helloDevice.cameraCommand',
		MEDIA_CONTROLS: 'conference.helloDevice.mediaControls',
		ON_MEDIA_CONTROLS_RESPONSE: 'conference.helloDevice.mediaControls.response',
	},
	MediaServer: {
		OUTGOING_SEND_OFFER: 'conference.ms.outgoing.send.offer',
		OUTGOING_SEND_ICE: 'conference.ms.outgoing.send.ice',
		INCOMING_SEND_ANSWER: 'conference.ms.incoming.send.answer',
		INCOMING_SEND_ICE: 'conference.ms.incoming.send.ice',
		INCOMING_SEND_CONFIG: 'conference.ms.incoming.send.config',
		INCOMING_SEND_RESTART_ICE: 'conference.ms.incoming.send.restart.ice',
		START_STREAMING: 'conference.ms.start.streaming',
		ON_OUTGOING_INIT_OFFER: 'conference.ms.outgoing.init.offer',
		ON_OUTGOING_TAKE_ANSWER: 'conference.ms.outgoing.take.answer',
		ON_INCOMING_TAKE_OFFER: 'conference.ms.incoming.take.offer',
	},
	HelloDevice: {
		MOVE_CAMERA: 'helloDevice.moveCamera',
		ZOOM_CAMERA: 'helloDevice.zoomCamera',
		SWITCH_CAMERA: 'helloDevice.switchCamera',
		COMMAND: 'helloDevice.command',
		DATA_CHANNEL_OFFER: 'helloDevice.dataChannelOffer',
		DATA_CHANNEL_ANSWER: 'helloDevice.dataChannelAnswer',
		NEW_ICE_CANDIDATE: 'helloDevice.newIceCandidate',
		SEND_ICE_CANDIDATE: 'helloDevice.sendIceCandidate',
		STOP_PEER_CONNECTION: 'helloDevice.stopPeerConnection',
		ON_TOGGLE_TV: 'helloDevice.toggleTv',
		TOGGLE_NIGHTVISION: 'helloDevice.toggleNightVision',
		ON_OFFLINE: 'helloDevice.offline',
		ON_BUSY: 'helloDevice.busy',
		ON_CAMERA_RESPONSE: 'helloDevice.cameraEventResponse',
		ON_INITIAL_STATE: 'helloDevice.initialState',
		ON_UPDATE: 'helloDevice.updateName',
		ON_TV_RESPONSE: 'helloDevice.tvEventResponse',
		ON_STATE_CHANGED: 'helloDevice.stateChanged',
		ON_AUDIO_OUTPUT_DEVICE: 'helloDevice.audioOutputDevice',
		CHECK_DEVICE_IN_CONFERENCE: 'helloDevice.checkDeviceInConferences',
		PATIENT_OBJECT_DETECTIONS: 'helloDevice.objectDetections',
		TOGGLE_AI_TOOLS: 'helloDevice.toggleAiTools',
		CANCEL_AI_ALERT: 'helloDevice.cancelAiAlert',
		ON_CALL_STATE_CHANGED: 'helloDevice.callStateChange',
	},
	User: {
		NOTIFICATIONS_UPDATED: 'user.notificationsUpdated',
		ON_PASSWORD_CHANGED: 'user.passwordChanged',
	},
	Team: {
		ON_TEAM_CHANNEL_NAME_CHANGED: 'company.teamChannelNameChanged',
	},
	Alerts: {
		NEW_MEASUREMENT_ALERT: 'alerts.newMeasurementAlert',
		NEW_AI_ALERT: 'alerts.newAiAlert',
	},
};

export default SocketEvents;

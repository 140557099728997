import React, { Component } from 'react';

import { Grid, Table, Button, Modal, Tabs } from 'components';
import Select from 'react-select';
import { getPoolsByHealthSystemId, getPoolRolesByHealthSystemId, getAssignedDevicesByHealthSystemId, deletePoolRole, deletePool } from 'api/nursePooling';
import AddPool from './Forms/AddPool';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import moment from 'moment';
import EditPool from './Forms/EditPool';
import PoolItems from './PoolItems';
import { DeviceListLevel, DeviceStatus } from 'constants/enums';
import { getDeviceList } from 'api/devices';
import { getDeviceAssignedRoom } from 'infrastructure/helpers/commonHelpers';
import { getHealthSystemSubTree } from 'api/users';
import { CSVLink } from 'react-csv';
//import AssignedDevices from './AssignedDevices';

class PoolsList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			pools: [],
			isAddPoolModalOpen: false,
			isEditPoolModalOpen: false,
			isDeletePoolModalOpen: false,
			addPool: {
				loading: false,
				loaded: false,
				error: false,
			},
			editPool: {
				loading: false,
				loaded: false,
				error: false,
			},
			assignDevice: {
				loading: false,
				laoded: false,
				error: false,
			},
			addRolesToPool: {
				loading: false,
				loaded: false,
				error: false,
			},
			currentPool: null,
			poolRoles: [],
			healthSystemDevices: [],
			assignedDevices: [],
			tree: [],
		};
	}

	async componentDidMount() {
		const selectedHealthSystemId = this.props.selectedHealthSystem.value;
		const [pools, poolRoles, devices, assignedDevices, tree] = await Promise.all([
			getPoolsByHealthSystemId(selectedHealthSystemId),
			getPoolRolesByHealthSystemId(selectedHealthSystemId),
			getDeviceList(DeviceListLevel.HEALTHSYSTEM, selectedHealthSystemId),
			getAssignedDevicesByHealthSystemId(selectedHealthSystemId),
			getHealthSystemSubTree(selectedHealthSystemId),
		]);

		this.setState({
			pools: pools,
			poolRoles: poolRoles,
			loading: false,
			healthSystemDevices: devices,
			assignedDevices: assignedDevices,
			tree: tree,
		});
	}

	submitMyForm = null;

	handleSubmitMyForm = e => {
		if (this.submitMyForm) {
			this.submitMyForm(e);
		}
	};

	bindSubmitForm = submitForm => {
		this.submitMyForm = submitForm;
	};

	onPoleAdded = (addedPool, healthSystemId, poolRoles) => {
		if (healthSystemId === this.props.selectedHealthSystem.value) {
			const transformedArray = poolRoles.map(role => {
				return { id: role.value, name: role.label };
			});
			const newPool = {
				id: addedPool.id,
				name: addedPool.name,
				createdBy: addedPool.createdBy,
				dateCreated: addedPool.dateCreated,
				assignedDevices: [],
				poolRoles: transformedArray,
			};

			this.setState({
				addPool: {
					...this.state.addPool,
					loading: false,
					loaded: true,
				},
				pools: [newPool].concat(...this.state.pools),
			});
		} else {
			this.setState({
				addPool: {
					...this.state.addPool,
					loading: false,
					loaded: true,
				},
			});
		}
	};

	onPoleRolesAdded = () => {
		this.setState({
			addRolesToPool: {
				...this.state.addRolesToPool,
				loading: false,
				loaded: true,
			},
		});
	};

	onPoleEdited = (poolId, poolUpdatedName) => {
		const poolIndex = this.state.pools.findIndex(pool => pool.id === poolId);

		let items = [...this.state.pools];
		const item = {
			...items[poolIndex],
			name: poolUpdatedName,
		};

		items[poolIndex] = item;
		this.setState({
			pools: items,
			editPool: {
				...this.state.editPool,
				loading: false,
				loaded: true,
			},
		});
	};

	onPoolItemsReordered = (poolId, poolRoles) => {
		const poolIndex = this.state.pools.findIndex(pool => pool.id === poolId);

		let items = [...this.state.pools];
		const item = {
			...items[poolIndex],
			poolRoles: poolRoles,
		};

		items[poolIndex] = item;
		this.setState({
			pools: items,
		});
	};

	onDeviceAssigned = (poolId, device) => {
		const poolIndex = this.state.pools.findIndex(pool => pool.id === poolId);

		let items = [...this.state.pools];
		const item = {
			...items[poolIndex],
			assignedDevices: [device].concat(...items[poolIndex].assignedDevices),
		};

		items[poolIndex] = item;
		this.setState({
			pools: items,
			assignDevice: {
				...this.state.assignDevice,
				loading: false,
				loaded: true,
			},
		});
	};

	onUnnasignSuccessful = (poolId, deviceId) => {
		const poolIndex = this.state.pools.findIndex(pool => pool.id === poolId);

		let items = [...this.state.pools];
		const item = {
			...items[poolIndex],
			assignedDevices: items[poolIndex].assignedDevices.filter(device => {
				return device.deviceId !== deviceId;
			}),
		};
		items[poolIndex] = item;
		this.setState({
			pools: items,
		});
	};

	onCloseAlert = activity => {
		this.setState({
			[activity]: {
				...this.state[activity],
				loading: false,
				loaded: false,
				error: false,
			},
		});
	};

	onLoading = activity => {
		this.setState({
			[activity]: {
				...this.state[activity],
				loading: true,
			},
		});
	};

	onCloseAddPoolModal = () => {
		this.setState({
			isAddPoolModalOpen: !this.state.isAddPoolModalOpen,
			addPool: {
				...this.state.addPool,
				loaded: false,
				error: false,
			},
		});
	};

	getEditButtons(pool) {
		return (
			<div className='wrapped'>
				<i
					style={{ marginRight: '0px' }}
					className='material-icons-outlined boxed-icon'
					onClick={() => this.setState({ isEditPoolModalOpen: true, currentPool: pool })}
					data-tooltip='Edit Pool'
					data-position='bottom'>
					edit
				</i>{' '}
				&nbsp;
				<CSVLink data={this.exportAsCsv(pool)} filename={`${pool.name}-${(+new Date()).toString()}.csv`}>
					<i data-tooltip='Export CSV' data-position='bottom' className='material-icons-outlined boxed-icon'>
						get_app
					</i>
				</CSVLink>{' '}
				&nbsp;
				<i
					className='material-icons-outlined boxed-icon'
					data-tooltip='Delete pool'
					data-position='bottom'
					onClick={() => this.setState({ isDeletePoolModalOpen: true, currentPool: pool })}>
					delete
				</i>
			</div>
		);
	}

	exportAsCsv(pool) {
		if (pool.assignedDevices.length === 0) {
			return [
				{
					'Pool Name': pool.name,
					'Health System': this.props.selectedHealthSystem.label,
					'Pool Date Created': moment(utcToLocalTime(pool.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					'Pool Created By': pool.createdBy,
					'Assigned Devices': 'N/A',
				},
			];
		} else {
			const devicesPaths = pool.assignedDevices
				.map(device => {
					return getDeviceAssignedRoom(this.state.tree, device.deviceId);
				})
				.filter(device => Object.keys(device).length !== 0 && device.constructor === Object);
			return devicesPaths.map(devicePath => {
				return {
					'Pool Name': pool.name,
					'Health System': this.props.selectedHealthSystem.label,
					'Pool Date Created': moment(utcToLocalTime(pool.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
					'Pool Created By': pool.createdBy,
					'Assigned Device': `${devicePath.roomName} > ${devicePath.floorName} > ${devicePath.departmentName} > ${devicePath.hospitalName}`,
				};
			});
		}
	}

	listAssignedDevices(currentPool) {
		if (currentPool === null) return;

		const devicesPaths = currentPool.assignedDevices.map(device => {
			return getDeviceAssignedRoom(this.state.tree, device.deviceId);
		});
		return devicesPaths.map(devicePath => {
			return <p className='pool-device'>{`${devicePath.roomName} > ${devicePath.floorName} > ${devicePath.departmentName} > ${devicePath.hospitalName}`}</p>;
		});
	}

	displayPools() {
		if (this.state.pools.length === 0) return [];

		return this.state.pools.map(pool => {
			return {
				name: pool.name,
				healthSystem: this.props.selectedHealthSystem.label,
				createdBy: pool.createdBy,
				dateCreated: moment(utcToLocalTime(pool.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				poolRoles: pool.poolRoles.length === 0 ? 'N/A' : this.listPoolItems(pool.poolRoles),
				devices: this.getNumberOfDevices(pool.assignedDevices, pool),
				edit: this.getEditButtons(pool),
			};
		});
	}

	getNumberOfDevices(devices, pool) {
		if (devices.length === 0) return <span>0</span>;

		return (
			<span
				data-tooltip='View Devices'
				data-position='right'
				style={{ cursor: 'pointer', textDecoration: 'underline' }}
				onClick={() => this.setState({ isViewPoolDevicesModalOpen: true, currentPool: pool })}>
				{devices.length}
			</span>
		);
	}

	listPoolItems(poolItems) {
		return poolItems.map(item => item.name).join(', ');
	}

	async onHealthSystemSelected(healthSystem) {
		await this.props.setPoolingCurrentHealthSystem(healthSystem);
		this.setState({
			selectedHealthSystem: healthSystem,
			loading: true,
		});

		const selectedHealthSystemId = healthSystem.value;
		const [pools, poolRoles, devices, assignedDevices, tree] = await Promise.all([
			getPoolsByHealthSystemId(selectedHealthSystemId),
			getPoolRolesByHealthSystemId(selectedHealthSystemId),
			getDeviceList(DeviceListLevel.HEALTHSYSTEM, selectedHealthSystemId),
			getAssignedDevicesByHealthSystemId(selectedHealthSystemId),
			getHealthSystemSubTree(selectedHealthSystemId),
		]);

		this.setState({
			pools: pools,
			poolRoles: poolRoles,
			loading: false,
			healthSystemDevices: devices,
			assignedDevices: assignedDevices,
			tree: tree,
		});
	}

	handleDeletePool = () => {
		this.setState({ deletingPool: true });
		deletePool(this.props.selectedHealthSystem.value, this.state.currentPool.id).then(() => {
			this.setState({
				pools: this.state.pools.filter(pool => {
					return pool.id !== this.state.currentPool.id;
				}),
				currentPool: null,
				deletingPool: false,
				isDeletePoolModalOpen: false,
			});
		});
	};

	render() {
		const poolRolesTableHeaders = [
			{ title: 'Pool Name' },
			{ title: 'Health System Name' },
			{ title: 'Created By' },
			{ title: 'Date Created' },
			{ title: 'Pool Roles' },
			{ title: 'Rooms' },
			{ edit: '' },
		];
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};
		return (
			<div>
				<Table
					isLoading={this.state.loading || this.props.healthSystems.length === 0}
					headers={poolRolesTableHeaders}
					rows={this.state.loading ? [] : this.displayPools()}>
					<Grid columns='300px 1fr' vertAlign='center'>
						<Select
							value={this.props.selectedHealthSystem}
							placeholder='Select Health System..'
							classNamePrefix='custom-select'
							options={this.props.healthSystems}
							components={{ DropdownIndicator }}
							onChange={value => this.onHealthSystemSelected(value)}
						/>
						<Button text='Add Pool' variant='white' horizAlign='end' marginRight='10px' onClick={() => this.setState({ isAddPoolModalOpen: true })} />
					</Grid>
				</Table>
				<Modal
					modalSelector='addPoolModal'
					display={this.state.isAddPoolModalOpen}
					position='right'
					onModalClose={this.onCloseAddPoolModal}
					onModalSubmit={this.handleSubmitMyForm}
					isLoading={this.state.addPool.loading}>
					<AddPool
						healthSystems={this.props.healthSystems}
						selectedHealthSystem={this.props.selectedHealthSystem}
						onLoading={this.onLoading}
						loaded={this.state.addPool.loaded}
						error={this.state.addPool.error}
						bindSubmitForm={this.bindSubmitForm}
						onSuccess={this.onPoleAdded}
						onCloseAlert={this.onCloseAlert}
					/>
				</Modal>
				<Modal
					modalSelector='deletePoolModal'
					display={this.state.isDeletePoolModalOpen}
					position='center'
					onModalSubmit={() => this.handleDeletePool()}
					onModalClose={() => this.setState({ isDeletePoolModalOpen: false })}>
					<form>
						<h3>Warning</h3>
						<p>Are you sure you want to delete this pool? All the assigned devices attached to this pool will be left without a pool.</p>
					</form>
				</Modal>
				<Modal
					modalSelector='viewPoolDevices'
					display={this.state.isViewPoolDevicesModalOpen}
					position='right'
					onModalClose={() => this.setState({ isViewPoolDevicesModalOpen: false, currentPool: null })}>
					<form>
						<h3>Assigned Devices List</h3>
						<p>This is the list of all assigned devices in this pool. </p>
						<div className='assigned-devices-list'>{this.listAssignedDevices(this.state.currentPool)}</div>
					</form>
				</Modal>
				<Modal
					modalSelector='editPoolModal'
					display={this.state.isEditPoolModalOpen}
					position='right'
					onModalClose={() => this.setState({ isEditPoolModalOpen: false, currentPool: null })}
					onModalSubmit={this.handleSubmitMyForm}
					isLoading={this.state.addRolesToPool.loading || this.state.assignDevice.loading || this.state.editPool.loading}>
					{this.state.currentPool !== null && (
						<Tabs
							links={[{ link: 'Edit Pool', active: true }, { link: 'Items Ordinal' }]}
							components={[
								<EditPool
									currentPool={this.state.currentPool}
									healthSystemId={this.props.selectedHealthSystem.value}
									bindSubmitForm={this.bindSubmitForm}
									onLoading={this.onLoading}
									loaded={this.state.editPool.loaded}
									error={this.state.editPool.error}
									onSuccess={this.onPoleEdited}
									onCloseAlert={this.onCloseAlert}
								/>,
								<PoolItems
									currentPool={this.state.currentPool}
									healthSystemId={this.props.selectedHealthSystem.value}
									poolRoles={this.state.poolRoles}
									bindSubmitForm={this.bindSubmitForm}
									onCloseAlert={this.onCloseAlert}
									onSuccess={this.onPoleRolesAdded}
									loaded={this.state.addRolesToPool.loaded}
									error={this.state.addRolesToPool.error}
									onLoading={this.onLoading}
									onPoolItemsReordered={this.onPoolItemsReordered}
								/>,
								{
									/*<AssignedDevices
									healthSystemDevices={this.state.healthSystemDevices}
									healthSystemAssignedDevices={this.state.assignedDevices}
									currentPool={this.state.currentPool}
									healthSystemId={this.props.selectedHealthSystem.value}
									bindSubmitForm={this.bindSubmitForm}
									onCloseAlert={this.onCloseAlert}
									loaded={this.state.assignDevice.loaded}
									error={this.state.assignDevice.error}
									onLoading={this.onLoading}
									onSuccess={this.onDeviceAssigned}
									onUnnasignSuccessful={this.onUnnasignSuccessful}
								/>*/
								},
							]}
						/>
					)}
				</Modal>
			</div>
		);
	}
}

export default PoolsList;

import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import { DeviceActionTypes, OverviewActionTypes } from 'constants/action-types';
import { getCurrentHealthSystemInfo, buildTreeModel, getBusyDevices, getStorage } from 'infrastructure/helpers/commonHelpers';
import { getAllHealthSystems } from 'api/healthSystems';
import { getSubTreeData } from 'api/organization';

// eslint-disable-next-line import/prefer-default-export
export const actionCreators = {
	toggleSwitchHealthSystemModal: () => ({ type: OverviewActionTypes.toggleSwitchHealthSystemModal }),
	toggleTreeVisibility: isTreeVisible => dispatch => {
		dispatch({ type: OverviewActionTypes.toggleTreeVisibility, payload: isTreeVisible });
	},
	toggleAssignDeviceModal: payload => dispatch => {
		dispatch({
			type: OverviewActionTypes.toggleAssignDeviceModal,
			payload,
		});
	},
	setDarkTheme: isDarkThemeEnabled => async dispatch => {
		dispatch({ type: OverviewActionTypes.setDarkTheme, payload: isDarkThemeEnabled });
	},
	addNewRoom: () => ({ type: OverviewActionTypes.addNewRoom }),
	createNewDepartment: data => ({ type: OverviewActionTypes.createNewDepartment, payload: data }),
	createNewHospital: data => ({ type: OverviewActionTypes.createNewHospital, payload: data }),
	setCurrentSectorLocation: data => ({ type: OverviewActionTypes.setCurrentSectorLocation, payload: data }),
	updateBreadcrumb: data => ({ type: OverviewActionTypes.updateBreadcrumb, payload: data }),
	setCurrentHealthSystemInfo: data => ({ type: OverviewActionTypes.setCurrentHealthSystemInfo, payload: data }),
	setTreeData: data => ({ type: OverviewActionTypes.setTreeData, payload: data }),
	setCurrentHospitalId: id => ({ type: OverviewActionTypes.setCurrentHospitalId, payload: id }),
	setCurrentDepartmentId: id => ({ type: OverviewActionTypes.setCurrentDepartmentId, payload: id }),
	setHealthSystemLogo: logo => ({ type: OverviewActionTypes.setCurrentHSLogo, payload: logo }),
	getAllHealthSystems: () => async dispatch => {
		let response = await getAllHealthSystems();
		if (response.healthSystems.length > 0) {
			let lsHs = getStorage().getItem('currentHealthSystemInfo');
			let currentHs = null;
			if (lsHs) {
				currentHs = JSON.parse(lsHs);
			} else {
				const [hs] = response.healthSystems;
				currentHs = {
					currentHealthSystemId: hs.id,
					currentHealthSystemName: hs.name,
					currentRegionId: hs.regions[0].id,
					ehrId: hs.ehrId,
					ehrTypeName: hs.ehrTypeName,
					tenantId: hs.tenantId,
				};
			}
			dispatch({ type: OverviewActionTypes.setAllHealthSystems, payload: { healthSystems: response.healthSystems, currentHealthSystem: currentHs } });
		}
	},
	createHospital: name => async () => {
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals?default-department=true`;
		await gatewayApi.post(url, {
			regionId: currentRegionId,
			name: name,
		});
	},
	createDepartment: name => async () => {
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/regions/${currentRegionId}/departments?default-floor=true`;
		await gatewayApi.post(url, {
			name,
		});
	},
	createFloor: (hospitalId, departmentId, name) => async () => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/floors`;
		await gatewayApi.post(url, {
			name: name,
		});
	},
	createRoom: (hospitalId, departmentId, name) => async () => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1.1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments/${departmentId}/rooms`;
		await gatewayApi.post(url, {
			name: name,
		});
	},
	getHospitalTree: hospitalId => async dispatch => {
		const { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/hospitals/${hospitalId}/departments`;
		const response = await gatewayApi.get(url);
		dispatch({ type: OverviewActionTypes.setHospitalTree, payload: response.data.hospital });
	},
	getTreeData: () => async dispatch => {
		const { currentHealthSystemId, currentRegionId } = getCurrentHealthSystemInfo();
		const hospitals = await getSubTreeData(currentHealthSystemId, currentRegionId);
		let treeData = buildTreeModel(hospitals, undefined);
		const { busy } = getBusyDevices(hospitals);
		dispatch({ type: DeviceActionTypes.SET_BULK_DEVICES_BUSY, payload: busy });

		let preSelected = {};
		if (treeData.length) {
			dispatch({ type: OverviewActionTypes.setCurrentHospitalId, payload: treeData.firstHospitalId });
			dispatch({ type: OverviewActionTypes.setCurrentDepartmentId, payload: treeData.firstDepartmentId });
		}
		dispatch({
			type: OverviewActionTypes.setTreeData,
			payload: {
				tree: treeData.length ? treeData : [{}],
				preSelected: preSelected,
			},
		});
	},
};

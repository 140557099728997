import React from 'react';
import { Grid, Button } from 'components';
import ProfilePicture from 'components/ProfilePicture';
import LocalParticipant from 'calls/scripts/local-participant';
import PatientParticipant from 'calls/scripts/patient-participant';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import classNames from 'classnames/bind';
import Video from 'calls/components/Video';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import { MediaControlCommands, MediaTypes } from 'constants/enums';
import { isMobileOrTabletDevice } from 'calls/helpers/conference-utils';

class ActiveParticipant extends React.Component {
	state = {
		volume: 0,
	};

	getTrackController = ofProps => {
		const props = ofProps || this.props;
		return props.participant.constructor === LocalParticipant ? props.participant.localTrackController : props.participant.remoteTrackController;
	};

	onTrackChanged = () => {
		this.setState({});
	};

	onVolumeChanged = volume => {
		this.setState({
			volume,
		});
	};

	componentDidMount() {
		const tc = this.getTrackController();
		tc.on(this.onTrackChanged);
		this.props.participant.on(ParticipantEvent.VOLUME_CHANGED, this.onVolumeChanged);
	}

	componentWillUnmount() {
		const tc = this.getTrackController();
		tc.off(this.onTrackChanged);
		this.props.participant.off(ParticipantEvent.VOLUME_CHANGED, this.onVolumeChanged);
	}

	componentDidUpdate(prevProps) {
		const oldTc = this.getTrackController(prevProps);
		const newTc = this.getTrackController();
		if (oldTc !== newTc) {
			oldTc.off(this.onTrackChanged);
			newTc.on(this.onTrackChanged);
			this.onVolumeChanged(0);
			prevProps.participant.off(ParticipantEvent.VOLUME_CHANGED, this.onVolumeChanged);
			this.props.participant.on(ParticipantEvent.VOLUME_CHANGED, this.onVolumeChanged);
		}
	}

	getCoordinates = e => {
		e.stopPropagation();
		if (!e.target || this.props.participant.constructor !== PatientParticipant) {
			return;
		}

		const rect = e.target.getBoundingClientRect();
		const x = ((e.clientX - rect.left) / rect.width) * 100;
		const y = ((e.clientY - rect.top) / rect.height) * 100;
		const xFloat = parseFloat(x.toFixed(2));
		const yFloat = parseFloat(y.toFixed(2));

		this.props.participant.sendMediaControlsEvent(MediaControlCommands.MOVE_TO_CLICKED_POSITION, MediaTypes.CAMERA, {
			x: xFloat,
			y: yFloat,
		});
	};

	render() {
		const { participant, trackType, showRightColumn, localParticipantIsInitiator } = this.props;

		const tc = this.getTrackController();
		const videoTrack = !participant.isCameraPrivacyOn && tc.tracks.get(trackType);

		if (participant.constructor === PatientParticipant && localParticipantIsInitiator && !videoTrack && !participant.isCameraPrivacyOn) {
			return (
				<Grid columns='1fr' rows='1fr' stretch='100vh' backgroundColor='#0153B6' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<h3 style={{ color: 'white' }}>You have toggled patient's camera off.</h3>
						<Button
							onClick={() => {
								participant.remoteTrackController.toggle(this.props.trackType);
							}}
							color='--gray-0'
							text='Turn camera on'
						/>
					</div>
				</Grid>
			);
		}

		const { name: participantName } = participant;
		const fullName = participantName && participantName.split('-').length === 2 ? participantName.split('-')[1] : participantName;

		const allowClickToMove = localParticipantIsInitiator && videoTrack && videoTrack.isActive() && !isMobileOrTabletDevice();

		return (
			<div
				className={classNames(
					'call-view-video',
					trackType === TrackType.SCREEN && videoTrack && videoTrack.isActive() ? 'participant-screenshare' : '',
					!showRightColumn ? 'screenshare-full' : '',
					[...this.props.participants.values()].length > 1 ? 'call-view-video-multiple-participants' : '',
					videoTrack && videoTrack.isActive() ? '' : 'call-view-without-video'
				)}
				onClick={allowClickToMove ? this.getCoordinates : null}>
				{videoTrack && videoTrack.isActive() ? (
					<Video
						track={videoTrack.track}
						styleTransform={participant.constructor === LocalParticipant && trackType === TrackType.VIDEO ? 'scaleX(-1)' : ''}
						height={(window.innerWidth * 9) / 16}
						width={window.innerWidth}
						muted
					/>
				) : (
					<div className='patient-audio-call-logo' style={{ boxShadow: `0 0 0 ${this.state.volume * 10}px rgba(255,255,255, .3)`, borderRadius: '100%' }}>
						<ProfilePicture fullName={fullName} className='call-profile-picture-wrapper' size='large' />
					</div>
				)}
			</div>
		);
	}
}

export default ActiveParticipant;

/* eslint-disable import/prefer-default-export */
import { APP_CONFIG, fileUploadApi, gatewayApi } from 'constants/global-variables';

export const createPatient = async ({ firstName, lastName, email, healthSystemId }) => {
	try {
		const { status, data } = await gatewayApi.post(`/v1/healthcare/patients`, {
			companyId: APP_CONFIG.companyId,
			firstName,
			lastName,
			email,
			healthSystemId,
		});
		return { status, data };
	} catch (error) {
		return { error };
	}
};

export const searchPatient = async (searchText = '', pageIndex = 0, pageSize = 10) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${APP_CONFIG.companyId}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchText}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editPatientProfile = async (userId, params) => {
	try {
		const { data } = await gatewayApi.put(`v1.3/organizations/${APP_CONFIG.companyId}/patients/${userId}`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientDetails = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${APP_CONFIG.companyId}/patient/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadProfilePic = async formData => {
	try {
		const { data } = await fileUploadApi.post(`/v1/media/pictures/profile`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};

import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

/**
 * @function getEhrTypes
 * @desc Gets ehrTypes
 */
export async function getEhrTypes() {
	try {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/ehr-types`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
}

import React from 'react';
import { Table, Grid, Button, Modal, Form, Input, Select as SelectTag } from 'components';
import Select from 'react-select';
import Pagination from 'components/Common/Pagination';
import { getTeamConfigurations, addDeviceConfiguration, deleteTVDeviceConfiguration } from 'api/userIdleConfigurations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { UserRoles, TeamTypes, TVTypes, ConfigHistoryTypes } from 'constants/enums';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { If, Then, Else } from 'react-if';
import _ from 'lodash';
import { getUserRole } from 'infrastructure/auth';
import { getHealthSystemHospitals } from 'api/organization';
import { Link } from 'react-router-dom';

class DeviceConfig extends React.Component {
	initalConfigurationFormData = {
		healthSystems: [],
		hospitals: [],
		hdmiPorts: [
			{ id: 1, value: 'HDMI 1' },
			{ id: 2, value: 'HDMI 2' },
			{ id: 3, value: 'HDMI 3' },
		],
		endCallSource: [
			{ id: 0, value: 'TV Channels' },
			{ id: 1, value: 'HDMI 1' },
			{ id: 2, value: 'HDMI 2' },
			{ id: 3, value: 'HDMI 3' },
		],
		tvs: [
			{ id: 1, value: TVTypes.LG },
			{ id: 2, value: TVTypes.SAMSUNG },
		],

		selectedEndCallSource: null,
		selectedHealthSystemValue: -1,
		selectedHospitalValue: -1,
		selectedHdmiPortValue: -1,
		selectedTVValue: -1,
		selectedHospital: null,
		ringtoneVolumeValue: '',
		isEdit: false,
		errors: {
			hospitalError: null,
			hdmiPortError: null,
			ringtoneVolumeError: null,
			endCallSourceError: null,
		},
	};

	state = {
		isLoading: true,
		showConfigurationModal: false,
		hospitals: [],
		hospital: null,
		currentHealthSystemId: null,
		configurations: [],
		isFormLoading: false,
		totalConfigs: null,
		configurationFormData: _.cloneDeep(this.initalConfigurationFormData),
		isDeleteConfigModalOpen: false,
		deleteConfig: null,
	};

	deviceConfigHeaders = [
		{ title: 'Hospital' },
		{ title: 'HDMI Port' },
		{ title: 'Default Source' },
		{ title: 'Ringtone Volume' },
		{ title: 'TV' },
		{ title: 'Created by' },
		{ title: 'Date Created' },
		{ title: '' },
	];

	transformTypes = {
		WithValues: 1,
		WithLabels: 2,
	};

	role = getUserRole();

	componentDidMount = () => {
		this.getAllDeviceConfigurations();
	};

	componentDidUpdate = async prevState => {
		if (
			this.props.pageIndex !== prevState.pageIndex ||
			this.props.pageSize !== prevState.pageSize ||
			this.props.selectedHealthSystem !== prevState.selectedHealthSystem ||
			this.props.selectedHospitalId !== prevState.selectedHospitalId
		) {
			let configs = await this.getHospitalConfigurations(
				this.props.selectedHealthSystem.value,
				this?.props?.selectedHospitalId?.value,
				this.props.pageSize,
				this.props.pageIndex
			);
			this.getDeviceConfigurations(configs.deviceConfigurations);
			this.getHospitals(this.props.selectedHealthSystem);
		}
	};

	transformHospitalsForSelect = hospitals => {
		return hospitals.map(hospital => {
			return { label: hospital.name, value: hospital.id };
		});
	};

	transformHealthSystemsForSelect = healthSystems => {
		return healthSystems.map(healthSystem => {
			return { label: healthSystem.name, value: healthSystem.id };
		});
	};

	getHealthSystemHospitals = async healthSystemId => {
		const hospitals = await getHealthSystemHospitals(healthSystemId);
		if (!hospitals.error) {
			return this.transformHealthSystemsForSelect(hospitals);
		}
		return [];
	};

	getHospitalConfigurations = async (healthSystemId, hospitalId, pageSize, pageIndex = 0) => {
		const response = await getTeamConfigurations({
			healthSystemId: healthSystemId !== '0' ? healthSystemId : null,
			hospitalId: hospitalId !== '0' ? hospitalId : null,
			pageSize: pageSize || 10,
			pageIndex: pageIndex,
			teamTypeId: hospitalId == null ? TeamTypes.HEALTHSYSTEM : TeamTypes.HOSPITAL,
		});
		return response;
	};

	onHealthSystemSelect = async selection => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedHealthSystem(selection);
			}
		);
	};

	onHospitalSelect = async selection => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedHospital(selection);
			}
		);
	};

	onFormHealthSystemSelect = async ({ id }) => {
		const { configurationFormData } = this.state;
		if (configurationFormData.isEdit) {
			return;
		}

		configurationFormData.selectedHealthSystemValue = configurationFormData.healthSystems.findIndex(hs => id === hs.id);

		// TODO: Show loader
		const hospitals = await getHealthSystemHospitals(id);
		if (!hospitals.error) {
			configurationFormData.hospitals = hospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
		}

		this.setState({
			configurationFormData,
		});
	};

	onFormHospitalSelect = ({ id }) => {
		const { configurationFormData } = this.state;
		if (configurationFormData.isEdit) {
			return;
		}

		configurationFormData.selectedHospitalValue = configurationFormData.hospitals.findIndex(hospital => id === hospital.id);
		configurationFormData.errors.hospitalError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormHdmiPortSelect = event => {
		const { configurationFormData } = this.state;

		configurationFormData.selectedHdmiPortValue = +event.target.value;
		configurationFormData.errors.hdmiPortError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormRingtoneVolumeChange = event => {
		const { configurationFormData } = this.state;

		configurationFormData.ringtoneVolumeValue = event.target.value;

		configurationFormData.errors.ringtoneVolumeError = null;

		this.setState({
			configurationFormData,
		});
	};

	onFormTVSelect = event => {
		const { configurationFormData } = this.state;
		configurationFormData.selectedTVValue = event.target.value;
		configurationFormData.errors.tvError = null;
		this.setState({
			configurationFormData,
		});
	};

	addDeviceConfiguration = async () => {
		const { configurationFormData, currentHealthSystemId, hospital } = this.state;
		let hasError = false;

		if (configurationFormData.selectedHospitalValue === -1 && !configurationFormData.selectedHospital) {
			configurationFormData.errors.hospitalError = 'Please select a hospital.';
			hasError = true;
		}

		if (configurationFormData.selectedTVValue === -1) {
			configurationFormData.errors.tvError = 'Please select a TV.';
			hasError = true;
		}

		if (configurationFormData.selectedHdmiPortValue === -1) {
			configurationFormData.errors.hdmiPortError = 'Please select a HDMI port.';
			hasError = true;
		}

		const ringtoneVolumeNumber = +configurationFormData.ringtoneVolumeValue;
		if (Number.isNaN(ringtoneVolumeNumber) || ringtoneVolumeNumber < 0 || ringtoneVolumeNumber > 100) {
			configurationFormData.errors.ringtoneVolumeError = 'Ringtone volume must be in between 0 and 100.';
			hasError = true;
		}

		if (hasError) {
			this.setState({ configurationFormData });
			return;
		}

		const { id: hospitalId } = !configurationFormData.selectedHospital
			? configurationFormData.hospitals[configurationFormData.selectedHospitalValue]
			: configurationFormData.selectedHospital;
		const hdmiPort = configurationFormData.selectedHdmiPortValue;
		let defaultSource = null;
		if (configurationFormData.selectedEndCallSource || configurationFormData.selectedEndCallSource === 0) {
			defaultSource = configurationFormData?.endCallSource[configurationFormData?.selectedEndCallSource]?.id;
		}

		this.setState({ isFormLoading: true });
		const { hasSucceeded } = await addDeviceConfiguration(hospitalId, {
			teamTypeId: TeamTypes.HOSPITAL,
			hdmiPort,
			defaultSource,
			ringtoneVolume: ringtoneVolumeNumber,
			tv: configurationFormData.selectedTVValue,
		});

		if (!hasSucceeded) {
			this.setState({ isFormLoading: false });
			return;
		}

		await this.resetAddConfigurationModal();

		const configurationsData = await this.getHospitalConfigurations(currentHealthSystemId, hospital ? hospital.id : null);
		this.getDeviceConfigurations(configurationsData.deviceConfigurations);
	};

	resetAddConfigurationModal = () => {
		return new Promise(res => {
			this.setState(
				{
					configurationFormData: _.cloneDeep(this.initalConfigurationFormData),
					showConfigurationModal: false,
					isFormLoading: false,
				},
				() => res()
			);
		});
	};

	getClassName = config => {
		if (config.isActive) {
			return 'active-configuration';
		}
		if (config.isDeleted) {
			return 'deleted-configuration';
		}
		return '';
	};

	getStatus = config => {
		if (config.isActive) {
			return 'Current';
		}
		if (config.isDeleted) {
			return 'Deleted';
		}
		return '';
	};

	getDeviceConfigurations = configurations => {
		if (!configurations.length) {
			this.setState({
				configurations: [],
				isLoading: false,
			});
			return;
		}
		const transformConfigs = configurations.map(config => ({
			hospital: config.team.name,
			hdmiPort: config.hdmiPort,
			defaultSource: this.initalConfigurationFormData.endCallSource.find(x => x.id === config.defaultSource)?.value,
			ringtoneVolume: config.ringtoneVolume,
			tv: config.tv,
			createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
			dateCreated: moment(utcToLocalTime(config.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
			actions: (
				<div className='wrapped' data-cy='viewHistoryEditButtons'>
					<Link to={`/configurations/${config.team.id}/type/${ConfigHistoryTypes.Devices}`}>
						<span className='material-icons-outlined cursor-pointer mr-20' data-cy='viewHistory' data-tooltip='View history' data-position='top'>
							list_alt
						</span>
					</Link>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-tooltip='Edit'
						data-position='top'
						onClick={() => this.toggleConfigurationModal(config)}>
						create
					</span>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='deleteActiveDirectory'
						data-tooltip='Delete'
						data-position='top'
						style={{ color: 'red' }}
						onClick={() => this.openDeleteModal(config)}>
						delete
					</span>
				</div>
			),
		}));
		this.setState({
			configurations: transformConfigs,
		});
	};

	getDefaultSource = defaultSource => {
		if (defaultSource == null || defaultSource === undefined) {
			return null;
		}
		return this.state.configurationFormData?.endCallSource?.find(ds => defaultSource === ds.id).id;
	};

	toggleConfigurationModal = async (config = null) => {
		const { showConfigurationModal, configurationFormData } = this.state;

		if (!showConfigurationModal) {
			configurationFormData.healthSystems = this.props.allHealthSystems.map(hs => ({ id: hs.id, value: hs.name }));
			configurationFormData.isEdit = !!config;

			if (this.role === UserRoles.SUPERUSER) {
				this.onFormHealthSystemSelect(configurationFormData.healthSystems[0]);
			}

			if (configurationFormData.isEdit) {
				configurationFormData.selectedHospital = config.team;
				configurationFormData.selectedHdmiPortValue = configurationFormData.hdmiPorts.find(port => config.hdmiPort === port.id).id;
				configurationFormData.selectedEndCallSource = this.getDefaultSource(config.defaultSource);
				configurationFormData.ringtoneVolumeValue = config.ringtoneVolume;
				configurationFormData.selectedTVValue = config.tv;
			}

			this.setState({
				configurationFormData,
				showConfigurationModal: !showConfigurationModal,
			});
		} else {
			this.resetAddConfigurationModal();
		}
	};

	openDeleteModal = deleteConfig => {
		this.setState({
			deleteConfig,
			isDeleteConfigModalOpen: true,
		});
	};

	onPaginationChange = async (pageSize, pageIndex) => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.onPaginationChange(pageSize, pageIndex);
			}
		);
	};

	getHospitals = async healthSystem => {
		let hospitals;
		if (healthSystem.value !== '0') {
			hospitals = await this.getHealthSystemHospitals(healthSystem.value);
			hospitals.unshift({ value: 0, label: 'All' });
		}
		this.setState({
			hospitals,
		});
	};

	transformArray = (array, type, isHealthSystem) => {
		if (type === this.transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item.name };
			});
			if (isHealthSystem) {
				if (this.role === UserRoles.ADMIN) {
					newArray.unshift({ id: '0', value: 'All' });
				}
			} else {
				newArray.unshift({ id: '0', value: 'All' });
			}
			return newArray;
		}
		if (type === this.transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item.name };
			});
			if (isHealthSystem) {
				if (this.role === UserRoles.ADMIN) {
					newArray.unshift({ value: '0', label: 'All' });
				}
			} else {
				newArray.unshift({ value: '0', label: 'All' });
			}
			return newArray;
		}
		return [];
	};

	onEndCallSourceSelect = event => {
		const { configurationFormData } = this.state;

		configurationFormData.selectedEndCallSource = +event.target.value;
		configurationFormData.errors.endCallSourceError = null;

		this.setState({
			configurationFormData,
		});
	};

	deleteConfig = async () => {
		this.setState({
			isLoading: true,
			isDeleteConfigModalOpen: false,
		});

		const { deleteConfig } = this.state;
		await deleteTVDeviceConfiguration(deleteConfig.team.id, deleteConfig.id);
		const configs = await this.getHospitalConfigurations();
		this.setState({ totalConfigs: configs.total });
		this.getDeviceConfigurations(configs.deviceConfigurations);
	};

	getAllDeviceConfigurations = async () => {
		const configs = await this.getHospitalConfigurations();
		this.setState({ totalConfigs: configs.total });
		this.getDeviceConfigurations(configs.deviceConfigurations);
	};

	render() {
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};

		const { configurationFormData, isFormLoading } = this.state;

		return (
			<div>
				<Table isLoading={this.state.isLoading} headers={this.deviceConfigHeaders} rows={this.state.configurations}>
					<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
						<Select
							value={this.props.selectedHealthSystem}
							placeholder='All'
							classNamePrefix='custom-select'
							options={this.transformArray(this.props.healthSystems, this.transformTypes.WithLabels, true)}
							components={{ DropdownIndicator }}
							onChange={this.onHealthSystemSelect}
							isDisabled={this.role === UserRoles.SUPERUSER}
						/>
						<Select
							value={this.props.selectedHospitalId ? this.state.hospitals?.find(x => x.value === this.props.selectedHospitalId) : null}
							isDisabled={this.props.selectedHealthSystem.value === '0'}
							classNamePrefix='custom-select'
							options={this.state.hospitals}
							components={{ DropdownIndicator }}
							onChange={this.onHospitalSelect}
						/>
						<Button text='New Configuration' horizAlign='end' onClick={() => this.toggleConfigurationModal()} />
					</Grid>
				</Table>
				<Pagination
					totalCount={this.state.totalConfigs}
					pageSize={{ value: this.props.pageSize, label: `${this.props.pageSize}` }}
					pageIndex={this.props.pageIndex}
					onChange={(pageSize, pageIndex) => this.onPaginationChange(pageSize.value, pageIndex)}
				/>
				<Modal
					modalSelector='deviceConfigurationModal'
					className='configuration-modal'
					display={this.state.showConfigurationModal}
					position='right'
					onModalSubmit={this.addDeviceConfiguration}
					onModalClose={() => this.toggleConfigurationModal()}
					isLoading={isFormLoading}>
					<Form title='Device Configurations' onSubmit={event => event.preventDefault()}>
						<If condition={!configurationFormData.isEdit}>
							<Then>
								<If condition={this.role === UserRoles.SUPERUSER}>
									<Then>
										<Input
											type='text'
											label='Select Health System'
											name='currentHealthSystemId'
											description='Select Health System so you can choose the hospital to apply the configurations.'
											placeholder='Select Health System'
											value={
												configurationFormData.selectedHealthSystemValue > -1
													? configurationFormData.healthSystems[configurationFormData.selectedHealthSystemValue].value
													: ''
											}
											validationOptions={{}}
											disabled={true}
										/>
									</Then>
									<Else>
										<SelectTag
											type='text'
											defaultValue={configurationFormData.selectedHealthSystemValue}
											label='Select Health System'
											name='currentHealthSystemId'
											onSelect={this.onFormHealthSystemSelect}
											items={configurationFormData.healthSystems}
											description='Select Health System so you can choose the hospital to apply the configurations.'
											placeholder='Select Health System'
										/>
									</Else>
								</If>
								<SelectTag
									type='text'
									defaultValue={configurationFormData.selectedHospitalValue}
									label='Choose Hospital'
									name='currentHospitalSystemId'
									onSelect={this.onFormHospitalSelect}
									items={configurationFormData.hospitals}
									description='Choose the hospital you want to apply changes to.'
									placeholder='Choose hospital'
									error={configurationFormData.errors.hospitalError}
								/>
							</Then>
							<Else>
								<Input
									type='text'
									label='Choose Hospital'
									description='Choose the hospital you want to apply changes to.'
									name='currentHospitalSystemId'
									placeholder='Choose hospital'
									value={configurationFormData.selectedHospital ? configurationFormData.selectedHospital.name : ''}
									validationOptions={{}}
									disabled={true}
								/>
							</Else>
						</If>
						<div className='input-el'>
							<label>Choose TV Setup</label>
							<p>Choose the type of TV setup.</p>
							<main>
								{configurationFormData.tvs.map(item => (
									<>
										<input
											type='radio'
											id={`tv${item.id}`}
											name='tv'
											value={item.value}
											onChange={this.onFormTVSelect}
											checked={configurationFormData.selectedTVValue === item.value}
										/>
										<label htmlFor={`tv${item.id}`}>{item.value}</label>
									</>
								))}
							</main>
							<small>{configurationFormData.errors.tvError}</small>
						</div>
						<div className='input-el'>
							<label>Select TV Kit 200 HDMI port</label>
							<p>Select the HDMI port where TV Kit 200 will be plugged into.</p>
							<main>
								{configurationFormData.hdmiPorts.map(item => (
									<>
										<input
											type='radio'
											id={`hdmi${item.id}`}
											name='hdmi'
											value={item.id}
											onChange={this.onFormHdmiPortSelect}
											checked={configurationFormData.selectedHdmiPortValue === item.id}
										/>
										<label htmlFor={`hdmi${item.id}`}>HDMI {item.id}</label>
									</>
								))}
							</main>
							<small>{configurationFormData.errors.hdmiPortError}</small>
						</div>
						<div className='input-el'>
							<label>TV Management</label>
							<p>Select default TV source. The TV will return to this source after the call ends.</p>
							<main>
								{configurationFormData.endCallSource.map(item => (
									<>
										<input
											type='radio'
											id={`endCallSource${item.id}`}
											name='endCallSource'
											value={item.id}
											onChange={this.onEndCallSourceSelect}
											checked={configurationFormData.selectedEndCallSource === item.id}
										/>
										<label htmlFor={`endCallSource${item.id}`}>{item.value}</label>
									</>
								))}
							</main>
							<small>{configurationFormData.errors.endCallSourceError}</small>
						</div>
						<Input
							type='text'
							label='Set ringtone volume'
							description='This setting will be applied to all the devices under this hospital.'
							name='ringtoneVolume'
							placeholder='Set ringtone volume'
							value={configurationFormData.ringtoneVolumeValue}
							onChange={this.onFormRingtoneVolumeChange}
							validationOptions={{ required: true, maxLength: 3 }}
							error={configurationFormData.errors.ringtoneVolumeError}
							bottomSpace='20px'
						/>
					</Form>
				</Modal>
				<Modal
					modalSelector='deleteTeamProfileModal'
					display={this.state.isDeleteConfigModalOpen}
					position='center'
					onModalSubmit={this.deleteConfig}
					onModalClose={() => this.setState({ isDeleteConfigModalOpen: false, deleteConfig: null })}>
					<form>
						<h3>Warning</h3>
						<p>Are you sure you want to delete this configuration ?</p>
					</form>
				</Modal>
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(DeviceConfig);

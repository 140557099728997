import React, { useEffect, useRef, useState } from 'react';

import { attachSinkId, getFilteredDeviceLists, getMediaDeviceUId } from 'calls/helpers/conference-utils';
import { MediaDeviceKinds } from 'constants/enums';

// Conference media settings common functions, modify here for TechCheck.js and MediaSettingsModal.js
const mediaSettingsFunctions = Component => {
	// eslint-disable-next-line react/display-name
	return props => {
		const { selectedMediaDevices, setSelectedMediaDevices } = props;
		const {
			[MediaDeviceKinds.AUDIO_INPUT]: activeMic,
			[MediaDeviceKinds.AUDIO_OUTPUT]: activeSpeaker,
			[MediaDeviceKinds.VIDEO_INPUT]: activeCamera,
		} = selectedMediaDevices;

		const audioRef = useRef(null);
		const [navigatorMediaDevices, setNavigatorMediaDevices] = useState({ micList: [], speakersList: [], cameraList: [] });
		const [isLoading, setIsLoading] = useState(true);
		const [audioIOFromSameDevice, setAudioIOFromSameDevice] = useState(true);

		const setSinkId = async device => {
			if (!audioRef.current) {
				return;
			}

			if (audioRef.current.currentTime > 0) {
				audioRef.current.pause();
				audioRef.current.currentTime = 0;
			}

			await attachSinkId(audioRef.current, await getMediaDeviceUId(device));
			audioRef.current.src = 'https://static.solaborate.com/global/brand/calls/call_ringing.mp3';
		};

		const handleSpeakersChange = async (device, init = false) => {
			await setSinkId(device);

			if (!init) {
				setSelectedMediaDevices({ [MediaDeviceKinds.AUDIO_OUTPUT]: device });
			}
		};

		const getMediaDevices = async () => {
			const filteredDeviceLists = await getFilteredDeviceLists();

			setNavigatorMediaDevices(filteredDeviceLists);
			setIsLoading(false);

			const selectedMic = activeMic || filteredDeviceLists.micList[0];
			if (selectedMic) {
				setSelectedMediaDevices({ [MediaDeviceKinds.AUDIO_INPUT]: selectedMic });
			}

			const selectedSpeaker = activeSpeaker || filteredDeviceLists.speakersList[0];
			if (selectedSpeaker) {
				handleSpeakersChange(selectedSpeaker, true);
			}
		};

		const getDefaultMic = () => {
			const { micList } = navigatorMediaDevices;
			if (!micList.length) {
				return -1;
			}

			if (!activeMic) {
				return 0;
			}

			return micList.findIndex(mic => mic.deviceId === activeMic.deviceId);
		};

		const getDefaultCam = () => {
			const { cameraList } = navigatorMediaDevices;
			if (!cameraList.length) {
				return -1;
			}

			if (!activeCamera) {
				return 0;
			}

			return cameraList.findIndex(camera => camera.deviceId === activeCamera.deviceId);
		};

		const getDefaultSpeakers = () => {
			const { speakersList } = navigatorMediaDevices;
			if (!speakersList.length) {
				return -1;
			}

			if (!activeSpeaker) {
				return 0;
			}

			return speakersList.findIndex(speaker => speaker.deviceId === activeSpeaker.deviceId);
		};

		useEffect(() => {
			getMediaDevices();
		}, []);

		useEffect(() => {
			const selectedAudioInputDevice = activeMic || navigatorMediaDevices.micList[getDefaultMic()];
			const selectedAudioOutputDevice = activeSpeaker || navigatorMediaDevices.speakersList[getDefaultSpeakers()];

			if (!selectedAudioInputDevice || !selectedAudioOutputDevice) {
				setAudioIOFromSameDevice(true);
				return;
			}

			setAudioIOFromSameDevice(
				selectedAudioInputDevice.groupId === selectedAudioOutputDevice.groupId ||
					(selectedAudioInputDevice.label && selectedAudioOutputDevice.label && selectedAudioInputDevice.label === selectedAudioOutputDevice.label)
			);
		}, [selectedMediaDevices, navigatorMediaDevices]);

		useEffect(() => {
			if (!activeSpeaker) {
				return;
			}

			setSinkId(activeSpeaker);
		}, [audioRef.current, activeSpeaker]);

		return (
			<Component
				{...props}
				{...{
					audioRef,
					isLoading,
					navigatorMediaDevices,
					audioIOFromSameDevice,
					handleSpeakersChange,
					getMediaDevices,
					getDefaultMic,
					getDefaultCam,
					getDefaultSpeakers,
				}}
			/>
		);
	};
};

export default mediaSettingsFunctions;

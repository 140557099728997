import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAiAlertActivities, getPatientAiAlerts } from 'api/alerts';
import { getDeviceOwner } from 'api/devices';
import { formattedDate } from 'infrastructure/helpers/dateHelper';
import { AiAlertTypes } from 'constants/enums';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import AlertHistoryModal from 'views/AlertHistory/AlertHistoryModal';
import Alert from 'components/Alert';
import { healthCareCdnUrl } from 'constants/global-variables';

const AlertsWrapper = styled.div`
	overflow: auto;
	max-height: calc(100vh - 260px);
	> div {
		padding: var(--spacing-m);
		border-bottom: 1px solid var(--gray-6);
		align-items: center;

		> div {
			height: 25px;
			width: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background: var(--gray-0);
			margin-right: var(--spacing-s);
			background: var(--gray-0);
		}

		img {
			width: 15px;
		}

		p {
			color: var(--gray-0);
			padding: 0;
			margin: 0;
			text-align: left;
			font-size: 14px;
			flex: 1;
		}

		span {
			color: var(--gray-3);
			padding: 0;
			margin: 0;
			text-align: left;
			font-size: 12px;
			margin-left: var(--spacing-s);
		}
	}
`;

const AiAlertsHistory = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [alertDetails, setAlertDetails] = useState(null);
	const [aiAlerts, setAiAlerts] = useState([]);
	const [error, setError] = useState('');
	const [isScrollLoading, setIsScrollLoading] = useState(true);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [pageIndexAiAlerts, setPageIndexAiAlerts] = useState(0);
	const [patientId, setPatientId] = useState(null);

	useEffect(() => {
		if (props.objectId) {
			setIsLoading(true);
			const fetchHelloDeviceOwner = async id => {
				const response = await getDeviceOwner(id);
				if (response.error) {
					setError(response.error.message);
				} else {
					setPatientId(response.id);
				}
				setIsLoading(false);
			};
			fetchHelloDeviceOwner(props.objectId);
		}
	}, [props.objectId]);

	const viewAlertDetails = async id => {
		const response = await getAiAlertActivities(id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAlertDetails(response.aiAlertActivities);
	};

	const imageUrl = `${healthCareCdnUrl}vsm/`;

	const alertTypes = [
		{
			id: 0,
			type: AiAlertTypes.RAIL_DOWN,
			icon: `${imageUrl}BedRails.svg`,
		},
		{
			id: 1,
			type: AiAlertTypes.PERSON_SITTING,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 2,
			type: AiAlertTypes.PERSON_STANDING,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 3,
			type: AiAlertTypes.PERSON_FACE_DOWN,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 4,
			type: AiAlertTypes.PERSON_LAYING_SIDE,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 5,
			type: AiAlertTypes.PERSON_LAYING_FRONT,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 6,
			type: AiAlertTypes.BABY_FACE_DOWN,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 7,
			type: AiAlertTypes.FALL_DETECTION,
			icon: `${imageUrl}FallDetection.svg`,
		},
		{
			id: 8,
			type: AiAlertTypes.PERSON_INACTIVE,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 9,
			type: AiAlertTypes.PERSON_GETTING_OUT_OF_BED,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 10,
			type: AiAlertTypes.PERSON_STANDING_WALKING,
			icon: `${imageUrl}PatientPosition.svg`,
		},
		{
			id: 11,
			type: AiAlertTypes.BABY_CRYING,
			icon: `${imageUrl}BabyCrying.svg`,
		},
	];

	const fetchAiAlerts = async () => {
		const params = {
			pageSize: 20,
			pageIndex: pageIndexAiAlerts,
			userId: patientId,
		};
		const response = await getPatientAiAlerts(params);
		if (response.error) {
			setError(response.error.message);
		} else if (response.aiAlerts.length === 0) {
			setIsScrollLoading(false);
			setIsLoading(false);
			return;
		} else {
			setAiAlerts(prevState => [...prevState, ...response.aiAlerts]);
			setPageIndexAiAlerts(prevState => prevState + 1);
			setHasReachedEnd(response.length < 20);
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	useEffect(() => {
		if (patientId) {
			fetchAiAlerts();
		}
	}, [patientId]);

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !isScrollLoading && !hasReachedEnd) {
			setIsScrollLoading(true);
			fetchAiAlerts();
		}
	};

	return (
		<>
			<AlertsWrapper onScroll={handleScroll}>
				{isLoading && <Loader />}
				{!isLoading && (
					<>
						{aiAlerts.length === 0 && (
							<div>
								<p>No AI alerts found.</p>
							</div>
						)}
						{aiAlerts.map(item => (
							<div className='flex cursor-pointer' onClick={() => viewAlertDetails(item.id)}>
								<div className='alert-history-modal-icon-wrapper'>
									<img src={alertTypes.find(el => el.type === item.aiAlertType.name)?.icon} alt='ico' />
								</div>
								<p>{item.aiAlertType.description}</p>
								<span>{formattedDate(item.dateCreated)}</span>
							</div>
						))}
					</>
				)}
			</AlertsWrapper>
			{alertDetails && (
				<Modal
					display={true}
					position='center'
					onModalSubmit={() => setAlertDetails(null)}
					onModalClose={() => setAlertDetails(null)}
					className='standard-modal-wrapper modal-wrapper-wo-btn z-10000'>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner view-request-history-modal'>
						<div className={`view-request-history-wrapper ${!alertDetails.length ? 'flex-align-center' : ''}`}>
							<div className='standard-modal-title alert-history full-width'>
								<h3>Alert Details</h3>
							</div>
							<AlertHistoryModal alertDetails={alertDetails} />
						</div>
					</div>
				</Modal>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default AiAlertsHistory;

import React from 'react';

const Help = () => (
	<div className='amwell-help'>
		<header>
			<img src='https://static.solaborate.com/americanwell/amwell_logo.svg' alt='AmWell logo' />
			<img src='https://static.solaborate.com/global/brand/assets/logos/sol-full-white.svg' alt='Solaborate logo' />
		</header>
		<main>
			<div>
				<img src='https://solprodmediastorage.blob.core.windows.net:443/static-assets/banyan/24-7-white.svg' alt='icon' />
				<h1>24/7 Support</h1>
				<p>If you're encountering any issues, please contact us on the live chat or via email at support@solaborate.com </p>
			</div>
		</main>
	</div>
);

export default Help;

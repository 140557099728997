/* eslint-disable import/prefer-default-export */
import { PatientAiSettingTypes } from 'constants/action-types';

const initialState = {
	aiSettings: [],
};

export const aiSettingsList = (state = initialState, action) => {
	switch (action.type) {
		case PatientAiSettingTypes.SET_PATIENT_AI_SETTINGS:
			return {
				...state,
				aiSettings: action.payload,
			};
		default:
			return state;
	}
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SocketContext } from 'io-client/SocketContext';
import { getUserProfile, setUserProfile } from 'infrastructure/auth';
import SocketEvents from 'constants/socket-events';
import { defaultDocumentTitle } from 'constants/global-variables';
import { ConferenceAction, ObjectType } from 'constants/enums';
import { Grid, Button, Avatar } from 'components';
import { incomingCallSound, stopIncomingCallSound, dropSound } from 'calls/scripts/call-sounds';

class IncomingCall extends Component {
	constructor(props, socket) {
		super(props, socket);
		this.state = {
			showSelf: false,
		};
		this.socket = socket;
		this.incomingConferenceInfo = null;
		this.incomingCallNotification = null;
		this.notificationPermission = {
			GRANTED: 'granted',
			DENIED: 'denied',
		};
	}

	componentDidMount() {
		this.addOnUnloadEvent();
		this.addSocketListeners();
	}

	componentWillUnmount() {
		this.removeSocketListeners();
	}

	componentDidUpdate() {
		if (this.state.showSelf) {
			document.title = `Patient is calling - ${defaultDocumentTitle}`;
		} else {
			document.title = `${this.props.notifications.notificationsCounter ? `(${this.props.notifications.notificationsCounter})` : ''} ${defaultDocumentTitle}`;
		}
	}

	startIncomingCallTimer = () => {
		this.incomingCallTimer = setTimeout(() => {
			if (this.incomingConferenceInfo) {
				this.socket.emit(SocketEvents.Conference.NOT_ANSWERING, {
					participantId: this.incomingConferenceInfo.participantId,
					conferenceId: this.incomingConferenceInfo.conferenceId,
				});
				this.sendMissedCallNotification();
			}
			this.hideIncomingCall();
		}, 10000);
	};

	addOnUnloadEvent = () => {
		window.addEventListener('beforeunload', () => {
			if (this.incomingConferenceInfo) {
				this.socket.emit(SocketEvents.Conference.DECLINE, {
					conferenceId: this.incomingConferenceInfo.conferenceId,
					participantId: this.incomingConferenceInfo.participantId,
				});
			}
			this.hideIncomingCall();
		});
	};

	onAnsweredElsewhere = () => {
		this.hideIncomingCall();
	};

	onInitiatorLeft = async () => {
		this.hideIncomingCall();
		await dropSound();
	};

	onIncomingCall = async conferenceInfo => {
		await incomingCallSound();
		this.incomingConferenceInfo = conferenceInfo;
		this.setState({ showSelf: true }, () => {
			this.startIncomingCallTimer();
			this.incomingCallNotification = this.sendIncomingCallNotification();
		});
	};

	addSocketListeners = () => {
		if (!this.socket) return;

		this.socket
			.on(SocketEvents.Conference.ON_ANSWERED_ELSEWHERE, this.onAnsweredElsewhere)
			.on(SocketEvents.Conference.ON_INITIATOR_LEFT, this.onInitiatorLeft)
			.on(SocketEvents.Conference.ON_INCOMING, this.onIncomingCall);
	};

	removeSocketListeners = () => {
		if (!this.socket) return;

		this.socket
			.off(SocketEvents.Conference.ON_ANSWERED_ELSEWHERE, this.onAnsweredElsewhere)
			.off(SocketEvents.Conference.ON_INITIATOR_LEFT, this.onInitiatorLeft)
			.off(SocketEvents.Conference.ON_INCOMING, this.onIncomingCall);
	};

	declineIncomingCall = () => {
		if (this.incomingConferenceInfo) {
			this.socket.emit(SocketEvents.Conference.DECLINE, {
				conferenceId: this.incomingConferenceInfo.conferenceId,
				participantId: this.incomingConferenceInfo.participantId,
			});
		}
		this.hideIncomingCall();
	};

	acceptIncomingCall = async () => {
		const { incomingConferenceInfo } = this;
		const { objectType } = incomingConferenceInfo.from;

		// BUG on queueing
		Object.assign(incomingConferenceInfo, { joinAsGuest: objectType === ObjectType.USER });

		// TODO remove this -> add shouldReceiveIncomingCall on app.js
		let userInfo = getUserProfile();
		userInfo = { ...userInfo, incomingCallsDisabled: true };
		setUserProfile(userInfo);

		this.props.history.push('/talk-to-patient', { conferenceAction: ConferenceAction.JOIN, incomingConferenceInfo });
		this.hideIncomingCall();

		const notification = await this.incomingCallNotification;
		if (notification) {
			notification.close();
		}
	};

	getDeviceName = () => {
		return this.incomingConferenceInfo.from.name.replace(/[,]/g, ' >').replace(/[-]/g, '>');
	};

	sendIncomingCallNotification = () => {
		return this.sendNotification({
			title: 'Incoming call',
			body: this.getDeviceName(),
			tag: `incoming-${this.incomingConferenceInfo.conferenceId}`,
			renotify: true,
		});
	};

	sendMissedCallNotification = () => {
		this.sendNotification(
			{
				title: 'Missed call',
				body: this.getDeviceName(),
				tag: `missed-${this.incomingConferenceInfo.conferenceId}`,
				renotify: true,
			},
			() => {
				//Its only a temporary solution since solution with redux requires some refactoring
				document.querySelector('#notifications > a').click();
			}
		);
	};

	sendNotification = async (options, onClickCallback) => {
		let newNotification;
		if (Notification.permission !== this.notificationPermission.GRANTED) {
			await Notification.requestPermission();
		}
		if (Notification.permission === this.notificationPermission.GRANTED) {
			newNotification = new Notification(options.title, {
				body: options.body,
				tag: options.tag,
				renotify: options.renotify,
			});
			newNotification.onclick = function() {
				window.focus();
				onClickCallback();
			};
		}
		return newNotification;
	};

	clearIncomingCallTimer = () => {
		if (this.incomingCallTimer) {
			clearTimeout(this.incomingCallTimer);
			this.incomingCallTimer = null;
		}
	};

	hideIncomingCall = () => {
		if (this.incomingConferenceInfo) {
			this.incomingConferenceInfo = null;
			stopIncomingCallSound();
			this.clearIncomingCallTimer();
			this.setState({ showSelf: false });
		}
	};

	render() {
		const { showSelf } = this.state;
		return (
			<div style={{ textAlign: 'center' }}>
				{showSelf && (
					<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
						<div>
							<Avatar src={this.props.src} size='large' fullName='' pulseAnimation='incoming-call-img' />
							<p className='incoming-call-text'>{this.incomingConferenceInfo.from.name}</p>
							<Button onClick={this.declineIncomingCall} icon='call_end' background='red' borderRadius='30px' marginRight='15px' />
							<Button onClick={this.acceptIncomingCall} icon='call' background='#22cb36' borderRadius='30px' />
						</div>
					</Grid>
				)}
			</div>
		);
	}
}

IncomingCall.contextType = SocketContext;

export default connect(state => state)(IncomingCall);

/**
 * Enums for Conference event to make UI changes
 * @enum {String}
 * @readonly
 */
const ConferenceEvent = {
	ON_NEW_PARTICIPANT: 'ON_NEW_PARTICIPANT',
	ON_NEW_PARTICIPANT_STATE: 'ON_NEW_PARTICIPANT_STATE',
	ON_CHANGED_STATE: 'ON_CHANGED_STATE',
	ON_ENDED: 'ON_ENDED',
	ON_REMOVED: 'ON_REMOVED',
	ON_TRACK_TOGGLE: 'ON_TRACK_TOGGLE',
	ON_CONNECTION_STATE_CHANGED: 'ON_CONNECTION_STATE_CHANGED',
};

export default ConferenceEvent;

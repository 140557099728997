import { gatewayApi } from 'constants/global-variables';

export const getPatientAiAlerts = async ({ userId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/patients/${userId}/ai?pageIndex=${pageIndex}&pageSize=${pageSize}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientManualAlerts = async ({ patientId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/patients/${patientId}/manual?pageIndex=${pageIndex}&pageSize=${pageSize}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAiAlertActivities = async aiAlertId => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/ai/${aiAlertId}/activities`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getManualAlertActivities = async manualAlertId => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/manual/${manualAlertId}/activities`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addAlertActivity = async ({ alertId, activityType, isAiAlert, comment = null }) => {
	try {
		const type = isAiAlert ? 'ai' : 'measurement';
		const dataToSend = isAiAlert ? { aiAlertActivityType: activityType, comment } : { measurementAlertActivityType: activityType, comment };
		const { data } = await gatewayApi.post(`/v1/alerts/${type}/${alertId}/activities`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveManualAlert = async sendData => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/manual`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};

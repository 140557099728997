const WindowMessages = {
	Window: {
		CLOSE: 'window.close',
		MAXIMIZE: 'window.maximize',
		MINIMIZE: 'window.minimize',
	},
	Call: {
		STARTED: 'call.started',
		ENDED: 'call.ended',
	},
};

export default WindowMessages;

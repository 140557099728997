import { TrackType } from '@solaborate/webrtc/lib/Tracks';

const { RemoteTrackController } = require('@solaborate/webrtc/lib/TrackController');
const { TrackAdded, TrackRemoved } = require('@solaborate/webrtc/lib/Connection');
const { default: SocketEvents } = require('constants/socket-events');

export default class RemoteTrackControllerImpl extends RemoteTrackController {
	constructor(negotiator, sfu, socket, conferenceId, localParticipantId, participantId) {
		super(negotiator);
		this.sfu = sfu;
		this.socket = socket;
		this.conferenceId = conferenceId;
		this.localParticipantId = localParticipantId;
		this.participantId = participantId;
	}

	enabled(trackType) {
		const track = this.tracks.get(trackType);
		return track && track.track.enabled;
	}

	enable(trackType) {
		const track = this.tracks.get(trackType);
		if (!this.sfu) {
			if (track) {
				track.track.enabled = true;
				this.fire(new TrackAdded(trackType, track.mid));
			}
		} else {
			this.socket.emit(SocketEvents.MediaServer.INCOMING_SEND_CONFIG, {
				conferenceId: this.conferenceId,
				subscriberParticipantId: this.localParticipantId,
				config: {
					[this.participantId]: {
						[trackType]: {
							removed: false,
						},
					},
				},
			});
		}
	}

	disable(trackType) {
		const track = this.tracks.get(trackType);
		if (!this.sfu) {
			if (track) {
				track.track.enabled = false;
				this.fire(new TrackRemoved(trackType));
			}
		} else {
			this.socket.emit(SocketEvents.MediaServer.INCOMING_SEND_CONFIG, {
				conferenceId: this.conferenceId,
				subscriberParticipantId: this.localParticipantId,
				config: {
					[this.participantId]: {
						[trackType]: {
							removed: true,
						},
					},
				},
			});
		}
	}

	/**
	 * Method used to request remote participan track toggle
	 * @param {TrackType} trackType
	 */
	toggle(trackType) {
		const exists = !!this.tracks.get(trackType);
		return this.socket.emitWithPromise(SocketEvents.Conference.TRACK_PARTICIPANT_TOGGLE, {
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			actioneeParticipantId: this.participantId,
			type: trackType === TrackType.AUDIO ? 1 : 2,
			muted: exists,
		});
	}
}

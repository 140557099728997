import React from 'react';
import CallNewRefactored from 'calls/views/CallNewRefactored';
import { getUserRole, signinRedirect, isAuthenticated } from 'infrastructure/auth';
import { UserRoles } from 'constants/enums';
import { Redirect } from 'react-router-dom';

const conferenceRouteRender = props => {
	if (!isAuthenticated()) {
		if (getUserRole() === UserRoles.GUEST) {
			const link = props.history.location.state?.conferenceLink;
			return <Redirect to={link.replace(/^.*\/\/[^/]+/, '')} />;
		}

		return signinRedirect();
	}

	return <CallNewRefactored {...props} />;
};

export default conferenceRouteRender;

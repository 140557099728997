import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';

import Select from 'components/Common/FormElements/Select';
import Alert from 'components/Alert';
import { getPoolRolesByHealthSystemId, addPool } from 'api/nursePooling';
import MultiSelect from 'components/Common/FormElements/MultiSelect';

class AddPool extends Component {
	constructor(props) {
		super(props);

		this.state = {
			poolRoles: [],
		};
	}

	async componentDidMount() {
		this.getPoolRolesByHealthSystemId(this.props.selectedHealthSystem.value);
	}

	getHealthSystems(healthSystems) {
		return healthSystems.map(hs => {
			return { id: hs.value, value: hs.label };
		});
	}

	onHealthSystemSelected = healthSystemId => {
		this.getPoolRolesByHealthSystemId(healthSystemId);
	};

	async getPoolRolesByHealthSystemId(healthSystemId) {
		const response = await getPoolRolesByHealthSystemId(healthSystemId);
		const poolRoles = response.map(role => {
			return { value: role.id, label: role.name };
		});
		this.setState({
			poolRoles: poolRoles,
		});
	}

	async componentDidUpdate(prevProps) {
		if (this.props.selectedHealthSystem.value !== prevProps.selectedHealthSystem.value) {
			this.getPoolRolesByHealthSystemId(this.props.selectedHealthSystem.value);
		}
	}

	render() {
		return (
			<div>
				<Formik
					enableReinitialize={true}
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={{
						healthSystem: this.props.selectedHealthSystem.value,
						name: '',
						poolRoles: [],
					}}
					onSubmit={(values, { resetForm }) => {
						this.props.onLoading('addPool');
						const poolRoles = values.poolRoles.map(role => {
							return role.value;
						});
						addPool(values.healthSystem, values.name, poolRoles).then(response => {
							this.props.onSuccess(response, values.healthSystem, values.poolRoles);
							resetForm({
								healthSystem: '',
								name: '',
								poolRoles: [],
							});
						});
					}}
					validationSchema={Yup.object().shape({
						healthSystem: Yup.string().required('Health system is required'),
						name: Yup.string()
							.required('Pool name is required')
							.min(3)
							.max(50),
						poolRoles: Yup.string()
							.required('Pool roles are required')
							.nullable(),
					})}>
					{formikProps => {
						const { values, touched, errors, setFieldValue, setFieldTouched } = formikProps;
						// bind the submission handler remotely
						this.props.bindSubmitForm(formikProps.submitForm);
						return (
							<Form>
								<h3>Add Pool</h3>
								<Field
									name='healthSystem'
									type='select'
									label='Enter Health System'
									placeholder='Select Health System'
									description='Please select at least one health system in order to add a pool'
									items={this.getHealthSystems(this.props.healthSystems)}
									onChange={this.onHealthSystemSelected}
									component={Select}
								/>
								<MultiSelect
									name='poolRoles'
									label='Pool Roles'
									placeholder='Select Roles'
									description='Please select at least one role for this pool'
									options={this.state.poolRoles}
									value={values.poolRoles}
									isMulti={true}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									touched={touched.poolRoles}
									error={errors.poolRoles}
									isClearable={true}
									backspaceRemovesValue={true}
								/>
								<Field name='name' type='text' label='Enter Name' placeholder='Name' description='Type the name for this pool' component={Input} />
								<Alert
									display={this.props.loaded}
									message='The pool role was successfully added to this health system !'
									variant='success'
									onClose={() => this.props.onCloseAlert('addPool')}
								/>
								<Alert
									display={this.props.error}
									message='Something went wrong! Please try again.'
									variant='error'
									onClose={() => this.props.onCloseAlert('addPool')}
								/>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default AddPool;

import React, { Component } from 'react';
import { Form, Modal, Input, Tabs, Alert } from 'components';
import { getUserProfile, getUserRole, setUserProfile } from 'infrastructure/auth';
import { changePassword } from 'api/user';
import { ChangePasswordUseCases, HttpStatusCodes, UserRoles } from 'constants/enums';
import { updateProfilePicture } from 'api/users';
import { uploadProfilePic } from 'api/media';
import ReactCrop from 'react-image-crop';
import { passwordRegEx } from 'constants/global-variables';
import ProfileInformation from 'components/UserSettings/ProfileInformation';

class UserSettingsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userProfile: {},
			virtualRoles: [{ value: 'Virtual Nurse' }, { value: 'Virtual Case Manager' }, { value: 'Virtual Sitter' }, { value: 'Virtual Intensivist' }],
			currentPassword: '',
			newPassword: '',
			newConfirmedPassword: '',
			passwordInfo: ChangePasswordUseCases.passwordInfo,
			passwordAlertVariant: 'neutral',
			isConfirmPasswordChangeModalOpen: false,
			selectedTab: 0,
			error: null,
			previewImage: null,
			profilePicture: '',
			crop: {
				width: 200,
				height: 200,
				aspect: 1,
			},
			isImageCropperVisible: false,
			uploadedImg: null,
		};

		this.COMPANY_INFO = {
			STARTED: 'Mon Mar 12 2018 00:00:00',
			NAME: 'AmWell',
		};

		this.TABS = {
			CHANGE_PASSWORD: 0,
			EDIT_PROFILE: 1,
		};
	}

	imageRef = null;

	componentDidMount = () => {
		const locations = this.getUserLocations();
		const userProfile = getUserProfile();
		const virtualRoleKey = this.state.virtualRoles.findIndex(r => r.value === userProfile.jobTitle);
		this.setState({
			userProfile: {
				fullName: `${userProfile.firstName} ${userProfile.lastName}`,
				profilePicUrl: userProfile.profilePicture?.url,
				virtualRole: userProfile.jobTitle,
				virtualRoleKey: virtualRoleKey,
				userWorkExperienceId: userProfile.jobId,
				locations: locations,
			},
		});
	};

	getUserLocations() {
		let locations = [];
		this.props.organization.allHealthSystems.forEach(healthSystem => locations.push({ description: healthSystem.name }));
		return locations;
	}

	saveAccountSettings = () => {
		if (this.state.selectedTab === this.TABS.CHANGE_PASSWORD) {
			this.changePassword();
		}
	};

	editProfile = async () => {
		const userProfile = getUserProfile();
		const { virtualRole, userWorkExperienceId } = this.state.userProfile;
		const data = {
			companyName: this.COMPANY_INFO.NAME,
			fromDate: this.COMPANY_INFO.STARTED,
			jobDescription: virtualRole,
			jobTitle: virtualRole,
			isCurrent: true,
			showCompanyOnPage: true,
			toDate: null,
		};

		let response;
		let userHasRole = userWorkExperienceId !== 0;
		if (userHasRole) {
			response = await this.props.mainLayoutActions.updateUserRole(data);
		} else {
			response = await this.props.mainLayoutActions.addNewUserRole({
				...data,
				idUserCompanyId: userWorkExperienceId,
			});
		}

		setUserProfile({
			...userProfile,
			jobTitle: response.data.entityData.JobTitle,
			jobId: userHasRole ? response.data.entityData.IdUserCompanies : null,
		});
		this.props.toggleAccountSettingsModal();
	};

	changeVirtualRole = (selection, index) => {
		let user = this.state.userProfile;
		this.setState({
			userProfile: {
				...user,
				virtualRole: selection.value,
				virtualRoleKey: index,
			},
		});
	};

	validatePassword = () => {
		let { currentPassword, newPassword, newConfirmedPassword } = this.state;
		let message;
		if (currentPassword === '') {
			message = ChangePasswordUseCases.currentPassEmpty;
		} else if (newPassword === '') {
			message = ChangePasswordUseCases.newPassEmpty;
		} else if (newPassword.length < 8) {
			message = ChangePasswordUseCases.minLengthRequired;
		} else if (newConfirmedPassword === '') {
			message = ChangePasswordUseCases.confirmNewPassEmpty;
		} else if (newPassword !== newConfirmedPassword) {
			message = ChangePasswordUseCases.newPasswordsDoNotMatch;
		} else if (currentPassword === newPassword) {
			message = ChangePasswordUseCases.newPassSameAsCurrent;
		} else if (passwordRegEx.test(newPassword) === false || passwordRegEx.test(newConfirmedPassword) === false) {
			message = ChangePasswordUseCases.strongPasswordCheck;
		}
		return message;
	};

	resetPasswordFields = () => {
		this.setState({
			passwordData: {
				...this.state.passwordData,
				newPassword: '',
				newConfirmedPassword: '',
			},
		});
	};

	changePassword = () => {
		let message = this.validatePassword();
		if (message) {
			if (message === ChangePasswordUseCases.newPasswordsDoNotMatch) {
				this.resetPasswordFields();
			}
			this.setPasswordErrorMessage(message);
		} else {
			this.toggleConfirmPasswordDialog();
		}
	};

	savePasswordChanges = async () => {
		try {
			const response = await changePassword({
				oldpassword: this.state.currentPassword,
				newpassword: this.state.newPassword,
			});
			if (response.HasSucceed) {
				this.setState(
					{
						passwordInfo: ChangePasswordUseCases.newPassSaved,
					},
					() => {
						this.signOut();
					}
				);
			}
		} catch (error) {
			this.setState({
				passwordInfo: ChangePasswordUseCases.currentPasswordIncorrect,
				passwordAlertVariant: 'error',
				currentPassword: '',
				newPassword: '',
				newConfirmedPassword: '',
				isConfirmPasswordChangeModalOpen: false,
			});
		}
	};

	setPasswordErrorMessage = msg => {
		this.setState({
			passwordInfo: msg,
			passwordAlertVariant: 'neutral',
		});
	};

	toggleConfirmPasswordDialog = () => {
		this.setState({
			isConfirmPasswordChangeModalOpen: !this.state.isConfirmPasswordChangeModalOpen,
		});
	};

	setInputValue = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	signOut = () => {
		this.props.history.push('/logout');
	};

	onImageUpload = async uploadedFile => {
		const file = uploadedFile[0];
		if (!file) {
			return;
		}

		const sizeInMB = file.size / 1024 / 1024;
		if (sizeInMB > 2) {
			this.setState({ error: 'Try uploading a file under 2 MB' });
			return;
		}

		const reader = new FileReader();
		reader.addEventListener('load', () => this.setState({ src: reader.result }));
		reader.readAsDataURL(file);

		this.setState({
			isImageCropperVisible: true,
			file,
		});
	};

	updateProfilePicture = async () => {
		const user = await getUserProfile();
		await updateProfilePicture(user.userId, this.state.profilePicture);
		const userProfile = getUserProfile();

		setUserProfile({
			...userProfile,
			profilePicture: { url: this.state.previewImage },
		});
		this.props.updateProfilePicture(this.state.previewImage);
	};

	onCropChange = crop => {
		this.setState({ crop });
	};

	toggleImageCropper = () => {
		this.setState({
			isImageCropperVisible: !this.state.isImageCropperVisible,
			crop: {
				width: 200,
				height: 200,
				aspect: 1,
			},
		});
	};

	onImageLoaded = image => {
		this.imageRef = image;
	};

	getImageCrop = () => {
		const { crop } = this.state;

		const image = this.imageRef;
		const xScale = image.naturalWidth > image.width ? image.naturalWidth / image.width : 1;
		const yScale = image.naturalHeight > image.height ? image.naturalHeight / image.height : 1;

		return {
			width: crop.width * xScale,
			height: crop.height * yScale,
			x: crop.x * xScale,
			y: crop.y * yScale,
		};
	};

	uploadLogo = async () => {
		const response = await uploadProfilePic(this.state.file, this.getImageCrop());

		if (response.status === HttpStatusCodes.OK) {
			this.setState({
				previewImage: response.data.thumbnails[2].url,
				profilePicture: response.data.fileName,
				isImageCropperVisible: false,
			});
		}
	};

	makeClientCrop = async () => {
		if (this.imageRef && this.state.crop.width && this.state.crop.height) {
			await this.uploadLogo();
		}
	};

	componentWillUnmount = () => {
		if (this.state.uploadedImg) {
			URL.revokeObjectURL(this.state.uploadedImg.preview);
		}
	};

	render() {
		return (
			<>
				<Modal
					display={this.props.isAccountSettingsModalOpen}
					modalSelector='accountSettingsModal'
					isLoading={this.state.userProfileLoading}
					position='right'
					onModalSubmit={this.saveAccountSettings}
					onModalClose={this.props.toggleAccountSettingsModal}>
					<Tabs
						links={[{ link: 'Change Password', active: true }, getUserRole() === UserRoles.PATIENT && { link: 'Profile Information' }]}
						onTabClick={index => this.setState({ selectedTab: index })}
						components={[
							<Form id='Change Password' onSubmit={event => event.preventDefault()}>
								<Alert
									display={true}
									hideCloseButton={true}
									message={this.state.passwordInfo}
									variant={this.state.passwordAlertVariant}
									onClose={this.props.onCloseAlert}
								/>
								<Input
									type='password'
									label='Current password'
									name='currentPassword'
									placeholder='Enter your current password'
									value={this.state.currentPassword}
									onChange={this.setInputValue}
									validationOptions={{ required: true, minLength: 6 }}
									bottomSpace='20px'
									width='100px'
								/>
								<Input
									type='password'
									label='New password'
									name='newPassword'
									placeholder='Type your new password'
									value={this.state.newPassword}
									onChange={this.setInputValue}
									validationOptions={{ required: true, minLength: 6 }}
									bottomSpace='20px'
									width='100px'
								/>
								<Input
									type='password'
									label='Confirm new password'
									name='newConfirmedPassword'
									placeholder='Retype your new password'
									value={this.state.newConfirmedPassword}
									onChange={this.setInputValue}
									validationOptions={{ required: true, minLength: 6 }}
									bottomSpace='20px'
									width='100px'
								/>
							</Form>,
							<ProfileInformation toggleModal={this.props.toggleAccountSettingsModal} />,
						]}
					/>
				</Modal>
				<Modal
					display={this.state.isConfirmPasswordChangeModalOpen}
					position='center'
					onModalSubmit={this.savePasswordChanges}
					onModalClose={this.toggleConfirmPasswordDialog}
					closeButtonText='Cancel'
					submitButtonText='Continue'>
					<Form title='Confirm action'>
						<p>Are you sure you want to change your password? You will be logged out of your account and must login with your new credentials.</p>
					</Form>
				</Modal>
				<Modal
					modalSelector='uploadHospitalLogo'
					className='crop-modal'
					display={this.state.isImageCropperVisible}
					position='center'
					onModalSubmit={this.makeClientCrop}
					onModalClose={this.toggleImageCropper}
					submitButtonText='Upload'>
					<ReactCrop
						src={this.state.src}
						crop={this.state.crop}
						ruleOfThirds={true}
						circularCrop={true}
						onImageLoaded={this.onImageLoaded}
						onChange={this.onCropChange}
					/>
				</Modal>
			</>
		);
	}
}

export default UserSettingsModal;

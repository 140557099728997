import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { Grid, Button, Loader } from 'components';
import StreamPermissions from 'calls/components/calls/StreamPermissions';
import { ConferenceAction, StreamError, UserRoles, UserTypes } from 'constants/enums';
import { checkActiveConference } from 'api/conference';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import Input from 'components/Common/FormElements/Input';
import { addGuestAccount } from 'api/users';
import { withRouter } from 'react-router-dom';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import classNames from 'classnames/bind';
import { getUserRoleId, getUserRole, isAuthenticated, getUserProfile, unbindUserManagerEvents } from 'infrastructure/auth';
import { SocketContext } from 'io-client/SocketContext';
import { amwellIconLink } from 'constants/global-variables';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

class JoinAsGuest extends Component {
	state = {
		showStreamErrorMessage: false,
		loadingConference: true,
		activeConference: false,
		hasVideoStream: false,
		audioStream: null,
		joiningConference: false,
	};

	socket = this.context;

	videoRef = React.createRef();

	componentDidMount = async () => {
		if (getStorage().getItem('userRole') === UserRoles.GUEST) {
			getStorage().clear();
		}

		const { conferenceId, invitationSecret } = this.props.match.params;
		const activeConf = await checkActiveConference(conferenceId, invitationSecret);
		if (activeConf.data.exists) {
			this.setState({
				conference: activeConf.data.conference,
				conferenceId,
				invitationSecret,
				loadingConference: false,
				activeConference: true,
			});
			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

				this.videoRef.current.srcObject = stream;

				this.setState({
					hasVideoStream: true,
					audioStream: stream,
				});
			} catch (error) {
				this.setState({ showStreamErrorMessage: true });
				console.error(error);
			}
		} else {
			this.setState({
				loadingConference: false,
				activeConference: false,
			});
		}
	};

	joinConference = joinAsGuest => {
		const localTrackTypes = [];
		if (this.state.audioStream) {
			this.state.audioStream.getAudioTracks().forEach(track => {
				track.stop();
			});
			localTrackTypes.push(TrackType.AUDIO);
		}
		if (this.videoRef.current && this.videoRef.current.srcObject) {
			this.videoRef.current.srcObject.getVideoTracks().forEach(track => {
				track.stop();
			});
			localTrackTypes.push(TrackType.VIDEO);
		}

		const conf = {
			...this.state.conference,
			participantId: v4(),
			callType: this.state.conference.initialCallType,
			conferenceId: this.state.conference.id,
			conferenceName: this.state.conference.name,
			conferenceLink: this.state.conference.link,
			joinAsGuest,
			localTrackTypes,
		};

		this.props.history.replace('/talk-to-patient', { conferenceAction: ConferenceAction.JOIN, incomingConferenceInfo: conf });
	};

	toggleMic = async () => {
		let { audioStream } = this.state;

		if (!audioStream) {
			audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
		} else {
			audioStream.getAudioTracks().forEach(track => {
				track.stop();
			});
			audioStream = null;
		}

		this.setState({ audioStream });
	};

	toggleCamera = async () => {
		if (!this.videoRef.current.srcObject) {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			this.videoRef.current.srcObject = stream;
		} else {
			this.videoRef.current.srcObject.getVideoTracks().forEach(track => {
				track.stop();
			});
			this.videoRef.current.srcObject = null;
		}

		this.setState({ hasVideoStream: !!this.videoRef.current.srcObject });
	};

	setJoiningConference = value => {
		return new Promise(res => {
			this.setState({ joiningConference: value }, () => res());
		});
	};

	submitForm = async values => {
		const { conferenceId, invitationSecret } = this.props.match.params;

		const activeConf = await checkActiveConference(conferenceId, invitationSecret);
		if (!activeConf?.data?.exists) {
			this.setState({
				loadingConference: false,
				activeConference: false,
			});
			return;
		}

		if (this.state.joiningConference) {
			return;
		}

		await this.setJoiningConference(true);

		try {
			const user = await addGuestAccount(values.firstName, this.state.conferenceId, this.state.invitationSecret);
			if (user) {
				if (getUserRoleId() === UserTypes.GUEST) {
					unbindUserManagerEvents();
				}

				await this.socket.doConnect();
				await this.socket.awaitAuthorization;

				this.joinConference(true);
			}
		} catch {
			this.setState({ joiningConference: false });
		}
	};

	displayUserProfileBox = () => {
		const userProfile = getUserProfile();
		return (
			<>
				<p>
					You're logged in as {userProfile.firstName} {userProfile.lastName}.
				</p>
				<Button onClick={() => this.joinConference(false)} text='Join now' size='large' />
			</>
		);
	};

	render() {
		if (this.state.loadingConference) {
			return (
				<Grid className='conference-loading' rows='auto' horizAlign='center' vertAlign='center' columns='1fr' stretch='100vh'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
						<p>Loading conference</p>
					</div>
				</Grid>
			);
		}
		if (!this.state.loadingConference && !this.state.activeConference) {
			return (
				<Grid className='conference-not-active' rows='auto' horizAlign='center' vertAlign='center' columns='1fr' stretch='100vh'>
					<h4>Conference is not active!</h4>
				</Grid>
			);
		}

		const { hasVideoStream, audioStream } = this.state;

		return (
			<>
				<Grid className='guest-join' rows='auto' horizAlign='center' vertAlign='center' columns='1fr' stretch='100vh'>
					<div>
						<main>
							<video ref={this.videoRef} autoPlay muted playsInline />
							<div className='guest-joining-mobile' />
							<footer>
								<button
									type='button'
									className={classNames('call-button', audioStream ? 'active' : '')}
									data-tooltip={audioStream ? 'Turn off mic' : 'Turn on mic'}
									data-position='top'
									onClick={this.toggleMic}>
									<i className='material-icons'>{audioStream ? 'mic_on' : 'mic_off'}</i>
								</button>
								<button
									type='button'
									className={classNames('call-button', hasVideoStream ? 'active' : '')}
									data-tooltip={hasVideoStream ? 'Turn off camera' : 'Turn on camera'}
									data-position='top'
									onClick={this.toggleCamera}>
									<i className='material-icons'>{hasVideoStream ? 'videocam' : 'videocam_off'}</i>
								</button>
							</footer>
						</main>
						<div>
							{isAuthenticated() && getUserRole() !== UserRoles.GUEST ? (
								this.displayUserProfileBox()
							) : (
								<>
									<p>
										You are about to join an AmWell session.
										<br />
										Please introduce yourself.
									</p>
									<footer>
										<Formik
											enableReinitialize={true}
											initialValues={{
												firstName: '',
											}}
											onSubmit={values => this.submitForm(values)}
											validationSchema={Yup.object().shape({
												firstName: Yup.string()
													.required('First name is required')
													.max(50, 'Name must be at most 50 characters'),
											})}
											render={() => (
												<Form>
													<Field name='firstName' autoComplete='off' type='text' placeholder='Your name' variant='filled' component={Input} />
													<Button text={!this.state.joiningConference && 'Ask to join'} size='large' type='submit' isLoading={this.state.joiningConference} />
												</Form>
											)}
										/>
									</footer>
								</>
							)}
							<footer className='cam-mic-btn'>
								<button
									type='button'
									className={classNames('call-button', audioStream ? 'active' : '')}
									data-tooltip={audioStream ? 'Turn off mic' : 'Turn on mic'}
									data-position='top'
									onClick={this.toggleMic}>
									<i className='material-icons'>{audioStream ? 'mic_on' : 'mic_off'}</i>
								</button>
								<button
									type='button'
									className={classNames('call-button', hasVideoStream ? 'active' : '')}
									data-tooltip={hasVideoStream ? 'Turn off camera' : 'Turn on camera'}
									data-position='top'
									onClick={this.toggleCamera}>
									<i className='material-icons'>{hasVideoStream ? 'videocam' : 'videocam_off'}</i>
								</button>
							</footer>
						</div>
					</div>
					{this.state.showStreamErrorMessage && <StreamPermissions reason={StreamError.PERMISSION_DISMISSED} />}
				</Grid>
				{this.state.joiningConference && (
					<div className='loader__container call-initiating-loader'>
						<div className='center-loader'>
							<img src={`${amwellIconLink}camera-loading.svg`} alt='cam-icon' />
							<Loader />
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinAsGuest));
JoinAsGuest.contextType = SocketContext;

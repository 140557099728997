/* eslint max-classes-per-file: ["error", 9] */

class Base {
	/**
	 *
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 */
	constructor(data) {
		this.conferenceId = data.conferenceId;
		this.participantId = data.participantId;
	}
}

export class ParticipantBusy extends Base {
	/**
	 *
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Number} data.objectId
	 * @param {Number} data.objectType
	 * @param {Boolean} data.conferenceEnded
	 * @param {Object} data.activeConferences
	 */
	constructor(data) {
		super(data);
		this.objectId = data.objectId;
		this.objectType = data.objectType;
		this.conferenceEnded = data.conferenceEnded;
		this.activeConferences = data.activeConferences;
	}
}

export class ParticipantDeclined extends Base {
	/**
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Boolean} data.conferenceEnded
	 */
	constructor(data) {
		super(data);
		this.conferenceEnded = data.conferenceEnded;
	}
}

export class ParticipantOffline extends Base {
	/**
	 *
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Number} data.objectId
	 * @param {Number} [data.helloDeviceId]
	 * @param {Number} data.objectType
	 * @param {Boolean} data.conferenceEnded
	 */
	constructor(data) {
		super(data);
		this.objectId = data.objectId || data.helloDeviceId;
		this.objectType = data.objectType;
		this.conferenceEnded = data.conferenceEnded;
	}
}

export class ParticipantNotAnswering extends Base {
	/**
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Boolean} data.conferenceEnded
	 */
	constructor(data) {
		super(data);
		this.conferenceEnded = data.conferenceEnded;
	}
}

export class ParticipantRemoved extends Base {
	/**
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Boolean} data.actioneeParticipantId
	 */
	constructor(data) {
		super(data);
		this.actioneeParticipantId = data.actioneeParticipantId;
	}
}

export class ParticipantLeft extends Base {
	/**
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Boolean} data.conferenceEnded
	 */
	constructor(data) {
		super(data);
		this.conferenceEnded = data.conferenceEnded;
	}
}

export class ParticipantConnecting extends Base {
	/**
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 */
	constructor(data) {
		super(data);
	}
}

export class ParticipantConnected extends Base {
	/**
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 */
	constructor(data) {
		super(data);
	}
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CameraTiltDirection, CameraType, CameraZoomLevels, CameraTiltAction, CameraCommands, CallTypes } from 'constants/enums';
import CameraBookmarks from 'calls/components/CameraBookmarks';
import classNames from 'classnames/bind';
import { isMobileOrTabletDevice } from 'calls/helpers/conference-utils';

const Tilt = props => {
	const [previousTiltAction, setPreviousTiltAction] = useState(null);

	const sendTiltEvent = (direction, action) => {
		if (
			props.disabled ||
			(props.cameraType === CameraType.HELLO && props.disabledDirections[direction]) ||
			(previousTiltAction === CameraTiltAction.STOP && action === CameraTiltAction.STOP)
		) {
			return;
		}

		props.tiltHandler(direction, action);
		setPreviousTiltAction(action);
	};

	const getMaxInputRange = props.cameraType === CameraType.HELLO ? CameraZoomLevels.HELLO_MAX : CameraZoomLevels.HUDDLE_MAX;

	return (
		<div className='tilt_container'>
			{!isMobileOrTabletDevice() && props.callType !== CallTypes.MONITORING && (
				<i data-tooltip='You can move the camera by clicking on its feed.' data-position='left' className='material-icons click-to-move-icon'>
					help_outline
				</i>
			)}
			<div className='tilt_figure'>
				<figure>
					<div
						className={classNames(
							'btn',
							'btn_up',
							props.disabled || (props.cameraType === CameraType.HELLO && props.disabledDirections[CameraTiltDirection.UP]) ? 'btn-disabled' : ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.UP, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.UP, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.UP, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<div
						className={classNames(
							'btn',
							'btn_right',
							props.disabled || (props.cameraType === CameraType.HELLO && props.disabledDirections[CameraTiltDirection.RIGHT]) ? 'btn-disabled' : ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.RIGHT, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.RIGHT, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.RIGHT, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<div
						className={classNames(
							'btn',
							'btn_left',
							props.disabled || (props.cameraType === CameraType.HELLO && props.disabledDirections[CameraTiltDirection.LEFT]) ? 'btn-disabled' : ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.LEFT, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.LEFT, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.LEFT, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
					<div
						className={classNames(
							'btn',
							'btn_down',
							props.disabled || (props.cameraType === CameraType.HELLO && props.disabledDirections[CameraTiltDirection.DOWN]) ? 'btn-disabled' : ''
						)}
						onMouseDown={() => {
							sendTiltEvent(CameraTiltDirection.DOWN, CameraTiltAction.START);
						}}
						onMouseUp={() => {
							sendTiltEvent(CameraTiltDirection.DOWN, CameraTiltAction.STOP);
						}}
						onMouseOut={() => {
							sendTiltEvent(CameraTiltDirection.DOWN, CameraTiltAction.STOP);
						}}
						onBlur={() => {}}
					/>
				</figure>
			</div>
			{props.cameraType === CameraType.HUDDLE && (
				<div
					className={classNames(
						'tilt_zoom_reset',
						props.cameraType === CameraType.HUDDLE ? 'huddle-reset' : '',
						props.disabled ? 'disabled' : '',
						isMobileOrTabletDevice() ? 'huddle-reset-mobile' : ''
					)}>
					<i
						data-tooltip='Reset Zoom & Center Camera position.'
						data-position='top'
						className='material-icons'
						onClick={!props.disabled ? () => props.sendCameraCommand(CameraCommands.RECENTER) : null}>
						home
					</i>
				</div>
			)}

			<div className='tilt__zoom'>
				<input
					type='range'
					min='0'
					max={getMaxInputRange}
					value={props.zoomRange}
					onChange={!props.disabled ? e => props.setZoomRange(e.target.value) : null}
					onMouseUp={e => (!props.disabled ? props.updateZoomLevel(e) : null)}
					onTouchEnd={e => (!props.disabled ? props.updateZoomLevel(e) : null)}
				/>
			</div>

			<div
				data-tooltip={props.cameraType === CameraType.HELLO ? 'Reset Zoom & Center Camera position.' : null}
				data-position='top'
				className={classNames('reset-camera', props.disabled ? 'disabled-reset' : '')}
				onClick={!props.disabled ? props.onResetCameraButtonClick : null}>
				<i className='material-icons-outlined'>refresh</i>
				<span>Reset Camera</span>
			</div>
			{props.cameraType === CameraType.HUDDLE && props.callType !== CallTypes.MONITORING && (
				<div className='bookmarks-wrapper'>
					<div className='bookmark-header' onClick={() => props.setIsBookmarksOpen(!props.isBookmarksOpen)}>
						<span className='material-icons'>bookmark</span>
						<span>{props.selectedBookmark?.name || 'No active Preset'}</span>
					</div>

					{props.isBookmarksOpen && (
						<CameraBookmarks
							sendCameraEvents={props.sendCameraEvent}
							bookmarks={props.bookmarkList}
							callType={props.callType}
							isBookmarksOpen={props.isBookmarksOpen}
							setIsBookmarksOpen={props.setIsBookmarksOpen}
							selectedBookmark={props.selectedBookmark}
						/>
					)}
				</div>
			)}
		</div>
	);
};

Tilt.defaultProps = {
	disabledDirections: {},
	selectedBookmark: null,
};

Tilt.propTypes = {
	disabledDirections: PropTypes.shape({
		[CameraTiltDirection.UP]: PropTypes.bool,
		[CameraTiltDirection.DOWN]: PropTypes.bool,
		[CameraTiltDirection.RIGHT]: PropTypes.bool,
		[CameraTiltDirection.LEFT]: PropTypes.bool,
	}),
	tiltHandler: PropTypes.func.isRequired,
	cameraType: PropTypes.string.isRequired,
	updateZoomLevel: PropTypes.func.isRequired,
	onResetCameraButtonClick: PropTypes.func.isRequired,
	zoomRange: PropTypes.number.isRequired,
	setZoomRange: PropTypes.func.isRequired,
	sendCameraCommand: PropTypes.func.isRequired,
	sendCameraEvent: PropTypes.func.isRequired,
	bookmarkList: PropTypes.arrayOf(PropTypes.string).isRequired,
	callType: PropTypes.number.isRequired,
	disabled: PropTypes.bool.isRequired,
	isBookmarksOpen: PropTypes.bool.isRequired,
	setIsBookmarksOpen: PropTypes.func.isRequired,
	selectedBookmark: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, isActive: PropTypes.bool }),
};

export default Tilt;

import React, { Component } from 'react';
import { Modal, Form } from 'components';

export default class RoomLockedModal extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<Modal display={true} position='center' onModalClose={() => this.props.close()} hideActionButtons={true}>
				<Form>
					<p>{`Room is ${this.props.isLocked ? 'locked' : 'unlocked'}`}</p>
					{!this.props.isLocked && <small>Anyone invited or with the link can join this video meeting room.</small>}
					{this.props.isLocked && <small>Anyone invited or with the link will be waiting for you as a host to accept them in this video meeting room.</small>}
					<br />
					<p>No users waiting</p>
					<small>Here will be shown all the waiting members that want to join in this video meeting room.</small>
				</Form>
			</Modal>
		);
	}
}

import { gatewayApi, APP_CONFIG } from 'constants/global-variables';

export function getUnassociatedDevices() {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1.1/organizations/${APP_CONFIG.companyId}/unassociated-devices`)
			.then(response => {
				res(response.data.result.devices);
			})
			.catch(e => rej(e));
	});
}

export function getDeviceList(level, id) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/level/${level}/id/${id}/devices`)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export const getDeviceSetting = async (deviceId, settingTypeId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/devices/${deviceId}/settings?settingTypeId=${settingTypeId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateDeviceSetting = async (deviceId, settingValue, settingTypeId) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${APP_CONFIG.companyId}/devices/${deviceId}/settings`, {
			settingTypeId,
			settingValue: settingValue,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export function deleteDevice(deviceId) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/organizations/${APP_CONFIG.companyId}/devices/${deviceId}`)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function sendCommand(deviceId, command, reason = null, dynamicData = '') {
	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/devices/${deviceId}/command`, {
				command,
				reason,
				dynamicData,
			})
			.then(response => {
				res(response);
			})
			.catch(e => rej(e));
	});
}

export function getLatestAppRelease(appId) {
	// DO NOT REMOVE. Returning default value until we decide to include force update in production
	return new Promise((res, rej) => {
		res({
			id: 0,
			short_version: '',
			version: '',
			uploaded_at: '',
			install_url: '',
			min_os: '',
		});
	});
	// return new Promise((res, rej) => {
	// 	gatewayApi
	// 		.get(`v1/device-apps/apps/${appId}/version/latest`)
	// 		.then(response => {
	// 			res(response.data);
	// 		})
	// 		.catch(e => rej(e));
	// });
}

/**
 * Send device force update command to Hello
 * @method forceUpdateDevice
 * @param {object} data
 * @param {number} data.deviceId
 * @param {string} data.dynamicData
 */
export function forceUpdateDevice(data) {
	// DO NOT REMOVE. Commented until we decide to include force update in production
	// return new Promise((res, rej) => {
	// 	gatewayApi
	// 		.post(`/v1/devices/${data.deviceId}/command`, {
	// 			command: DeviceCommands.FORCE_UPDATE,
	// 			...data,
	// 		})
	// 		.then(response => {
	// 			res(response);
	// 		})
	// 		.catch(e => rej(e));
	// });
}

export function getDeviceRebootLogs(deviceId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/devices/${deviceId}/logs/type/command`)
			.then(response => {
				res(response.data.deviceCommandLogs);
			})
			.catch(e => rej(e));
	});
}

export const updateAiSettings = async (patientId, deviceId, params) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/patients/${patientId}/devices/${deviceId}/ai-settings`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAiSettings = async (patientId, deviceId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients/${patientId}/devices/${deviceId}/ai-settings`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceOwner = async deviceId => {
	try {
		const url = `v1/devices/${deviceId}/owner`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientDeviceOwner = async ({ userId, deviceName, deviceId }) => {
	try {
		const { data } = await gatewayApi.put(`/v1.1/healthcare/organizations/${APP_CONFIG.companyId}/devices/${deviceId}/owner`, {
			userId,
			deviceName,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanyDeviceOwner = async deviceId => {
	try {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/devices/${deviceId}/owner`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deletePatientDeviceOwner = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(`/v1.1/healthcare/organizations/${APP_CONFIG.companyId}/devices/${deviceId}/owner`);
		return data;
	} catch (error) {
		return { error };
	}
};

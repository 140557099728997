import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'components/Common/FormElements/Input';

import Select from 'components/Common/FormElements/Select';
import Alert from 'components/Alert';

import { addPoolRole } from 'api/nursePooling';

class AddPoolRole extends Component {
	getHealthSystems(healthSystems) {
		return healthSystems.map(hs => {
			return { id: hs.value, value: hs.label };
		});
	}

	render() {
		return (
			<div>
				<Formik
					enableReinitialize={true}
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={{
						healthSystem: this.props.selectedHealthSystem.value,
						name: '',
					}}
					onSubmit={(values, { resetForm }) => {
						this.props.onLoading('addPoolRole');
						addPoolRole(values.healthSystem, values.name).then(response => {
							this.props.onSuccess(response, values.healthSystem);
							resetForm({
								healthSystem: '',
								name: '',
							});
						});
					}}
					validationSchema={Yup.object().shape({
						healthSystem: Yup.string().required('Health system is required'),
						name: Yup.string()
							.required('Pool role name is required')
							.min(3)
							.max(50),
					})}>
					{formikProps => {
						// bind the submission handler remotely
						this.props.bindSubmitForm(formikProps.submitForm);

						return (
							<Form>
								<h3>Add Pool Role</h3>
								<Field
									name='healthSystem'
									type='select'
									label='Enter Health System'
									placeholder='Select Health System'
									description='Please select at least one health system in order to add a pool role'
									items={this.getHealthSystems(this.props.healthSystems)}
									component={Select}
								/>
								<Field name='name' type='text' label='Enter Name' placeholder='Name' description='Type the name for this pool role' component={Input} />
								<Alert
									display={this.props.loaded}
									message='The pool role was successfully added to this health system !'
									variant='success'
									onClose={() => this.props.onCloseAlert('addPoolRole')}
								/>
								<Alert
									display={this.props.error}
									message='Something went wrong! Please try again. '
									variant='error'
									onClose={() => this.props.onCloseAlert('addPoolRole')}
								/>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default AddPoolRole;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import CameraControls from 'calls/components/CameraControls';
import { CameraType } from 'constants/enums';
import { Alert } from 'components';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import RoomLockedModal from 'calls/components/calls/modals/RoomLockedModal';
import MediaSettingsModal from 'calls/components/calls/modals/MediaSettingsModal';
import ActiveParticipant from 'calls/components/calls/ActiveParticipant';
import { getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { getLogoUrl } from 'api/organization';
import classNames from 'classnames/bind';
import CallDuration from 'calls/components/CallDuration';

export default function CallMain(props) {
	const {
		activeParticipant,
		activeTrackType,
		patientParticipant,
		disableButtons,
		isLocked,
		showRoomLockedModal,
		closeRoomLockedModal,
		isGridView,
		showRightColumn,
		participants,
		localParticipant,
		isCamSettingsActive,
		closeCamSettingsModal,
		changeMediaConstraints,
		selectedMediaDevices,
		setSelectedMediaDevices,
		togglePTZ,
		callStartTime,
	} = props;

	const [statePatientParticipant, setStatePatientParticipant] = useState(patientParticipant);
	const [healthSystemPicture, setHealthSystemPicture] = useState('');

	const patientCameraPrivacyStateChanged = () => {
		const clonedParticipant = _.cloneDeep(patientParticipant);
		setStatePatientParticipant(clonedParticipant);
		props.setIsPrivacyButtonEnabled(
			clonedParticipant && clonedParticipant.cameraType === CameraType.HELLO && (clonedParticipant.isMicPrivacyOn || clonedParticipant.isCameraPrivacyOn)
		);
	};

	useEffect(() => {
		const setLogo = async () => {
			const currentHealthSystemInfo = getCurrentHealthSystemInfo();
			if (currentHealthSystemInfo) {
				const currentHealthSystemLogo = await getLogoUrl(currentHealthSystemInfo.currentHealthSystemId);
				setHealthSystemPicture(currentHealthSystemLogo);
			}
		};
		setLogo();
	}, []);

	useEffect(() => {
		if (patientParticipant) {
			patientParticipant.on(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, patientCameraPrivacyStateChanged);
		}

		return () => {
			if (patientParticipant) {
				patientParticipant.off(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, patientCameraPrivacyStateChanged);
			}
		};
	}, [patientParticipant]);

	const getPrivacyButtonsErrorMessage = () => {
		if (!statePatientParticipant) {
			return '';
		}

		const { isMicPrivacyOn, isCameraPrivacyOn, cameraType } = statePatientParticipant;
		if (cameraType !== CameraType.HELLO || (!isMicPrivacyOn && !isCameraPrivacyOn)) {
			return '';
		}

		let message = 'see';
		if (isMicPrivacyOn && !isCameraPrivacyOn) {
			message = 'hear';
		} else if (isMicPrivacyOn && isCameraPrivacyOn) {
			message += ' or hear';
		}

		return `Privacy buttons are enabled on Hello Device. Disable them to ${message} the patient.`;
	};

	const shouldShowPrivacyButtonsErrorMessage =
		statePatientParticipant &&
		statePatientParticipant.cameraType === CameraType.HELLO &&
		(statePatientParticipant.isMicPrivacyOn || statePatientParticipant.isCameraPrivacyOn);

	return (
		<main className={classNames(props.isNotExpanded ? 'main-not-expanded' : '', participants.size > 1 ? 'main-multiple-participants' : '')}>
			<div className={classNames('logo', participants.size > 1 ? 'multiple-part-logo' : '')}>
				{healthSystemPicture && <img src={healthSystemPicture} alt='left-nav-logo' />}
			</div>
			<ActiveParticipant
				showRightColumn={showRightColumn}
				participant={activeParticipant}
				localParticipantIsInitiator={localParticipant.isInitiator}
				trackType={activeTrackType}
				participants={participants}
			/>
			{callStartTime && <CallDuration callStartTime={callStartTime} />}
			{!isGridView && patientParticipant && localParticipant.isInitiator && (
				<CameraControls participant={patientParticipant} isDisabled={disableButtons} isCloseBtnAvailable={true} onPtzClose={togglePTZ} />
			)}
			{isCamSettingsActive && (
				<MediaSettingsModal
					localParticipant={localParticipant}
					changeMediaConstraints={changeMediaConstraints}
					selectedMediaDevices={selectedMediaDevices}
					setSelectedMediaDevices={setSelectedMediaDevices}
					onModalClose={closeCamSettingsModal}
				/>
			)}
			{showRoomLockedModal && <RoomLockedModal isLocked={isLocked} close={closeRoomLockedModal} />}
			<Alert
				message={getPrivacyButtonsErrorMessage()}
				variant='error'
				persist={true}
				display={shouldShowPrivacyButtonsErrorMessage}
				hideCloseButton={true}
				position='top'
			/>
		</main>
	);
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DeviceStatus, SectorTypes } from 'constants/enums';
import { amwellIconLink } from 'constants/global-variables';
import { useSelector } from 'react-redux';

let sectors = {
	hospital: {
		subtype: 'room',
		icon: 'meeting_room',
		img: 'room.svg',
	},
	department: {
		subtype: 'room',
		icon: 'meeting_room',
		img: 'room.svg',
	},
	floor: {
		subtype: 'room',
		icon: 'meeting_room',
		img: 'room.svg',
	},
};

const OptionName = props => {
	const { option } = props;
	const busyDevices = useSelector(state => state.devicesStatusList.busyDevices);

	const isDeviceBusy = () => {
		if (!option.helloDeviceId) {
			return false;
		}

		return busyDevices.some(id => +id === +option.helloDeviceId);
	};

	return (
		<>
			{option.name}
			{option.helloDeviceId && (
				<i className={classNames(option.status === DeviceStatus.OFFLINE ? 'material-icons-outlined' : 'material-icons', 'room-status', option.status)}>
					fiber_manual_record
				</i>
			)}
			{isDeviceBusy() && (
				<i className={classNames('material-icons-round', 'room-status', 'on-call')} data-tooltip='This device is on a call' data-position='right'>
					fiber_manual_record
				</i>
			)}
		</>
	);
};

const OptionsList = ({
	options,
	expandedOptions,
	onChange,
	onAdd,
	onLinkClick,
	onAddDevice,
	isMonitoring,
	selectedSectorId,
	setSelectedId,
	expandAll,
	onRoomClick,
}) => {
	const findProperId = item => {
		return item[`${item.type}Id`];
	};

	const childrenHidden = option => {
		return option.subOptions.every(item => item.hidden === true);
	};

	const childrenMatchSearch = option => {
		return option.subOptions.some(item => item.matchesSearch === true);
	};

	const toggleOptions = option => {
		if ((childrenHidden(option) && !childrenMatchSearch(option)) || !childrenMatchSearch(option)) {
			option.subOptions.forEach(item => {
				item.hidden = !item.hidden;
			});
		} else {
			option.subOptions
				.filter(i => i.matchesSearch)
				.forEach(item => {
					item.hidden = !item.hidden;
				});
		}
	};

	const toggleExpand = (selectedOptionId, isAddingSection, option) => {
		if (expandedOptions[selectedOptionId] && !isAddingSection) {
			delete expandedOptions[selectedOptionId];
		} else {
			expandedOptions[selectedOptionId] = {};
		}
		if (expandAll) {
			toggleOptions(option);
		}
		onChange(expandedOptions);
	};

	const addNewOptionClick = selection => {
		const hasNewSectorTextField = selection.subOptions.some(item => item.isNewOption === true);
		if (hasNewSectorTextField) {
			return;
		}

		if (selection.type === SectorTypes.ROOM) {
			onAddDevice(selection);
			return;
		}

		selection.subOptions.push({
			...selection,
			icon: sectors[selection.type].icon,
			type: sectors[selection.type].subtype,
			img: sectors[selection.type].img,
			isNewOption: true,
			subOptions: [],
		});
		handleSubOptionsListChange();
		toggleExpand(findProperId(selection), true, selection);
	};

	const addNewOptionOnEnter = (event, selection) => {
		if (event.which === 13) {
			const maxCharacters = 127;
			if (event.target.value.length > maxCharacters) {
				selection.error = `Cannot add ${selection.type} with more than ${maxCharacters} characters`;
				toggleExpand(findProperId(selection), false, selection);
				return;
			}
			selection.name = event.target.value;
			handleSubOptionsListChange();
			onAdd(selection);
		}
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		expandedOptions[optionId] = subSelections;
		onChange(expandedOptions);
	};

	const selectOption = (event, option) => {
		setSelectedId(option[option.type + 'Id']);
		option.isSelected = true;
		if (onLinkClick) {
			onLinkClick(option, event);
		}
		toggleExpand(findProperId(option), true, option);
	};

	return (
		<div>
			{options.map((option, index) => (
				<div key={index} className={classNames('tree', expandAll ? 'search-mode' : '', !option.name && !option.isNewOption ? 'hidden' : '')}>
					<div
						onClick={onRoomClick}
						className={classNames('tree__child', option[option.type + 'Id'] === selectedSectorId ? 'selected' : '', option.hidden ? 'hidden' : '')}
						data-cy='healthSystemTree'>
						{option.isNewOption && (
							<>
								<div className='tree__new'>
									{!option.img && <i className='material-icons-outlined'>{option.icon}</i>}
									{option.img && <img src={`${amwellIconLink}${option.img}`} alt='option-icon' />}
									<input
										onKeyUp={event => {
											addNewOptionOnEnter(event, option);
										}}
										type='text'
										placeholder={`Add ${option.type}`}
										autoFocus
									/>
								</div>
								<p>{option.error}</p>
							</>
						)}
						{!option.isNewOption && (
							<div>
								{!option.img && <i className='material-icons-outlined'>{option.icon}</i>}
								<img src={`${amwellIconLink}${option.img}`} alt='option-icon' />
								{option.link && !isMonitoring && (
									<Link
										to={option.link}
										className='link'
										onClick={event => {
											selectOption(event, option);
										}}>
										<OptionName option={option} />
									</Link>
								)}
								{isMonitoring && (
									<a
										className='link'
										onClick={event => {
											selectOption(event, option);
										}}>
										<OptionName option={option} />
									</a>
								)}
								{option.tooltip && !option.isCreatingSector && (
									<a
										className={classNames('action', option.customActionIcon?.className || '')}
										data-cy='newSector'
										onClick={() => {
											addNewOptionClick(option);
										}}
										data-tooltip={option.tooltip && !option.customActionIcon ? option.tooltip : null}
										data-position='left'>
										<i
											className='material-icons-outlined'
											data-cy='addSectorTooltip'
											style={{ color: option.customActionIcon ? option.customActionIcon.iconColor : '' }}>
											{option.customActionIcon ? option.customActionIcon.icon : 'add_box'}{' '}
										</i>
									</a>
								)}
								<i
									className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
									onClick={() => {
										toggleExpand(findProperId(option), false, option);
									}}>
									{expandedOptions[findProperId(option)] || (expandAll && !childrenHidden(option)) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
								</i>
							</div>
						)}
					</div>
					{option.subOptions && expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							onRoomClick={onRoomClick}
						/>
					)}
					{option.subOptions && expandedOptions[findProperId(option)] && !expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							onRoomClick={onRoomClick}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class TreeView extends Component {
	state = {
		selectedSectorId: this.props.selectedSectorId,
		expandedOptions: this.props.preSelected ? this.props.preSelected : {},
	};

	setSelectedId = sectorId => {
		this.setState({ selectedSectorId: sectorId });
	};

	render() {
		let { selectedSectorId, expandedOptions } = this.state;
		return (
			<OptionsList
				onAdd={this.props.onAdd}
				onAddDevice={this.props.onAddDevice}
				onLinkClick={this.props.onLinkClick}
				options={this.props.data}
				onChange={() => this.setState({ expandedOptions })}
				expandedOptions={this.state.expandedOptions}
				isMonitoring={this.props.isMonitoring}
				selectedSectorId={selectedSectorId}
				setSelectedId={this.setSelectedId}
				expandAll={this.props.expandAll}
				onRoomClick={this.props.onRoomClick}
			/>
		);
	}
}

export default TreeView;

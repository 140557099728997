/* eslint-disable import/prefer-default-export */
import { gatewayApi } from 'constants/global-variables';

export async function sendStats(data, retries = 5) {
	const url = `v1.1/callmetrics`;
	return new Promise((res, rej) => {
		gatewayApi
			.post(url, data)
			.then(response => {
				res(response.data);
			})
			.catch(ex => {
				console.error(`error while sending call stats logs`, ex);
				if (retries > 0) {
					sendStats(data, retries - 1);
				}
				rej(ex);
			});
	});
}

import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { ParticipantState, DeviceStatusMessages, FeedColors, CameraEventTypes, ParticipantRemoveReason, CameraType, UserRoles } from 'constants/enums';
import _ from 'lodash';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { TrackAdded } from '@solaborate/webrtc/lib/Connection';
import { Loader, Dropdown, ListGroup, Alert } from 'components';
import CameraControls from 'calls/components/CameraControls';
import SocketEvents from 'constants/socket-events';
import { SocketContext } from 'io-client/SocketContext';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import VideoFeedAiButtons from 'calls/components/monitoring/VideoFeedAiButtons';
import { getUserRole } from 'infrastructure/auth';
import FeedWarningItems from 'calls/components/monitoring/FeedWarningItems';

class VideoFeed extends Component {
	constructor(props, socket) {
		super(props, socket);
		this.audioRef = React.createRef();
		this.socket = socket;
		this.state = {
			detectionMode: null,
			error: null,
		};
	}

	userRole = getUserRole();

	componentDidMount() {
		this.props.participant.remoteTrackController.on(this.onTrackChanged);
		this.props.participant.on(ParticipantEvent.STATE_CHANGED, this.onStateChanged);
		this.props.participant.on(ParticipantEvent.DEVICE_CONTROLS_LOCKED, this.onDeviceControlsLocked);
		this.props.participant.on(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, this.onStateChanged);
		this.socket.on(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, this.onCameraResponse);
	}

	componentWillUnmount() {
		this.props.participant.remoteTrackController.off(this.onTrackChanged);
		this.props.participant.off(ParticipantEvent.STATE_CHANGED, this.onStateChanged);
		this.props.participant.off(ParticipantEvent.DEVICE_CONTROLS_LOCKED, this.onDeviceControlsLocked);
		this.props.participant.off(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, this.onStateChanged);
		this.socket.off(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, this.onCameraResponse);
	}

	onDeviceControlsLocked = value => {
		if (!value) {
			return;
		}

		this.props.showDeviceControllsLockedModal();
	};

	onCameraResponse = data => {
		if (data.objectId !== this.props.participant.objectId) {
			return;
		}

		if (data.event === CameraEventTypes.NIGHT_VISION) {
			Object.assign(this.props.participant, { nightVisionMode: data.isSuccessful });
			this.setState({});
		}

		if (data.event === CameraEventTypes.SWITCH) {
			Object.assign(this.props.participant, { cameraType: data.message });
			this.setState({});
		}
	};

	onTrackChanged = ev => {
		this.setState({}, () => {
			if (ev.type === TrackType.VIDEO || (ev.track && ev.track.type === TrackType.VIDEO)) {
				if (ev.constructor === TrackAdded) {
					this.videoRef.srcObject = new MediaStream([ev.track.track]);
				}
			} else if (ev.type === TrackType.AUDIO || (ev.track && ev.track.type === TrackType.AUDIO)) {
				if (ev.constructor === TrackAdded) {
					this.audioRef.current.srcObject = new MediaStream([ev.track.track]);
				} else {
					this.audioRef.current.srcObject = null;
				}
			}
		});
	};

	onStateChanged = () => {
		// re-render the view
		this.setState({});
	};

	getDeviceStatus() {
		switch (this.props.participant.state) {
			case ParticipantState.BUSY.type:
				if (this.props.onPatientBusyNurse && this.props.onPatientBusyNurse.name) {
					return `${DeviceStatusMessages.deviceOnCallWithNurse} ${this.props.onPatientBusyNurse.name}.`;
				}
				return DeviceStatusMessages.deviceOnCall;
			case ParticipantState.OFFLINE.type:
				return DeviceStatusMessages.deviceOffline;
			case ParticipantState.NOT_ANSWERING.type:
				return DeviceStatusMessages.notAnswering;
			case ParticipantState.DISCONNECTED.type:
			case ParticipantState.LEFT_CALL.type:
				return DeviceStatusMessages.leftCall;
			case ParticipantState.RECONNECTING.type:
				return DeviceStatusMessages.reconnecting;
			case ParticipantState.REMOVING.type:
				return DeviceStatusMessages.removingParticipant;
			case ParticipantState.REMOVED.type:
				return DeviceStatusMessages.removed;
			default:
				return DeviceStatusMessages.initiatingMonitoring;
		}
	}

	setFeedColor = (event, item) => {
		this.props.participant.feedColor = this.props.participant.feedColor === item.color ? 'var(--dark-theme-gray-2)' : item.color;
		this.setState({});
	};

	getFeedColors = () => {
		return [
			{
				title: (
					<>
						<i className='material-icons' style={{ color: FeedColors.yellow }}>
							fiber_manual_record
						</i>
						Falls
						{this.props.participant.feedColor === FeedColors.yellow && <span className='material-icons'>done</span>}
					</>
				),
				color: FeedColors.yellow,
			},
			{
				title: (
					<>
						<i className='material-icons' style={{ color: FeedColors.blue }}>
							fiber_manual_record
						</i>
						Behavioral
						{this.props.participant.feedColor === FeedColors.blue && <span className='material-icons'>done</span>}
					</>
				),
				color: FeedColors.blue,
			},
			{
				title: (
					<>
						<i className='material-icons' style={{ color: FeedColors.red }}>
							fiber_manual_record
						</i>
						Isolation
						{this.props.participant.feedColor === FeedColors.red && <span className='material-icons'>done</span>}
					</>
				),
				color: FeedColors.red,
			},
			{
				title: (
					<>
						<i className='material-icons' style={{ color: FeedColors.pink }}>
							fiber_manual_record
						</i>
						Seizure
						{this.props.participant.feedColor === FeedColors.pink && <span className='material-icons'>done</span>}
					</>
				),
				color: FeedColors.pink,
			},
			{
				title: (
					<>
						<i className='material-icons' style={{ color: FeedColors.black }}>
							fiber_manual_record
						</i>
						Hospice
						{this.props.participant.feedColor === FeedColors.black && <span className='material-icons'>done</span>}
					</>
				),
				color: FeedColors.black,
			},
			{
				title: (
					<>
						<i className='material-icons' style={{ color: FeedColors.orange }}>
							fiber_manual_record
						</i>
						Detox
						{this.props.participant.feedColor === FeedColors.orange && <span className='material-icons'>done</span>}
					</>
				),
				color: FeedColors.orange,
			},
		];
	};

	toggleLocalTrack = async type => {
		const { deviceControlsLocked } = await this.props.toggleLocalTrack(type, this.props.participant.id);
		if (deviceControlsLocked) {
			this.props.showDeviceControllsLockedModal();
		} else {
			this.props.checkForWarning();
		}
	};

	toggleRemoteTrack = async type => {
		const { deviceControlsLocked } = await this.props.toggleRemoteTrack(type, this.props.participant.id);
		if (deviceControlsLocked) {
			this.props.showDeviceControllsLockedModal();
		}
	};

	toggleNightVision = async () => {
		const result = await this.props.participant.toggleNightVision();
		if (result?.deviceControlsLocked) {
			this.props.showDeviceControllsLockedModal();
		}
	};

	setVideoRef = node => {
		this.videoRef = node;
		// re-render to reset canvas width/height
		this.setState({});
	};

	render() {
		const { participant, disableButtons, hasZoomedFeed, toggleAiAlertsModal, expand, close } = this.props;

		const showButtons =
			!disableButtons &&
			participant.state === ParticipantState.CONNECTED.type &&
			participant.removeReason !== ParticipantRemoveReason.CONFERENCE_TERMINATED_BY_ADMINISTRATOR;
		const hasAudioTrack = !!participant.remoteTrackController.tracks.get(TrackType.AUDIO);
		const hasVideoTrack = !!participant.remoteTrackController.tracks.get(TrackType.VIDEO);
		const showVideo =
			hasVideoTrack &&
			participant.state === ParticipantState.CONNECTED.type &&
			participant.removeReason !== ParticipantRemoveReason.CONFERENCE_TERMINATED_BY_ADMINISTRATOR;

		const hasLocalAudioTrack = participant.localTrackController.tracks.get(TrackType.AUDIO);

		const tools = {
			isPatientPositionOn: this.state['isPatientPositionOn'],
			isGettingOutOfBedOn: this.state['isGettingOutOfBedOn'],
			isBedAndRailsOn: this.state['isBedAndRailsOn'],
			isSkeletonPrivacyModeOn: this.state['isSkeletonPrivacyModeOn'],
		};

		return (
			<VideoFeedWrapper className={`${participant.expandMonitoringFeed ? 'expand-feed' : ''}`}>
				<header style={{ background: participant.feedColor }}>
					<h1 title={participant.name}>{participant.name}</h1>
					{showButtons && (
						<>
							<Dropdown
								position={hasZoomedFeed && !participant.expandMonitoringFeed ? 'top' : 'bottom'}
								icon='more_horiz'
								closeDropdownOnItemClick={true}
								className='precautions-dropdown'>
								<ListGroup lists={this.getFeedColors()} onItemClick={this.setFeedColor} />
							</Dropdown>

							{participant?.cameraType === CameraType.HELLO && (
								<li className='multiple-feeds-remove'>
									<button type='button' onClick={this.toggleNightVision}>
										<i className='material-icons'>{participant.nightVisionMode ? 'wb_sunny' : 'brightness_3'}</i>
									</button>
								</li>
							)}

							{this.userRole === UserRoles.NURSE && !this.props.isAiDisabled && !participant.isDefaultOwner && (
								<li className='multiple-feeds-remove'>
									<button
										type='button'
										onClick={() => toggleAiAlertsModal(participant.id)}
										data-tooltip={participant.isAiAlertModalOpen ? 'Hide Alerts modal.' : 'Show Alerts modal.'}
										data-position='bottom'>
										<i className={participant.isAiAlertModalOpen ? 'material-icons' : 'material-icons-outlined'}>notifications</i>
									</button>
								</li>
							)}

							<li>
								<i
									data-tooltip={participant.isMicPrivacyOn ? 'Privacy buttons are enabled on Hello Device. Disable them to hear the patient.' : null}
									data-position='bottom'
									className='material-icons'
									style={{ color: participant.isMicPrivacyOn ? '#e84118' : '#ffffff' }}>
									mic_off
								</i>
							</li>
							<li>
								<i
									className='material-icons'
									data-tooltip={participant.isCameraPrivacyOn ? 'Privacy buttons are enabled on Hello Device. Disable them to see the patient.' : null}
									data-position='bottom'
									style={{ color: participant.isCameraPrivacyOn ? '#e84118' : '#ffffff' }}>
									videocam_off
								</i>
							</li>
							<li>
								<button type='button' onClick={() => expand(participant.id)}>
									<i className='material-icons'>{participant.expandMonitoringFeed ? 'unfold_less' : 'unfold_more'}</i>
								</button>
							</li>
						</>
					)}
					<li>
						<button type='button' onClick={() => close(participant.id)}>
							<i className='material-icons' data-cy='closeMonitoringFeed'>
								close
							</i>
						</button>
					</li>
				</header>
				{!showVideo && (
					<div className='center-loader full-width'>
						<p>{this.getDeviceStatus()}</p>
						{(participant.state === ParticipantState.CONNECTING.type || participant.state === ParticipantState.REMOVING.type) && <Loader />}
					</div>
				)}
				<video ref={this.setVideoRef} hidden={!showVideo} autoPlay muted />
				<audio ref={this.audioRef} autoPlay />

				<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />

				{showVideo && (
					<>
						<FeedWarningItems
							stopVoiceOver={this.props.stopVoiceOver}
							participant={participant}
							hierarchyNaming={this.props.hierarchyNaming}
							numberOfFeeds={this.props.numberOfFeeds}
							conferenceInfo={this.props.conferenceInfo}
							tools={tools}
							detectionMode={this.state.detectionMode}
							isAiDisabled={this.props.isAiDisabled}
						/>
						<VideoFeedBottomOptions isSingleFeed={this.props.numberOfFeeds === 1 || this.props.participant.expandMonitoringFeed}>
							<li>
								<VideoFeedAiButtons
									prepareCanvas={(tool, detectionMode) => {
										const initialState = {
											detectionMode: false,
											disabledAiControls: _.cloneDeep(this.state.disabledAiControls),
										};

										const newState = {
											...initialState,
											[tool]: !this.state[tool],
											detectionMode: !this.state[tool] ? detectionMode : null,
										};

										this.setState(newState);
									}}
									onAiSettingClick={this.props.onAiSettingClick}
									participant={participant}
									isAiDisabled={this.props.isAiDisabled}
								/>
								<button type='button' onClick={() => this.toggleLocalTrack(TrackType.AUDIO)}>
									<i className='material-icons'>{hasLocalAudioTrack ? 'mic' : 'mic_off'}</i>
								</button>
								<button type='button' onClick={() => this.toggleRemoteTrack(TrackType.AUDIO)}>
									<i className='material-icons'>{hasAudioTrack ? 'volume_up' : 'volume_off'}</i>
								</button>
							</li>
						</VideoFeedBottomOptions>

						<CameraControls participant={participant} isDisabled={disableButtons} />
					</>
				)}
			</VideoFeedWrapper>
		);
	}
}

const VideoFeedWrapper = styled.div`
	background: var(--gray-5);
	position: relative;

	li {
		list-style: none;
		display: flex;

		button {
			padding: 2px;
		}

		i {
			&::before {
				white-space: normal;
				width: 200px;
				line-height: 18px;
			}
		}

		[data-tooltip],
		[data-tooltip] i {
			cursor: default;
		}
	}

	header {
		width: 100%;
		background: var(--gray-3);
		display: flex;
		align-items: center;
		padding: 0 var(--spacing-s);
	}

	h1 {
		font-size: 14px;
		color: var(--gray-0);
		padding: var(--spacing-s) 0;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		flex: 1;
		text-align: left;
		text-overflow: ellipsis;
		min-height: 28px;
		max-width: calc(100% - 25px);
	}

	> div {
		display: flex;
		flex-direction: column;
		margin-left: auto;
	}

	button {
		background: none;
		border: none;
		font-size: 0;
	}

	.center-loader {
		p {
			color: var(--gray-0);
			font-size: 14px;
			margin: 0;
		}
	}

	li > i,
	button i,
	.dropdown i {
		color: var(--gray-0);
		cursor: pointer;
		font-size: 18px;
		margin-right: 0 !important;
	}

	li > i {
		cursor: auto;
		padding: 0 4px;
	}

	.dropdown__items a > i {
		margin: 0;
	}

	video {
		height: calc(100% - 30px);
	}

	.dropdown {
		padding: 0;
	}

	.dropdown__items > .list-group a > span {
		display: flex;
		align-items: center;
	}

	.dropdown__items > .list-group a > span > span {
		left: 30px;
		position: relative;
		font-size: 16px;
	}

	.dropdown__items > .list-group a > span i {
		margin-right: var(--spacing-s) !important;
	}
`;

const VideoFeedOptions = styled.div`
	font-size: 0;
	display: flex;
	a {
		font-size: 0;
	}

	.dropdown__items {
		outline: none;
	}
`;

const VideoFeedBottomOptions = styled.div`
	position: absolute;
	right: var(--spacing-m);
	bottom: var(--spacing-m);
	font-size: 0;
	display: flex;
	flex-direction: row !important;

	${props =>
		props.isSingleFeed &&
		css`
			div > div {
				width: 30px;
				height: 30px;
			}

			button {
				width: 30px;
				height: 30px;
			}
		`}
	div {
		margin-left: var(--spacing-s);
		cursor: pointer !important;
	}

	button {
		background: var(--gray-0);
		padding: var(--spacing-s);
		border-radius: 100%;
		margin-left: var(--spacing-s);
		cursor: pointer;
		i {
			cursor: pointer;
			color: var(--gray-5);
			font-size: 16px;
			margin-right: 0px;
		}
	}
`;

VideoFeed.contextType = SocketContext;

export default VideoFeed;

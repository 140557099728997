import React from 'react';
import { Modal, Form } from 'components';
import { DefaultCameraTypes, SettingTypes } from 'constants/enums';

const DefaultCameraModal = props => {
	const cameraTypes = [
		{ id: 1, label: '5x Camera', value: DefaultCameraTypes.HELLO },
		{ id: 2, label: '20x Camera', value: DefaultCameraTypes.HUDDLE },
	];

	return (
		<Modal
			modalSelector='toggleSelectDefaultCamera'
			display={props.display}
			position='right'
			submitButtonText='Save'
			isLoading={props.isLoading}
			onModalClose={props.onModalClose}
			onModalSubmit={() => props.onModalSubmit(SettingTypes.CAMERA_TYPE)}>
			<Form title='Default Camera' onSubmit={event => event.preventDefault()}>
				<div>
					<p>Select default camera</p>
					<div className='input-el'>
						<main>
							{cameraTypes.map(item => (
								<label key={item.id}>
									<input
										type='radio'
										name='cameraType'
										value={item.value.toString()}
										onChange={event => props.onChange(event.target.value)}
										checked={props.selectedDevice?.defaultCamType === item.value.toString()}
									/>
									{item.label}
								</label>
							))}
						</main>
					</div>
				</div>
				<p>
					Selected camera will be shown as default in every call. <br />
					Prior to selecting the 20x Camera as a default camera, please make sure that it is connected to the device.
				</p>
			</Form>
		</Modal>
	);
};

export default DefaultCameraModal;

import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
//import Alert from 'components/Alert';
import { Table, Loader } from 'components';
import { assignRoleToNurses, unassignRoleFromNurse, getAssignedNursesByHealthSystemId } from 'api/nursePooling';
import { NursePoolRolePriorities } from 'constants/enums';
//import MultiSelect from 'components/Common/FormElements/MultiSelect';

class AssignedNurses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAssignNurseForm: false,
			assignedNurses: this.props.currentPoolRole.assignedNurses,
			fetchHealthSystemAssignedNurses: {
				loading: true,
				error: false,
				nurses: [],
			},
		};
	}

	async componentDidMount() {
		const allHealthSystemAssignedNurses = await getAssignedNursesByHealthSystemId(this.props.healthSystemId);
		this.setState({
			fetchHealthSystemAssignedNurses: {
				...this.state.fetchHealthSystemAssignedNurses,
				loading: false,
				nurses: allHealthSystemAssignedNurses,
			},
		});
	}

	displayListOfNurses() {
		return this.state.assignedNurses.map(nurse => {
			return {
				name: `${nurse.firstName} ${nurse.lastName}`,
				email: nurse.email,
				priority: this.getPriority(nurse.priority),
				//edit: this.getEditButtons(nurse.id),
			};
		});
	}

	getPriority = npr => {
		if (npr === NursePoolRolePriorities.PRIMARY.type) return NursePoolRolePriorities.PRIMARY.message;
		if (npr === NursePoolRolePriorities.SECONDARY.type) return NursePoolRolePriorities.SECONDARY.message;
		if (!npr) return 'Blank';
	};

	// getEditButtons(nurseId) {
	// 	return (
	// 		<div className='wrapped'>
	// 			<i className='material-icons-outlined boxed-icon' onClick={() => this.unassignRoleFromNurse(nurseId)}>
	// 				delete
	// 			</i>
	// 		</div>
	// 	);
	// }

	unassignRoleFromNurse(nurseId) {
		unassignRoleFromNurse(this.props.healthSystemId, this.props.currentPoolRole.id, nurseId).then(() => {
			this.setState(
				{
					assignedNurses: this.state.assignedNurses.filter(nurse => {
						return nurse.id !== nurseId;
					}),
					fetchHealthSystemAssignedNurses: {
						...this.state.fetchHealthSystemAssignedNurses,
						nurses: this.state.fetchHealthSystemAssignedNurses.nurses.filter(nurse => {
							return nurse.id !== nurseId;
						}),
					},
				},
				this.props.onUnnasignSuccessful(this.props.currentPoolRole.id, nurseId)
			);
		});
	}

	getNurses(allNurses, assignedNurses) {
		const unassignedNurses = allNurses.filter(vcp => {
			return !assignedNurses.some(assignedNurse => {
				return vcp.userInfo.id === assignedNurse.id;
			});
		});

		return unassignedNurses.map(vcp => {
			return { value: vcp.userInfo.id, label: vcp.userInfo.firstName + ' ' + vcp.userInfo.lastName };
		});
	}

	render() {
		const nurseTableHeaders = [{ title: 'Nurse' }, { title: 'Email' }, { title: 'Priority' }];

		if (this.state.fetchHealthSystemAssignedNurses.loading) {
			return <Loader />;
		}

		return (
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={{
						nurses: [],
					}}
					onSubmit={(values, { resetForm }) => {
						const nursesIds = values.nurses.map(nurse => nurse.value);
						this.props.onLoading('assignNurse');
						assignRoleToNurses(this.props.healthSystemId, this.props.currentPoolRole.id, nursesIds).then(() => {
							const assignedNursesObjects = [];
							nursesIds.forEach(nurseId => {
								const nurseToBeAdded = this.props.virtualCareProviders.find(nurse => nurse.userInfo.id === nurseId);
								const assignedNurseObj = {
									id: nurseToBeAdded.userInfo.id,
									firstName: nurseToBeAdded.userInfo.firstName,
									lastName: nurseToBeAdded.userInfo.lastName,
									email: nurseToBeAdded.userInfo.email,
								};
								assignedNursesObjects.push(assignedNurseObj);
							});

							this.setState({
								assignedNurses: assignedNursesObjects.concat(...this.state.assignedNurses),
								fetchHealthSystemAssignedNurses: {
									...this.state.fetchHealthSystemAssignedNurses,
									nurses: assignedNursesObjects.concat(...this.state.fetchHealthSystemAssignedNurses.nurses),
								},
							});

							this.props.onSuccess(this.props.currentPoolRole.id, assignedNursesObjects);
							resetForm({
								nurses: [],
							});
						});
					}}
					validationSchema={Yup.object().shape({
						nurses: Yup.string()
							.required('Please select at least one of the nurses from the dropdown above!')
							.nullable(),
					})}>
					{formikProps => {
						// bind the submission handler remotely
						const { values, touched, errors, setFieldValue, setFieldTouched } = formikProps;
						this.props.bindSubmitForm(formikProps.submitForm);
						return (
							<Form>
								<h3>Manage assigned nurses</h3>
								<Table isLoading={this.state.loadingHealthSystems} headers={nurseTableHeaders} rows={this.displayListOfNurses()} />
								{/*DO NOT REMOVE COMMENTED SECTION*/}
								{/* <a
									data-cy='manageAssignedNurses'
									className='action'
									onClick={() => {
										this.setState({ showAssignNurseForm: !this.state.showAssignNurseForm });
									}}
									data-tooltip={'Assign a new nurse to this pool role'}
									data-position='right'>
									<i className='material-icons-outlined'>add_box</i>
								</a>
								{this.state.showAssignNurseForm && (
									<div style={{ paddingTop: '15px' }}>
										<MultiSelect
											name='nurses'
											label='Select Nurses'
											placeholder='Select Nurses'
											description='Please select the nurse you wish to assign to this pool role'
											options={this.getNurses(this.props.virtualCareProviders, this.state.fetchHealthSystemAssignedNurses.nurses)}
											value={values.nurses}
											isMulti={true}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											touched={touched.nurses}
											error={errors.nurses}
											isClearable={true}
											backspaceRemovesValue={true}
										/>
									</div>
								)}
								<Alert
									display={this.props.loaded}
									message='The nurse was successfully assigned to this pool role !'
									variant='success'
									onClose={() => this.props.onCloseAlert('assignNurse')}
								/>
								<Alert
									display={this.props.error}
									message='Something went wrong! Please try again. '
									variant='error'
									onClose={() => this.props.onCloseAlert('assignNurse')}
								/> */}
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export default AssignedNurses;

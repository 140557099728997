import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getManualAlertActivities, getPatientManualAlerts } from 'api/alerts';
import { getDeviceOwner } from 'api/devices';
import { formattedDate } from 'infrastructure/helpers/dateHelper';
import { ManualAlertTypes } from 'constants/enums';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import AlertHistoryModal from 'views/AlertHistory/AlertHistoryModal';
import Alert from 'components/Alert';
import { healthCareCdnUrl } from 'constants/global-variables';

const AlertsWrapper = styled.div`
	overflow: auto;
	max-height: calc(100vh - 260px);
	span.circle-loader {
		margin-top: 15px;
	}
	> div {
		padding: var(--spacing-m);
		border-bottom: 1px solid var(--gray-6);
		align-items: center;

		> div {
			height: 25px;
			width: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background: var(--gray-0);
			margin-right: var(--spacing-s);
			background: var(--gray-0);
		}

		img {
			width: 15px;
		}

		p {
			color: var(--gray-0);
			padding: 0;
			margin: 0;
			text-align: left;
			font-size: 14px;
			flex: 1;
		}

		span {
			color: var(--gray-3);
			padding: 0;
			margin: 0;
			text-align: left;
			font-size: 12px;
			margin-left: var(--spacing-s);
		}
	}
`;

const ManualAlerts = ({ feed }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [alertDetails, setAlertDetails] = useState(null);
	const [manualAlerts, setManualAlerts] = useState([]);
	const [error, setError] = useState('');
	const [isScrollLoading, setIsScrollLoading] = useState(true);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [pageIndexManualAlerts, setPageIndexManualAlerts] = useState(0);
	const [patientId, setPatientId] = useState(null);

	useEffect(() => {
		if (feed.objectId) {
			setIsLoading(true);
			const fetchHelloDeviceOwner = async id => {
				const response = await getDeviceOwner(id);
				if (response.error) {
					setError(response.error.message);
				} else {
					setPatientId(response.id);
				}
				setIsLoading(false);
			};
			fetchHelloDeviceOwner(feed.objectId);
		}
	}, [feed.objectId]);

	const imageUrl = `${healthCareCdnUrl}vsm/`;

	const manualAlertTypes = [
		{
			id: 1,
			type: ManualAlertTypes.FALL_DETECTED,
			icon: `${imageUrl}FallDetection.svg`,
		},
		{
			id: 2,
			type: ManualAlertTypes.RISK_OF_FALL,
			icon: `${imageUrl}FallDetection.svg`,
		},
		{
			id: 3,
			type: ManualAlertTypes.PATIENT_NEEDS_HELP,
			icon: `${imageUrl}Heart-1.svg`,
		},
		{
			id: 4,
			type: ManualAlertTypes.PATIENT_REQUESTED_HELP,
			icon: `${imageUrl}Heart-1.svg`,
		},
		{
			id: 5,
			type: ManualAlertTypes.CUSTOM,
			icon: `${imageUrl}Heart-1.svg`,
		},
	];

	const fetchManualAlerts = async () => {
		const params = {
			pageSize: 20,
			pageIndex: pageIndexManualAlerts,
			patientId,
		};
		const response = await getPatientManualAlerts(params);
		if (response.error) {
			setError(response.error.message);
		} else if (response.patientManualAlerts.length === 0) {
			setIsScrollLoading(false);
			setIsLoading(false);
			return;
		} else {
			setManualAlerts(prevState => [...prevState, ...response.patientManualAlerts]);
			setPageIndexManualAlerts(prevState => prevState + 1);
			setHasReachedEnd(response.length < 20);
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	useEffect(() => {
		if (patientId) {
			fetchManualAlerts();
		}
	}, [patientId]);

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !isScrollLoading && !hasReachedEnd) {
			setIsScrollLoading(true);
			fetchManualAlerts();
		}
	};

	const viewAlertDetails = async id => {
		const response = await getManualAlertActivities(id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAlertDetails(response.manualAlertActivities);
	};

	return (
		<>
			<AlertsWrapper onScroll={handleScroll}>
				{isLoading && <Loader />}
				{!isLoading && (
					<>
						{manualAlerts.length === 0 && (
							<div>
								<p>No manual alerts found.</p>
							</div>
						)}
						{manualAlerts.map(item => (
							<div className='flex cursor-pointer' onClick={() => viewAlertDetails(item.id)}>
								<div className='alert-history-modal-icon-wrapper'>
									<img
										src={
											manualAlertTypes.find(el => el.type === item.manualAlertType.id)?.icon || `${healthCareCdnUrl}monitoring/video-feed/report-as-false.svg`
										}
										alt='ico'
									/>
								</div>
								<p>{item.manualAlertType.description}</p>
								<span>{formattedDate(item.dateCreated)}</span>
							</div>
						))}
					</>
				)}
			</AlertsWrapper>
			{alertDetails && (
				<Modal
					display={true}
					position='center'
					onModalSubmit={() => setAlertDetails(null)}
					onModalClose={() => setAlertDetails(null)}
					className='standard-modal-wrapper modal-wrapper-wo-btn z-10000'>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner view-request-history-modal'>
						<div className={`view-request-history-wrapper ${!alertDetails.length ? 'flex-align-center' : ''}`}>
							<div className='standard-modal-title alert-history full-width'>
								<h3>Alert Details</h3>
							</div>
							<AlertHistoryModal alertDetails={alertDetails} />
						</div>
					</div>
				</Modal>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default ManualAlerts;

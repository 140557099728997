import { OverviewActionTypes } from '../../constants/action-types';

const initialState = {
	userInfo: null,
	isLeftNavigationExpanded: false,
};

const mainLayout = (state, action) => {
	state = state || initialState;

	if (action.type === OverviewActionTypes.setUserInfo) {
		return { ...state, userInfo: action.payload };
	}

	if (action.type === OverviewActionTypes.toggleLeftNavigation) {
		return { ...state, isLeftNavigationExpanded: !state.isLeftNavigationExpanded };
	}

	return state;
};

export default mainLayout;

import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import { UserRoles, UserTypes } from 'constants/enums';

export function getPoolRolesByHealthSystemId(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles`;
		gatewayApi
			.get(url)
			.then(response => res(response.data.poolRoles))
			.catch(e => rej(e));
	});
}

export function getHealthSystemsVCPs(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/companies/${APP_CONFIG.companyId}/teams/${healthSystemId}/members`;
		gatewayApi
			.get(url)
			.then(response => {
				const vcps = response.data.teamMembers.filter(vcp => vcp.roles.some(role => role.id === UserTypes.VIRTUALCAREPROVIDER));
				res(vcps);
			})
			.catch(e => rej(e));
	});
}

export function getNursePoolRoles() {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/pool-roles`;
		gatewayApi
			.get(url)
			.then(response => res(response.data.poolRoles))
			.catch(e => rej(e));
	});
}

export function addPoolRole(healthSystemId, name) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles`;
		gatewayApi
			.post(url, { Name: name })
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function editPoolRole(healthSystemId, poolRoleId, name) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles/${poolRoleId}`;
		gatewayApi
			.put(url, { UpdatedName: name })
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function assignRoleToNurse(healthSystemId, poolRoleId, nurseId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles/${poolRoleId}/assign-nurse`;
		gatewayApi
			.post(url, { NurseUserId: nurseId })
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function assignRolesToNurse(healthSystemId, poolRoles, nurseId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/assign-nurse`;
		gatewayApi
			.post(url, {
				NurseUserId: nurseId,
				NursePoolRoles: poolRoles,
			})
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function assignRoleToNurses(healthSystemId, poolRoleId, nursesIds) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles/${poolRoleId}/assign-nurses`;
		gatewayApi
			.post(url, { Nurses: nursesIds })
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function unassignRoleFromNurse(healthSystemId, poolRoleId, nurseId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles/${poolRoleId}/assigned-nurses/${nurseId}`;
		gatewayApi
			.delete(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function getPoolsByHealthSystemId(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools`;
		gatewayApi
			.get(url)
			.then(response => res(response.data.pools))
			.catch(e => rej(e));
	});
}

export function addPool(healthSystemId, name, poolRoles) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools`;
		gatewayApi
			.post(url, { Name: name, PoolRoles: poolRoles })
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function editPool(healthSystemId, poolId, name) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}`;
		gatewayApi
			.put(url, { UpdatedName: name })
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function addRolesToPool(healthSystemId, poolId, poolRoles) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}/pool-roles`;
		gatewayApi
			.post(url, { PoolRoles: poolRoles })
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function deleteRoleFromPool(healthSystemId, poolId, poolRoleId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}/pool-roles/${poolRoleId}`;
		gatewayApi
			.delete(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function updatePoolItemOrder(healthSystemId, poolId, poolRoleId, ordinal) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}/pool-roles/${poolRoleId}`;
		gatewayApi
			.put(url, { Ordinal: ordinal })
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function assignPoolToDevice(healthSystemId, poolId, deviceId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}/devices/${deviceId}/assign`;
		gatewayApi
			.post(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function assignPoolToDevices(healthSystemId, poolId, devices) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}/assign-devices`;
		gatewayApi
			.post(url, {
				Devices: devices,
			})
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function unassignPoolFromDevice(healthSystemId, poolId, deviceId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}/devices/${deviceId}/unassign`;
		gatewayApi
			.delete(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function getNurseHealthSystemPoolRole(nurseId, healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/nurses/${nurseId}/pool-role`;
		gatewayApi
			.get(url)
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function getDevicePool(deviceId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/devices/${deviceId}/assigned-pool`;
		gatewayApi
			.get(url)
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function getDevicesPools(devicesIds) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/devices/assigned-pools`;
		gatewayApi
			.post(url, { Devices: devicesIds })
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export function getAssignedDevicesByHealthSystemId(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/assigned-devices`;
		gatewayApi
			.get(url)
			.then(response => res(response.data.devices))
			.catch(e => rej(e));
	});
}

export function deletePoolRole(healthSystemId, poolRoleId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles/${poolRoleId}`;
		gatewayApi
			.delete(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function deletePool(healthSystemId, poolId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pools/${poolId}`;
		gatewayApi
			.delete(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function getAssignedNursesByHealthSystemId(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/assigned-nurses`;
		gatewayApi
			.get(url)
			.then(response => res(response.data.assignedNurses))
			.catch(e => rej(e));
	});
}

export function addGeneralNurse(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/general-nurse`;
		gatewayApi
			.post(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function deleteGeneralNurse(healthSystemId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/general-nurse`;
		gatewayApi
			.delete(url)
			.then(() => res())
			.catch(e => rej(e));
	});
}

export function updateNursePoolRolePriority(healthSystemId, poolRoleId, nurseUserId, priorityId) {
	return new Promise((res, rej) => {
		const url = `/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/pool-roles/${poolRoleId}/assigned-nurses/${nurseUserId}/priority`;
		gatewayApi
			.put(url, {
				PriorityId: priorityId,
			})
			.then(() => res())
			.catch(e => rej(e));
	});
}

import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import { APP_CONFIG } from 'constants/global-variables';

export default class ConferenceInfo {
	/**
	 * Creates an instance of conference used to start or join
	 * @param {Number} callType
	 * @param {String} conferenceId
	 * @param {String} conferenceName
	 * @param {String} conversationId
	 * @param {String} participantId
	 * @param {Array} participants
	 * @param {*} inputDevices
	 * @param {Object} fromUser
	 * @param {Boolean} isChat
	 * @param {Boolean} isDirectToHello
	 * @param {Boolean} isMeetingRoom
	 * @param {Boolean} isMultiparty
	 * @param {Boolean} isLocked
	 * @param {Boolean} isUsingMediaServer
	 * @param {String} conferenceLink
	 * @param {String} refToken
	 */
	constructor(
		callType,
		conferenceId,
		conferenceName,
		conversationId,
		participantId,
		participants,
		inputDevices,
		fromUser,
		isChat,
		isDirectToHello,
		isMeetingRoom,
		isMultiparty,
		isLocked,
		isUsingMediaServer,
		conferenceLink = '',
		refToken = ''
	) {
		this.callType = callType;
		this.conferenceId = conferenceId;
		this.conferenceName = conferenceName;
		this.conversationId = conversationId;
		this.participantId = participantId;
		this.participants = participants;
		this.inputDevices = inputDevices;
		this.from = fromUser;
		this.isChat = isChat;
		this.isDirectToHello = isDirectToHello;
		this.isMeetingRoom = isMeetingRoom;
		this.isMultiparty = isMultiparty;
		this.isLocked = isLocked;
		this.isUsingMediaServer = isUsingMediaServer;
		this.conferenceLink = conferenceLink;
		this.source = 'WEB';
		this.refToken = refToken;
		this.localTrackTypes = [TrackType.AUDIO, TrackType.VIDEO];
		this.tenantId = APP_CONFIG.companyId;
	}
}

import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { updateNurse, getNurseProfileInfo } from 'api/users';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers';

import ProfilePicture from 'components/ProfilePicture';
import { Alert, Loader } from 'components';
import Select from 'components/Common/FormElements/Select';
import Input from 'components/Common/FormElements/Input';
import { ClientTypes, UserTypes } from 'constants/enums';

class UserInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			role: {},
		};
	}

	async componentDidMount() {
		this.setState({ loading: true });
		let profileInfo = await getNurseProfileInfo(this.props.currentMemberObj.userInfo.userId);

		this.setState({
			loading: false,
			role: profileInfo.workExperience.length !== 0 ? this.getAmwellRole(profileInfo.workExperience) : {},
		});
	}

	getAmwellRole(workExperiences) {
		return workExperiences.find(ex => ex.showOnBC && ex.company === ClientTypes.AMWELL.message);
	}

	getRoleName = roles => {
		switch (roles[0].id) {
			case UserTypes.OWNER: {
				return 'Owner';
			}
			case UserTypes.ADMIN: {
				return 'Admin';
			}
			case UserTypes.VIRTUALCAREPROVIDER: {
				return 'Virtual Care Provider';
			}
			case UserTypes.VIRTUALSITTER: {
				return 'Virtual Sitter';
			}
			case UserTypes.SUPERUSER: {
				return 'Super User';
			}
			default:
				return '';
		}
	};

	render() {
		if (this.state.loading) {
			return <Loader />;
		}

		return (
			<Formik
				initialValues={{
					firstName: decodeHtml(this.props.currentMemberObj.userInfo.firstName),
					lastName: decodeHtml(this.props.currentMemberObj.userInfo.lastName),
					role: this.state.role ? this.state.role.jobTitle : '',
				}}
				onSubmit={values => {
					this.props.onLoading('updateNurse');
					updateNurse(
						values,
						this.props.currentMemberObj.userInfo.userId,
						this.props.currentMemberObj.userInfo.id,
						this.state.role ? this.state.role.workExperienceId : 0
					).then(() => {
						this.props.onSuccess('updateNurse');
						this.props.userUpdated(this.props.currentMemberObj.userInfo.userId, values.firstName, values.lastName);
					});
				}}
				validationSchema={Yup.object().shape({
					firstName: Yup.string()
						.trim()
						.required('First name cannot be empty')
						.min(2, 'First name must be at least two characters')
						.max(50, 'First name can have a maximum of 50 characters'),
					lastName: Yup.string()
						.trim()
						.required('Last name cannot be empty')
						.min(2, 'Last name must be at least two characters')
						.max(50, 'Last name can have a maximum of 50 characters'),
				})}>
				{formikProps => {
					// bind the submission handler remotely
					this.props.bindSubmitForm(formikProps.submitForm);
					return (
						<Form>
							<h3>Modify {this.getRoleName(this.props.currentMemberObj.roles)} Information</h3>
							<div className='edit-member-form' style={{ textAlign: 'center' }}>
								<ProfilePicture
									src={this.props.currentMemberObj.userInfo.profilePicture}
									firstName={this.props.currentMemberObj.userInfo.firstName}
									lastName={this.props.currentMemberObj.userInfo.lastName}
									size='medium'
									className='margin-lr-auto'
								/>
								<p>{decodeHtml(this.props.currentMemberObj.userInfo.firstName + ' ' + this.props.currentMemberObj.userInfo.lastName)}</p>
							</div>
							<Field
								name='firstName'
								type='text'
								label='Enter First Name'
								placeholder='First Name'
								description="Modify the virtual care provider's first name"
								component={Input}
							/>
							<Field
								name='lastName'
								type='text'
								label='Enter Last Name'
								placeholder='Last Name'
								description="Modify the virtual care provider's last name"
								component={Input}
							/>
							{this.props.currentMemberObj.roles[0].id === UserTypes.VIRTUALCAREPROVIDER && (
								<Field
									name='role'
									type='select'
									label='Select Member Role'
									initialValue='Virtual Sitter'
									placeholder='Select Member Role'
									description='Please assign a role for the virtual care provider.'
									items={[
										{ id: 'Virtual Nurse', value: 'Virtual Nurse' },
										{ id: 'Virtual Case Manager', value: 'Virtual Case Manager' },
										{ id: 'Virtual Sitter', value: 'Virtual Sitter' },
										{ id: 'Virtual Intensivist', value: 'Virtual Intensivist' },
									]}
									component={Select}
								/>
							)}
							<Alert
								display={this.props.loaded}
								message='The member was successfully modified !'
								variant='success'
								onClose={() => this.props.onCloseAlert('updateNurse')}
							/>
							<Alert
								display={this.props.error}
								message='Something went wrong! Please try again. '
								variant='error'
								onClose={() => this.props.onCloseAlert('updateNurse')}
							/>
						</Form>
					);
				}}
			</Formik>
		);
	}
}

export default UserInformation;

export default class ConferenceParticipant {
	/**
	 * Creates an instance of a participant you want to invite in a conference
	 * @param {String} id
	 * @param {Number} objectId
	 * @param {Number} objectType
	 * @param {String} [name]
	 * @param {String} [jobTitle]
	 */
	constructor(id, objectId, objectType, name, jobTitle) {
		this.id = id;
		this.objectId = objectId;
		this.objectType = objectType;
		this.name = name;
		this.jobTitle = jobTitle;
	}
}

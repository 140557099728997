import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import Dropdown from 'components/Dropdown';
import ListGroup from 'components/ListGroup';
import { ObjectType, ParticipantState, RemoteParticipantAction } from 'constants/enums';
import ProfilePicture from 'components/ProfilePicture';

import { Form, Modal } from 'components';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import Video from 'calls/components/Video';

export default class ParticipantCamera extends Component {
	state = {
		isRemoveParticipantModalOpen: false,
	};

	componentDidMount() {
		this.props.participant.remoteTrackController.on(this.onStateChanged);
		this.props.participant.on(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, this.onStateChanged);
	}

	componentWillUnmount() {
		this.props.participant.remoteTrackController.off(this.onStateChanged);
		this.props.participant.off(ParticipantEvent.CAMERA_PRIVACY_STATE_CHANGED, this.onStateChanged);
	}

	onStateChanged = () => {
		this.setState({});
	};

	/**
	 *
	 * @param {*} event
	 * @param {*} item
	 */
	onPeerMediaToggleClicked = async (event, item) => {
		const { disableButtons, participant } = this.props;
		if (disableButtons || (item.id === TrackType.AUDIO && participant.isMicPrivacyOn) || (item.id === TrackType.VIDEO && participant.isCameraPrivacyOn)) {
			return;
		}

		if (item.id === RemoteParticipantAction.REMOVE) {
			this.props.onRemoveParticipant();
		} else if (item.id === RemoteParticipantAction.MAKE_HOST) {
			let response = await this.props.onTransferOwnership();
			if (response.ok) {
				this.setState({});
			}
		} else if (item.id === ParticipantEvent.PIN_FEED) {
			this.props.onPinnedFeed();
		} else {
			const { remoteTrackController } = participant;
			remoteTrackController.toggle(item.id);
		}
	};

	buildListFromStates = () => {
		const { participant, localParticipantIsInitiator, isPinned } = this.props;
		if (!localParticipantIsInitiator) {
			return [
				{
					id: ParticipantEvent.PIN_FEED,
					title: isPinned ? 'Unpin from main screen' : 'Pin to main screen',
					icon: 'push_pin',
				},
			];
		}

		const { tracks } = participant.remoteTrackController;
		const hasVideoTrack = !!tracks.get(TrackType.VIDEO);
		const hasAudioTrack = !!tracks.get(TrackType.AUDIO);
		let lists = [];

		if (participant.objectType === ObjectType.HELLO_DEVICE) {
			lists.push({
				id: TrackType.VIDEO,
				title: hasVideoTrack ? 'Camera Off' : 'Camera On',
				icon: hasVideoTrack ? 'videocam' : 'videocam_off',
				classNames: { disabled: participant.isCameraPrivacyOn },
			});
		}
		if (participant.objectType !== ObjectType.SIP_USER || participant.objectType === ObjectType.HELLO_DEVICE) {
			lists.push({
				id: TrackType.AUDIO,
				title: hasAudioTrack ? 'Mute Audio' : 'Unmute Audio',
				icon: hasAudioTrack ? 'mic' : 'mic_off',
				classNames: { disabled: participant.isMicPrivacyOn },
			});
		}
		if (participant.objectType === ObjectType.USER) {
			lists.push({
				id: RemoteParticipantAction.MAKE_HOST,
				title: 'Make host',
				icon: 'account_circle',
			});
		}
		return [
			...lists,
			...[
				{
					id: ParticipantEvent.PIN_FEED,
					title: isPinned ? 'Unpin from main screen' : 'Pin to main screen',
					icon: 'push_pin',
				},

				{
					id: RemoteParticipantAction.REMOVE,
					title: 'Remove From Visit',
					classNames: 'red',
					icon: 'person_remove',
				},
			],
		];
	};

	toggleRemoveParticipantModal = () => {
		const { isRemoveParticipantModalOpen } = this.state;
		this.setState({ isRemoveParticipantModalOpen: !isRemoveParticipantModalOpen });
	};

	render() {
		const { remoteTrackController, name: participantName, objectType, isMicPrivacyOn, isCameraPrivacyOn, state } = this.props.participant;
		const { participantVolume, isPinned } = this.props;
		const showAudioOff = !remoteTrackController.tracks.get(TrackType.AUDIO) || (objectType === ObjectType.HELLO_DEVICE && isMicPrivacyOn);
		const videoTrack = !isCameraPrivacyOn && remoteTrackController.tracks.get(TrackType.VIDEO);
		const name = participantName && participantName.split('-').length === 2 ? participantName.split('-')[1] : participantName;
		return (
			<div className={`participant${participantVolume > 0 && !showAudioOff ? ' volume-on' : ''}`}>
				<header className={showAudioOff && !this.props.localParticipantIsInitiator && 'show-audio-off'}>
					<p title={name || ''}>
						{isPinned && <i className='material-icons-outlined'>push_pin</i>}
						{state === ParticipantState.RECONNECTING.type ? 'Reconnecting' : name}
					</p>
					<div>
						<Dropdown closeDropdownOnItemClick={true} position='bottom' icon='more_horiz'>
							<ListGroup onItemClick={this.onPeerMediaToggleClicked} lists={this.buildListFromStates()} />
						</Dropdown>
						{/* {connection && <PeerStats connection={connection} />} */}
					</div>
				</header>
				<main onClick={() => this.props.onClick(TrackType.VIDEO)} className={videoTrack ? 'participant-has-video ' : ''}>
					{videoTrack && (
						<>
							<Video track={videoTrack.track} muted className={this.props.imgSrc ? 'hidden' : ''} />
						</>
					)}
					<div>
						<ProfilePicture fullName={name} size='small-to-medium' />
					</div>
					<footer>
						<p>{state === ParticipantState.RECONNECTING.type ? 'Reconnecting' : name}</p>
						{!showAudioOff && (
							<div className='participant-volume'>
								<span style={{ height: `${participantVolume * 1.5}px` }} />
								<span style={{ height: `${participantVolume * 3}px` }} />
								<span style={{ height: `${participantVolume * 4.5}px` }} />
								<span style={{ height: `${participantVolume * 3}px` }} />
								<span style={{ height: `${participantVolume * 1.5}px` }} />
							</div>
						)}
						{showAudioOff && <i className='material-icons-outlined mic_off-icon'>mic_off</i>}
					</footer>
				</main>
			</div>
		);
	}
}

ParticipantCamera.propTypes = {
	participant: PropTypes.object,
	onClick: PropTypes.func,
};

import React from 'react';
import classNames from 'classnames/bind';
import { withRouter, Route, Link } from 'react-router-dom';

const Breadcrumb = props => (
	<ul className='breadcrumb'>
		{props.links.map((link, index) => (
			<li key={index} className={classNames(props.links.length - 1 === index ? 'active' : '')} onClick={() => props.onBreadcrumbClick(props.links, index)}>
				<Link to={link.link}>{link.name}</Link>
			</li>
		))}
	</ul>
);

export default withRouter(Breadcrumb);

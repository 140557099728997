import React, { Component } from 'react';
import Select from 'react-select';

class Pagination extends Component {
	pageSizeChanged = pageSize => {
		this.props.onChange(pageSize, 0);
	};

	goToNextPage = () => {
		this.props.onChange(this.props.pageSize, this.props.pageIndex + 1);
	};

	goToPrevPage = () => {
		this.props.onChange(this.props.pageSize, this.props.pageIndex - 1);
	};

	totalNumberOfPages = () => {
		let pages = parseInt(Math.ceil(parseFloat(this.props.totalCount / this.props.pageSize.value)));
		return pages || 1;
	};

	render() {
		const options = [
			{ value: 10, label: '10' },
			{ value: 25, label: '25' },
			{ value: 50, label: '50' },
		];

		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};

		return (
			<div className='pagination-container'>
				<div className='pagination'>
					<span>Rows per page:</span>
					<Select
						menuPlacement='top'
						value={this.props.pageSize}
						classNamePrefix='custom-select'
						options={options}
						components={{ DropdownIndicator }}
						onChange={this.pageSizeChanged}
					/>
					<span>
						{' '}
						{this.props.pageIndex + 1} of {this.totalNumberOfPages()}{' '}
					</span>
					<span className='pagination-controls' data-cy='paginationControls'>
						{this.props.pageIndex !== 0 && (
							<button onClick={() => this.goToPrevPage()}>
								<i className='material-icons'>keyboard_arrow_left</i>
							</button>
						)}
						{this.props.pageIndex + 1 !== this.totalNumberOfPages() && this.totalNumberOfPages() !== 0 && (
							<button onClick={() => this.goToNextPage()}>
								<i className='material-icons'>keyboard_arrow_right</i>
							</button>
						)}
					</span>
				</div>
			</div>
		);
	}
}

export default Pagination;

let channel;
if ('BroadcastChannel' in window) {
	channel = new BroadcastChannel('notifications');
}

export const postMessage = msg => {
	if ('BroadcastChannel' in window) {
		channel.postMessage(msg);
	}
};

export const onMessage = handler => {
	if ('BroadcastChannel' in window) {
		channel.onmessage = handler;
	}
};

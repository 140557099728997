export default class FromUser {
	/**
	 * Creates an instance of the users who started the call
	 * @param {String} name
	 * @param {String} jobTitle
	 * @param {String | Object} picture
	 * @param {String} friendlyUrl
	 */
	constructor(name, jobTitle, picture, friendlyUrl) {
		this.name = name;
		this.jobTitle = jobTitle;
		this.picture = picture;
		this.friendlyUrl = friendlyUrl;
	}
}

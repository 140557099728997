import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import SocketEvents from 'constants/socket-events';

const { LocalTrackController } = require('@solaborate/webrtc/lib/TrackController');

export default class LocalTrackControllerStopTracks extends LocalTrackController {
	constructor(connection, factory, socket, conferenceId, localParticipantId, remoteParticipantId) {
		super(connection, factory);
		this.socket = socket;
		this.conferenceId = conferenceId;
		this.localParticipantId = localParticipantId;
		this.remoteParticipantId = remoteParticipantId;
	}

	/**
	 * @param {TrackType | TrackType[]} trackType
	 * @returns {Promise<any>}
	 */
	async add(trackType) {
		const result = await this.logToogleEvent(trackType);

		if (!result.ok) {
			return { deviceControlsLocked: true };
		}

		await super.add(trackType);
	}

	/**
	 *
	 * @param {TrackType | TrackType[]} trackType
	 * @returns {Promise<void>}
	 */
	async remove(trackType) {
		const req = Array.isArray(trackType) ? trackType : [trackType];
		req.forEach(type => {
			const t = this.tracks.get(type);
			if (t) {
				t.stop();
			}
		});

		await super.remove(trackType);
		this.logToogleEvent(trackType);
	}

	/**
	 *
	 * @param {TrackType | TrackType[]} trackType
	 */
	logToogleEvent(trackType) {
		const isAudio = trackType == TrackType.AUDIO || trackType.includes(TrackType.AUDIO);
		const isVideo = trackType == TrackType.VIDEO || trackType.includes(TrackType.VIDEO);
		const isScreen = trackType == TrackType.SCREEN || trackType.includes(TrackType.SCREEN);

		return this.socket.emitWithPromise(SocketEvents.Conference.TOGGLE_STREAMS, {
			isAudio: isAudio,
			isVideo: isVideo,
			isScreen: isScreen,
			audioTrackId: isAudio ? this.tracks.get(TrackType.AUDIO)?.track?.id ?? null : null,
			videoTrackId: isVideo ? this.tracks.get(TrackType.VIDEO)?.track?.id ?? null : null,
			screenTrackId: isScreen ? this.tracks.get(TrackType.SCREEN)?.track?.id ?? null : null,
			conferenceId: this.conferenceId,
			participantId: this.localParticipantId,
			actioneeParticipantId: this.remoteParticipantId ?? null,
		});
	}
}

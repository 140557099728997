import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { Modal, Form, Input, Alert } from 'components';
import { deleteOrgUnit, getLogoUrl, saveLogo, uploadLogo } from 'api/organization';
import { editHealthSystem } from 'api/healthSystems';
import { DeviceListLevel } from 'constants/enums';
import { getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { getEhrTypes } from 'api/ehrTypes';
import _ from 'lodash';
import Autocomplete from 'react-autocomplete';
import ReactCrop from 'react-image-crop';
import classNames from 'classnames';

class EditHealthSystemModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isConfirmDeleteRegionModalOpen: false,
			healthSystemName: '',
			healthSystemId: '',
			alert: {
				message: false,
				messageAlert: '',
				variantAlert: '',
			},
			healthSystemNameError: '',
			ehrTypeNameError: '',

			tenantId: null,
			tenantIdError: '',

			ehrId: null,
			ehrIdError: '',
			ehrTypes: [],
			ehrTypeName: '',
			isUploadLogoModalOpen: false,
			logoData: null,
			aiDisabled: 'true',
			currentHealthSystemLogoError: false,
			isHealthSystemLogoModalLoading: false,
			file: null,
			crop: {
				width: 200,
				height: 200,
			},
		};

		this.inputFileRef = React.createRef();

		this.hasPressedEnter = 13;
		this.healthSystemActionsEnum = {
			EDIT_HEALTH_SYSTEM: 0,
			DELETE_HEALTH_SYSTEM: 3,
		};
	}

	aiOptions = [
		{ id: 0, label: 'Enable AI', value: 'false' },
		{ id: 1, label: 'Disable AI', value: 'true' },
	];

	convertEHRTypeKey(ehrTypes) {
		return ehrTypes.map(ehr => {
			return {
				label: ehr.name,
			};
		});
	}

	async componentDidMount() {
		const { ehrId, ehrTypeName, tenantId, aiDisabled } = this.props;

		let { currentHealthSystemId } = getCurrentHealthSystemInfo();

		const { ehrTypes } = await getEhrTypes();

		const healthSystemLogo = await getLogoUrl(currentHealthSystemId);

		if (healthSystemLogo) {
			this.props.onSetHealthSystemLogo(healthSystemLogo);
		}
		this.props.setHealthSystemLogo(healthSystemLogo);

		if (ehrTypes) {
			const convertedEhrTypes = this.convertEHRTypeKey(ehrTypes);
			this.setState({
				ehrTypeName,
				ehrId,
				tenantId,
				aiDisabled,
				ehrTypes: convertedEhrTypes,
			});
		} else this.setState({ ehrTypesError: 'Could not fetch EHR types !!' });
	}

	componentDidUpdate(prevProps) {
		if (this.inputFileRef.current && this.props.isOpen && !prevProps.isOpen) {
			this.inputFileRef.current.value = '';
			this.setHealthSystemFields();
		}

		if (!_.isEmpty(this.props.currentRegions) && !_.isEqual(this.props.currentRegions, prevProps.currentRegions)) {
			this.setState({
				healthSystemName: this.props.healthSystemData.currentHealthSystemName,
			});
		}

		if (!_.isEmpty(this.props.healthSystemData) && !_.isEqual(this.props.healthSystemData, prevProps.healthSystemData)) {
			this.setHealthSystemFields();
		}
	}

	setHealthSystemFields = () => {
		this.setState({
			ehrTypeName: this.props.healthSystemData.ehrTypeName || '',
			ehrId: this.props.healthSystemData.ehrId || '',
			tenantId: this.props.healthSystemData.tenantId || '',
			aiDisabled: this.props.healthSystemData.aiDisabled || 'true',
		});
	};

	editHealthSystemActions = async data => {
		let response = null;
		switch (data.action) {
			case this.healthSystemActionsEnum.EDIT_HEALTH_SYSTEM:
				response = await editHealthSystem(data.healthSystemId, data.name, data.ehrId, data.ehrTypeName, data.tenantId, data.aiDisabled);
				break;
			case this.healthSystemActionsEnum.DELETE_HEALTH_SYSTEM:
				response = await deleteOrgUnit(DeviceListLevel.HEALTHSYSTEM, data.healthSystemId);
				break;
			default:
				break;
		}
		return response;
	};

	setHealthSystemInputValue = evt => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};

	checkIfHealthSystemExists = (hsId, hsName) => {
		return this.props.allHealthSystems.some(healthSystem => healthSystem.name.toLowerCase() === hsName.toLowerCase() && healthSystem.id !== hsId);
	};

	isValidHealthSystem = () => {
		let { currentHealthSystemId } = getCurrentHealthSystemInfo();
		let hsExists = this.checkIfHealthSystemExists(currentHealthSystemId, this.state.healthSystemName);

		const maxHealthSystemCharacters = 127;
		let healthSystemNameError = '';

		if (this.state.healthSystemName.length > maxHealthSystemCharacters) {
			healthSystemNameError = `The length of Health System name must be ${maxHealthSystemCharacters} characters or fewer.`;
		}
		if (!this.state.healthSystemName) {
			healthSystemNameError = 'Health system cannot be empty';
		}
		if (hsExists) {
			healthSystemNameError = 'Health System with this name already exists';
		}
		this.setState({
			healthSystemNameError,
		});

		return !healthSystemNameError;
	};

	isValidEhrType = () => {
		const maxEhrTypeNameCharacters = 63;
		let ehrTypeNameError = '';
		if (this.state.ehrTypeName.length > maxEhrTypeNameCharacters) {
			ehrTypeNameError = `The length of Ehr Type Name must be ${maxEhrTypeNameCharacters} characters or fewer.`;
		}
		this.setState({
			ehrTypeNameError,
		});
		return !ehrTypeNameError;
	};

	deleteSelected = async () => {
		let { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const response = await this.editHealthSystemActions({
			action: this.healthSystemActionsEnum.DELETE_HEALTH_SYSTEM,
			healthSystemId: currentHealthSystemId,
		});
		if (!response.error) {
			this.props.onHealthSystemDelete();
			this.toggleConfirmDeleteModal();
		}
	};

	toggleConfirmDeleteModal = regionId => {
		this.setState({
			isConfirmDeleteRegionModalOpen: !this.state.isConfirmDeleteRegionModalOpen,
			isRegionSelected: !!regionId,
			selectedRegionId: regionId,
			healthSystemNameError: '',
			ehrTypeNameError: '',
		});
	};

	onSelectFile = ({ target }) => {
		const [file] = target.files;

		if (!file) {
			return;
		}

		if (file.size > 600000 || !file.type.includes('png')) {
			this.setState({ currentHealthSystemLogoError: true });
			return;
		}
		const reader = new FileReader();
		reader.addEventListener('load', () => this.setState({ src: reader.result }));
		reader.readAsDataURL(file);

		this.setState({ file, isUploadLogoModalOpen: true });
	};

	getImageCrop = () => {
		const { crop } = this.state;

		const image = this.imageRef;
		const xScale = image.naturalWidth > image.width ? image.naturalWidth / image.width : 1;
		const yScale = image.naturalHeight > image.height ? image.naturalHeight / image.height : 1;

		return {
			width: crop.width * xScale,
			height: crop.height * yScale,
			x: crop.x * xScale,
			y: crop.y * yScale,
		};
	};

	uploadLogo = async () => {
		const { data, error } = await uploadLogo(this.state.file, this.getImageCrop());
		let errorStateParameters = null;

		if (error) {
			errorStateParameters = {
				fileError: error.request.status === 400,
				isEditSectorModalOpen: true,
			};
		}

		this.setState({
			isHealthSystemLogoModalLoading: false,
			isUploadLogoModalOpen: false,
			src: '',
			...errorStateParameters,
		});

		return data;
	};

	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropChange = crop => {
		this.setState({ crop });
	};

	makeClientCrop = async () => {
		this.setState({
			isHealthSystemLogoModalLoading: true,
			crop: {
				width: 200,
				height: 200,
			},
		});

		if (this.imageRef && this.state.crop.width && this.state.crop.height) {
			const imageData = await this.uploadLogo();

			if (imageData) {
				const logoData = {
					picture: imageData.originalUrl,
				};
				this.setState({ logoData });
				this.props.onSetHealthSystemLogo(imageData.originalUrl);
			}
		}
	};

	toggleHealthSystemLogoCrop = () => {
		this.setState({
			isUploadLogoModalOpen: !this.state.isUploadLogoModalOpen,
			crop: {
				width: 200,
				height: 200,
			},
		});
		this.inputFileRef.current.value = '';
	};

	handleChange = evt => {
		this.setState({
			[evt.target.name]: evt.target.value,
		});
	};

	toggleAlert = (type, messageAlert, variantAlert) => {
		this.setState(
			prevState => ({
				...prevState.alert,
				[type]: true,
				messageAlert,
				variantAlert,
			}),
			() => {
				setTimeout(() => {
					this.setState({
						[type]: false,
					});
				}, 3000);
			}
		);
	};

	emptyInput = key => {
		this.setState({ [key]: '' });
	};

	changeHealthSystem = async () => {
		let { currentHealthSystemId } = getCurrentHealthSystemInfo();
		const { healthSystemName, ehrId, ehrTypeName, tenantId, aiDisabled } = this.state;

		if (this.isValidHealthSystem() && this.isValidEhrType()) {
			const response = await this.editHealthSystemActions({
				action: this.healthSystemActionsEnum.EDIT_HEALTH_SYSTEM,
				healthSystemId: currentHealthSystemId,
				name: healthSystemName,
				ehrId,
				ehrTypeName,
				tenantId,
				aiDisabled,
			});
			if (!response.error) {
				if (this.state.logoData) {
					await saveLogo(currentHealthSystemId, this.state.logoData);
					this.props.onSetHealthSystemLogo(this.state.logoData.picture);
					this.props.setHealthSystemLogo(this.state.logoData.picture);
				}

				this.props.onHealthSystemDataChanged(healthSystemName, ehrId, ehrTypeName, tenantId, aiDisabled);

				this.toggleAlert('message', `Changes saved`, 'dark');
			} else {
				this.toggleAlert('message', `Something went wrong`, 'error');
			}
		}
	};

	validateHomeCareAttr() {
		if (!this.state.ehrId || !this.state.tenantId || !this.state.ehrTypeName) return false;
		return this.state.ehrId.trim().length && this.state.tenantId.trim().length;
	}

	handleAutocompleteChange = evt => {
		this.setState({
			ehrTypeName: evt.target.value,
		});
	};

	handleAutocompleteSelect = val => {
		this.setState({
			ehrTypeName: val,
		});
	};

	renderEhrType = (state, val) => {
		return state.label.toLowerCase().indexOf(val.toLowerCase()) !== -1;
	};

	resetFieldsOnCancel = async () => {
		let { currentHealthSystemId } = getCurrentHealthSystemInfo();

		const healthSystemLogo = await getLogoUrl(currentHealthSystemId);

		if (healthSystemLogo) {
			this.props.onSetHealthSystemLogo(healthSystemLogo);
		}

		const defaultInputFields = {
			healthSystemName: this.props.healthSystemData.currentHealthSystemName,
			ehrTypeName: this.props.healthSystemData.ehrTypeName || '',
			ehrId: this.props.healthSystemData.ehrId || '',
			tenantId: this.props.healthSystemData.tenantId || '',
			logoData: null,
			file: null,
		};

		const defaultErrors = {
			healthSystemNameError: '',
			ehrTypeNameError: '',
			tenantIdError: '',
			ehrIdError: '',
		};

		const mergedDefaultState = {
			...defaultInputFields,
			...defaultErrors,
		};

		this.setState(mergedDefaultState, () => {
			this.props.onModalClose();
		});
	};

	render() {
		return (
			<>
				<Modal
					modalSelector='editHealthSystemModal'
					className='edit-hs'
					display={this.props.isOpen}
					isLoading={this.state.isEditSystemModalLoading}
					onModalSubmit={this.changeHealthSystem}
					position='right'
					onModalClose={this.resetFieldsOnCancel}>
					<Form title='Manage Health System'>
						<div className='hs-list hs-name'>
							<Input
								onChange={this.setHealthSystemInputValue}
								name='healthSystemName'
								label='Edit Health System'
								description='Any changes you make will be applied to this health system. You can rename or delete this health system.'
								error={this.state.healthSystemNameError}
								type='text'
								value={this.state.healthSystemName}
								validationOptions={{}}
								bottomSpace='10px'
							/>
							<a onClick={() => this.toggleConfirmDeleteModal()}>
								<i className={classNames('material-icons-outlined delete', this.state.healthSystemNameError ? 'error' : '')} data-cy='deleteHSBtn'>
									delete
								</i>
							</a>
						</div>
						<div className='hs-list'>
							<div className='input filled'>
								<p className='label'>Health System Logo</p>
								{this.props.currentHealthSystemLogo && <img src={this.props.currentHealthSystemLogo} alt='logo' />}
								<p className='description'>Any changes you make will be applied to this health system. You can edit this Logo.</p>
								<input
									type='file'
									onChange={this.onSelectFile}
									ref={this.inputFileRef}
									name='healthSystemLogo'
									placeholder='Health System Logo'
									accept='.png'
								/>
								{this.state.currentHealthSystemLogoError && <small>Picture size can't exceed 600KB and file type should be .PNG.</small>}
							</div>
						</div>
						<div className='hs-list'>
							<div className='input'>
								<p className='label'>EHR Type</p>

								<Autocomplete
									getItemValue={item => item.label}
									items={this.state.ehrTypes}
									renderItem={(item, isHighlighted) => (
										<div
											style={{
												padding: 4,
												fontSize: 14,
												background: isHighlighted ? 'var(--dark-theme-gray-2)' : 'var(--dark-theme-gray-3)',
												color: 'var(--gray-0)',
											}}>
											{item.label}
										</div>
									)}
									shouldItemRender={this.renderEhrType}
									value={this.state.ehrTypeName}
									wrapperStyle={{ width: '100' }}
									onChange={this.handleAutocompleteChange}
									menuStyle={{ position: 'initial', overflow: 'auto', maxHeight: 200 }}
									inputProps={{ placeholder: 'EHR Type' }}
									onSelect={val => this.handleAutocompleteSelect(val)}
								/>
								<p>{this.state.ehrTypeNameError}</p>
							</div>
						</div>

						<div className='hs-list'>
							<Input
								onChange={this.handleChange}
								name='ehrId'
								label='EHR ID'
								description='Any changes you make will be applied to this health system. You can edit this EHR ID.'
								error={this.state.ehrIdError}
								type='text'
								value={this.state.ehrId}
								validationOptions={{ maxLength: 127 }}
								bottomSpace='0'
							/>
							<a className='delete-button' onClick={() => this.emptyInput('ehrId')}>
								<i className='material-icons-outlined delete'>delete</i>
							</a>
						</div>

						<div className='hs-list hs-list-no-bottom'>
							<Input
								onChange={this.handleChange}
								name='tenantId'
								label='Tenant ID'
								description='Any changes you make will be applied to this health system. You can edit this Tenant ID.'
								error={this.state.tenantIdError}
								type='text'
								value={this.state.tenantId}
								validationOptions={{ required: true, maxLength: 127 }}
								bottomSpace='0'
							/>
							<a className='delete-button' onClick={() => this.emptyInput('tenantId')}>
								<i className='material-icons-outlined delete'>delete</i>
							</a>
						</div>

						<div className='input-el'>
							<label className='white-color'>Select Enable/Disable AI features</label>
							<p className='white-color'>Select whether you want to enable or disable AI features in Monitoring.</p>
							<main>
								{this.aiOptions.map(item => (
									<>
										<input
											type='radio'
											id={item.label}
											name='aiDisabled'
											value={item.value}
											onChange={this.handleChange}
											checked={this.state.aiDisabled === item.value}
										/>
										<label className='white-color' htmlFor={item.label}>
											{item.label}
										</label>
									</>
								))}
							</main>
						</div>

						<Alert
							alertSelector='editHealthSystemAlertMessage'
							display={this.state.message}
							fixed={true}
							hideCloseButton={true}
							message={this.state.messageAlert}
							variant={this.state.variantAlert}
						/>
					</Form>
				</Modal>
				<Modal
					modalSelector='healthSystemModalCenter'
					display={this.state.isConfirmDeleteRegionModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteSelected}
					onModalClose={() => this.toggleConfirmDeleteModal()}>
					<Form title='Delete' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to delete {this.state.isRegionSelected ? 'location' : 'health system'}?</p>
					</Form>
				</Modal>
				<Modal
					modalSelector='uploadHospitalLogo'
					className='crop-modal'
					display={this.state.isUploadLogoModalOpen}
					position='center'
					submitButtonText='Save'
					onModalSubmit={this.makeClientCrop}
					onModalClose={this.toggleHealthSystemLogoCrop}
					isLoading={this.state.isHealthSystemLogoModalLoading}>
					{this.state.src && (
						<div>
							<ReactCrop src={this.state.src} crop={this.state.crop} ruleOfThirds={true} onImageLoaded={this.onImageLoaded} onChange={this.onCropChange} />
						</div>
					)}
				</Modal>
			</>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(EditHealthSystemModal);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter } from 'react-router-dom';
import { SectorTypes, UserRoles } from 'constants/enums';
import { getCurrentHealthSystemInfo, findSectorById, isValidSector, searchSectors, showPath, getStorage } from 'infrastructure/helpers/commonHelpers';
import { Dropdown, ListGroup, TreeView, Grid, SkeletonLoader, Form, Select, Modal, Input } from 'components';
import EditHealthSystemModal from 'components/Common/EditHealthSystemModal';
import { getUserRole } from 'infrastructure/auth';
import { getLogoUrl } from 'api/organization';
import { isMobile } from 'react-device-detect';

class SectorList extends Component {
	static defaultProps = {
		isMonitoring: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			healthSystems: [],
			isSwitchHealthSystemModalOpen: false,
			isSwitchHealthSystemModalLoading: true,
			isEditHealthSystemModalOpen: false,
			treeItems: {},
			showFeedsLimitModal: false,
			sectorSearchValue: '',
			expandAllTreeItems: false,
			currentHealthSystemLogo: null,
		};

		this.healthSystemOptions =
			getUserRole() === UserRoles.ADMIN
				? [
						{
							title: 'Switch Health System',
							icon: 'compare_arrows',
							id: 'switch_hs',
						},
						{
							title: 'Create New Health System',
							icon: 'add_circle_outline',
							id: 'create',
						},
						{
							title: 'Edit Health System',
							id: 'edit_hs',
							icon: 'edit',
						},
				  ]
				: [
						// {
						// 	title: 'Edit Health System',
						// 	id: 'edit_hs',
						// 	icon: 'edit',
						// },
				  ];
	}

	componentDidMount = async () => {
		await this.props.getAllHealthSystems();
		let currentHealthSystemInfo = getCurrentHealthSystemInfo();
		let currentHealthSystem = this.props.allHealthSystems.filter(healthSystem => {
			return currentHealthSystemInfo.currentHealthSystemId === healthSystem.id;
		});

		this.setState({
			...currentHealthSystemInfo,
			currentHealthSystemName: currentHealthSystem[0].name,
			currentHealthSystem: currentHealthSystem[0],
			currentEhrId: currentHealthSystem[0].ehrId,
			currentEhrTypeName: currentHealthSystem[0].ehrTypeName,
			currentTenantId: currentHealthSystem[0].tenantId,
			currentAiDisabled: currentHealthSystem[0].aiDisabled,
		});

		await this.props.getTreeData();
		const hasHospitals = this.hasHospitals();
		const hasDepartments = this.hasDepartments();
		if (window.location.pathname === '/health-system' && [UserRoles.ADMIN, UserRoles.SUPERUSER].includes(getUserRole()) && hasHospitals) {
			this.props.history.push(`/health-system/${this.props.currentHospitalId}`);
			if (hasDepartments) {
				this.props.history.push(`/health-system/${this.props.currentHospitalId}/department/${this.props.currentDepartmentId}`);
			}
		} else if (!this.props.isMonitoring && !hasHospitals) {
			this.props.history.push(`/health-system`);
		}
		this.setState({
			treeItems: this.getExpandedOptions(),
			treeViewKey: this.state.treeViewKey + 1,
		});
		let selectedSectorId = this.getSelectedSectorId();
		if (selectedSectorId && hasHospitals) {
			let selectedSector = findSectorById(this.props.treeData.tree, this.getSelectedSectorId());
			this.props.updateBreadcrumb(selectedSector.breadcrumb);
		}
		this.updateHealthSystemList();
	};

	componentDidUpdate = prevProps => {
		let isHealthSystemListUpdated = JSON.stringify(this.props.allHealthSystems) !== JSON.stringify(prevProps.allHealthSystems);
		if (isHealthSystemListUpdated && this.state.isSwitchHealthSystemModalOpen) {
			this.updateHealthSystemList();
		}
	};

	setHealthSystemLogo = link => {
		this.setState({
			currentHealthSystemLogo: link,
		});
	};

	getExpandedOptions = () => {
		let { healthSystem, department, floor, room } = this.props.match.params;
		let treeObj = {};
		if (healthSystem) {
			treeObj = { [healthSystem]: {} };
		}
		if (department) {
			treeObj = {
				[healthSystem]: { [department]: {} },
			};
		}
		if (floor) {
			treeObj = {
				[healthSystem]: {
					[department]: { [floor]: {} },
				},
			};
		}
		if (room) {
			treeObj = {
				[healthSystem]: {
					[department]: {
						[floor]: { [room]: {} },
					},
				},
			};
		}
		return treeObj;
	};

	getSelectedSectorId = () => {
		let { healthSystem, department, floor, room } = this.props.match.params;
		let selectedSectorId;
		if (healthSystem) {
			selectedSectorId = healthSystem;
		}
		if (department) {
			selectedSectorId = department;
		}
		if (floor) {
			selectedSectorId = floor;
		}
		if (room) {
			selectedSectorId = room;
		}
		return selectedSectorId;
	};

	updateHealthSystemList = () => {
		let healthSystems = this.transformHealthSystemArr(this.props.allHealthSystems);
		let regions = this.transformRegionsArr(this.props.allHealthSystems[healthSystems.currentHealthSystemIndex].regions);
		this.setState({
			healthSystems: healthSystems.allHealthSystems,
			currentHealthSystem: healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex],
			currentHealthSystemIndex: healthSystems.currentHealthSystemIndex,
			currentRegionId: healthSystems.allHealthSystems[healthSystems.currentHealthSystemIndex].regions[regions.currentRegionIndex].id,
			isSwitchHealthSystemModalLoading: false,
		});
	};

	onNewSector = async sector => {
		let isValid = isValidSector(sector, this.props.treeData.tree, sector.name);
		if (!isValid) {
			sector.error = `${sector.type} with this name already exists`;
			return;
		}
		sector.isNewOption = false;
		sector.subOptions = [];
		sector.tooltip = `Add ${sector.type}`;
		sector.isCreatingSector = true;
		this.createNewSector(sector);
	};

	createNewSector = async sector => {
		if (sector.type === SectorTypes.DEPARTMENT) {
			await this.props.createDepartment(sector.name);
		} else if (sector.type === SectorTypes.ROOM && !sector.defaultDepartmentId) {
			await this.props.createRoom(sector.hospitalId, sector.departmentId, sector.name);
		} else if (sector.type === SectorTypes.ROOM && sector.defaultDepartmentId) {
			await this.props.createRoom(sector.hospitalId, sector.defaultDepartmentId, sector.name);
		} else if (sector.type === SectorTypes.HOSPITAL) {
			await this.props.createHospital(sector.name);
		}
		this.props.getTreeData();
	};

	hasNoSectors = () => {
		return (
			this.props.treeData.tree.filter(sector => {
				return !sector.isNewOption && sector.name;
			}).length <= 0
		);
	};

	hasHospitals = () => {
		return Object.keys(this.props.treeData.tree[0]).length > 0;
	};

	hasDepartments = () => {
		return this.props.treeData?.tree[0]?.type === 'department';
	};

	createNewDepartment = () => {
		const hasNewSectorTextField = this.props.treeData.tree.some(item => item.isNewOption === true);
		if (!hasNewSectorTextField) {
			this.props.createNewDepartment({
				icon: 'account_balance',
				type: 'department',
				img: 'department.svg',
				isNewOption: true,
				subOptions: [],
			});
		}
	};

	createNewHospital = () => {
		const hasNewSectorTextField = this.props.treeData.tree.some(item => item.isNewOption === true);
		if (!hasNewSectorTextField) {
			this.props.createNewHospital({
				icon: 'account_balance',
				type: 'hospital',
				img: 'department.svg',
				isNewOption: true,
				subOptions: [],
			});
		}
	};

	transformRegionsArr = regions => {
		let currentRegions = [];
		let currentRegionIndex = 0;
		regions.forEach((region, regionIndex) => {
			if (region.id === this.state.currentRegionId) {
				currentRegionIndex = regionIndex;
			}
			currentRegions.push({
				value: region.name,
				id: region.id,
			});
		});
		return {
			currentRegions,
			currentRegionIndex,
		};
	};

	transformHealthSystemArr = healthSystems => {
		let allHealthSystems = [];
		let currentHealthSystemIndex = 0;
		healthSystems.forEach((healthSystem, healthSystemIndex) => {
			if (healthSystem.id === this.state.currentHealthSystemId) {
				currentHealthSystemIndex = healthSystemIndex;
			}
			allHealthSystems.push({
				value: healthSystem.name,
				name: healthSystem.name,
				id: healthSystem.id,
				firstRegion: healthSystem.regions[0],
				regions: healthSystem.regions,
				ehrId: healthSystem.ehrId,
				ehrTypeName: healthSystem.ehrTypeName,
				tenantId: healthSystem.tenantId,
				aiDisabled: healthSystem.aiDisabled,
			});
		});
		return {
			allHealthSystems,
			currentHealthSystemIndex,
		};
	};

	toggleSwitchHealthSystemModal = async () => {
		this.setState(
			{
				isSwitchHealthSystemModalOpen: !this.state.isSwitchHealthSystemModalOpen,
			},
			() => {
				this.props.getAllHealthSystems();
			}
		);
	};

	toggleEditHealthSystemModal = () => {
		this.setState(
			{
				isEditHealthSystemModalOpen: !this.state.isEditHealthSystemModalOpen,
			},
			() => {
				if (this.state.isEditHealthSystemModalOpen) {
					let currentHealthSystem = this.props.allHealthSystems.filter(healthSystem => {
						return healthSystem.id === this.state.currentHealthSystemId;
					});
					this.setState({
						currentRegions: currentHealthSystem[0].regions,
					});
				}
			}
		);
	};

	onTreeViewLinkClick = option => {
		this.props.setCurrentSectorLocation(option.treeLocation);

		if (this.props.isMonitoring) {
			this.props.onTreeViewLinkClick(option);
		} else {
			this.props.updateBreadcrumb(option.breadcrumb);
		}
	};

	changeHealthSystem = async (healthSystem, healthSystemIndex) => {
		let regions = this.transformRegionsArr(healthSystem.regions);
		const currentHealthSystemLogo = await getLogoUrl(healthSystem.id);
		this.setState({
			currentHealthSystem: healthSystem,
			currentHealthSystemIndex: healthSystemIndex,
			currentRegions: regions.currentRegions,
			currentRegionId: healthSystem.firstRegion ? healthSystem.firstRegion.id : null,
			currentRegionIndex: 0,
			currentHealthSystemLogo,
		});
	};

	setCurrentHealthSystemInfo = async (isSwitchModal = true) => {
		this.setState({
			isSwitchHealthSystemModalLoading: true,
			expandAllTreeItems: false,
		});
		const currentHealthSystemLogo = await getLogoUrl(this.state.currentHealthSystem.id);
		this.props.setHealthSystemLogo(currentHealthSystemLogo);
		let newHealthSystemInfo = {
			currentHealthSystemId: this.state.currentHealthSystem.id,
			currentRegionId: this.state.currentRegionId,
			currentHealthSystemName: this.state.currentHealthSystem.name,
			ehrId: this.state.currentHealthSystem.ehrId,
			ehrTypeName: this.state.currentHealthSystem.ehrTypeName,
			tenantId: this.state.currentHealthSystem.tenantId,
			aiDisabled: this.state.currentHealthSystem.aiDisabled,
			currentHealthSystemLogo,
		};

		getStorage().setItem('currentHealthSystemInfo', JSON.stringify(newHealthSystemInfo));
		if (!this.props.isMonitoring) {
			this.props.history.push(`/health-system`);
		}
		await this.props.getTreeData();
		if (isSwitchModal) {
			this.toggleSwitchHealthSystemModal();
		}
		this.setState({
			currentHealthSystemName: this.state.currentHealthSystem.name,
			currentHealthSystemId: this.state.currentHealthSystem.id,
			currentEhrId: this.state.currentHealthSystem.ehrId,
			currentEhrTypeName: this.state.currentHealthSystem.ehrTypeName,
			currentTenantId: this.state.currentHealthSystem.tenantId,
			currentAiDisabled: this.state.currentHealthSystem.aiDisabled,
			isSwitchHealthSystemModalLoading: false,
			sectorSearchValue: '',
			currentHealthSystemLogo,
		});

		if ([UserRoles.ADMIN, UserRoles.SUPERUSER].includes(getUserRole()) && this.hasHospitals()) {
			this.props.history.push(`/health-system/${this.props.currentHospitalId}`);
			if (this.hasDepartments()) {
				this.props.history.push(`/health-system/${this.props.currentHospitalId}/department/${this.props.currentDepartmentId}`);
			}
		}
		this.props.updateBreadcrumb([]);
		this.props.setCurrentHealthSystemInfo(newHealthSystemInfo);
	};

	onAddDevice = selection => {
		this.props.addFeed(selection);
	};

	handleHealthSystemOptions = (event, item) => {
		if (item.id === 'switch_hs') {
			this.toggleSwitchHealthSystemModal();
		} else if (item.id === 'edit_hs') {
			this.toggleEditHealthSystemModal();
		} else if (item.id === 'create') {
			this.props.history.push('/create');
		}
	};

	switchHealthSystem = () => {
		let firstHealthSystem = this.props.allHealthSystems[0];
		this.setState({
			currentHealthSystem: firstHealthSystem,
			currentHealthSystemId: firstHealthSystem.id,
			currentRegionId: firstHealthSystem.regions[0].id,
			currentHealthSystemName: firstHealthSystem.name,
			isEditHealthSystemModalOpen: false,
		});
		this.setCurrentHealthSystemInfo(false);
	};

	updateHealthSystemName = name => {
		this.setState({
			currentHealthSystemName: name,
		});

		let existingHS = getStorage().getItem('currentHealthSystemInfo');
		if (existingHS) {
			const newHSInfo = JSON.parse(existingHS);
			newHSInfo.currentHealthSystemName = name;
			getStorage().setItem('currentHealthSystemInfo', JSON.stringify(newHSInfo));
		}
	};

	toggleFeedsLimitModal = () => {
		this.setState({
			showFeedsLimitModal: !this.state.showFeedsLimitModal,
		});
	};

	setSearchValue = event => {
		const { target } = event;
		this.setState(
			{
				[target.name]: target.value,
			},
			() => {
				if (target.value.length > 1) {
					let sectors = searchSectors(this.props.treeData.tree, target.value);
					showPath(this.props.treeData.tree, sectors);
					this.setState({
						expandAllTreeItems: true,
					});
				} else {
					searchSectors(this.props.treeData.tree, '');
					this.setState({
						expandAllTreeItems: false,
					});
				}
			}
		);
	};

	isRoomURL = () => {
		let pathName = window.location.pathname.split('/');
		return pathName.some((path, index) => path.toLowerCase() === 'room' && pathName[index + 1]);
	};

	onRoomClick = () => {
		if (this.isRoomURL() && isMobile) {
			this.props.toggleTreeVisibility(false);
		}
	};

	updateHealthSystemData = (name, ehrId, ehrTypeName, tenantId, aiDisabled) => {
		this.setState({
			currentHealthSystemName: name,
			currentEhrId: ehrId,
			currentEhrTypeName: ehrTypeName,
			currentTenantId: tenantId,
			currentAiDisabled: aiDisabled,
		});

		let newHealthSystemTypeInfo = {
			currentHealthSystemId: this.state.currentHealthSystem.id,
			currentRegionId: this.state.currentRegionId,
			currentHealthSystemName: name,
			currentHealthSystemLogo: this.state.currentHealthSystemLogo,
			ehrId,
			ehrTypeName,
			tenantId,
			aiDisabled,
		};
		getStorage().setItem('currentHealthSystemInfo', JSON.stringify(newHealthSystemTypeInfo));
	};

	render() {
		const userRole = getUserRole();
		return (
			<>
				{this.props.treeData.tree.length > 0 && (
					<>
						<header className={[UserRoles.ADMIN].includes(userRole) ? 'admin-header' : ''}>
							<div className='header-title' data-tooltip={this.state.currentHealthSystemName} data-position='bottom'>
								<h2>{this.state.currentHealthSystemName}</h2>
							</div>
							{[UserRoles.ADMIN].includes(userRole) && (
								<Dropdown position='bottom' icon='more_horiz'>
									<ListGroup onItemClick={this.handleHealthSystemOptions} lists={this.healthSystemOptions} />
								</Dropdown>
							)}
						</header>
						{[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) && (
							<button type='button' className='create-hospital' data-cy='createHospital' onClick={this.createNewHospital}>
								<i className='material-icons-outlined'>add_box</i>
								<span>Create Hospital</span>
							</button>
						)}
						{this.hasHospitals() && (
							<Input
								type='text'
								name='sectorSearchValue'
								placeholder='Search'
								value={this.state.sectorSearchValue}
								onChange={this.setSearchValue}
								validationOptions={{}}
								bottomSpace='15px'
								autoComplete='off'
							/>
						)}
						<TreeView
							key={this.state.treeViewKey}
							onAddDevice={this.onAddDevice}
							data={this.props.treeData.tree}
							preSelected={this.state.treeItems}
							onAdd={this.onNewSector}
							onLinkClick={this.onTreeViewLinkClick}
							isMonitoring={this.props.isMonitoring}
							selectedSectorId={this.getSelectedSectorId()}
							expandAll={this.state.expandAllTreeItems}
							onRoomClick={this.onRoomClick}
						/>
					</>
				)}
				{!this.props.treeData.tree.length && (
					<Grid width='100%' horizAlign='center'>
						<SkeletonLoader rows={15} padding='20px 0' />
					</Grid>
				)}
				<Modal
					modalSelector='switchHealthSystemModal'
					display={this.state.isSwitchHealthSystemModalOpen}
					isLoading={this.state.isSwitchHealthSystemModalLoading}
					onModalSubmit={this.setCurrentHealthSystemInfo}
					position='left'
					onModalClose={this.toggleSwitchHealthSystemModal}
					className='hs-modal-wrapper'>
					<Form title={userRole === UserRoles.SUPERUSER ? 'Switch Location' : 'Switch Health System or Location'}>
						{userRole !== UserRoles.SUPERUSER && (
							<Select
								type='text'
								label='Select Health System'
								name='currentHealthSystemId'
								defaultValue={this.state.currentHealthSystemIndex}
								onSelect={this.changeHealthSystem}
								items={this.state.healthSystems}
								description='Choose the health system you want to manage.'
								placeholder='Select Health System'
							/>
						)}
					</Form>
				</Modal>
				<Modal
					className='feeds-limit'
					display={this.state.showFeedsLimitModal}
					onModalClose={this.toggleFeedsLimitModal}
					closeButtonText='Close'
					position='center'>
					<Form title='Maximum feeds reached'>
						<p>You have reached the maximum number of rooms to monitor. You can close one of them in order to add another.</p>
					</Form>
				</Modal>
				<EditHealthSystemModal
					onHealthSystemDelete={this.switchHealthSystem}
					onHealthSystemNameChange={this.updateHealthSystemName}
					onHealthSystemDataChanged={this.updateHealthSystemData}
					isOpen={this.state.isEditHealthSystemModalOpen}
					onModalClose={this.toggleEditHealthSystemModal}
					onSetHealthSystemLogo={this.setHealthSystemLogo}
					currentHealthSystemLogo={this.state.currentHealthSystemLogo}
					healthSystemData={{
						currentHealthSystemName: this.state.currentHealthSystemName,
						currentHealthSystemId: this.state.currentHealthSystemId,
						ehrId: this.state.currentEhrId,
						ehrTypeName: this.state.currentEhrTypeName,
						tenantId: this.state.currentTenantId,
						aiDisabled: this.state.currentAiDisabled,
					}}
					currentRegions={this.state.currentRegions}
				/>
			</>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(SectorList));

import React, { Component } from 'react';
import Button from 'components/Button';
import classNames from 'classnames/bind';
import { If, Then, Else } from 'react-if';
import Loader from 'components/Loader';

class Modal extends Component {
	constructor(props) {
		super(props);
		this.modalReference = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyPress);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyPress);
	}

	handleKeyPress = event => {
		if (event.key === 'Escape' && this.props.display === true) {
			this.props.onModalClose();
		}
	};

	handleOutsideClick = event => {
		let modalClass = `.${this.modalReference.current.classList[0]}`;
		if (!event.target.closest(modalClass)) {
			this.props.onModalClose();
		}
	};
	render() {
		return (
			<div
				data-cy={this.props.modalSelector}
				className={classNames(
					'modal',
					this.props.className,
					this.props.position,
					this.props.display ? 'show' : '',
					this.props.isLoading ? 'loading' : '',
					this.props.hideActionButtons ? 'hide-buttons' : ''
				)}
				onClick={evt => this.handleOutsideClick(evt)}>
				<div className='modal__wrapper' ref={this.modalReference}>
					<div className='modal__content' data-test-id='modal-content'>
						{this.props.children}
					</div>

					{!this.props.hideCloseButton && (
						<a className='modal__close' onClick={this.props.onModalClose}>
							<i className='material-icons-outlined'>close</i>
						</a>
					)}
					<div className='modal__actions'>
						<If condition={!!this.props.isLoading}>
							<Then>
								<div className='modal__loader'>
									<Loader />
								</div>
							</Then>
							<Else>
								{!this.props.hideCloseButton && (
									<Button
										className='cancel-btn'
										text={this.props.closeButtonText ? this.props.closeButtonText : 'Cancel'}
										variant='white'
										size='large'
										onClick={event => this.props.onModalClose(event, true)}
									/>
								)}
								{this.props.submitButtonText !== '' && (
									<Button
										className='save-btn'
										text={this.props.submitButtonText ? this.props.submitButtonText : 'Save'}
										size='large'
										onClick={this.props.onModalSubmit}
										isDisabled={this.props.isSubmitDisabled}
									/>
								)}
							</Else>
						</If>
					</div>
				</div>
			</div>
		);
	}
}

export default Modal;

import { CallTypes, MediaDeviceKinds } from 'constants/enums';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import _ from 'lodash';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

const defaultDevicesStoreKey = 'defaultMediaDevices';

/**
 * @typedef {Object} TrackTypes
 * @property {TrackType[]} remoteTrackTypes - The X Coordinate
 * @property {TrackType[]} localTrackTypes - The Y Coordinate
 */

/**
 * Method used to return track types from call type
 * @param {Number} callType
 * @returns {TrackTypes}
 */
export const callTypeToTrackTypes = callType => {
	let localTrackTypes = [TrackType.AUDIO, TrackType.VIDEO];
	let remoteTrackTypes = [TrackType.AUDIO, TrackType.VIDEO];

	if (callType === CallTypes.SECURITYCAM) {
		localTrackTypes = [];
	} else if (callType === CallTypes.MONITORING) {
		localTrackTypes = [];
		remoteTrackTypes = [TrackType.VIDEO];
	}

	return {
		localTrackTypes,
		remoteTrackTypes,
	};
};

/**
 * Console helper function to print a table with transceivers directions
 * @example
 * checkTransceivers([TrackType.AUDIO, TrackType.VIDEO], [TrackType.AUDIO, TrackType.VIDEO, TrackType.SCREEN], connection[0].pc)
 * checkTransceivers(['audio', 'video'], ['audio', 'video'], connection[0].pc)
 * @param {string[]} offeringTracks
 * @param {string[]} receivingTracks
 * @param {RTCPeerConnection} peerConnection
 */
export const checkTransceivers = (offeringTracks, receivingTracks, peerConnection) => {
	const result = [];
	const MID = new Map();
	MID.set(TrackType.AUDIO, '0');
	MID.set(TrackType.VIDEO, '1');
	MID.set(TrackType.SCREEN, '2');
	const transceivers = peerConnection.getTransceivers();
	[TrackType.AUDIO, TrackType.VIDEO, TrackType.SCREEN].map(async type => {
		const offeringThisTrack = offeringTracks.includes(type);
		const receivingThisTrack = receivingTracks.includes(type);
		if (!offeringThisTrack && !receivingThisTrack) {
			return;
		}

		const transceiver = transceivers.find(t => t.mid === MID.get(type));
		let hasCorrectDirection = false;
		let correctDirection = '';
		if (offeringThisTrack && !receivingThisTrack) {
			hasCorrectDirection = transceiver.direction === 'sendonly';
			correctDirection = 'sendonly';
		} else if (!offeringThisTrack && receivingThisTrack) {
			hasCorrectDirection = transceiver.direction === 'recvonly';
			correctDirection = 'recvonly';
		} else {
			hasCorrectDirection = transceiver.direction === 'sendrecv';
			correctDirection = 'sendrecv';
		}
		result.push({
			trackType: type,
			currentDirection: transceiver.direction,
			correctDirection,
			hasCorrectDirection,
		});
	});

	console.table(result);
	return result;
};

// TODO Not ready to be used
export const getConferenceName = (isChat, isDirectToHello, fromUser, helloParticipant) => {
	if (isDirectToHello) {
		return helloParticipant.name;
	}

	if (isChat) {
		return fromUser.name;
	}
};

/**
 * Method used to validate an email
 * @param {String} email
 */
export const isEmail = email => {
	if (!email || email === null) {
		return false;
	}
	const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegEx.test(email);
};

/**
 * Set audio element audio output id
 * @param {any} element HTMLMediaElement
 * @param {string} deviceId
 */
export const attachSinkId = async (element, deviceId) => {
	if (!element || !deviceId) {
		console.warn('Arguments missing!');
		return;
	}

	try {
		await element.setSinkId(deviceId);
	} catch (error) {
		console.warn(error);
	}
};

/**
 * Check if media device deviceId is 'default' and filter with groupId if true
 * else return deviceId directly
 * @param {MediaDeviceInfo} device
 * @param {MediaDeviceInfo[]} [mediaDevices]
 * @returns {Promise<string>} Device unique ID
 *
 * @example
 * 		getDefaultMediaDeviceId({
 *			deviceId: 'default',
 *			kind: 'audiooutput',
 *			label: 'Default - Speakers (Realtek(R) Audio)',
 *			groupId: 'd97d6a6618732296023679dc0976812ceebd069baf62c8c56a9ae4b84e2f8008'
 *		})
 */
export const getMediaDeviceUId = async (device, mediaDevices = null) => {
	if (!device) {
		return '';
	}

	if (device.deviceId !== 'default') {
		return device.deviceId;
	}

	const mediaDevice = (mediaDevices || (await navigator.mediaDevices.enumerateDevices())).find(
		d => d.kind === device.kind && d.deviceId !== 'default' && d.groupId === device.groupId
	);
	if (!mediaDevice) {
		return '';
	}

	return mediaDevice.deviceId;
};

/**
 * @param {MediaDeviceKind} kind
 * @returns {string}
 */
export const getDeviceListKey = kind => {
	let key = 'cameraList';
	if (kind === MediaDeviceKinds.AUDIO_INPUT) {
		key = 'micList';
	} else if (kind === MediaDeviceKinds.AUDIO_OUTPUT) {
		key = 'speakersList';
	}

	return key;
};

/**
 * Get filtered input/output media devices
 * @returns {Promise<{ micList: MediaDeviceInfo[], cameraList: MediaDeviceInfo[], speakersList: MediaDeviceInfo[]}>}
 *
 */
export const getFilteredDeviceLists = async () => {
	const mediaDevices = await navigator.mediaDevices.enumerateDevices();

	return mediaDevices.reduce(
		(acc, device) => {
			const key = getDeviceListKey(device.kind);

			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(device);

			return acc;
		},
		{ micList: [], cameraList: [], speakersList: [] }
	);
};

/**
 * Set selected media devices in localStorage
 * @param {{ micList?: MediaDeviceInfo[], cameraList?: MediaDeviceInfo[], speakersList?: MediaDeviceInfo[]}} selectedDevices
 */
export const storeDefaultMediaDevices = selectedDevices => {
	const defaultDevices = getStorage().getItem(defaultDevicesStoreKey);
	const parsedDefaultDevices = defaultDevices ? JSON.parse(defaultDevices) : {};

	getStorage().setItem(defaultDevicesStoreKey, JSON.stringify({ ...parsedDefaultDevices, ...selectedDevices }));
};

/**
 * Get default media devices from store
 * Re-set navigator.mediaDevices in store because MediaDevice.groupId might change after page refresh
 * @returns {Promise<{ micList?: MediaDeviceInfo[], cameraList?: MediaDeviceInfo[], speakersList?: MediaDeviceInfo[]}>}
 */
export const getStoredDefaultMediaDevices = async () => {
	const defaultMediaDevices = JSON.parse(getStorage().getItem(defaultDevicesStoreKey));
	if (!defaultMediaDevices) {
		return null;
	}

	const mediaDevices = await navigator.mediaDevices.enumerateDevices();
	const defaultMediaDevicesToStore = Object.values(defaultMediaDevices).reduce((acc, device) => {
		const mediaDevice = mediaDevices.find(d => d.deviceId === device?.deviceId && d.kind === device?.kind);
		if (mediaDevice && mediaDevice.deviceId) {
			acc[mediaDevice.kind] = mediaDevice;
		}
		return acc;
	}, {});

	if (!_.isEmpty(defaultMediaDevicesToStore)) {
		storeDefaultMediaDevices(defaultMediaDevicesToStore);
	}

	return defaultMediaDevicesToStore;
};

export const isMobileOrTabletDevice = () => 'ontouchstart' in document.documentElement && 'orientation' in window && navigator.maxTouchPoints > 0;

import { NursePoolingActionTypes } from 'constants/action-types';

const initialState = {
	currentHealthSystem: null,
};

const nursePooling = (state, action) => {
	const newState = state ? { ...state } : { ...initialState };

	if (action.type === NursePoolingActionTypes.SET_CURRENT_HEALTH_SYTEM) {
		return { ...newState, currentHealthSystem: action.payload };
	}

	return newState;
};

export default nursePooling;

import React, { useEffect, useState } from 'react';
import { Grid, CallButton, TvControls, Dropdown, Form, Modal } from 'components';
import { TrackType } from '@solaborate/webrtc/lib/Tracks';
import CallDuration from 'calls/components/CallDuration';
import NightVisionControl from 'calls/components/NightVisionControl';
import { CallTypes, CameraType, ObjectType } from 'constants/enums';
import classNames from 'classnames/bind';
import { amwellIconLink } from 'constants/global-variables';
import { isMobile } from 'react-device-detect';

const CallFooter = props => {
	const {
		conferenceName,
		patientParticipant,
		localParticipant,
		callStartTime,
		disableButtons,
		callType,
		isInviteVisible,
		toggleGridView,
		togglePTZ,
		toggleTrack,
		endCall,
		activeParticipant,
		isGridView,
		openCamSettingsView,
		isMaximizedFrame,
		isFrameWindow,
		onToggleMaximize,
		participants,
		closeInviteParticipantModal,
		toggleMobileCameraSwitch,
	} = props;

	const getPtzButton = ({ className, tooltip, text, showToggleBtn, isDisabled }) => {
		return (
			<CallButton
				imgIcon={`${amwellIconLink}${patientParticipant.showPTZ ? 'ptz-line.svg' : 'ptz.svg'}`}
				wrapperClassName={className}
				onClick={togglePTZ}
				isActive={patientParticipant.showPTZ}
				tooltipPosition='top'
				tooltip={tooltip ? (patientParticipant.showPTZ ? 'Hide PTZ' : 'Show PTZ') : ''}
				isDisabled={isDisabled}
				text={text}
				showToggleBtn={showToggleBtn}
			/>
		);
	};

	const shouldHideOnPatientView = callType !== CallTypes.SECURITYCAM;
	const localTracks = localParticipant.localTrackController.tracks;
	const hasLocalAudioTrack = localTracks.get(TrackType.AUDIO);
	const hasLocalVideoTrack = localTracks.get(TrackType.VIDEO);
	const hasLocalScreenTrack = localTracks.get(TrackType.SCREEN);
	const hasLocalScreenShareTrack = localTracks.get(TrackType.SCREENAUDIO);

	const hasPatientParticipantAudioTrack = patientParticipant && patientParticipant.remoteTrackController.tracks.get(TrackType.AUDIO);
	const hasPatientParticipantVideoTrack = patientParticipant && patientParticipant.remoteTrackController.tracks.get(TrackType.VIDEO);
	const isActivePatientParticipant = activeParticipant && activeParticipant.objectType === ObjectType.HELLO_DEVICE;
	const [volumeRange, setVolumeRange] = useState(hasLocalScreenShareTrack?.volume ?? 100);
	const [isMuted, setIsMuted] = useState(false);
	const [previousVolume, setIsPreviousVolume] = useState(hasLocalScreenShareTrack?.volume ?? 100);
	const [showCecNotSupportedModal, setShowCecNotSupportedModal] = useState(false);

	useEffect(() => {
		setVolumeRange(hasLocalScreenShareTrack?.volume);
	}, [hasLocalScreenShareTrack]);

	useEffect(() => {
		const vol = isMuted ? 0 : previousVolume;
		setVolumeRange(vol);
		if (hasLocalScreenShareTrack?.volume !== undefined) {
			hasLocalScreenShareTrack.volume = +vol;
		}
	}, [isMuted]);

	return (
		<>
			<footer>
				<div onClick={event => event.stopPropagation()}>
					<Grid
						className={classNames(
							'call-view__footer',
							!isMaximizedFrame ? ' footer-maximized-frame' : '',
							isInviteVisible ? ' footer-not-expanded' : '',
							[...participants.values()].length > 1 ? ' footer-multiple-participants' : '',
							isGridView ? ' footer-grid-view' : ''
						)}
						vertAlign='center'
						columns='1fr'>
						<div className='call-view__footer--desc'>
							<h3>
								{conferenceName}
								{/* {healthSystemPath && (
								<span data-tooltip={healthSystemPath} data-position='top'>
									<i className='material-icons-outlined'>info</i>
								</span>
							)} */}
							</h3>
							{callStartTime && <CallDuration callStartTime={callStartTime} />}
						</div>
						<div>
							{shouldHideOnPatientView && (
								<CallButton
									icon={hasLocalVideoTrack ? 'videocam' : 'videocam_off'}
									name='camera'
									isActive={hasLocalVideoTrack}
									onClick={() => toggleTrack(TrackType.VIDEO, !hasLocalVideoTrack)}
									text='Camera'
									isDisabled={disableButtons}
								/>
							)}
							{!shouldHideOnPatientView && (
								<CallButton
									icon={hasLocalAudioTrack ? 'mic' : 'mic_off'}
									name='mic'
									isActive={hasLocalAudioTrack}
									onClick={() => toggleTrack(TrackType.AUDIO, !hasLocalAudioTrack)}
									text='Mic'
									isDisabled={disableButtons}
								/>
							)}

							{shouldHideOnPatientView && (
								<CallButton
									icon={hasLocalAudioTrack ? 'mic' : 'mic_off'}
									name='mic'
									isActive={hasLocalAudioTrack}
									onClick={() => toggleTrack(TrackType.AUDIO, !hasLocalAudioTrack)}
									text='Mic'
									isDisabled={disableButtons}
								/>
							)}
							{localParticipant.isInitiator && patientParticipant && (
								<TvControls
									participant={patientParticipant}
									callType={callType}
									isDisabled={disableButtons}
									toggleCecNotSupportedModal={() => setShowCecNotSupportedModal(prevState => !prevState)}
								/>
							)}
							{patientParticipant && !shouldHideOnPatientView && (
								<CallButton
									icon={hasPatientParticipantAudioTrack ? 'volume_up' : 'volume_off'}
									isActive={hasPatientParticipantAudioTrack}
									onClick={() => {
										if (patientParticipant.isMicPrivacyOn) return;
										patientParticipant.remoteTrackController.toggle(TrackType.AUDIO);
									}}
									text='Audio'
									isDisabled={disableButtons || patientParticipant.isMicPrivacyOn}
								/>
							)}

							{isMaximizedFrame && (
								<Dropdown
									position='top'
									title='More'
									imageUrl={`${amwellIconLink}aw-view-more.svg`}
									className='call-dropdown-btn call-button-wrapper more-dropdown'
									isDropdownTitleVisible={true}
									dropdownInnerTitle='More'
									onClick={closeInviteParticipantModal}
									closeDropdownOnItemClick={true}>
									{shouldHideOnPatientView && !isMobile && (
										<div className='flex'>
											{hasLocalScreenShareTrack && (
												<div className='flex shared-volume-range'>
													<span onClick={() => setIsMuted(prevState => !prevState)}>
														<img src={`${amwellIconLink}${isMuted ? 'muted.svg?v3' : 'volume.svg'}`} alt='volume icon' />
													</span>
													<div className='volume-dropdown mpi-volume-dropdown'>
														<span className='volume-value'>{volumeRange}</span>
														<input
															type='range'
															id='start'
															name='volume'
															min='0'
															max='100'
															value={volumeRange}
															onChange={event => {
																setVolumeRange(event.target.value);
																setIsPreviousVolume(event.target.value);
																hasLocalScreenShareTrack.volume = +event.target.value;
																setIsMuted(false);
															}}
														/>
														<i className='material-icons-outlined'>volume_up</i>
													</div>
												</div>
											)}
											<CallButton
												imgIcon={`${amwellIconLink}${hasLocalScreenTrack ? 'stop-share.svg' : 'share.svg'}`}
												name='screenshare'
												isActive={hasLocalScreenTrack}
												onClick={() => toggleTrack(TrackType.SCREEN, !hasLocalScreenTrack)}
												isDisabled={disableButtons}
												wrapperClassName='call-footer-btn-wrapper call-button-remove-mobile'
												text={hasLocalScreenTrack ? 'Stop Sharing' : 'Share'}
											/>
										</div>
									)}

									{!isGridView && localParticipant.isInitiator && patientParticipant && patientParticipant.objectType === ObjectType.HELLO_DEVICE && (
										<div className='flex ptz-btn-wrapper'>
											{getPtzButton({
												className: 'call-footer-btn-wrapper',
												tooltip: false,
												text: patientParticipant.showPTZ ? 'Hide PTZ' : 'Show PTZ',
												showToggleBtn: false,
												isDisabled: !hasPatientParticipantVideoTrack || !isActivePatientParticipant || disableButtons,
											})}
										</div>
									)}
									{patientParticipant && localParticipant.isInitiator && patientParticipant.cameraType === CameraType.HELLO && (
										<div>
											<NightVisionControl participant={patientParticipant} showButton={true} isDisabled={!hasPatientParticipantVideoTrack || disableButtons} />
										</div>
									)}

									{shouldHideOnPatientView && (
										<div className='flex'>
											<CallButton
												imgIcon={`${amwellIconLink}${isGridView ? 'default-view.svg' : 'grid-view.svg'}`}
												name='gridview'
												isActive={isGridView}
												onClick={toggleGridView}
												isDisabled={disableButtons}
												wrapperClassName='call-footer-btn-wrapper'
												text={isGridView ? 'Default View' : 'Grid View'}
											/>
										</div>
									)}
									{/* {shouldHideOnPatientView && localParticipant.isInitiator && (
								<div className='flex'>
									<CallButton
										icon='lock'
										name='lock'
										isActive={false}
										onClick={toggleLockRoomModal}
										isDisabled={disableButtons}
										wrapperClassName='call-footer-btn-wrapper'
										text='Lock Room'
									/>
								</div>
							)} */}
									{localParticipant.isInitiator && patientParticipant && (
										<TvControls participant={patientParticipant} callType={callType} isDisabled={disableButtons} />
									)}
									<div className='flex'>
										<div className='call-button-wrapper call-footer-btn-wrapper' onClick={openCamSettingsView}>
											<div className={classNames('call-button', 'active')}>
												<img src={`${amwellIconLink}device-settings.svg`} alt='cam icon' />
											</div>
											<span>Device Settings</span>
										</div>
									</div>
									{isMaximizedFrame && isFrameWindow && (
										<div className='flex'>
											<div className='call-button-wrapper call-footer-btn-wrapper' onClick={onToggleMaximize}>
												<div className={classNames('call-button', 'active')}>
													<i className='material-icons'>close_fullscreen</i>
												</div>
												<span>Minimize</span>
											</div>
										</div>
									)}
								</Dropdown>
							)}
							{isMobile && hasLocalVideoTrack && (
								<CallButton
									imgIcon={`${amwellIconLink}switch.svg`}
									name='switch'
									text='Switch'
									onClick={toggleMobileCameraSwitch}
									isDisabled={disableButtons}
								/>
							)}

							<CallButton icon='close' name='endcall' isActive={true} variant='end' onClick={endCall} text='End' isDisabled={disableButtons} />
						</div>
						<div />
					</Grid>
					{!isMaximizedFrame && (
						<button className='call-maximize' type='button' onClick={onToggleMaximize}>
							<i className='material-icons'>open_in_full</i>
						</button>
					)}
				</div>
			</footer>
			<Modal
				display={showCecNotSupportedModal}
				position='center'
				closeButtonText='Close'
				submitButtonText=''
				onModalClose={() => setShowCecNotSupportedModal(false)}
				className='z-10000'>
				<Form title='Unsupported TV'>
					<p>This command is not supported on this patient's TV.</p>
				</Form>
			</Modal>
		</>
	);
};

export default CallFooter;

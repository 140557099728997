import React from 'react';
import { Modal, Form } from 'components';

export default function DeviceControlsLocked(props) {
	return (
		<Modal display={true} position='center' closeButtonText='Dismiss' submitButtonText='' onModalClose={props.close} className='device-unavailable-modal'>
			<Form title='Device unavailable'>
				<p>A Virtual Care Provider is in a call with this device. You will be able to use these controls as soon as the call ends.</p>
			</Form>
		</Modal>
	);
}

import { NotificationsCounterActionTypes } from 'constants/action-types';

const initialState = {
	notificationsCounter: 0,

	fetchingNotificationCounter: false,
};

const notifications = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case NotificationsCounterActionTypes.FETCHING_NOTIFICATIONS_COUNTER:
			return {
				...state,
				fetchingNotificationCounter: true,
			};
		case NotificationsCounterActionTypes.FETCH_NOTIFICATIONS_COUNTER_FAILURE:
			return {
				...state,
				fetchingNotificationCounter: false,
			};
		case NotificationsCounterActionTypes.FETCH_NOTIFICATIONS_COUNTER_SUCCESS:
			return {
				...state,
				fetchingNotificationCounter: false,
				notificationsCounter: action.data,
			};
	}

	return state;
};

export default notifications;

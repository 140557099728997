import moment from 'moment';

export function formatDate(dateObj) {
	let month = '' + (dateObj.getMonth() + 1);
	let day = '' + dateObj.getDate();
	let year = dateObj.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [month, day, year].join('/');
}

export function formatTime(dateObj) {
	let h = '' + dateObj.getHours();
	let m = '' + dateObj.getMinutes();

	if (h.length < 2) h = '0' + h;
	if (m.length < 2) m = '0' + m;

	return h + ':' + m;
}

export function formatDateTime(dateTimeObj) {
	let month = '' + (dateTimeObj.getMonth() + 1);
	let day = '' + dateTimeObj.getDate();
	let year = dateTimeObj.getFullYear();
	let hour = '' + dateTimeObj.getHours();
	let minutes = '' + dateTimeObj.getMinutes();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;
	if (hour.length < 2) hour = '0' + hour;
	if (minutes.length < 2) minutes = '0' + minutes;

	return [day, month, year].join('/') + '-' + hour + ':' + minutes;
}

export const calculateDuration = (startedAt, endedAt, hideDays = false) => {
	if (!endedAt) {
		return 'N/A';
	}
	let delta = Math.abs(startedAt - endedAt) / 1000;

	const days = Math.floor(delta / 86400);
	delta -= days * 86400;

	let hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;

	let minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;

	let seconds = Math.round(delta % 60);

	const durationString = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

	if (hideDays || days === 0) {
		return durationString;
	}
	return `${days}d ${durationString}`;
};

export function durationUntilNow(utcDateTime) {
	const k = moment(moment.utc().format()).diff(moment.utc(utcDateTime));
	const days = Math.floor(k / 1000 / 86400);
	const f = days > 0 ? `${days}[d] HH:mm:ss` : 'HH:mm:ss';
	return moment.utc(moment(moment.utc().format()).diff(moment.utc(utcDateTime))).format(f);
}

export function utcToLocalTime(date) {
	const stillUtc = moment.utc(date).toDate();
	const localDate = moment(stillUtc);
	return localDate;
}

export const unixToDate = unix => {
	const date = moment.unix(unix);
	return moment(moment.utc(date).toDate());
};

export const getDateDifference = (start, end) => {
	return moment.duration(moment(end).diff(start)).asDays();
};

export function formattedDate(date) {
	const currentDate = moment.utc(date).local();
	let res = moment.utc(currentDate).format('MMM DD, YYYY, LT');
	const isThisYear = moment().year() - currentDate.year() === 0;
	const isToday = currentDate.diff(moment(), 'days') === 0;
	if (isThisYear) {
		res = moment
			.utc(currentDate)
			.local()
			.format('MMM DD, LT');
	}
	if (isToday) {
		res = moment
			.utc(currentDate)
			.local()
			.format('LT');
	}
	return res;
}

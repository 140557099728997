import React, { Component } from 'react';

import Pagination from 'components/Common/Pagination';
import { Grid, Input, Table, Button, Badge, Modal } from 'components';

import { getInvitedMembers, updateInviteStatus, createInvite, createNurseInvite, getHealthSystemInvitedUsers } from 'api/users';
import { InviteStatuses, InviteTypes, UserRoles } from 'constants/enums';
import { formatDate } from 'infrastructure/helpers/dateHelper';
import { decodeHtml, getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { getUserProfile, getUserRole } from 'infrastructure/auth';

class InvitedUsersList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pageSize: { value: 10, label: '10' },
			pageIndex: 0,
			totalCount: 0,
			invitedMembers: [],
			loading: true,
			searchValue: '',
			isCancelModalOpen: false,
			isResendModalOpen: false,
			currentInviteId: null,
			currentHealthSystemId: null,
		};
	}

	async componentDidMount() {
		let response = {};
		let healthSystem = getCurrentHealthSystemInfo();

		if (getUserRole() === UserRoles.ADMIN) {
			response = await getInvitedMembers();
		} else {
			response = await getHealthSystemInvitedUsers(healthSystem.currentHealthSystemId);
		}

		this.setState({
			loading: false,
			totalCount: response.totalCount,
			invitedMembers: response.companyInvites,
			currentHealthSystemId: healthSystem.currentHealthSystemId,
		});
	}

	async onChange(pageSize, pageIndex) {
		this.setState({ loading: true });

		let response = {};
		if (getUserRole() === UserRoles.ADMIN) {
			response = await getInvitedMembers(pageSize.value, pageIndex, this.state.searchValue);
		} else {
			response = await getHealthSystemInvitedUsers(this.state.currentHealthSystemId, pageSize.value, pageIndex, this.state.searchValue);
		}

		this.setState({
			loading: false,
			invitedMembers: response.companyInvites,
			pageSize: pageSize,
			pageIndex: pageIndex,
			totalCount: response.totalCount,
		});
	}

	displayInvitedUsers() {
		if (this.state.invitedMembers.length === 0) return [];

		return this.state.invitedMembers.map(user => {
			return {
				emailBox: this.getEmailBox(user.email),
				date: formatDate(new Date(user.dateCreated)),
				invitedBy: this.getProfileBox(
					decodeHtml(`${user.sender.companyUser.firstName} ${user.sender.companyUser.lastName}`),
					getUserProfile().userId === user.sender.companyUser.userId
				),
				location: user.invitationInfo ? user.invitationInfo.teamName : 'N/A',
				// status: this.getStatus(user.inviteStatusId),
				role: this.getInviteType(user.inviteTypeId),
				edit: user.inviteStatusId !== InviteStatuses.ACCEPTED.type && this.getEditButtons(user.id),
			};
		});
	}

	getEditButtons(inviteId) {
		return (
			<div className='wrapped'>
				<div data-tooltip='Resend Invite' data-position='bottom'>
					<i className='material-icons-outlined boxed-icon' data-cy='resendInviteBtn' onClick={() => this.openModal('isResendModalOpen', inviteId)}>
						email
					</i>
				</div>
				&nbsp;
				<div data-tooltip='Cancel Invite' data-position='bottom'>
					<i className='material-icons-outlined boxed-icon' data-cy='cancelInviteBtn' onClick={() => this.openModal('isCancelModalOpen', inviteId)}>
						remove
					</i>
				</div>
			</div>
		);
	}

	openModal(type, inviteId) {
		this.setState({
			[type]: true,
			currentInviteId: inviteId,
		});
	}

	getEmailBox(email) {
		return (
			<div className='div-container'>
				<span>{email}</span>
			</div>
		);
	}

	getProfileBox(name, current) {
		return (
			<div className='div-container'>
				<span className={current && 'bold'}>
					{name} {current && '(You)'}
				</span>
			</div>
		);
	}

	getInviteType(inviteTypeId) {
		switch (inviteTypeId) {
			case InviteTypes.ADMIN.type: {
				return InviteTypes.ADMIN.message;
			}
			case InviteTypes.VIRTUALCAREPROVIDER.type: {
				return InviteTypes.VIRTUALCAREPROVIDER.message;
			}
			case InviteTypes.VIRTUALSITTER.type: {
				return InviteTypes.VIRTUALSITTER.message;
			}
			case InviteTypes.SUPERUSER.type: {
				return InviteTypes.SUPERUSER.message;
			}
			default:
				return '';
		}
	}

	getStatus(inviteStatusId) {
		switch (inviteStatusId) {
			case InviteStatuses.SENT.type: {
				return <Badge text={InviteStatuses.SENT.message} variant='gray' />;
			}
			case InviteStatuses.ACCEPTED.type: {
				return <Badge text={InviteStatuses.ACCEPTED.message} variant='green' />;
			}
			case InviteStatuses.PENDING.type: {
				return <Badge text={InviteStatuses.PENDING.message} variant='gray' />;
			}
			case InviteStatuses.CANCELLED.type: {
				return <Badge text={InviteStatuses.CANCELLED.message} variant='red' />;
			}
			case InviteStatuses.FAILED.type: {
				return <Badge text={InviteStatuses.FAILED.message} variant='red' />;
			}
			default:
				return '';
		}
	}

	handleSearchChanges = async event => {
		let searchValue = event.target.value;
		this.setState({ loading: true, pageIndex: 0, searchValue: searchValue });

		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(
			async function() {
				let response;
				if (getUserRole() === UserRoles.ADMIN) {
					response = await getInvitedMembers(this.state.pageSize.value, 0, searchValue);
				} else {
					response = await getHealthSystemInvitedUsers(this.state.currentHealthSystemId, this.state.pageSize.value, 0, searchValue);
				}

				this.setState({
					loading: false,
					invitedMembers: response.companyInvites,
					totalCount: response.totalCount,
				});
			}.bind(this),
			500
		);
	};

	handleCancelInvite = () => {
		let inviteIndex = this.state.invitedMembers.findIndex(invite => invite.id === this.state.currentInviteId);

		updateInviteStatus(this.state.currentInviteId, InviteStatuses.CANCELLED.type).then(() => {
			let items = [...this.state.invitedMembers];
			let item = {
				...items[inviteIndex],
				inviteStatusId: InviteStatuses.CANCELLED.type,
			};

			items[inviteIndex] = item;
			this.setState({ invitedMembers: items, currentInviteId: null, isCancelModalOpen: false });
		});
	};

	handleResendInvite = () => {
		let inviteIndex = this.state.invitedMembers.findIndex(invite => invite.id === this.state.currentInviteId);
		let invite = this.state.invitedMembers.find(i => i.id === this.state.currentInviteId);
		let healthSystemId = invite.invitationInfo ? invite.invitationInfo.teamId : null;

		if (invite.inviteTypeId === InviteTypes.ADMIN.type) {
			createInvite([invite.email], invite.inviteTypeId).then(() => {
				let items = [...this.state.invitedMembers];
				let item = {
					...items[inviteIndex],
					inviteStatusId: InviteStatuses.SENT.type,
				};

				items[inviteIndex] = item;
				this.setState({ invitedMembers: items, currentInviteId: null, isResendModalOpen: false });
			});
		} else {
			createNurseInvite([invite.email], invite.inviteTypeId, healthSystemId).then(() => {
				let items = [...this.state.invitedMembers];
				let item = {
					...items[inviteIndex],
					inviteStatusId: InviteStatuses.SENT.type,
				};

				items[inviteIndex] = item;
				this.setState({ invitedMembers: items, currentInviteId: null, isResendModalOpen: false });
			});
		}
	};

	render() {
		const invitedUsersTableHeaders = [
			{ title: 'Email' },
			{ title: 'Date Sent' },
			{ title: 'Invited By' },
			{ title: 'Health System' },
			// { title: 'Status' },
			{ title: 'Role' },
			{ title: '' },
		];
		const userRole = getUserRole();
		return (
			<div>
				<Table isLoading={this.state.loading} headers={invitedUsersTableHeaders} rows={this.state.loading ? [] : this.displayInvitedUsers()} isEditable={false}>
					<Grid columns={userRole === UserRoles.ADMIN ? '250px 10fr 1fr 1fr 1fr' : '250px 10fr 1fr'} vertAlign='center'>
						<Input
							validationOptions={{}}
							type='text'
							placeholder='Search Users'
							bottomSpace='0'
							variant='filled'
							name='searchUsers'
							onChange={this.handleSearchChanges}
							icon='search'
						/>
						<Button
							text='Add Virtual Sitter'
							variant='white'
							horizAlign='end'
							marginRight='10px'
							onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.VIRTUALSITTER.type)}
						/>
						<Button
							text='Add Virtual Care Provider'
							variant='white'
							horizAlign='end'
							marginRight='10px'
							onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.VIRTUALCAREPROVIDER.type)}
						/>
						{userRole === UserRoles.ADMIN && (
							<Button text='Add Super User' horizAlign='end' onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.SUPERUSER.type)} />
						)}
						{userRole === UserRoles.ADMIN && (
							<Button text='Add Admin' horizAlign='end' onClick={() => this.props.toggleSendInvitationsModal(InviteTypes.ADMIN.type)} />
						)}
					</Grid>
				</Table>
				<Pagination
					totalCount={this.state.totalCount}
					pageSize={this.state.pageSize}
					pageIndex={this.state.pageIndex}
					onChange={(pageSize, pageIndex) => this.onChange(pageSize, pageIndex)}
				/>
				<Modal
					modalSelector='cancelInviteModal'
					display={this.state.isCancelModalOpen}
					position='center'
					onModalSubmit={() => this.handleCancelInvite()}
					onModalClose={() => this.setState({ isCancelModalOpen: false })}>
					<form>
						<h3>Confirmation</h3>
						<p>Are you sure you want to cancel this invitation? The link sent to this invited user will be deactivated and cannot be used any longer.</p>
					</form>
				</Modal>
				<Modal
					modalSelector='resendInviteModal'
					display={this.state.isResendModalOpen}
					position='center'
					onModalSubmit={() => this.handleResendInvite()}
					onModalClose={() => this.setState({ isResendModalOpen: false })}>
					<form>
						<h3>Warning</h3>
						<p>Are you sure you want to resend this invitation? The user you are inviting will receive another email asking them to join AmWell.</p>
					</form>
				</Modal>
			</div>
		);
	}
}

export default InvitedUsersList;

import { LocalTrackController } from '@solaborate/webrtc/lib/TrackController';
import Emitter from './emitter';

export default class LocalParticipant extends Emitter {
	/**
	 * @param {object} data
	 * @param {string} data.id
	 * @param {string} data.name
	 * @param {number} data.objectId
	 * @param {number} data.objectType
	 * @param {string} data.picture
	 * @param {boolean} data.isInitiator
	 * @param {any} sfuConnection // { negotiate(offerTracks: any): Promise<void>; } | SfuConnection
	 * @param {LocalTrackController} localTrackController
	 */
	constructor(data, sfuConnection, localTrackController) {
		super();
		this.id = data.id;
		this.name = data.name;
		this.objectId = data.objectId;
		this.objectType = data.objectType;
		this.picture = data.picture || 'https://maxcdn.developershub.info/media/profile-pictures/150/150/duser.jpg'; // backup image
		this.isInitiator = data.isInitiator;
		// isInitiator changes but isOriginalOwner below does not change
		this.isOriginalOwner = data.isInitiator;

		this.sfuConnection = sfuConnection;
		this.localTrackController = localTrackController;
	}
}

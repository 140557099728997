import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import axios from 'axios';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

export const deleteOrgUnit = async (level, id) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/organizations/${APP_CONFIG.companyId}/level/${level}/id/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export function reAssignDevice({ hospitalId, departmentId, floorId, roomId, deviceId }) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(
				`/v1/organizations/${APP_CONFIG.companyId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices/${deviceId}/re-assign`
			)
			.then(response => {
				res(response.data.devices);
			})
			.catch(e => rej(e));
	});
}

export function getTreeData(currentHealthSystemId, currentRegionId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/regions/${currentRegionId}`)
			.then(response => {
				res(response.data.organization.healthSystem.hospitals);
			})
			.catch(e => rej(e));
	});
}

export function getSubTreeData(currentHealthSystemId, currentRegionId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${currentHealthSystemId}/regions/${currentRegionId}/subtree`)
			.then(response => {
				res(response.data.organization.healthSystem.hospitals);
			})
			.catch(e => rej(e));
	});
}

export function uploadLogo(fileInput, { x, y, width, height }) {
	const formData = new FormData();
	formData.append('file', fileInput, fileInput.name);
	formData.append('cropOffsetX', x.toFixed());
	formData.append('cropOffsetY', y.toFixed());
	formData.append('cropWidth', width.toFixed());
	formData.append('cropHeight', height.toFixed());

	const accessToken = getStorage().getItem('access_token');
	return new Promise(res => {
		axios({
			method: 'post',
			baseURL: APP_CONFIG.URL.gatewayApiBasePath,
			url: '/v1/media/pictures/organization-logo',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then(({ data }) => res({ data }))
			.catch(error => res({ error }));
	});
}

export async function saveLogo(teamId, data) {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/companies/${APP_CONFIG.companyId}/teams/${teamId}/picture`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export async function getLogoUrl(teamId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/companies/${APP_CONFIG.companyId}/teams/${teamId}/picture`)
			.then(response => {
				res(response.data.picture);
			})
			.catch(e => rej(e));
	});
}

/**
 * @function getSpecialties
 * @desc get specialties for this room.
 * @param {string} roomId, roomId for this department
 */
export async function getSpecialties(roomId) {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/rooms/${roomId}/specialties`);

		return data;
	} catch (error) {
		return { error };
	}
}

/**
 * @function updateSpecialties
 * @desc Updates a specialty
 * @param {string[]} specialties, array of specialties with updated names, example: Pediatric, Internal Medicine
 * @param {string} roomId, roomId for this department
 */
export async function updateSpecialties(roomId, specialties) {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${APP_CONFIG.companyId}/rooms/${roomId}/specialties`, {
			specialties,
		});
		return data;
	} catch (error) {
		return { error };
	}
}

export const getHealthSystemHospitals = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/healthsystems/${healthSystemId}/hospitals`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHospitalRooms = async (healthSystemId, hospitalId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/rooms`);
		return data;
	} catch (error) {
		return { error };
	}
};

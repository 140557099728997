import React from 'react';
import { Table, Grid, Button, Modal, Form, Input, Select as SelectTag, Alert } from 'components';
import Select from 'react-select';
import { getHealthSystems } from 'api/users';
import { getUserIdleConfigurations, getUserIdleConfigurationHealthSystems, getUserIdleConfigurationHistory } from 'api/userIdleConfigurations';
import moment from 'moment';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import Pagination from 'components/Common/Pagination';
import { getUserRole } from 'infrastructure/auth';
import { ConfigHistoryTypes, Roles, UserRoles } from 'constants/enums';
import { getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { Link } from 'react-router-dom';
import UserIdleForm from './UserIdleForm';

export default class UserIdleTimeout extends React.Component {
	state = {
		isLoading: true,
		isModalOpen: false,
		healthSystems: [],
		userIdleConfigurations: [],
		totalUserIdleConfigs: 0,
		editConfig: null,
		isConfigurationModalOpen: false,
		isHistoryView: false,
		historyPageSize: { value: 10, label: '10' },
		historyPageIndex: 0,
		selectedUserIdle: null,
		error: '',
	};

	transformTypes = {
		WithValues: 1,
		WithLabels: 2,
	};

	userIdleHeaders = [
		{ title: 'Health System' },
		{ title: 'Role' },
		{ title: 'Timeout Minutes' },
		{ title: 'Created by' },
		{ title: 'Date Created' },
		{ title: '' },
	];

	role = getUserRole();

	roles = Object.values(Roles);

	componentDidMount = async () => {
		this.getAllUserIdleConfigurations();
		this.getConfigurations();
	};

	componentDidUpdate = prevProps => {
		if (
			this.props.pageIndex !== prevProps.pageIndex ||
			this.props.pageSize !== prevProps.pageSize ||
			this.props.selectedHealthSystem !== prevProps.selectedHealthSystem ||
			this.props.selectedRole !== prevProps.selectedRole
		) {
			this.getConfigurations();
		}
	};

	onHealthSystemSelected = selectedHealthSystem => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedHealthSystem(selectedHealthSystem);
			}
		);
	};

	onRoleSelected = selectedRole => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.setSelectedRole(selectedRole);
			}
		);
	};

	onPaginationChange = (pageSize, pageIndex) => {
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				await this.props.onPaginationChange(pageSize.value, pageIndex);
			}
		);
	};

	getConfigurations = async (isSubmitted = false) => {
		if (isSubmitted) {
			this.setState({
				isLoading: true,
			});
		}
		const { userIdleConfigurations, total } = await getUserIdleConfigurations({
			pageSize: this.props.pageSize,
			pageIndex: this.props.pageIndex,
			healthSystemId: this.props.selectedHealthSystem.value === '0' ? null : this.props.selectedHealthSystem.value,
			roleId: this.props.selectedRole.value === '0' ? null : this.props.selectedRole.value,
		});

		const newState = {
			userIdleConfigurations,
			totalUserIdleConfigs: total,
			isLoading: false,
		};

		if (isSubmitted) {
			const result = await getUserIdleConfigurationHealthSystems();
			newState.healthSystemsWithConfigs = result.healthSystems;
		}

		this.setState(newState);
	};

	transformArray = (array, type, isHealthSystem = false, propName = 'name') => {
		if (type === this.transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item[propName] };
			});
			if (isHealthSystem) {
				if (this.role === UserRoles.ADMIN) {
					newArray.unshift({ id: '0', value: 'All' });
				}
			} else {
				newArray.unshift({ id: '0', value: 'All' });
			}
			return newArray;
		}
		if (type === this.transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item[propName] };
			});
			if (isHealthSystem) {
				if (this.role === UserRoles.ADMIN) {
					newArray.unshift({ value: '0', label: 'All' });
				}
			} else {
				newArray.unshift({ value: '0', label: 'All' });
			}
			return newArray;
		}
		return [];
	};

	openEditModal = config => {
		this.setState({
			editConfig: {
				healthSystem: config.team.id,
				role: config.role.id,
				minutes: config.timeoutMinutes,
			},
			isModalOpen: true,
		});
	};

	getUserIdleTimeoutHistory = async id => {
		const params = { id: id || this.state.selectedUserIdle, pageSize: this.state.historyPageSize.value, pageIndex: this.state.historyPageIndex };
		const result = await getUserIdleConfigurationHistory(params);
		if (!result.error) {
			this.setState({ userIdleConfigurations: result.history, isHistoryView: true, totalUserIdleConfigs: result.total, isLoading: false });
		}
	};

	getAllUserIdleConfigurations = async () => {
		const [healthSystemResponse] = await Promise.all([getHealthSystems()]);
		const responseError = healthSystemResponse.error;
		if (responseError) {
			this.setState({ error: 'Something went wrong' });
			return;
		}
		this.role = getUserRole();
		const currentHealthSystem = getCurrentHealthSystemInfo();
		this.setState({
			healthSystems:
				this.role === UserRoles.ADMIN
					? healthSystemResponse
					: [
							{
								id: currentHealthSystem.currentHealthSystemId,
								name: currentHealthSystem.currentHealthSystemName,
								ehrId: currentHealthSystem.ehrId,
								ehrTypeName: currentHealthSystem.ehrTypeName,
								tenantId: currentHealthSystem.tenantId,
							},
					  ],
		});
	};

	getButtons = config => {
		return (
			<div className='wrapped'>
				<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.UserIdle}`}>
					<span className='material-icons-outlined cursor-pointer mr-20' data-cy='viewHistory' data-tooltip='View history' data-position='top'>
						list_alt
					</span>
				</Link>
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-cy='edit'
					data-tooltip='Edit'
					data-position='top'
					onClick={() => this.openEditModal(config)}>
					create
				</span>
			</div>
		);
	};

	getStatus = config => {
		if (config.isActive) {
			return 'Current';
		}
		if (config.isDeleted) {
			return 'Deleted';
		}
		return '';
	};

	transformUserIdleConfigurations = () => {
		return this.state.userIdleConfigurations.map(item => {
			return {
				healthSystem: item.team.name,
				role: item.role.name,
				timeoutMinutes: item.timeoutMinutes,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: moment(utcToLocalTime(item.dateCreated)).format('MM/DD/YYYY-hh:mm A'),
				...(!this.state.isHistoryView && {
					edit: this.getButtons(item),
				}),
				...(this.state.isHistoryView && {
					current: this.getStatus(item),
				}),
			};
		});
	};

	toggleConfigurationModal = () => {
		this.setState({
			isConfigurationModalOpen: !this.state.isConfigurationModalOpen,
		});
	};

	render() {
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};

		return (
			<div>
				<Table isLoading={this.state.isLoading} headers={this.userIdleHeaders} rows={this.state.isLoading ? [] : this.transformUserIdleConfigurations()}>
					<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
						<Select
							value={this.props.selectedHealthSystem}
							placeholder='Select Health System..'
							isDisabled={this.role === UserRoles.SUPERUSER}
							classNamePrefix='custom-select'
							options={this.transformArray(this.props.healthSystems, this.transformTypes.WithLabels, true)}
							components={{ DropdownIndicator }}
							onChange={hs => this.onHealthSystemSelected(hs)}
						/>
						<Select
							value={this.props.selectedRole}
							placeholder='Select role..'
							classNamePrefix='custom-select'
							options={this.transformArray(this.roles, this.transformTypes.WithLabels, false, 'value')}
							components={{ DropdownIndicator }}
							onChange={role => this.onRoleSelected(role)}
						/>
						<Button text='Set time out' horizAlign='end' onClick={() => this.setState({ isModalOpen: true })} />
					</Grid>
				</Table>
				<Pagination
					totalCount={this.state.totalUserIdleConfigs}
					pageSize={{ value: this.props.pageSize, label: `${this.props.pageSize}` }}
					pageIndex={this.props.pageIndex}
					onChange={(pageSize, pageIndex) => this.onPaginationChange(pageSize, pageIndex)}
				/>
				<UserIdleForm
					isModalOpen={this.state.isModalOpen}
					initialValues={this.state.editConfig || null}
					isSuperUser={this.role === UserRoles.SUPERUSER}
					healthSystems={this.transformArray(this.state.healthSystems, this.transformTypes.WithValues, true)}
					roles={this.transformArray(this.roles, this.transformTypes.WithValues, false, 'value')}
					toggleModal={() => this.setState({ isModalOpen: !this.state.isModalOpen, editConfig: null })}
					onSucceeded={() => this.getConfigurations(true)}
				/>
				<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />
			</div>
		);
	}
}

import { gatewayApi, APP_CONFIG } from 'constants/global-variables';
import axios from 'axios';

export function getUserIdleConfigurations({ pageSize = 10, pageIndex = 0, healthSystemId = null, roleId = null }) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`v1/healthcare/organizations/${companyId}/configurations/user-idle`, {
				params: {
					pageSize,
					pageIndex,
					healthSystemId,
					roleId,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function addUserIdleConfiguration(healthSystemIds, roleId, timeoutMinutes) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		const config = {
			healthSystemIds,
			roleId,
			timeoutMinutes,
		};
		gatewayApi
			.post(`v1/healthcare/organizations/${companyId}/configurations/user-idle`, config)
			.then(respnse => {
				res(respnse.data);
			})
			.catch(e => rej(e));
	});
}

export function getUserIdleConfigurationHistory({ id, pageSize = 10, pageIndex = 0 }) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`v1/healthcare/organizations/${companyId}/configurations/user-idle/${id}/history`, {
				params: {
					pageSize,
					pageIndex,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getUserIdleConfigurationMinutes(healthSystemId, roleId) {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`/v1/healthcare/organizations/${companyId}/health-systems/${healthSystemId}/configurations/user-idle/minutes?roleId=${roleId}`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getTeamConfigurations(params) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/configurations/tv-device`, {
				params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function addDeviceConfiguration(teamId, data) {
	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/team/${teamId}/configurations/tv-device`, data)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getDeviceConfigurationHistory(teamId, params) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/team/${teamId}/configurations/tv-device/history`, {
				params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getUserIdleConfigurationHealthSystems() {
	return new Promise((res, rej) => {
		const { companyId } = APP_CONFIG;
		gatewayApi
			.get(`/v1/healthcare/organizations/${companyId}/health-systems/configurations/user-idle`, {
				params: {
					companyId,
				},
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function deleteTVDeviceConfiguration(teamId, id) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/healthcare/organizations/${APP_CONFIG.companyId}/team/${teamId}/configurations/tv-device/${id}`)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

import { AiAlertTypes, ManualAlertTypes } from 'constants/enums';
import { healthCareCdnUrl } from 'constants/global-variables';

const imageUrl = `${healthCareCdnUrl}vsm/`;

export const AiAlertActivityType = {
	AI_ALERT_SENT: 1,
	AI_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	AI_FAKE_ALERT: 6,
	AI_FORWARD_TO_NURSES: 7,
};

export const ManualAlertActivityTypes = {
	MANUAL_ALERT_SENT: 1,
	MANUAL_ALERT_RECEIVED: 2,
	ADDED_AS_AN_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	MANUAL_FAKE_ALERT: 6,
	MANUAL_FORWARD_TO_NURSES: 7,
	SAVED_ACTIVITY: 8,
};

export const getAiAlertType = type => {
	switch (type) {
		case AiAlertTypes.BABY_CRYING:
			return 'Baby is crying';
		case AiAlertTypes.BABY_FACE_DOWN:
			return 'Baby has face down';
		case AiAlertTypes.FALL_DETECTION:
			return 'Fall detected';
		case AiAlertTypes.PERSON_FACE_DOWN:
			return 'Patient has face down';
		case AiAlertTypes.PERSON_GETTING_OUT_OF_BED:
			return 'Patient is getting out of bed';
		case AiAlertTypes.PERSON_INACTIVE:
			return 'Patient is inactive';
		case AiAlertTypes.PERSON_LAYING_FRONT:
			return 'Patient laying front detected';
		case AiAlertTypes.PERSON_LAYING_SIDE:
			return 'Patient laying side detected';
		case AiAlertTypes.PERSON_SITTING:
			return 'Patient sitting detected';
		case AiAlertTypes.PERSON_STANDING:
			return 'Patient standing detected';
		case AiAlertTypes.RAIL_DOWN:
			return 'Rail down detected';
		default:
			return '';
	}
};

export const manualAlertType = [
	{
		id: 1,
		type: ManualAlertTypes.FALL_DETECTED,
		icon: `${imageUrl}FallDetection.svg`,
	},
	{
		id: 2,
		type: ManualAlertTypes.RISK_OF_FALL,
		icon: `${imageUrl}FallDetection.svg`,
	},
	{
		id: 3,
		type: ManualAlertTypes.PATIENT_NEEDS_HELP,
		icon: `${imageUrl}Heart-1.svg`,
	},
	{
		id: 4,
		type: ManualAlertTypes.PATIENT_REQUESTED_HELP,
		icon: `${imageUrl}Heart-1.svg`,
	},
	{
		id: 5,
		type: ManualAlertTypes.CUSTOM,
		icon: `${imageUrl}Heart-1.svg`,
	},
];

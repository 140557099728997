import React from 'react';
import Tabs from 'components/Tabs';
import styled from 'styled-components';
import AiAlerts from 'calls/components/monitoring/AiAlerts';
import ManualAlerts from 'calls/components/monitoring/ManualAlerts';

const Aside = styled.aside`
	padding-left: var(--spacing-sl) !important;

	> p {
		text-align: left;
		padding-top: 0;
		margin-bottom: var(--spacing-s);
	}
`;

const AlertsModal = ({ feed, onAiSettingsClick, setInactiveTimeToFeed, isAiDisabled }) => {
	return (
		<Aside>
			<p>{feed.name}</p>
			<Tabs
				links={[{ link: 'Ai Alerts', active: true }, { link: 'Care Events' }]}
				components={[
					<AiAlerts isAiDisabled={isAiDisabled} onAiSettingsClick={onAiSettingsClick} feed={feed} setInactiveTimeToFeed={setInactiveTimeToFeed} />,
					<ManualAlerts feed={feed} />,
				]}
			/>
		</Aside>
	);
};

export default AlertsModal;

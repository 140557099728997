let channel;
if ('BroadcastChannel' in window) {
	channel = new BroadcastChannel('userIdle');
}

export const postUserIdleMessage = msg => {
	if ('BroadcastChannel' in window) {
		channel.postMessage(msg);
	}
};

export const onUserIdleMessage = handler => {
	if ('BroadcastChannel' in window) {
		channel.onmessage = handler;
	}
};

export const closeUserIdleChannel = () => {
	if ('BroadcastChannel' in window && channel) {
		channel.close();
	}
};

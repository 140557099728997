import React, { Component } from 'react';
import moment from 'moment';
import { SocketContext } from 'io-client/SocketContext';
import { calculateDuration, utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { getUserProfile, getUserRole } from 'infrastructure/auth';
import { Table, Modal, Grid, Button, Alert } from 'components';
import Pagination from 'components/Common/Pagination';
import {
	ObjectType,
	CallStatus,
	DeviceListLevel,
	CallTypes,
	ParticipantState,
	UserRoles,
	TerminateRequestFailureReasonMessages,
	TerminateRequestFailureReasonEnum,
} from 'constants/enums';
import SocketEvents from 'constants/socket-events';
import { getDeviceOngoingConferencesByLevel, getOrgConferenceOngoingParticipants } from 'api/calllogs';
import { getHealthSystems } from 'api/users';

class OngoingCallsList extends Component {
	constructor(props, socket) {
		super(props);
		this.socket = socket;
		this.state = {
			logs: [],
			loading: true,
			pageSize: { value: 10, label: '10' },
			pageIndex: 0,
			totalCount: 0,
			currentLevel: DeviceListLevel.ORGANIZATION,
			currentTeamId: null,
			showTerminateCallModal: false,
			isTerminateCallModalLoading: false,
			terminateRequestFailureReason: null,
			terminateRequestSubmitButtonText: '',
			terminateRequestCloseButtonText: 'Cancel',
			terminateRequestInProgress: false,
			selectedConferenceId: null,
			showParticipantTerminateCallModal: false,
			selectedParticipantId: null,
			isListOfRoomsModalLoading: false,
			rooms: [],
			hasErrors: false,
		};
	}

	userProfile = getUserProfile();

	async componentDidMount() {
		this.bindSocketEvents();
		this.bindWindowEventListener();
		const userRole = getUserRole();
		const healthSystems = await getHealthSystems();
		// uncomment this if you need to use it
		// const healthSystemsOptions = healthSystems.map(hs => {
		// 	return { value: hs.id, label: hs.name };
		// });

		let currentLevel = DeviceListLevel.ORGANIZATION;
		let currentTeamId;
		if (userRole !== UserRoles.ADMIN) {
			currentLevel = DeviceListLevel.HEALTHSYSTEM;
			currentTeamId = healthSystems[0].id;
		}

		const result = await getDeviceOngoingConferencesByLevel(currentLevel, currentTeamId);
		const logs = this.getLogs(result.conferences);
		this.setState({
			logs,
			loading: false,
			totalCount: result.totalCount,
			pageIndex: result.pageIndex,
			currentLevel,
			currentTeamId,
			// healthSystems: healthSystemsOptions, // uncomment this if you need to use it
		});
	}

	componentWillUnmount() {
		this.clearTerminateRequestTimeout();
		this.unbindSocketEvents();
	}

	onTerminateRequestAcceptedHandler = data => {
		if (this.state.selectedConferenceId === data.conferenceId) {
			// stop the timeout for terminate request
			this.clearTerminateRequestTimeout();

			// remove conference from logs
			const logs = this.removeConferenceFromLogs(data.conferenceId);
			this.setState({
				isTerminateCallModalLoading: false,
				terminateRequestSubmitButtonText: '',
				terminateRequestInProgress: false,
				terminateRequestCloseButtonText: 'Close',
				logs,
			});
		}
	};

	bindSocketEvents() {
		this.socket.on(SocketEvents.Conference.ON_TERMINATE_REQUEST_ACCEPTED, this.onTerminateRequestAcceptedHandler);
	}

	unbindSocketEvents() {
		this.socket.off(SocketEvents.Conference.ON_TERMINATE_REQUEST_ACCEPTED, this.onTerminateRequestAcceptedHandler);
	}

	bindWindowEventListener() {
		window.addEventListener('beforeunload', event => {
			if (this.state.terminateRequestInProgress) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		});

		window.addEventListener('unload', () => {
			if (this.state.terminateRequestInProgress) {
				this.sendTerminateRequestCloseEvent();
			}
		});
	}

	getCallType(callType, initiator) {
		if (callType === CallTypes.AUDIO && initiator === 'Virtual Care Provider') {
			return 'Talk To Patient';
		}
		if (callType === CallTypes.AUDIO && initiator === 'Patient') {
			return 'Talk To Nurse';
		}
		if (callType === CallTypes.VIDEO) {
			return 'Video Call';
		}
		if (callType === CallTypes.SECURITYCAM) {
			return 'Patient View';
		}
		if (callType === CallTypes.MONITORING) {
			return 'Monitoring';
		}
		return undefined;
	}

	isSuccessfulCall(status) {
		if (status === CallStatus.FAILED.type || status === CallStatus.MISSED.type || status === CallStatus.ABORTED.type) {
			return false;
		}

		return true;
	}

	getVirtualCareProvider(participants, status) {
		if (status === CallStatus.SUCCESSFUL.type && participants.length > 2) {
			return participants.find(participant => {
				return (
					participant.objectType === ObjectType.USER &&
					participant.isInitiator(
						participant.state === ParticipantState.CONNECTED.type ||
							participant.state === ParticipantState.LEFT_CALL.type ||
							participant.state === ParticipantState.CONNECTING.type ||
							participants.state === ParticipantState.DISRUPTED.type
					)
				);
			});
		}

		return participants.find(participant => {
			return participant.objectType === ObjectType.USER;
		});
	}

	getPatientRoom(participants) {
		return participants.find(participant => participant.objectType === ObjectType.HELLO_DEVICE);
	}

	getCallStatus(status) {
		let result;
		switch (status) {
			case CallStatus.UNDEFINED.type: {
				result = CallStatus.UNDEFINED.message;
				break;
			}
			case CallStatus.SUCCESSFUL.type: {
				result = CallStatus.SUCCESSFUL.message;
				break;
			}
			case CallStatus.PARTIALLYSUCCESSFUL.type: {
				result = CallStatus.PARTIALLYSUCCESSFUL.message;
				break;
			}
			case CallStatus.FAILED.type: {
				result = CallStatus.FAILED.message;
				break;
			}
			case CallStatus.DISRUPTED.type: {
				result = CallStatus.DISRUPTED.message;
				break;
			}
			case CallStatus.PARTIALLYDISRUPTED.type: {
				result = CallStatus.PARTIALLYDISRUPTED.message;
				break;
			}
			case CallStatus.MISSED.type: {
				result = CallStatus.MISSED.message;
				break;
			}
			case CallStatus.ABORTED.type: {
				result = CallStatus.ABORTED.message;
				break;
			}
			case CallStatus.DROPPED.type: {
				result = CallStatus.DROPPED.message;
				break;
			}
			default: {
				break;
			}
		}
		return result;
	}

	getLogs(conferences) {
		const filtered = conferences.filter(conference => {
			return typeof this.getVirtualCareProvider(conference.participants, conference.status) !== 'undefined';
		});
		return filtered.map(conference => {
			const room = this.getPatientRoom(conference.participants);
			const vcp = this.getVirtualCareProvider(conference.participants, conference.status);
			const status = this.getCallStatus(conference.status);
			const startedAt = moment(utcToLocalTime(conference.startedAt)).format('MM/DD/YYYY-hh:mm A');
			const initiator = vcp?.isInitiator ? 'Virtual Care Provider' : 'Patient';
			return {
				id: conference.id,
				initiator: initiator,
				provider: room?.isInitiator && !this.isSuccessfulCall(conference.status) ? 'N/A' : vcp?.name,
				room: room?.name ?? 'N/A',
				startedAt: startedAt,
				origin: vcp?.isInitiator ? 'Outgoing' : 'Incoming',
				status: status,
				callType: this.getCallType(conference.callType, initiator),
				duration: conference.callDuration,
				usedAudio: { room: room?.usedAudio ?? 'N/A', vcp: vcp?.usedAudio ?? 'N/A' },
				usedVideo: { room: room?.usedVideo ?? 'N/A', vcp: vcp?.usedVideo ?? 'N/A' },
				usedScreen: { room: room?.usedScreen ?? 'N/A', vcp: vcp?.usedScreen ?? 'N/A' },
				participants: conference.participants,
				queue: room?.isInitiator ? conference.participants.filter(participant => participant.objectType === ObjectType.USER) : [],
			};
		});
	}

	openListOfRoomsModal = async conferenceId => {
		this.setState({
			selectedConferenceId: conferenceId,
			isListOfRoomsModalLoading: true,
			showListOfRoomsModal: true,
		});
		const { participants, error } = await getOrgConferenceOngoingParticipants(conferenceId);
		this.setState({ rooms: error ? [] : participants, isListOfRoomsModalLoading: false, showListOfRoomsModal: true, hasErrors: !!error });
	};

	getTableRows() {
		if (this.state.logs.length === 0) return [];
		return this.state.logs.map(log => {
			return {
				conferenceId: log.id,
				//initiator: log.initiator,
				provider: log.provider,
				room:
					log.callType === 'Monitoring' ? (
						<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openListOfRoomsModal(log.id)}>
							View All
						</span>
					) : (
						log.room
					),
				date: (
					<div style={{ fontSize: '12px' }}>
						<span style={{ display: 'block' }}>{log.startedAt}</span>
					</div>
				),
				// origin: log.origin,
				duration: log.duration,
				callType: log.callType,
				terminateCall: (
					<div className='wrapped'>
						<i className='material-icons-outlined boxed-icon' onClick={() => this.openForceTerminateCallModal(log.id)}>
							highlight_off
						</i>
					</div>
				),
			};
		});
	}

	onChange(pageSize, pageIndex) {
		this.setState({ loading: true }, () => {
			getDeviceOngoingConferencesByLevel(
				this.state.currentLevel,
				this.state.currentTeamId ? this.state.currentTeamId : undefined,
				pageSize.value,
				pageIndex
			).then(result => {
				let logs = this.getLogs(result.conferences);
				this.setState({
					logs: logs,
					loading: false,
					pageSize: pageSize,
					pageIndex: pageIndex,
					totalCount: result.totalCount,
				});
			});
		});
	}

	openParticipantForceTerminateCallModal(conferenceId, participantId) {
		this.setState({
			showParticipantTerminateCallModal: true,
			terminateRequestSubmitButtonText: 'End call for room',
			selectedParticipantId: participantId,
			selectedConferenceId: conferenceId,
			showListOfRoomsModal: false,
			isListOfRoomsModalLoading: false,
		});
	}

	closeParticipantTerminalCallModal = () => {
		if (this.state.terminateRequestInProgress) {
			this.sendTerminateRequestCloseEvent();
		}
		this.clearTerminateRequestTimeout();
		this.setState({
			showParticipantTerminateCallModal: false,
			isTerminateCallModalLoading: false,
			terminateRequestFailureReason: null,
			terminateRequestSubmitButtonText: '',
			terminateRequestCloseButtonText: 'Close',
			terminateRequestInProgress: false,
			selectedParticipantId: null,
		});
	};

	openForceTerminateCallModal(conferenceId) {
		this.setState({
			showTerminateCallModal: true,
			terminateRequestSubmitButtonText: 'End call',
			selectedConferenceId: conferenceId,
		});
	}

	openTerminateCallModal(conferenceId) {
		this.setState({
			showTerminateCallModal: true,
			terminateRequestSubmitButtonText: 'End call',
			selectedConferenceId: conferenceId,
		});
	}

	closeTerminalCallModal = () => {
		if (this.state.terminateRequestInProgress) {
			this.sendTerminateRequestCloseEvent();
		}
		this.clearTerminateRequestTimeout();
		this.setState({
			showTerminateCallModal: false,
			isTerminateCallModalLoading: false,
			terminateRequestFailureReason: null,
			terminateRequestSubmitButtonText: '',
			terminateRequestCloseButtonText: 'Close',
			terminateRequestInProgress: false,
			selectedConferenceId: null,
		});
	};

	onParticipantForceTerminalCallModalSubmit = () => {
		this.setState({
			isTerminateCallModalLoading: true,
			terminateRequestSubmitButtonText: 'Retry',
			terminateRequestInProgress: true,
		});

		const user = {
			fullName: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
		};
		this.socket.emit(SocketEvents.Conference.PARTICIPANT_FORCE_TERMINATE, { participantId: this.state.selectedParticipantId, user }, response => {
			if (!response.ok) {
				this.clearTerminateRequestTimeout();
				const newState = {
					isTerminateCallModalLoading: false,
					terminateRequestFailureReason: response.failureReason, // shows the reason
					terminateRequestSubmitButtonText: '', // hides submit the button
					terminateRequestInProgress: false,
				};
				if (response.failureReason === TerminateRequestFailureReasonEnum.NULL_CONFERENCE) {
					// remove conference from logs
					const logs = this.removeConferenceFromLogs(this.state.selectedConferenceId);
					Object.assign(newState, { logs, terminateRequestCloseButtonText: 'Close' });
				} else if (
					[TerminateRequestFailureReasonEnum.UNHANDLED_EXCEPTION, TerminateRequestFailureReasonEnum.NULL_CONNECTED_USER_PARTICIPANT].includes(
						response.failureReason
					)
				) {
					Object.assign(newState, { terminateRequestSubmitButtonText: 'Retry' });
				}

				this.setState(newState);
				return;
			}

			const logs = this.changeStatusOfParticipantLogs(this.state.selectedParticipantId);
			this.setState({
				isTerminateCallModalLoading: false,
				terminateRequestSubmitButtonText: '',
				terminateRequestInProgress: false,
				terminateRequestCloseButtonText: 'Close',
				logs,
			});
		});
	};

	onForceTerminalCallModalSubmit = async () => {
		this.setState({
			isTerminateCallModalLoading: true,
			terminateRequestSubmitButtonText: 'Retry',
			terminateRequestInProgress: true,
		});

		const user = {
			fullName: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
		};
		this.socket.emit(SocketEvents.Conference.FORCE_TERMINATE, { conferenceId: this.state.selectedConferenceId, user }, response => {
			if (!response.ok) {
				this.clearTerminateRequestTimeout();
				const newState = {
					isTerminateCallModalLoading: false,
					terminateRequestFailureReason: response.failureReason, // shows the reason
					terminateRequestSubmitButtonText: '', // hides submit the button
					terminateRequestInProgress: false,
				};
				if (response.failureReason === TerminateRequestFailureReasonEnum.NULL_CONFERENCE) {
					// remove conference from logs
					const logs = this.removeConferenceFromLogs(this.state.selectedConferenceId);
					Object.assign(newState, { logs });
					Object.assign(newState, { terminateRequestCloseButtonText: 'Close' });
				} else if (
					[TerminateRequestFailureReasonEnum.UNHANDLED_EXCEPTION, TerminateRequestFailureReasonEnum.NULL_CONNECTED_USER_PARTICIPANT].includes(
						response.failureReason
					)
				) {
					Object.assign(newState, { terminateRequestSubmitButtonText: 'Retry' });
				}

				this.setState(newState);
				return;
			}

			const logs = this.removeConferenceFromLogs(this.state.selectedConferenceId);
			this.setState({
				isTerminateCallModalLoading: false,
				terminateRequestSubmitButtonText: '',
				terminateRequestInProgress: false,
				terminateRequestCloseButtonText: 'Close',
				logs,
			});
		});
	};

	onTerminalCallModalSubmit = async () => {
		this.setState({
			isTerminateCallModalLoading: true,
			terminateRequestSubmitButtonText: 'Retry',
			terminateRequestInProgress: true,
		});

		this.socket.emit(SocketEvents.Conference.TERMINATE_REQUEST, { conferenceId: this.state.selectedConferenceId }, response => {
			if (!response.ok) {
				this.clearTerminateRequestTimeout();
				const newState = {
					isTerminateCallModalLoading: false,
					terminateRequestFailureReason: response.failureReason, // shows the reason
					terminateRequestSubmitButtonText: '', // hides submit the button
					terminateRequestInProgress: false,
				};
				if (response.failureReason === TerminateRequestFailureReasonEnum.NULL_CONFERENCE) {
					// remove conference from logs
					const logs = this.removeConferenceFromLogs(this.state.selectedConferenceId);
					Object.assign(newState, { logs });
					Object.assign(newState, { terminateRequestCloseButtonText: 'Close' });
				} else if (
					[TerminateRequestFailureReasonEnum.UNHANDLED_EXCEPTION, TerminateRequestFailureReasonEnum.NULL_CONNECTED_USER_PARTICIPANT].includes(
						response.failureReason
					)
				) {
					Object.assign(newState, { terminateRequestSubmitButtonText: 'Retry' });
				}

				this.setState(newState);
				return undefined;
			}

			this.terminateRequestTimeout = setTimeout(() => {
				this.clearTerminateRequestTimeout();
				this.setState({
					isTerminateCallModalLoading: false,
				});
			}, 30000);
			return undefined;
		});
	};

	clearTerminateRequestTimeout = () => {
		if (this.terminateRequestTimeout) {
			clearTimeout(this.terminateRequestTimeout);
			this.terminateRequestTimeout = null;
		}
	};

	changeStatusOfParticipantLogs = participantId => {
		const logs = [...this.state.logs];
		const log = logs.find(conference => conference.id === this.state.selectedConferenceId);
		const participant = log.participants.find(x => x.id === participantId);
		participant.state = ParticipantState.REMOVED.type;
		return logs;
	};

	sendTerminateRequestCloseEvent = () => {
		this.socket.emit(SocketEvents.Conference.TERMINATE_REQUEST_CANCEL, { conferenceId: this.state.selectedConferenceId });
	};

	removeConferenceFromLogs = conferenceId => {
		// remove conference from logs
		const logs = [...this.state.logs];
		const conferenceIndex = logs.findIndex(conference => conference.id === conferenceId);
		if (conferenceIndex !== -1) {
			logs.splice(conferenceIndex, 1);
		}
		return logs;
	};

	tableHeaders = [
		{ title: 'ID' },
		// { title: 'Call Initiator' },
		{ title: 'Virtual Care Provider' },
		{ title: 'Room' },
		{ title: 'Date/Time' },
		// { title: 'Call Origin' },
		{ title: 'Call Duration (so far)' },
		{ title: 'Call Type' },
		{ edit: '' },
	];

	getParticipantState(state) {
		switch (state) {
			case ParticipantState.CONNECTING.type: {
				return ParticipantState.CONNECTING.message;
			}
			case ParticipantState.CONNECTED.type: {
				return ParticipantState.CONNECTED.message;
			}
			case ParticipantState.BUSY.type: {
				return ParticipantState.BUSY.message;
			}
			case ParticipantState.DECLINED.type: {
				return ParticipantState.DECLINED.message;
			}
			case ParticipantState.OFFLINE.type: {
				return ParticipantState.OFFLINE.message;
			}
			case ParticipantState.LEFT_CALL.type: {
				return ParticipantState.LEFT_CALL.message;
			}
			case ParticipantState.NOT_ANSWERING.type: {
				return ParticipantState.NOT_ANSWERING.message;
			}
			case ParticipantState.CANT_CONNECT.type: {
				return ParticipantState.CANT_CONNECT.message;
			}
			case ParticipantState.DISRUPTED.type: {
				return ParticipantState.DISRUPTED.message;
			}
			case ParticipantState.REMOVING.type:
			case ParticipantState.REMOVED.type:
				return ParticipantState.REMOVED.message;
			default: {
				return 'N/A';
			}
		}
	}

	getParticipantDuration = (timeJoinedDate, participant) => {
		const isConnected = participant.state === ParticipantState.CONNECTED.type;
		if (!isConnected && !participant.endedTime) {
			return 'N/A';
		}
		return calculateDuration(timeJoinedDate, participant.state === ParticipantState.CONNECTED.type ? moment().utc() : utcToLocalTime(participant.endedTime));
	};

	showListOfRooms = () => {
		const headers = [{ title: 'Room' }, { title: 'Status' }, { title: 'Added at' }, { title: 'Duration' }, { title: '' }];
		const conference = this.state.logs.find(c => c.id === this.state.selectedConferenceId);
		if (!conference || !this.state.rooms) {
			// eslint-disable-next-line no-console
			return console.error('Error while getting list of rooms!');
		}

		const rooms = this.state.rooms.reduce((acc, participant) => {
			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				const timeJoinedDate = utcToLocalTime(participant.joinTime);
				acc.push({
					name: participant.fullName,
					status: this.getParticipantState(participant.state),
					timeJoined: moment(timeJoinedDate).format('MM/DD/YYYY-hh:mm A'),
					duration: this.getParticipantDuration(timeJoinedDate, participant),
					terminateCall: (
						<>
							{![ParticipantState.REMOVED.type, ParticipantState.REMOVING.type].includes(participant.state) && (
								<div className='wrapped'>
									<i className='material-icons-outlined boxed-icon' onClick={() => this.openParticipantForceTerminateCallModal(conference.id, participant.id)}>
										highlight_off
									</i>
								</div>
							)}
						</>
					),
				});
			}
			return acc;
		}, []);

		return <Table isLoading={this.state.isListOfRoomsModalLoading} headers={headers} rows={rooms} tableEmptyText='No rooms at the moment.' />;
	};

	render() {
		return (
			<div>
				<Table
					isLoading={this.state.loading}
					headers={this.tableHeaders}
					rows={this.state.loading ? [] : this.getTableRows()}
					tableEmptyText='No ongoing conferences at the moment.'>
					<Grid vertAlign='center'>
						<Button text='Refresh' isLoading={this.state.loading} horizAlign='end' onClick={() => this.onChange(this.state.pageSize, this.state.pageIndex)} />
					</Grid>
				</Table>

				<Pagination
					totalCount={this.state.totalCount}
					pageSize={this.state.pageSize}
					pageIndex={this.state.pageIndex}
					onChange={(pageSize, pageIndex) => this.onChange(pageSize, pageIndex)}
				/>

				<Modal
					isLoading={this.state.isTerminateCallModalLoading}
					display={this.state.showTerminateCallModal}
					position='center'
					submitButtonText={this.state.terminateRequestSubmitButtonText}
					closeButtonText={this.state.terminateRequestCloseButtonText}
					onModalSubmit={this.onForceTerminalCallModalSubmit}
					onModalClose={this.closeTerminalCallModal}>
					<form>
						{this.state.terminateRequestFailureReason === null && (
							<>
								{this.state.terminateRequestSubmitButtonText === 'End call' && (
									<>
										<h3>Warning</h3>
										<p>Are you sure you want to terminate this session? This will end the call between the care provider and the patient.</p>
									</>
								)}
								{this.state.terminateRequestSubmitButtonText === 'Retry' && (
									<>
										<h3>Terminate request timeout</h3>
										<p>Do you want to retry? This will end the call between the care provider and the patient.</p>
									</>
								)}

								{this.state.terminateRequestSubmitButtonText === '' && (
									<>
										<h3>Done</h3>
										<p>Session has been terminted successfully.</p>
									</>
								)}
							</>
						)}
						{this.state.terminateRequestFailureReason !== null && (
							<>
								<h3>Error</h3>
								<p>{TerminateRequestFailureReasonMessages[this.state.terminateRequestFailureReason]}</p>
							</>
						)}
					</form>
				</Modal>

				{/* room force terminate */}
				<Modal
					isLoading={this.state.isTerminateCallModalLoading}
					display={this.state.showParticipantTerminateCallModal}
					position='center'
					submitButtonText={this.state.terminateRequestSubmitButtonText}
					closeButtonText={this.state.terminateRequestCloseButtonText}
					onModalSubmit={this.onParticipantForceTerminalCallModalSubmit}
					onModalClose={this.closeParticipantTerminalCallModal}>
					<form>
						{this.state.terminateRequestFailureReason === null && (
							<>
								{this.state.terminateRequestSubmitButtonText === 'End call for room' && (
									<>
										<h3>Warning</h3>
										<p>Are you sure you want to terminate this room session? This will end the call between the care provider and the patient.</p>
									</>
								)}
								{this.state.terminateRequestSubmitButtonText === 'Retry' && (
									<>
										<h3>Terminate request timeout</h3>
										<p>Do you want to retry? This will end the call between the care provider and the patient.</p>
									</>
								)}

								{this.state.terminateRequestSubmitButtonText === '' && (
									<>
										<h3>Done</h3>
										<p>Room session has been terminted successfully.</p>
									</>
								)}
							</>
						)}
						{this.state.terminateRequestFailureReason !== null && (
							<>
								<h3>Error</h3>
								<p>{TerminateRequestFailureReasonMessages[this.state.terminateRequestFailureReason]}</p>
							</>
						)}
					</form>
				</Modal>

				<Modal
					display={this.state.showListOfRoomsModal}
					position='center'
					isLoading={this.state.isListOfRoomsModalLoading}
					hideActionButtons={true}
					onModalSubmit={this.state.showListOfRoomsModal}
					onModalClose={() => this.setState({ showListOfRoomsModal: false, selectedConferenceId: null, isListOfRoomsModalLoading: false })}>
					<form>
						<h3>List of Rooms</h3>
						{this.state.showListOfRoomsModal && this.showListOfRooms()}
					</form>
				</Modal>
				<Alert display={this.state.hasErrors} fixed={true} message='Something went wrong while fetching participants' variant='error' />
			</div>
		);
	}
}

OngoingCallsList.contextType = SocketContext;

export default OngoingCallsList;

/* eslint-disable import/prefer-default-export */
import { gatewayApi, legacyApi, APP_CONFIG } from 'constants/global-variables';
import { UserRoles } from 'constants/enums';
import { OverviewActionTypes } from 'constants/action-types';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

export const actionCreators = {
	toggleLeftNavigation: () => ({ type: OverviewActionTypes.toggleLeftNavigation }),
	setUserInfo: data => async (dispatch, getState) => {
		const userId = getStorage().getItem('userId');
		const url = `/v1/companies/organizations/${APP_CONFIG.companyId}/members/${userId}/roles`;
		const response = await gatewayApi.get(url);

		let userRole = UserRoles.NURSE;
		response.data.roles.forEach(role => {
			if (role.name === UserRoles.ADMIN) {
				userRole = UserRoles.ADMIN;
				return;
			} else if (role.name === UserRoles.VIRTUALSITTER) {
				userRole = UserRoles.VIRTUALSITTER;
				return;
			}
		});

		let userInfo = {
			role: userRole,
		};

		dispatch({ type: OverviewActionTypes.setUserInfo, payload: userInfo });
	},
	addNewUserRole: data => async (dispatch, getState) => {
		const url = `/v1.1/api/profile/UpdateUserWorkExperience`;
		return await legacyApi.post(url, data);
	},
	updateUserRole: data => async (dispatch, getState) => {
		const url = `/v1.1/api/profile/SaveUserCompany`;
		return legacyApi.post(url, data);
	},
};
